import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Label,
  Input,
  Row,
  Col,
  Button,
  ButtonGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function AvisosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_AVISOS = process.env.REACT_APP_URL_AVISOS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [alumnos, setAlumnos] = useState([]);
  const [selectedAlumno, setSelectedAlumno] = useState(0);
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(0);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(0);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(0);
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAlumnos = res.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const clear = () => {
    setSelectedPlantel("");
    setSelectedArea("");
    setSelectedGrado("");
    setSelectedClase("");
    setSelectedAlumno("");
    setSubject("");
    setText("");
  };

  let mailsAEnviar = alumnos
    .map((a) => {
      if (
        (selectedPlantel == 0 ||
          selectedPlantel == a.clases[0].planteles[0]._id) &&
        (selectedArea == 0 ||
          selectedArea == a.clases[0].grados[0].areas[0]._id) &&
        (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
        (selectedClase == 0 || selectedClase == a.clases[0]._id) &&
        (selectedAlumno == 0 || selectedAlumno == a._id)
      ) {
        return {
          email_papa: a.familias[0].email_papa,
          email_mama: a.familias[0].email_mama,
          apellidos:
            a.familias[0].apellido_paterno +
            " " +
            a.familias[0].apellido_materno,
        };
      }
    })
    .filter(function (el) {
      return el != null;
    });

  const groupByMailPapa = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        email_papa: item.email_papa,
      };
      groups[val].email_papa = item.email_papa;
      groups[val].email_mama = item.email_mama;
      groups[val].apellidos = item.apellidos;
      return groups;
    }, []);
  };
  const agrupadoMailsEnviar = groupByMailPapa(mailsAEnviar, "email_papa");
  const objectMails = Object.values(agrupadoMailsEnviar);

  function send() {
    objectMails.map((a) => {
      axios
        .post(
          URL_AVISOS,
          {
            subject,
            text,
            email: [a.email_papa, a.email_mama],
            nombre: [a.apellidos],
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios
          .post(
            URL_LOGS,
            {
              tipo:'Mail Masivo',
              detalle: `Titulo: ${subject} / Text: ${text}`,
              user: user.id
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          clear();
          Swal.fire("Good job!", "Enviado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    });
  }
  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card container">
        {user.avisos_create ? (
          <div className="card-body">
            <Row>
              <Col md={8}>
                <Button
                  size="sm"
                  href="/MenuAcademico"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Col>
            </Row>
            <h3 align="center">Envio de Notificaciones</h3>

            <Row>
              {user.planteles == PLANTELES_GENERAL ? (
                <Col md={2}>
                  <Label className="mr-sm-2">Planteles</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedPlantel}
                    onChange={(e) => setSelectedPlantel(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {planteles
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (a._id != PLANTELES_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              ) : (
                <Col md={2}>
                  <Label className="mr-sm-2">Planteles</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedPlantel}
                    onChange={(e) => setSelectedPlantel(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {planteles
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (a._id == user.planteles) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              )}
              {user.areas == AREAS_GENERAL ? (
                <Col md={2}>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    size="sm"
                    type="select"
                    value={selectedArea}
                    required
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (AREAS_GENERAL != a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              ) : (
                <Col md={2}>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    size="sm"
                    type="select"
                    value={selectedArea}
                    required
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              )}
              <Col md={2}>
                <Label className="mr-sm-2">Grados</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedGrado}
                  required
                  onChange={(e) => setSelectedGrado(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {grados
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (selectedArea == 0 || selectedArea == a.areas[0]._id) {
                        return (
                          <option value={a._id}>
                            {a.name} {a.areas[0].name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Clases</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedClase}
                  required
                  onChange={(e) => setSelectedClase(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {clases
                    .sort(
                      (a, b) =>
                        a.grados[0].name - b.grados[0].name ||
                        b.name - a.name ||
                        a.planteles[0].name - b.planteles[0].name
                    )
                    .map((a) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == a.planteles[0]._id) &&
                        (selectedArea == 0 ||
                          selectedArea == a.grados[0].areas[0]._id) &&
                        (selectedGrado == 0 || selectedGrado == a.grados[0]._id)
                      ) {
                        return (
                          <option value={a._id}>
                            {a.grados[0].name} {a.name}{" "}
                            {a.grados[0].areas[0].name} {a.planteles[0].name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Alumnos</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedAlumno}
                  required
                  onChange={(e) => setSelectedAlumno(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {alumnos
                    .sort((a, b) =>
                      a.familias[0].apellido_paterno >
                      b.familias[0].apellido_paterno
                        ? 1
                        : -1
                    )
                    .map((a) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == a.clases[0].planteles[0]._id) &&
                        (selectedArea == 0 ||
                          selectedArea == a.clases[0].grados[0].areas[0]._id) &&
                        (selectedGrado == 0 ||
                          selectedGrado == a.clases[0].grados[0]._id) &&
                        (selectedClase == 0 || selectedClase == a.clases[0]._id)
                      ) {
                        return (
                          <option value={a._id}>
                            {a.familias[0].apellido_paterno}{" "}
                            {a.familias[0].apellido_materno} {a.name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label for="exampleText">Asunto</Label>
                <Input
                  type="text"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="exampleText">Texto</Label>
                <Input
                  type="textarea"
                  onChange={(e) => setText(e.target.value)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
            <Button color="info" onClick={send}>
              Enviar
            </Button>
            </Col>
            </Row>
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default AvisosCreate;
