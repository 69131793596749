import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

function DonativosRecurrentesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DONATIVOS_RECURRENTES = process.env.REACT_APP_URL_DONATIVOS_RECURRENTES;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
  const URL_CONCEPTOS_DON = process.env.REACT_APP_URL_CONCEPTOS_DON;
  const URL_EVENTOS_DON = process.env.REACT_APP_URL_EVENTOS_DON;
  const URL_SALAS_DON = process.env.REACT_APP_URL_SALAS_DON;
  const URL_INSTITUCIONES_DON = process.env.REACT_APP_URL_INSTITUCIONES_DON;
  const [text, setText] = useState(false);

  const [donador, setDonador] = useState([]);
  const [conceptosDon, setConceptosDon] = useState([]);
  const [eventosDon, setEventosDon] = useState([]);
  const [salasDon, setSalasDon] = useState([]);
  const [institucionesDon, setInstitucionesDon] = useState([]);
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [frecuencia, setFrecuencia] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [importe_actual, setImporteActual] = useState("");
  const [selectedDonador, setSelectedDonador] = useState("");
  const [selectedConceptosDon, setSelectedConceptosDon] = useState("");
  const [selectedEventosDon, setSelectedEventosDon] = useState("");
  const [selectedSalasDon, setSelectedSalasDon] = useState("");
  const [selectedInstitucionesDon, setSelectedInstitucionesDon] = useState("");
  const [value, setValue] = useState('');
  const [validaBoton, setValidaBoton] = useState(true);

  const [mes1, setMes1] = useState("0");
  const [mes2, setMes2] = useState("0");

  const clear = () => {
    setDonador([]);
    setFechaInicio("");
    setDescripcion("");
    setImporteActual("");
    setFrecuencia('');
    setSelectedDonador("");
    setSelectedConceptosDon("");
    setSelectedSalasDon("");
    setSelectedEventosDon("");
    setSelectedInstitucionesDon("");
    setValidaBoton(true)
  };

  useEffect(() => {
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDonadores = res.data;
        setDonador(allDonadores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_CONCEPTOS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosDon = res.data;
        setConceptosDon(allConceptosDon);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_EVENTOS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allEventosDon = res.data;
        setEventosDon(allEventosDon);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_SALAS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSalasDon = res.data;
        setSalasDon(allSalasDon);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_INSTITUCIONES_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInstitucionesDon = res.data;
        setInstitucionesDon(allInstitucionesDon);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveDonativoFrecuente = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    axios
      .post(
        URL_DONATIVOS_RECURRENTES,
        {
          fecha_inicio,
          fecha_fin,
          frecuencia,
          descripcion,
          importe_actual,
          importe_anterior:0,
          ultimo_aumento:fecha_inicio,
          donadores: value._id,
          salasDon: selectedSalasDon,
          eventosDon: selectedEventosDon,
          institucionesDon: selectedInstitucionesDon,
          conceptosDon: selectedConceptosDon,
          mes1,
          mes2
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Donativo Recurrente',
            detalle: `${value._id}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  const options = donador.map((option) => {
    const junta = option.apellido + " " + option.nombre + " / " + option.nombre_comercial
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option, junta
    };
  })

  return (
    <>
      <Header />
      <br />
      {user.donativos_recurrentes_create  ?(
      <div className="container">
        <div className="card container col-sm-8">
          <h3 align="center">Crea un Donativo Recurrente</h3>
          <Form onSubmit={saveDonativoFrecuente}>
          <Row>
            <Col md={6}>
            <Label className="mr-sm-2">Donador</Label>
            <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedDonador) => {
                      setValue(selectedDonador);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => <TextField {...params} label="Selecciona" variant="outlined" />}
                    renderOption={(option) => (
                      <React.Fragment>
                    {option.apellido} {option.nombre} / {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
            </Col>
            <Col md={6}>
            <Label for="exampleEmail" className="mr-sm-12">
                    Frecuencia
                  </Label>
                  <Input
                    type="select"
                    placeholder="Frecuencia"
                    value={frecuencia}
                    required
                    onChange={(e) => {
                      setFrecuencia(e.target.value);
                    }}
                  >
                    <option value="0">Frecuencia</option>
                    <option value="Quincenal">Quincenal</option>
                    <option value="Mensual">Mensual</option>
                    <option value="Bimestral">Bimestral</option>
                    <option value="Trimestral">Trimestral</option>
                    <option value="Semestral">Semestral</option>
                    <option value="Anual">Anual</option>
                  </Input>
            </Col>
            </Row>
            {frecuencia == "Semestral" ?(
              <Row>
                            <Col md={6}>
            <Label for="exampleEmail" className="mr-sm-12">
                   Meses que se le cargara
                  </Label>
                  <Input
                    type="select"
                    placeholder="Mes1"
                    value={mes1}
                    required
                    onChange={(e) => {
                      setMes1(e.target.value);
                    }}
                  >
                    <option value="0">Mes 1</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </Input>
            </Col>
            <Col md={6}>
            <Label for="exampleEmail" className="mr-sm-12">
                  </Label>
                  <Input
                    type="select"
                    placeholder="Mes2"
                    value={mes2}
                    required
                    onChange={(e) => {
                      setMes2(e.target.value);
                    }}
                  >
                    <option value="0">Mes 1</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </Input>
            </Col>
              </Row>
            ):undefined}
            
            {frecuencia == "Anual" ?(
              <Row>
                            <Col md={6}>
            <Label for="exampleEmail" className="mr-sm-12">
                   Mes que se le cargara
                  </Label>
                  <Input
                    type="select"
                    placeholder="Mes1"
                    value={mes1}
                    required
                    onChange={(e) => {
                      setMes1(e.target.value);
                    }}
                  >
                    <option value="0">Mes 1</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </Input>
            </Col>
              </Row>
            ):undefined}
            <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Sala</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSalasDon}
                  required
                  onChange={(e) => {
                    setSelectedSalasDon(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Sala</option>
                  {salasDon.map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
              <Col md={6}>
                <Label className="mr-sm-2">Institucion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedInstitucionesDon}
                  required
                  onChange={(e) => {
                    setSelectedInstitucionesDon(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Institucion</option>
                  {institucionesDon.map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Evento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedEventosDon}
                  required
                  onChange={(e) => {
                    setSelectedEventosDon(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Evento</option>
                  {eventosDon
                  .sort((a, b) => a.name > b.name ? 1 : -1)
                  .map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
              <Col md={6}>
                <Label className="mr-sm-2">Concepto</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedConceptosDon}
                  required
                  onChange={(e) => {
                    setSelectedConceptosDon(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Concepto</option>
                  {conceptosDon
                  .sort((a, b) => a.name > b.name ? 1 : -1)
                  .map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label>Importe</Label>
                <Input
                  className="col-sm-12"
                  type="number"
                  placeholder="Importe"
                  value={importe_actual}
                  required
                  onChange={(e) => {
                    setImporteActual(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label>Fecha Inicio</Label>
                <Input
                  type="date"
                  value={fecha_inicio}
                  required
                  onChange={(e) => {
                    setFechaInicio(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
            <Col md={6}>
                <Label>Fecha Fin</Label>
                <Input
                  type="date"
                  value={fecha_fin}
                  required
                  onChange={(e) => {
                    setFechaFin(e.target.value);
                  }}
                />
              </Col>

              <Col md={6}>
              <Label className="mr-sm-2">Descripcion</Label>
            <Input
              className="col-sm-12"
              type="text"
              placeholder="Descripcion"
              value={descripcion}
              required
              onChange={(e) => {
                setDescripcion(e.target.value);
              }}
            />
              </Col>
            </Row>
          
           
            <Row>
            {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Registrar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Registrar
      </Button>)}
              <div>
                <SweetAlert
                  show={text}
                  title="Creado con Exito"
                  // text="SweetAlert in React"
                  onConfirm={() => {
                    setText(false);
                  }}
                />
              </div>
              <Button
                // Editar Ruta
                href="/DonativosRecurrentes"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
        </div>
      </div>
      ): undefined } 
    </>
  );
}

export default DonativosRecurrentesCreate;
