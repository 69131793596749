import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import EdoCtaFamInfo from "../EdoCtaFamInfo";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ConveniosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CONVENIOS = process.env.REACT_APP_URL_CONVENIOS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_ABONOS_COLEGIATURAS = process.env.REACT_APP_URL_ABONOS_COLEGIATURAS;
  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [fecha, setFecha] = useState("");
  const [saldo, setSaldo] = useState(0);
  const [parcialidad, setParcialidad] = useState(0);
  const [numero_pagos, setNumeroPagos] = useState(0);
  const [importe, setImporte] = useState(0);
  const [concepto, setConcepto] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);
  const [value, setValue] = useState("");
  const [alumnos, setAlumnos] = useState([])

  const [idFamilia, setIDFamilia] = useState("");
  const [nameFamilia, setNameFamilia] = useState("");
  const [emailPapa, setEmailPapa] = useState("");
  const [emailMama, setEmailMama] = useState("");

  const [modalSaldo, setModalSaldo] = useState(false);
  const toggleSaldo = () => setModalSaldo(!modalSaldo);

  useEffect(() => {
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveConvenios = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_CONVENIOS,
        {
          fecha,
          familias: selectedFamilia,
          numero_pagos,
          importe,
          parcialidad,
          concepto,
          observaciones
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        if(concepto == "Colegiatura"){
        //   axios.post(
        //   URL_LOGS,
        //   {
        //     tipo: "Crear Departamento",
        //     detalle: name,
        //     user: user.id,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //     },
        //   }
        // );
        }else{
          console.log("Inscripcion")
        }
        // axios.post(
        //   URL_LOGS,
        //   {
        //     tipo: "Crear Departamento",
        //     detalle: name,
        //     user: user.id,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //     },
        //   }
        // );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          // window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  function jalaInfo() {
    setIDFamilia(selectedFamilia);
    // setNameFamilia(nameFamilia);
    // setEmailPapa(emailPapa);
    // setEmailMama(emailMama);
    toggleSaldo();
  }

  function calculaParcialidadNumero(numero){
    setNumeroPagos(numero)
    if(importe>0){
    setParcialidad(parseFloat(importe/numero).toFixed(2))}
  }
  function calculaParcialidadImporte(importe){
    setImporte(importe)
    if(numero_pagos>0){
    setParcialidad(parseFloat(importe/numero_pagos).toFixed(2))}
  }

  const selectFamilia = (e)=>{
    setSelectedFamilia(e.target.value);
  const URL_ALUMNOS_FAM = `${process.env.REACT_APP_URL_ALUMNOS}/familia/${value._id}`
  axios
  .get(URL_ALUMNOS_FAM, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allAlumnos = response.data;
    setAlumnos(allAlumnos);
  })
  .catch((err) => {
    console.log(err);
  });
}

const options = familias.map((option) => {
  const junta =
   option.codigo_familia + " " + option.apellido_paterno + " " + option.apellido_materno;
  const firstLetter = option.apellido_paterno[0].toUpperCase();
  return {
    firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
    ...option,
    junta,
  };
});
  return (
    <> 
    <Header />
    <br />
    <br />
      <div className="card container">
        <h3 align="center">Crea un nuevo Convenio</h3>
        <Form onSubmit={saveConvenios}>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Row>
          <Col md={4}>
          <Label className="mr-sm-2">
            Fecha de Inicio
          </Label>
          <Input
            type="date"
            value={fecha}
            required
            onChange={(e) => {
              setFecha(e.target.value);
            }}
          />
          </Col>
          <Col md={4}>
          <Label className="mr-sm-2">Familia</Label>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedFamilia) => {
                      setValue(selectedFamilia)
                    }}
                    onBlur={selectFamilia}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo_familia} {option.apellido_paterno} {option.apellido_materno}
                      </React.Fragment>
                    )}
                  />
                  </Col>
                  {/* <Col md={2}>
                <Label className="mr-sm-2">
                  Saldo
                </Label>
                <Input
                  type="text"
                  value={saldo}
                  disabled
                  onChange={(e) => {
                    setSaldo(e.target.value);
                  }}
                />
                </Col> */}
                <Col md={2}>
                <Label className="mr-sm-2">
                  {/* Estado de Cuenta */}
                </Label>
                <br />
                <Button
                  color="info"
                  size="sm"
                  onClick={(e) =>
                    jalaInfo()
                  }
                >
                  {/* <i class="fas fa-file-invoice-dollar"></i> */}
                  Estado de Cuenta
                </Button>
                </Col>
                </Row>
            
                <Row>
                <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Importe
                    </Label>
                    <Input
                      type="number"
                      placeholder="Importe"
                      value={importe}
                      required
                      onChange={(e) => {
                        calculaParcialidadImporte(e.target.value);
                      }}
                    />
                  </Col>
                <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Numero de Pagos
                    </Label>
                    <Input
                      type="number"
                      placeholder="Numero de Pagos"
                      value={numero_pagos}
                      required
                      onChange={(e) => {
                        calculaParcialidadNumero(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                <Label className="mr-sm-2">
                  Importe de cada cargo
                </Label>
                <Input
                  type="text"
                  value={parcialidad}
                  disabled
                  onChange={(e) => {
                    setParcialidad(e.target.value);
                  }}
                />
                </Col>
                  </Row>
                  <Row>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Concepto
                    </Label>
                    <Input
                      type="select"
                      placeholder="Concepto"
                      value={concepto}
                      required
                      onChange={(e) => {
                        setConcepto(e.target.value);
                      }}
                    >
                      <option value="0">Concepto</option>
                      <option value="Colegiatura">Colegiatura</option>
                      <option value="Inscripcion">Inscripcion</option>
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="textarea"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>
          <br />
          <Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                // text="SweetAlert in React"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button
              href="/ListadoConvenios"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
        <Modal size="xl" isOpen={modalSaldo} toggle={toggleSaldo}>
                  <ModalHeader toggle={toggleSaldo}>
                    <h4>Estado de Cuenta {nameFamilia}</h4>
                  </ModalHeader>
                  <ModalBody>
                    <EdoCtaFamInfo
                      idFamilia={idFamilia}
                      nameFamilia={nameFamilia}
                      emailPapa={emailPapa}
                      emailMama={emailMama}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={toggleSaldo}>
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>
      </div>
    </>
  );
}

export default ConveniosCreate;
