import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import { Col, Row, Label, Input, Button } from "reactstrap";
import GraficasGastos from "./GraficasGastos";
import GraficasNominas from "./GraficasNominas";
import GraficasEgresosBarras from "./GraficasEgresosBarras";
import GraficasEgresosPie from "./GraficasEgresosPie";
import GraficasEgresosPieGas from "./GraficasEgresosPieGas";
import GraficasEgresosPieNom from "./GraficasEgresosPieNom";
import GraficasEgresosPieGasArea from "./GraficasEgresosPieGasArea";
import GraficasEgresosPieNomArea from "./GraficasEgresosPieNomArea";
import GraficasEgresosPieGasDep from "./GraficasEgresosPieGasDep";
import Loading from "../../Loading";

function GraficasCarteraEgresos() {
  const { user } = useContext(AuthContext);
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [loading, setLoading] = useState(false);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [Refresh, setRefresh] = useState(false);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  

  const cambiarEstado = () => {
    setLoading(true);
    setRefresh(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  if (
    loading &&
    user.menu_reportes &&
    user.menu_egresos
  ) {
    return (
      <>
        <Header />
        <div className="container">
          <br />
          <div className="card container col-6" style={{ paddingLeft: "70px" }}>
            <br />
            <h3 align="center">Reporte de Egresos</h3>
            <Row>
              <Col md={4}>
                <Label>Fecha Inicio</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaInicio}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Fecha Fin</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaFin}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedFechaFin(e.target.value);
                  }}
                />
              </Col>
              </Row>
              <Row>
                <Col md={4}>
                <Label>Plantel</Label>
                {user.planteles == PLANTELES_GENERAL?
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedPlantel(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {planteles
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (a._id != PLANTELES_GENERAL) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>:
                  <Input
                  bsSize="sm"
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedPlantel(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {planteles
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (user.planteles == a._id) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>  }
              </Col>
               <Col md={4}>
                <Label>Area</Label>
                {user.areas == AREAS_GENERAL?
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedArea(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (a._id != AREAS_GENERAL) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>:
                  <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedArea(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (user.areas == a._id) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>  }
              </Col>
              <Col md={2}>
                <Label></Label>
                <Button size="sm" onClick={() => cambiarEstado()}>
                  Cargar
                </Button>
              </Col>
              </Row>
            <br />
          </div>
        </div>
        <Loading />
      </>
    );
  } else {
  return (
    <>
      <Header />
      {user.menu_reportes && user.menu_egresos ?(
        <div className="container">
          <br />
          <div className="card container col-6" style={{ paddingLeft: "70px" }}>
            <br />
            <h3 align="center">Reporte de Egresos</h3>
            <Row>
              <Col md={4}>
                <Label>Fecha Inicio</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaInicio}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Fecha Fin</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaFin}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedFechaFin(e.target.value);
                  }}
                />
              </Col>
              </Row>
              <Row>
              <Col md={4}>
                <Label>Plantel</Label>
                {user.planteles == PLANTELES_GENERAL?
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedPlantel(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {planteles
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (a._id != PLANTELES_GENERAL) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>:
                  <Input
                  bsSize="sm"
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedPlantel(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {planteles
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (user.planteles == a._id) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>  }
              </Col>
               <Col md={4}>
                <Label>Area</Label>
                {user.areas == AREAS_GENERAL?
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedArea(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (a._id != AREAS_GENERAL) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>:
                  <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {
                    setRefresh(false);
                    setSelectedArea(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (user.areas == a._id) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>  }
              </Col>
              <Col md={2}>
                <Label></Label>
              <Button size="sm" onClick={() => cambiarEstado()}>Cargar</Button>
              </Col>
            </Row>
            <br />
          </div>
      <br />
      <Row>
          {Refresh && (
            <div className="card container">
              <div>
                <h3 align="center">Resumen Egresos</h3>
              </div>
                <GraficasEgresosBarras
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
            </div>
            )}
          </Row>
          <Row>
          {Refresh && (
            <div className="card container">
              <div>
                <h3 align="center">Resumen Egresos</h3>
              </div>
                <GraficasEgresosPie
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
            </div>
            )}
          </Row>
          <div className=" container">
            <Row>
              <Col md={4}>
                  {Refresh && (
                <div className="card container">
                  <div>
                    <h4 align="center">Gastos por Plantel</h4>
                  </div>
                <GraficasEgresosPieGas
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
                </div>
              )}
              </Col>
              <Col md={4}>
                  {Refresh && (
                <div className="card container">
                  <div>
                    <h4 align="center">Gastos por Area</h4>
                  </div>
                <GraficasEgresosPieGasArea
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
                </div>
              )}
              </Col>
              <Col md={4}>
                  {Refresh && (
                <div className="card container">
                  <div>
                    <h4 align="center">Gastos por Departamento</h4>
                  </div>
                <GraficasEgresosPieGasDep
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
                </div>
              )}
              </Col>
            </Row>
          </div>
          <div className=" container">
            <Row>
              <Col md={6}>
                  {Refresh && (
                <div className="card container">
                  <div>
                    <h4 align="center">Nominas por Plantel</h4>
                  </div>
                <GraficasEgresosPieNom
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
                </div>
              )}
              </Col>
              <Col md={6}>
                  {Refresh && (
                <div className="card container">
                  <div>
                    <h4 align="center">Nominas por Area</h4>
                  </div>
                <GraficasEgresosPieNomArea
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
                </div>
              )}
              </Col>
            </Row>
          </div>
          <div className=" container">
            <Row>
              <Col md={6}>
                  {Refresh && (
                <div className="card container">
                  <div>
                    <h4 align="center">Gastos por dia</h4>
                  </div>
                <GraficasGastos
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
                </div>
              )}
              </Col>
              <Col md={6}>
                  {Refresh && (
                <div className="card container">
                  <div>
                    <h4 align="center">Nominas por dia</h4>
                  </div>
                <GraficasNominas
                  fechaInicio={selectedFechaInicio}
                  fechaFin={selectedFechaFin}
                  selectedPlantel={selectedPlantel}
                  selectedArea={selectedArea}
                />
                </div>
              )}
              </Col>
            </Row>
          </div>
        </div> 
      ) : undefined}
    </>
  )
}
}

export default GraficasCarteraEgresos;
