import React, { useState, useEffect, useContext  } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import TablaCartera from "./TablaCartera";
import axios from "axios";
import { Badge, Row, Col, Button, Label, Input, ButtonGroup } from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"

function VistaCartera() {
  const { user } = useContext(AuthContext);
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_ABONOS_DONATIVOS = process.env.REACT_APP_URL_ABONOS_DONATIVOS;
  const URL_ABONOS_COLEGIATURAS = process.env.REACT_APP_URL_ABONOS_COLEGIATURAS;
  const URL_ABONOS_INSCRIPCIONES =
    process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
  const URL_NOMINAS = process.env.REACT_APP_URL_NOMINAS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const [nominas, setNominas] = useState([]);
  const [gastos, setGastos] = useState([]);
  const [donativos, setDonativos] = useState([]);
  const [colegiaturas, setColegiaturas] = useState([]);
  const [inscripciones, setInscripciones] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("0");
  const [selectedFechaFin, setSelectedFechaFin] = useState('0');
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  useEffect(() => {
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_DONATIVOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonativos = response.data;
        setDonativos(allDonativos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_COLEGIATURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColegiaturas = response.data;
        setColegiaturas(allColegiaturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_INSCRIPCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInscripciones = response.data;
        setInscripciones(allInscripciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_NOMINAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNominas = response.data;
        setNominas(allNominas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGastos = response.data;
        setGastos(allGastos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaFin, selectedFechaInicio]);

  const arrayColegiaturas = colegiaturas.map((n) => {
    return {
      fecha: n.fecha,
      movimiento: "Colegiatura",
      name: [n.familias[0].codigo_familia + " " + n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno],
      importe: n.importe,
      plantel: n.planteles[0].name,
      idPlantel: n.planteles[0]._id,
      idArea: n.areas[0]._id,
      area: n.areas[0].name,
    };
  });

  const arrayInscripciones = inscripciones.map((n) => {
    return {
      fecha: n.fecha,
      movimiento: "Inscripcion",
      name: [n.familias[0].codigo_familia + " " + n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno],
      importe: n.importe,
      plantel: n.planteles[0].name,
      area: n.areas[0].name,
      idPlantel: n.planteles[0]._id,
      idArea: n.areas[0]._id,
    };
  });

  const arrayDonativos = donativos.map((n) => {
    return {
      fecha: n.fecha,
      movimiento: "Donativo",
      name: [n.donadores[0].nombre + " "+ n.donadores[0].apellido],
      importe: n.importe,
      plantel: 'General',
      area: 'General',
      idPlantel: PLANTELES_GENERAL,
      idArea: AREAS_GENERAL,
    };
  });

  const arrayIngresos = arrayColegiaturas.concat(
    arrayInscripciones,
    arrayDonativos
  );
  const arrayFinalIngresos = arrayIngresos.filter(function (el) {
    return el != null;
  });

  let importesIngresos = arrayFinalIngresos.map((i)=>{
    if (user.planteles == PLANTELES_GENERAL &&
      user.areas == AREAS_GENERAL){
      if(
      (selectedPlantel == 0 ||
        selectedPlantel == i.idPlantel) &&
      (selectedArea == 0 ||
        selectedArea == i.idArea) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ) {
      return i.importe
    }else return 0} else {if(
      user.planteles == i.idPlantel && user.areas == i.idArea &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ){
      return i.importe
    } else return 0}
  })

   let totalIngresos = importesIngresos.reduce(
    (total, importe, index) => total + importe, 0);


  const arrayNominas = nominas.map((n) => {
    if (n.pagado=="Si") {
      return {
        fecha: n.fecha,
        movimiento: "Nomina",
        col_pro: [n.colaboradores[0].nombre + " " + n.colaboradores[0].apellido],
        importe: n.importe,
        plantel: n.planteles[0].name,
        area: n.areas[0].name,
        idPlantel: n.planteles[0]._id,
        idArea: n.areas[0]._id,
      };
    }
  });

  const arrayGastos = gastos.map((g) => {
    if (g.pagado=="Si") {
      return {
        fecha: g.fecha,
        movimiento: "Gasto",
        col_pro: g.proveedores[0].nombre_comercial,
        importe: g.total,
        plantel: g.presupuestos[0].planteles[0].name,
        area: g.presupuestos[0].areas[0].name,
        idPlantel: g.presupuestos[0].planteles[0]._id,
        idArea: g.presupuestos[0].areas[0]._id,
      };
    }
  });

  const arrayEgresos = arrayNominas.concat(arrayGastos);
  const arrayFinalEgresos = arrayEgresos.filter(function (el) {
    return el != null;
  });

  let importesEgresos = arrayFinalEgresos.map((i)=>{
    if (user.planteles == PLANTELES_GENERAL &&
      user.areas == AREAS_GENERAL){
      if(
      (selectedPlantel == 0 ||
        selectedPlantel == i.idPlantel) &&
      (selectedArea == 0 ||
        selectedArea == i.idArea) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ) {
      return i.importe
    }else return 0} else {if(
      user.planteles == i.idPlantel && user.areas == i.idArea &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ){
      return i.importe
    } else return 0}
  })


   let totalEgresos = importesEgresos.reduce(
    (total, importe, index) => total + importe, 0);
  

let totalIngresosPDF = 0

  function PDFTablaIngresos() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    totalIngresosPDF = 0;
    const data = arrayFinalIngresos.map((i) => {
        if (user.planteles == PLANTELES_GENERAL &&
          user.areas == AREAS_GENERAL){
      if (
        (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
        (selectedPlantel == 0 ||
          selectedPlantel == i.idPlantel) &&
        (selectedArea == 0 ||
          selectedArea == i.idArea) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= i.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
      ) {
        totalIngresosPDF = totalIngresosPDF + i.importe;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(i.importe);
        return [
          i.fecha,
          i.movimiento,
          i.name,
          i.plantel,
          i.area,
          importePDF
        ];
      } else return null} else{ if(
        (i.idPlantel == user.planteles && i.idArea == user.areas) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= i.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
      ){
        totalIngresosPDF = totalIngresosPDF + i.importe;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(i.importe);
        return [
          i.fecha,
          i.movimiento,
          i.name,
          i.plantel,
          i.area,
          importePDF
        ]}
        else return null}
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Ingresos del ${selectedFechaInicio} al ${selectedFechaFin}`, 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Nombre",
          "Plantel",
          "Area",
          "Importe"
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalIngresosPDF),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Ingresos-${selectedFechaInicio}-${selectedFechaFin}.pdf`);
  }

  function excelIngresos(){
    const dataExcel =  arrayFinalIngresos.map((i) => {
      if (user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL){
    if (
      (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
      (selectedPlantel == 0 ||
        selectedPlantel == i.idPlantel) &&
      (selectedArea == 0 ||
        selectedArea == i.idArea) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ) {
      return {
        Fecha: i.fecha,
        Movimiento: i.movimiento,
        Nombre: i.name,
        Plantel: i.plantel,
        Area: i.area, 
        Importe: i.importe
      }
    } else return null} else{ if(
      (i.idPlantel == user.planteles && i.idArea == user.areas) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ){
      return {
        Fecha: i.fecha,
        Movimiento: i.movimiento,
        Nombre: i.name,
        Plantel: i.plantel,
        Area: i.area, 
        Importe: i.importe
      }}
      else return null}
  });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = `Ingresos-${selectedFechaInicio}-${selectedFechaFin}`;

option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: `Ingresos`,
    sheetFilter: [
          "Fecha",
          "Movimiento",
          "Nombre",
          "Plantel",
          "Area",
          "Importe"
        ],
    sheetHeader: [
          "Fecha",
          "Movimiento",
          "Nombre",
          "Plantel",
          "Area",
          "Importe"
        ],
  },
];
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

  let totalEgresosPDF = 0

  function PDFTablaEgresos() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    totalEgresosPDF = 0;
    const data = arrayFinalEgresos.map((i) => {
      if (user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL){
    if (
      (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
      (selectedPlantel == 0 ||
        selectedPlantel == i.idPlantel) &&
      (selectedArea == 0 ||
        selectedArea == i.idArea) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ) {
      totalEgresosPDF = totalEgresosPDF + i.importe;
      const importePDF = "$" + new Intl.NumberFormat("en-US").format(i.importe);
      return [
        i.fecha,
        i.movimiento,
        i.col_pro,
        i.plantel,
        i.area,
        importePDF
      ];
    } else return null} else{ if(
      (i.idPlantel == user.planteles && i.idArea == user.areas) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ){
      totalEgresosPDF = totalEgresosPDF + i.importe;
      const importePDF = "$" + new Intl.NumberFormat("en-US").format(i.importe);
      return [
        i.fecha,
        i.movimiento,
        i.col_pro,
        i.plantel,
        i.area,
        importePDF
      ]}
      else return null}
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
    const doc = new jsPDF('');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Egresos del ${selectedFechaInicio} al ${selectedFechaFin}`, 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Nombre",
          "Plantel",
          "Area",
          "Importe"
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalEgresosPDF),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Egresos-${selectedFechaInicio}-${selectedFechaFin}.pdf`);
  }

  function excelEgresos(){
    const dataExcel =  arrayFinalEgresos.map((i) => {
      if (user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL){
    if (
      (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
      (selectedPlantel == 0 ||
        selectedPlantel == i.idPlantel) &&
      (selectedArea == 0 ||
        selectedArea == i.idArea) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ) {
      return {
        Fecha: i.fecha,
        Movimiento: i.movimiento,
        Nombre: i.col_pro,
        Plantel: i.plantel,
        Area: i.area, 
        Importe: i.importe
      }
    } else return null} else{ if(
      (i.idPlantel == user.planteles && i.idArea == user.areas) &&
      (selectedFechaInicio == 0 ||
        selectedFechaInicio <= i.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
    ){
      return {
        Fecha: i.fecha,
        Movimiento: i.movimiento,
        Nombre: i.col_pro,
        Plantel: i.plantel,
        Area: i.area, 
        Importe: i.importe
      }}
      else return null}
  });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = `Egresos-${selectedFechaInicio}-${selectedFechaFin}`;

option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: `Egresos`,
    sheetFilter: [
          "Fecha",
          "Movimiento",
          "Nombre",
          "Plantel",
          "Area",
          "Importe"
        ],
    sheetHeader: [
          "Fecha",
          "Movimiento",
          "Nombre",
          "Plantel",
          "Area",
          "Importe"
        ],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }


  return (
    <>
      <Header />
    {user.menu_reportes && user.menu_ingresos && user.menu_egresos ?(
      <div className="card ">
        <br />
        <Row>
          <Col sm={3}>
            <Button size="sm" color="danger" href="/MenuReportes">
              Regresar
            </Button>
          </Col>
          </Row>
          <br />
          <Row>
          <Col sm={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value);
              }}
            />
          </Col>
          <Col sm={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value);
              }}
            />
          </Col>
          <Col sm={2}>
            <Label className="mr-sm-2">Plantel</Label>
            {user.planteles == PLANTELES_GENERAL?
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => {setSelectedPlantel(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {planteles
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                      return <option value={a._id}>{a.name}</option>
                  })}
                  </Input>:
                  <Input
                  bsSize="sm"
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => {setSelectedPlantel(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {planteles
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (user.planteles == a._id) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>  }
          </Col>
          <Col sm={2}>
            <Label className="mr-sm-2">Area</Label>
            {user.areas == AREAS_GENERAL?
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {setSelectedArea(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                      return <option value={a._id}>{a.name}</option>
                  })}
                  </Input>:
                  <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {setSelectedArea(e.target.value);
                  }}
                ><option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => a.name - b.name)
                  .map((a) => {
                    if (user.areas == a._id) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                  </Input>  }
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={6}>
            <div className="card">
              <h3 align="center">Ingresos</h3>
              <Row>
                <Col sm={6}>
              <ButtonGroup>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={PDFTablaIngresos}
                        >
                          PDF <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button size="sm" className="btn" color="info">
                          eMail <i class="fas fa-at"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excelIngresos}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </ButtonGroup>
                      </Col>
                      <Col sm={6}>
              <h4 align="right">
                Total{" "}
                <Badge id="Total" color="info" className="BadgeSize badge-pill">
                  {"$" + new Intl.NumberFormat("en-US").format(totalIngresos)}
                </Badge>
              </h4>
                </Col>
              </Row>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th className="tituloTabla">Fecha</th>
                    <th className="tituloTabla">Movimiento</th>
                    <th className="tituloTabla">Nombre</th>
                    <th className="tituloTabla">Plantel</th>
                    <th className="tituloTabla">Area</th>
                    <th className="tituloTabla">Importe</th>
                  </tr>
                  {user.planteles == PLANTELES_GENERAL &&
                    user.areas == AREAS_GENERAL ?
                  arrayFinalIngresos
                    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
                    .map((i) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == i.idPlantel) &&
                        (selectedArea == 0 ||
                          selectedArea == i.idArea) &&
                        (selectedFechaInicio == 0 ||
                          selectedFechaInicio <= i.fecha) &&
                        (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
                      ) {
                        return (
                          <tr>
                            <TablaCartera
                              name={i.name}
                              fecha={i.fecha}
                              movimiento={i.movimiento}
                              importe={i.importe}
                              plantel={i.plantel}
                              area={i.area}
                            />
                          </tr>
                        );
                      }
                    }):
                    arrayFinalIngresos
                    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
                    .map((i) => {
                      if (
                        (user.planteles == i.idPlantel) &&
                          (user.areas == i.idArea) &&
                        (selectedFechaInicio == 0 ||
                          selectedFechaInicio <= i.fecha) &&
                        (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
                      ) {
                        return (
                          <tr>
                            <TablaCartera
                              name={i.name}
                              fecha={i.fecha}
                              movimiento={i.movimiento}
                              importe={i.importe}
                              plantel={i.plantel}
                              area={i.area}
                            />
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </Col>
          <Col sm={6}>
            <div className="card">
              <h3 align="center">Egresos</h3>
              <Row>
                <Col sm={6}>
              <ButtonGroup>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={PDFTablaEgresos}
                        >
                          PDF <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button size="sm" className="btn" color="info">
                          eMail <i class="fas fa-at"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excelEgresos}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </ButtonGroup>
                      </Col>
                      <Col sm={6}>
              <h4 align="right">
                Total{" "}
                <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                  {"$" + new Intl.NumberFormat("en-US").format(totalEgresos)}
                </Badge>
              </h4>
                </Col>
              </Row>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th className="tituloTabla">Fecha</th>
                    <th className="tituloTabla">Movimiento</th>
                    <th className="tituloTabla">Nombre</th>
                    <th className="tituloTabla">Plantel</th>
                    <th className="tituloTabla">Area</th>
                    <th className="tituloTabla">Importe</th>
                  </tr>
                  {user.planteles == PLANTELES_GENERAL &&
                    user.areas == AREAS_GENERAL ?
                  arrayFinalEgresos
                    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
                    .map((i) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == i.idPlantel) &&
                        (selectedArea == 0 ||
                          selectedArea == i.idArea) &&
                        (selectedFechaInicio == 0 ||
                          selectedFechaInicio <= i.fecha) &&
                        (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
                      ) {
                        return (
                          <tr>
                            <TablaCartera
                              name={i.col_pro}
                              fecha={i.fecha}
                              movimiento={i.movimiento}
                              importe={i.importe}
                              plantel={i.plantel}
                              area={i.area}
                            />
                          </tr>
                        );
                      }
                    }):
                    arrayFinalEgresos
                    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
                    .map((i) => {
                      if (
                        (user.planteles == i.idPlantel) &&
                      (user.areas == i.idArea) &&                        
                        (selectedFechaInicio == 0 ||
                          selectedFechaInicio <= i.fecha) &&
                        (selectedFechaFin == 0 || selectedFechaFin >= i.fecha)
                      ) {
                        return (
                          <tr>
                            <TablaCartera
                              name={i.col_pro}
                              fecha={i.fecha}
                              movimiento={i.movimiento}
                              importe={i.importe}
                              plantel={i.plantel}
                              area={i.area}
                            />
                          </tr>
                        );
                      }
                    })
                    }
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
      ): undefined } 
    </>
  );
}

export default VistaCartera;
