import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import axios from 'axios'

function DonadoresInfo(props) {
    const URL_DONADORES_INFO = `${process.env.REACT_APP_URL_DONADORES}/${props.idDonador}`;
    const [donador, setDonador] = useState([]);
    const [grupo, setGrupo] = useState([]);
    const [colaborador, setColaborador] =useState([])
    const [ejecutivo, setEjecutivo] =useState([])
  
    useEffect(() => {
      axios
        .get(URL_DONADORES_INFO, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allColaborador = response.data.colaboradores[0]
          setColaborador(allColaborador);
          let allEjecutivo = response.data.ejecutivo[0]
          setEjecutivo(allEjecutivo);
          let allGrupo = response.data.grupos[0]
          setGrupo(allGrupo);
          let allDonador = response.data
          setDonador(allDonador);
        })
        .catch((err) => {
          console.log(err);
        });
    },[]);

    return (
        <>

        <div className="card container">
           <Table size="lg" borderless>
             <tbody>
                <tr><td>Nombre:</td><td>{donador.apellido} {donador.nombre} </td></tr>
             {/* <tr><td>Nombre Comercial:</td><td>{donador.nombre_comercial}</td></tr> */}
                <tr><td>Razon Social:</td><td>{donador.razon_social}</td></tr>
                <tr><td>Telefono:</td><td>{donador.telefono}</td></tr>
                <tr><td>Celular:</td><td>{donador.celular}</td></tr>
                <tr><td>Email:</td><td>{donador.email}</td></tr>
                <tr><td>Domicilio:</td><td>{donador.calle} {donador.numero_ext} {donador.numero_int}</td></tr>
                <tr><td>Colonia:</td><td>{donador.colonia}</td></tr>
                <tr><td>CP:</td><td>{donador.cp}</td></tr>
                <tr><td>Descripcion del Donativo:</td><td>{donador.tipoDonativo}</td></tr>
                <tr><td>Forma de Pago:</td><td>{donador.forma_pago}</td></tr>
                <tr><td>Cobrador:</td><td>{colaborador.nombre + " " + colaborador.apellido} </td></tr>
                <tr><td>Ejecutivo:</td><td>{ejecutivo.nombre + " " + ejecutivo.apellido} </td></tr>
                <tr><td>Observaciones:</td><td>{donador.observaciones}</td></tr>
                </tbody> 
                </Table>
            </div>
   
        </>
    )
}

export default DonadoresInfo
