import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";

function AlumnosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [text, setText] = useState(false);

  const [name, setName] = useState("");
  const [genero, setGenero] = useState("");
  const [registro, setRegistro] = useState("");
  const [fecha_ingreso, setFechaIngreso] = useState("");
  const [fecha_nacimiento, setFechaNacimiento] = useState("");
  const [telefono, setTelefono] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [alergias, setAlergias] = useState("");
  const [tipo_sangre, setTipoSangre] = useState("");
  const [image, setImage] = useState("");
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState("");
  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [file, setFile] = useState();
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
    setGenero("");
    setRegistro("");
    setFechaIngreso("");
    setFechaNacimiento("");
    setTelefono("");
    setObservaciones("");
    setAlergias("");
    setTipoSangre("");
    setImage("");
    setClases([]);
    setSelectedClase("");
    setFamilias([]);
    setSelectedFamilia("");
    setValidaBoton(true);
  };

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveAlumno = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ALUMNOS,
          {
            name,
            genero,
            registro,
            fecha_ingreso,
            fecha_nacimiento,
            telefono,
            observaciones,
            alergias,
            tipo_sangre,
            clases: selectedClase,
            familias: selectedFamilia,
            beca_importe_col: 0,
            beca_porcentaje_col: 0,
            beca_importe_ins: 0,
            beca_porcentaje_ins: 0,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        // .then((data) => {
        //   const formData = new FormData();
        //   formData.append("file", file);
        //   formData.append("user", data.data._id);

        //   axios.post(URL_FILEPOST, formData, {
        //     headers: {
        //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //     },
        //   });
        // })
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Alta Alumno",
              detalle: `Nombre: ${name} / Familia: ${selectedFamilia}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          clear();
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      let message = error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Algo salio mal",
        text: message,
        allowEscapeKey: true,
      });
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.alumnos_create ? (
        <div className="container">
          <div className="card container">
            <h3 align="center">Dar de alta nuevo Alumno</h3>
            <Form onSubmit={saveAlumno}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Nombre
                    </Label>
                    <Input
                      type="text"
                      placeholder="Nombre del Alumno"
                      value={name}
                      required
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <Label className="mr-sm-2">Genero</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={genero}
                      required
                      onChange={(e) => {
                        setGenero(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona el Genero</option>
                      <option value="Hombre">Hombre</option>;
                      <option value="Mujer">Mujer</option>;
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      No Registro
                    </Label>
                    <Input
                      type="text"
                      placeholder="No de Registro"
                      value={registro}
                      required
                      onChange={(e) => {
                        setRegistro(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha de Nac
                    </Label>
                    <Input
                      type="date"
                      value={fecha_nacimiento}
                      required
                      onChange={(e) => {
                        setFechaNacimiento(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha de Ingreso
                    </Label>
                    <Input
                      type="date"
                      value={fecha_ingreso}
                      required
                      onChange={(e) => {
                        setFechaIngreso(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Telefono
                    </Label>
                    <Input
                      type="number"
                      placeholder="Telefono"
                      value={telefono}
                      required
                      onChange={(e) => {
                        setTelefono(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Alergias
                    </Label>
                    <Input
                      type="text"
                      placeholder="Alergias"
                      value={alergias}
                      required
                      onChange={(e) => {
                        setAlergias(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Tipo de Sangre
                    </Label>
                    <Input
                      type="select"
                      placeholder="Tipo de Sangre"
                      value={tipo_sangre}
                      required
                      onChange={(e) => {
                        setTipoSangre(e.target.value);
                      }}
                    >
                      <option value="0">Tipo de Sangre</option>
                      <option value="A+">A+</option>
                      <option value="B+">B+</option>
                      <option value="AB+">AB+</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="A-">A-</option>
                      <option value="B-">B-</option>
                      <option value="AB-">AB-</option>
                      <option value="NS">NS</option>
                    </Input>
                  </Col>
                  {/* <Col md={2}>
                    <Button color="success">
                      <Label>Subir Foto</Label>
                      <Input
                        type="file"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </Button>
                  </Col> */}
                </Row>
                <Row>
                  <Col md={4}>
                    <Label className="mr-sm-2">Familia</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedFamilia}
                      required
                      onChange={(e) => {
                        setSelectedFamilia(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona la Familia</option>
                      {familias.map((a) => {
                        return (
                          <option value={a._id}>
                            {a.codigo_familia} {a.apellido_paterno}{" "}
                            {a.apellido_materno}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label className="mr-sm-2">Clase</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedClase}
                      required
                      onChange={(i) => {
                        setSelectedClase(i.target.value);
                      }}
                    >
                      <option value="1">Selecciona su Clase</option>
                      {clases.map((p) => {
                        if (
                          user.planteles == PLANTELES_GENERAL &&
                          user.areas == AREAS_GENERAL
                        ) {
                          return (
                            <option value={p._id}>
                              {p.grados[0].name} {p.name}{" "}
                              {p.grados[0].areas[0].name} {p.planteles[0].name}{" "}
                            </option>
                          );
                        } else if (
                          user.planteles == p.planteles[0]._id &&
                          user.areas == p.grados[0].areas[0]._id
                        ) {
                          return (
                            <option value={p._id}>
                              {p.grados[0].name} {p.name}{" "}
                              {p.grados[0].areas[0].name} {p.planteles[0].name}{" "}
                            </option>
                          );
                        }
                      })}
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="textarea"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <Row>
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    // text="SweetAlert in React"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
                <Button
                  href="/Alumnos"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AlumnosCreate;
