import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ClasesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [name, setName] = useState("");
  const [text, setText] = useState(false);
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState("");
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
    setGrados([]);
    setSelectedGrado("");
    setSelectedPlantel("");
    setPlanteles([]);
    setValidaBoton(true);
  };
  useEffect(() => {
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let plantel;
  const saveClases = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    if (user.planteles != PLANTELES_GENERAL) {
      plantel = user.planteles;
    } else {
      plantel = selectedPlantel;
    }
    axios
      .post(
        URL_CLASES,
        {
          name,
          grados: selectedGrado,
          planteles: plantel,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Clase",
            detalle: `${name} / ${selectedGrado}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea una Clase nueva</h3>
        <Form onSubmit={saveClases}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Clase
            </Label>
            <Input
              type="text"
              placeholder="Nombre de la Clase"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <br />
            <Label className="mr-sm-2">Grado</Label>
            <Input
              className="col-sm-12"
              type="select"
              value={selectedGrado}
              required
              onChange={(e) => {
                setSelectedGrado(e.target.value);
              }}
            >
              <option value="0">Selecciona un Grado</option>
              {grados
                .sort((a, b) => (a.numero_interno > b.numero_interno ? 1 : -1))
                .map((a) => {
                  if (user.areas == AREAS_GENERAL) {
                    return (
                      <option value={a._id}>
                        {a.name} {a.areas[0].name}
                      </option>
                    );
                  } else if (user.areas == a.areas[0]._id) {
                    return (
                      <option value={a._id}>
                        {a.name} {a.areas[0].name}
                      </option>
                    );
                  }
                })}
            </Input>
            <br />
            {user.planteles == PLANTELES_GENERAL ? (
              <div>
                <Label className="mr-sm-2">Plantel</Label>
                <Input
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => setSelectedPlantel(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {planteles
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (a._id != PLANTELES_GENERAL) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </div>
            ) : (
              <div>
                <Label className="mr-sm-2">Plantel</Label>
                <Input
                  type="select"
                  value={selectedPlantel}
                  onChange={(e) => setSelectedPlantel(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {planteles
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (user.planteles == a._id) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </div>
            )}
          </FormGroup>
          <br />
          <Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                // text="SweetAlert in React"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button
              href="/MenuEscuelas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ClasesCreate;
