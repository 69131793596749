import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../../../contexts/AuthContext'
import TablaConceptosDon from "./TablaConceptosDon";
import ConceptosDonCreate from "./ConceptosDonCreate";
import axios from "axios";
import { Table } from "reactstrap";
import Header from "../../../../../layout/Header/Header";
// import Footer from "../../../layout/Footer/Footer";

function ListadoConceptosDon() {
  const { user } = useContext(AuthContext);
  const URL_CONCEPTOS_DON = process.env.REACT_APP_URL_CONCEPTOS_DON;
  const [conceptos, setConceptos] = useState([]);

  useEffect(() => {
    axios
      .get(URL_CONCEPTOS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allConceptos = response.data;
        setConceptos(allConceptos);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
      {user.menu_donativos && user.menu_catalogos ?(
        <div className="row">
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Conceptos</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th className="tituloTabla">Nombre</th>
                        <th className="tituloTabla">Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {conceptos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return (
                          <tr>
                            <TablaConceptosDon name={a.name} _id={a._id} is_active={a.is_active} />
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          <div className="col-6">
            <div className="card">
              <div className="card-body">
                <ConceptosDonCreate />
              </div>
            </div>
          </div>
        </div>
        ): undefined } 
      </div>
      
    </>
  );
}

export default ListadoConceptosDon;
