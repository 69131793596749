import React, { useContext } from "react";
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from '../../layout/Header/Header'
import { AuthContext } from "../../contexts/AuthContext";

function BotonesAlumnos() {
  const { user } = useContext(AuthContext);

    return (
        <>
      <Header />
      <br />
      <br />
      <div className="container">
      {user.menu_alumnos  ?(
        <Row>
          <Col md={4} align="center">
            {user.menu_familias ?(
            <Button href="/Familias" className="botonesMenu" color="success">
            <i class="fas fa-user-friends fa-7x"></i>
              <br />
              <br />
              Familias
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-user-friends fa-7x"></i>
              <br />
              <br />
              Familias
            </Button> } 
          </Col>
          <Col md={4} align="center">
          {user.alumnos_listado ?(
            <Button href="/Alumnos" className="botonesMenu" color="success">
            <i class="fas fa-user-graduate fa-7x"></i>
              <br />
              <br />
              Alumnos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-user-graduate fa-7x"></i>
              <br />
              <br />
              Alumnos
            </Button> } 
            </Col>
            <Col md={4} align="center">
          {user.alumnos_listado ?(
            <Button href="/Avisos" className="botonesMenu" color="success">
            <i class="fas fa-bullhorn fa-7x"></i>
              <br />
              <br />
              Avisos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bullhorn fa-7x"></i>
              <br />
              <br />
              Avisos
            </Button> } 
            </Col>
        </Row>
        ): undefined } 
      </div>
      
    </>
    )
}

export default BotonesAlumnos
