import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../layout/Header/Header";
// import TablaAbonosDonativos from "./TablaAbonosDonativos";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TableHeader, Pagination, Search } from "../../DataTable"
import useFullPageLoader from "../../hooks/useFullPageLoader"

function ListadoAbonosFamilias() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ABONOS_COLEGIATURAS = process.env.REACT_APP_URL_ABONOS_COLEGIATURAS;
  const URL_ABONOS_INSCRIPCIONES = process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
  const URL_ABONOS_FAMILIAS = process.env.REACT_APP_URL_ABONOS_FAMILIAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [abonosColegiaturas, setAbonosColegiaturas] = useState([]);
  const [abonosInscripciones, setAbonosInscripciones] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [concepto, setConcepto] = useState(""); 
  const [forma_pago, setFormaPago] = useState(""); 
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');
  const [value, setValue] = useState();
  //--- Pagination
  const [comments, setComments] = useState([]);
  const [commentsCol, setCommentsCol] = useState([]);
  const [commentsIns, setCommentsIns] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  //Edit
  const [idAbonoEdit, setIdAbonoEdit] = useState();
  const [fechaEdit, setFechaEdit] = useState();
  const [familiasEdit, setFamiliasEdit] = useState();
  const [importeEdit, setImporteEdit] = useState();
  const [forma_pago_edit, setFormaPagoEdit] = useState();
  const [conceptoEdit, setConceptoEdit] = useState("");
  const [valueEdit, setValueEdit] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);  
  const [validaBoton, setValidaBoton] = useState(true);
  const [text, setText] = useState(false);
  const [alumnos, setAlumnos] = useState([])
  const [selectedFamiliaEdit, setSelectedFamiliaEdit] = useState("");
  
   const [activo, setActivo] = useState("");
  
  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_ABONOS_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonos = response.data
        let arrayTabla = allAbonos
        .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            numeroColegiatura: a.idAbonoColegiatura,
            numeroInscripcion: a.idAbonoInscripcion,
            fecha: a.fecha,
            familia: a.familias[0].codigo_familia + " " + a.familias[0].apellido_paterno + " " + a.familias[0].apellido_materno,
            idFamilia: a.familias[0]._id,
            concepto: a.concepto,
            forma_pago: a.forma_pago,
            importe: a.importe
          };
        }).filter(function (el) {
          return el != null;
        });
        let dataFinal = Object.values(arrayTabla);
    // setAbonosColegiaturas(allAbonosColegiaturas);
    setComments(dataFinal)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let total = 0;

  // PDF
  let totalAbonos = 0;

  function PDFTabla() {
    totalAbonos = 0;
    const data = comments.map((c) => {
      if (
        (forma_pago == 0 || forma_pago == c.forma_pago) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                (value == null || value._id == c.idFamilia) &&
                (concepto == 0 || concepto == c.concepto)
      ) {
        totalAbonos = totalAbonos + c.importe;
        return [
          c.fecha,
          c.familia,
          c.concepto,
          c.forma_pago,
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Ingresos ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Familia", "Concepto", "Forma Pago", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`AbonosFamilia.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((c) => {
      if (
        (forma_pago == 0 || forma_pago == c.forma_pago) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                (value == null || value._id == c.idFamilia) &&
                (concepto == 0 || concepto == c.concepto)
      ) {
        return {
          Fecha: c.fecha,
          Familia: c.familia,
          Concepto: c.concepto,
          Forma: c.forma_pago,
          Importe: c.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAbonosFamilias";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAbonosFamilias",
        sheetFilter: ["Fecha", "Familia", "Concepto", "Forma", "Importe"],
        sheetHeader: ["Fecha", "Familia", "Concepto", "Forma", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail(){
    totalAbonos = 0;
    const data = comments.map((c) => {
      if (
        (forma_pago == 0 || forma_pago == c.forma_pago) &&
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                (value == null || value._id == c.idFamilia) &&
                (concepto == 0 || concepto == c.concepto)
      ) {
        totalAbonos = totalAbonos + c.importe;
        return [
          c.fecha,
          c.familia,
          c.concepto,
          c.forma_pago,
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Ingresos ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Familia", "Concepto", "Forma Pago", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Detalle Abonos Familias',
         email: mailTo,
         fileName: 'Detalle-Abonos-Familias.pdf',
         att: base64File,
         text: 
         `<!DOCTYPE html>
         <html>
           <head>
             <style>                                
               .img-container {
                               display: block;
                               margin-left: auto;
                               margin-right: auto;
                               height: 90px;
                               width: auto;
                             }
             </style>
           </head>
           <body>
             <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
             <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
             <h3>Hola,</h3>
             <h3>Adjunto encontraras el detalle de los abonos.</h3>
             <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
             Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
           </body>
         </html>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
      })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }


  const options = familias.map((option) => {
    const junta =
     option.codigo_familia + " " + option.apellido_paterno + " " + option.apellido_materno;
    const firstLetter = option.apellido_paterno[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const headers = [
    { name: "Numero", field: "numero", sortable: false },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Familia", field: "familia", sortable: true },
    { name: "Concepto", field: "concepto", sortable: true },
    { name: "Forma de Pago", field: "forma_pago", sortable: true },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                // comment.numero.toLowerCase().includes(search.toLowerCase()) ||
                comment.familia.toLowerCase().includes(search.toLowerCase()) ||
                comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
                comment.forma_pago.toLowerCase().includes(search.toLowerCase())
        );
    }

    // if(user.planteles != PLANTELES_GENERAL){
    //   computedComments = computedComments.filter((e)=>
    //   e.idPlantel.includes(user.planteles)
    //   ) 
    // } 

    // if(user.areas != AREAS_GENERAL){
    //   computedComments = computedComments.filter((e)=>
    //   e.idArea.includes(user.areas)
    //   ) 
    // } 

    
    if(value){
      computedComments = computedComments.filter((e)=>
      e.idFamilia.includes(value._id)
      ) 
    } 

    if(concepto){
      computedComments = computedComments.filter((e)=>
      e.concepto.includes(concepto)
      ) 
    }

    if(forma_pago){
      computedComments = computedComments.filter((e)=>
      e.forma_pago.includes(forma_pago)
      ) 
    }

    if(selectedFechaInicio){
      computedComments = computedComments.filter((e)=>
      e.fecha >= selectedFechaInicio
      ) 
    }
  
    if(selectedFechaFin){
      computedComments = computedComments.filter((e)=>
      e.fecha <= selectedFechaFin
      ) 
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, value, concepto, forma_pago, selectedFechaInicio, selectedFechaFin]);

  function EditAbono(id, fecha, idFamilia, importe, forma_pago, concepto){
        setIdAbonoEdit(id)
        setFechaEdit(fecha)
        setSelectedFamiliaEdit(idFamilia)
        setImporteEdit(importe)
        setFormaPagoEdit(forma_pago)
        setValueEdit(idFamilia)
        setConceptoEdit(concepto)
        toggleEdit()
      }

    // const selectFamilia = (e)=>{
    //     // setSelectedFamilia(e.target.value);
    //     // setValueEdit(e.target.value)
    // }

  const EditAbonoSave = (event) => {
      event.preventDefault();
      setValidaBoton(false)
      if(conceptoEdit=="Colegiatura"){
          axios
        .patch(
          `${URL_ABONOS_COLEGIATURAS}/${idAbonoEdit}`,
          {
            fecha:fechaEdit,
            importe:importeEdit,
            forma_pago:forma_pago_edit,
            familias: selectedFamiliaEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios
          .post(
            URL_LOGS,
            {
              tipo:'Editar Abono Colegiatura',
              detalle: `Fecha: ${fechaEdit} / Familia: ${selectedFamiliaEdit}`,
              user: user.id
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
        })}
        else{
          axios
          .patch(
            `${URL_ABONOS_INSCRIPCIONES}/${idAbonoEdit}`,
            {
              fecha:fechaEdit,
              importe:importeEdit,
              forma_pago:forma_pago_edit,
              familias: selectedFamiliaEdit,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios
            .post(
              URL_LOGS,
              {
                tipo:'Editar Abono Inscripcion',
                detalle: `Fecha: ${fechaEdit} / Familia: ${selectedFamiliaEdit}`,
                user: user.id
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response}`,
            });
            console.log(error);
          })
        }
      }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.colegiaturas_abonos ? (
        <div className="card container col-10">
          <Row>
            <Col sm={8}>
              {user.colegiaturas_create ? (
                <Button
                  size="sm"
                  href="/AbonosColegiaturas"
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Abono
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Abono
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCobranza"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button size="sm" className="btn" color="info" onClick={toggleMail}>
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col> 
          </Row>

          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Detalle de Ingresos</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                      <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>


          <h3 align="center">Abonos Familias</h3>

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br/>
          <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
              <tr>
                <td></td>
                <td></td>
                <td style={{ paddingTop: "0px" }}>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedFamilia) => {
                      setValue(selectedFamilia)
                      setCurrentPage(1)
                    }}
                    onBlur={selectedFamilia}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo_familia} {option.apellido_paterno} {option.apellido_materno}
                      </React.Fragment>
                    )}
                  />
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    placeholder="Concepto"
                    value={concepto}
                    required
                    onChange={(e) => {
                      setConcepto(e.target.value);
                      setCurrentPage(1)
                    }}
                  >
                    <option value="">Concepto</option>
                    <option value="Colegiatura">Colegiatura</option>
                    <option value="Inscripcion">Inscripcion</option>
                  </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                      setCurrentPage(1)
                    }}
                  >
                    <option value="">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                </td>
              </tr>
            <tbody>
              {commentsData
                .map((c) => {
                    total = total + c.importe;
                    return (
                      <tr>
                        <td>{c.numeroColegiatura}{c.numeroInscripcion}</td>
                        <td>{c.fecha}</td>
                        <td>{c.familia}</td>
                        <td>{c.concepto}</td>
                        <td>{c.forma_pago}</td>
                        <td>{"$" + new Intl.NumberFormat("en-US").format(c.importe)}</td>
                        {/* <td><Button size="sm" className="btn" color="danger" 
                          onClick={(e)=>PDFRecibo(c.idAbonoDonativo, c.fecha, c.donadores[0].nombre + " " + c.donadores[0].apellido, c.importe)}
                          >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                        </td> */}
                        <td>
                        {user.colegiaturas_abonos_edit ? (
                          <Button size="sm" className="btn" color="info" 
                          onClick={(e)=>EditAbono(c._id, c.fecha, c.idFamilia, c.importe, c.forma_pago, c.concepto)}
                          >
                          <i class="far fa-edit"></i>
                        </Button>
                        ):(
                          <Button size="sm" className="btn" color="info" disabled >
                          <i class="far fa-edit"></i>
                        </Button>
                        )}
                        </td>
                      </tr>
                      
                    );
                })}
                
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
                <td></td>
              </tr>
             
            </tbody>
          </Table>
          <br/>
              <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
                      <ModalHeader toggle={toggleEdit}><h4>Editar Abono</h4></ModalHeader>
                      <ModalBody>
            <Form  onSubmit={EditAbonoSave} >
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Familia</Label>
                  {/* <Autocomplete
                    size="small"
                    value={valueEdit}
                    onChange={(event, familiasEdit) => {
                      setValueEdit(familiasEdit);
                      selectFamilia()
                    }}
                    onBlur={selectFamilia}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo_familia} {option.apellido_paterno} {option.apellido_materno}
                      </React.Fragment>
                    )}
                  /> */}
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedFamiliaEdit}
                    required
                    onChange={(e) => {
                      setSelectedFamiliaEdit(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {familias
                      .sort((a, b) => (a.apellido_paterno > b.apellido_paterno ? 1 : -1))
                      .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.apellido_paterno} {a.apellido_materno}
                            </option>
                          );
                      })}
                  </Input>
                </Col>
                <Col md={6}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fechaEdit}
                    required
                    onChange={(e) => {
                      setFechaEdit(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label>Importe</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    placeholder="Importe"
                    value={importeEdit}
                    required
                    onChange={(e) => {
                      setImporteEdit(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Forma de Pago</Label>
                  <Input
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago_edit}
                    required
                    onChange={(e) => {
                      setFormaPagoEdit(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                </Col>
              </Row>

              <br />
              <Row>
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                 Guardar
                </Button>
                ):(
                <Button type="submit" className="btn btn-success" disabled>
                 Guardar
                </Button>)}
                
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
              </Row>
            </Form>
            </ModalBody>
                      
                    </Modal>
                       
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoAbonosFamilias;
