import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from 'moment';

function ListadoAbonosDonativos() {
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ABONOS_DONATIVOS = process.env.REACT_APP_URL_ABONOS_DONATIVOS;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [abonosDonativos, setAbonosDonativos] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [donadores, setDonadores] = useState([]);
  const [selectedDonador, setSelectedDonador] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [contactos, setContactos] = useState([]);
  const [selectedContacto, setSelectedContacto] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [value, setValue] = useState();

  const [idAbonoEdit, setIdAbonoEdit] = useState();
  const [numeroEdit, setNumeroEdit] = useState();
  const [fechaEdit, setFechaEdit] = useState();
  const [donadoresEdit, setDonadoresEdit] = useState();
  const [importeEdit, setImporteEdit] = useState();
  const [importeEditViejo, setImporteEditViejo] = useState();
  const [forma_pago_edit, setFormaPagoEdit] = useState();
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [validaBoton, setValidaBoton] = useState(true);
  const [text, setText] = useState(false);
  const [valueEdit, setValueEdit] = useState("");
  const [nombreDonador, setNombreDonador] = useState();

  function jalaFechas (){

    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'success',
      title: 'Danos unos segundos....'
    })
    axios
    .get(`${URL_ABONOS_DONATIVOS}/fechas/${selectedFechaInicio}/${selectedFechaFin}`,
     {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allAbonosDonativos = response.data;
      setAbonosDonativos(allAbonosDonativos);
    })
    .catch((err) => {
      console.log(err);
    });
  }


  useEffect(() => {
    jalaFechas()
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDonadores = res.data;
        setDonadores(allDonadores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let total = 0;

  // PDF
  let totalAbonos = 0;

  function PDFTabla() {
    totalAbonos = 0;
    const data = abonosDonativos.map((a) => {
      if (
        (forma_pago == 0 || forma_pago == a.forma_pago) &&
        (value == null || value._id == a.donadores[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.donadores[0].colaboradores[0]._id) &&
        (selectedContacto == 0 ||
          selectedContacto == a.donadores[0].contacto[0]._id)
      ) {
        totalAbonos = totalAbonos + a.importe;
        return [
          a.fecha,
          a.donadores[0].nombre + " " + a.donadores[0].apellido,
          [
            a.donadores[0].colaboradores[0].nombre +
              " " +
              a.donadores[0].colaboradores[0].apellido,
          ],
          a.forma_pago,
          "$" + new Intl.NumberFormat("en-US").format(a.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Abonos Donativos ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Nombre", "Cobrador", "Forma Pago", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`AbonosDonativos.pdf`);
  }

  function excel() {
    const dataExcel = abonosDonativos.map((c) => {
      if (
        (forma_pago == 0 || forma_pago == c.forma_pago) &&
        (value == null || value._id == c.donadores[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.donadores[0].colaboradores[0]._id) &&
        (selectedContacto == 0 ||
          selectedContacto == c.donadores[0].contacto[0]._id)
      ) {
        return {
          Fecha: c.fecha,
          Nombre: c.donadores[0].apellido + " " + c.donadores[0].nombre,
          Cobrador:
            c.donadores[0].colaboradores[0].nombre +
            " " +
            c.donadores[0].colaboradores[0].apellido,
          Forma: c.forma_pago,
          Importe: c.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAbonosDonativos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAbonosDonativos",
        sheetFilter: ["Fecha", "Nombre", "Cobrador", "Forma", "Importe"],
        sheetHeader: ["Fecha", "Nombre", "Cobrador", "Forma", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    totalAbonos = 0;
    const data = abonosDonativos.map((a) => {
      if (
        (forma_pago == 0 || forma_pago == a.forma_pago) &&
        (value == null || value._id == a.donadores[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.donadores[0].colaboradores[0]._id) &&
        (selectedContacto == 0 ||
          selectedContacto == a.donadores[0].contacto[0]._id)
      ) {
        totalAbonos = totalAbonos + a.importe;
        return [
          a.fecha,
          a.donadores[0].nombre + " " + a.donadores[0].apellido,
          [
            a.donadores[0].colaboradores[0].nombre +
              " " +
              a.donadores[0].colaboradores[0].apellido,
          ],
          a.forma_pago,
          "$" + new Intl.NumberFormat("en-US").format(a.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Abonos Donativos ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Nombre", "Cobrador", "Forma Pago", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
      var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Detalle Abonos Donativos",
          email: mailTo,
          fileName: "Detalle-Abonos-Donativos.pdf",
          att: base64File,
          text: `<!DOCTYPE html>
         <html>
           <head>
             <style>                                
               .img-container {
                               display: block;
                               margin-left: auto;
                               margin-right: auto;
                               height: 90px;
                               width: auto;
                             }
             </style>
           </head>
           <body>
             <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
             <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
             <h3>Hola,</h3>
             <h3>Adjunto encontraras el detalle de abonos donativos.</h3>
             <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
             Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
           </body>
         </html>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const options = donadores.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const optionsEdit = donadores.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function PDFRecibo(idAbonoDonativo, fecha, nombre, importe) {
    let idAbonoDonativoRecibo = idAbonoDonativo;
    let fechaRecibo = fecha;
    let nombreRecibo = nombre;
    let importeRecibo = importe;
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    // Horizontal, Vertical, Ancho, Alto
    doc.addImage(img, "png", 150, 10, 35, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // Horizontal, Vertical, Ancho, Alto
    doc.addImage(img2, "png", 95, 15, 23, 35);
    // Horizontal, Vertical
    doc.setFontSize(16);
    doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 65, 65);
    doc.text(`__________________`, 80, 150);
    doc.text(`Firma`, 100, 160);
    doc.autoTable({
      head: [["No Recibo", "Fecha", "Donador", "Concepto", "Importe"]],
      body: [
        [
          idAbonoDonativoRecibo,
          fechaRecibo,
          nombreRecibo,
          "Recibo de Donativo",
          "$" + new Intl.NumberFormat("en-US").format(importeRecibo),
        ],
      ],
      startY: 70,
    });
    doc.save(`Recibo-${idAbonoDonativoRecibo}.pdf`);
  }

  function EditAbono(
    id,
    idAbonoDonativo,
    fecha,
    donadores,
    importe,
    forma_pago,
    ND,
    AD
  ) {
    setIdAbonoEdit(id);
    setNumeroEdit(idAbonoDonativo);
    setFechaEdit(fecha);
    setDonadoresEdit(donadores);
    setImporteEdit(importe);
    setImporteEditViejo(importe);
    setFormaPagoEdit(forma_pago);
    setValueEdit(donadores);
    setNombreDonador(ND + " " + AD)
    toggleEdit();
  }

  const EditAbonoSave = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    // Horizontal, Vertical, Ancho, Alto
    doc.addImage(img, "png", 150, 10, 35, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // Horizontal, Vertical, Ancho, Alto
    doc.addImage(img2, "png", 95, 15, 23, 35);
    // Horizontal, Vertical
    doc.setFontSize(16);
    doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 65, 65);
    doc.text(`__________________`, 80, 100);
    doc.text(`Firma`, 100, 110);
    doc.autoTable({
      head: [["No Recibo", "Fecha", "Donador", "Concepto", "Importe"]],
      body: [
        [
          numeroEdit,
          fechaEdit,
          nombreDonador,
          "Recibo de Donativo",
          "$" + new Intl.NumberFormat("en-US").format(importeEdit),
        ],
      ],
      startY: 70,
    });

    var att = doc.output("arraybuffer");
      var base64File = encode(att);

    axios
      .patch(
        `${URL_ABONOS_DONATIVOS}/${idAbonoEdit}`,
        {
          fecha: fechaEdit,
          importe: importeEdit,
          forma_pago: forma_pago_edit,
          donadores: donadoresEdit,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
          .patch(
            `${URL_DONADORES}AbonosEdit/${donadoresEdit}`,
            {
              abonoViejo: importeEditViejo,
              abonoNuevo: importeEdit,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios
              .post(
                `${URL_ABONOS_DONATIVOS}/mail`,
                {
                  fecha: fechaEdit,
                  importe: importeEdit,
                  donadores: donadoresEdit,
                  base64File,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Editar Abono Donativo",
                      detalle: `${donadoresEdit}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      doc.save(`Recibo-${numeroEdit}.pdf`);
                      // clear()
                      window.location.reload();
                    }, 2000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_donativos ? (
        <div className="card container col-10">
          <Row>
            <Col sm={8}>
              {user.donativos_abonos_create ? (
                <Button
                  size="sm"
                  href="/AbonosDonativosCreate"
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Abono
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Abono
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuDonativos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Detalle de Abonos Donativos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Detalle Abonos Donativos</h3>

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
                    <Button     
                    size="sm"   
                        className="btn ir" 
                        color="info"
                        onClick={jalaFechas}
                        >
                          Ir 
                        </Button>
                    </Col>
          </Row>

          <Table size="sm" striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                <th style={{ paddingBottom: "0px" }}>Numero</th>
                <th style={{ paddingBottom: "0px" }}>Fecha</th>
                <th style={{ paddingBottom: "0px" }}>Donador</th>
                <th style={{ paddingBottom: "0px" }}>Contacto</th>
                <th style={{ paddingBottom: "0px" }}>Cobrador</th>
                <th style={{ paddingBottom: "0px" }}>Forma de Pago</th>
                <th style={{ paddingBottom: "0px" }}>Importe</th>
                <th style={{ paddingBottom: "0px" }}>Detalles</th>
                <th style={{ paddingBottom: "0px" }}>Editar</th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td style={{ paddingTop: "0px" }}>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedDonador) => {
                      setValue(selectedDonador);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.apellido} {option.nombre} /{" "}
                        {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedContacto}
                    required
                    onChange={(e) => setSelectedContacto(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {colaboradores
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}{" "}
                          </option>
                        );
                      })}
                  </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    required
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        if (a.cobrador == "Si") {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        }
                      })}
                  </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                </td>
              </tr>
            </thead>
            <tbody>
              {abonosDonativos
                .sort((a, b) =>
                  a.idAbonoDonativo < b.idAbonoDonativo ? 1 : -1
                )
                .map((c) => {
                  if (
                    (forma_pago == 0 || forma_pago == c.forma_pago) &&
                    (selectedColaborador == 0 ||
                      selectedColaborador ==
                        c.donadores[0].colaboradores[0]._id) &&
                    (selectedContacto == 0 ||
                      selectedContacto == c.donadores[0].contacto[0]._id) &&
                    (value == null || value._id == c.donadores[0]._id) &&
                    c.importe > 0
                  ) {
                    total = total + c.importe;
                    return (
                      <tr>
                        <td>{"# " + c.idAbonoDonativo}</td>
                        <td>{c.fecha}</td>
                        <td>
                          {c.donadores[0].apellido} {c.donadores[0].nombre}
                        </td>
                        <td>
                          {c.donadores[0].contacto[0].nombre +
                            " " +
                            c.donadores[0].contacto[0].apellido}
                        </td>
                        <td>
                          {c.donadores[0].colaboradores[0].nombre +
                            " " +
                            c.donadores[0].colaboradores[0].apellido}
                        </td>
                        <td>{c.forma_pago}</td>
                        <td>
                          {"$" +
                            new Intl.NumberFormat("en-US").format(c.importe)}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFRecibo(
                                c.idAbonoDonativo,
                                c.fecha,
                                c.donadores[0].apellido +
                                  " " +
                                  c.donadores[0].nombre,
                                c.importe
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                        </td>
                        <td>
                          {user.donativos_abonos_edit ? (
                            <Button
                              size="sm"
                              className="btn"
                              color="info"
                              onClick={(e) =>
                                EditAbono(
                                  c._id,
                                  c.idAbonoDonativo,
                                  c.fecha,
                                  c.donadores[0]._id,
                                  c.importe,
                                  c.forma_pago,
                                  c.donadores[0].nombre,
                                  c.donadores[0].apellido
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                          ) : (
                            <Button
                              size="sm"
                              className="btn"
                              color="info"
                              disabled
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  }
                })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4>Editar Abono Donativos</h4>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={EditAbonoSave}>
                <Row>
                  <Col md={6}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fechaEdit}
                      required
                      onChange={(e) => {
                        setFechaEdit(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Label>Importe</Label>
                    <Input
                      className="col-sm-12"
                      type="number"
                      placeholder="Importe"
                      value={importeEdit}
                      required
                      onChange={(e) => {
                        setImporteEdit(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Label>Forma de Pago</Label>
                    <Input
                      type="select"
                      placeholder="Forma de Pago"
                      value={forma_pago_edit}
                      required
                      onChange={(e) => {
                        setFormaPagoEdit(e.target.value);
                      }}
                    >
                      <option value="0">Forma de Pago</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Deposito">Deposito</option>
                      <option value="TDC">TDC</option>
                      <option value="Cheque">Cheque</option>
                    </Input>
                  </Col>
                </Row>

                <br />
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}

                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ListadoAbonosDonativos;
