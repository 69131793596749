import React, { useState, useEffect, useContext, useMemo } from "react";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TablaDonativos from "./TablaDonativos";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DonadoresInfo from "../Donadores/DonadoresInfo";
import DonativosInfo from "./DonativosInfo";

function ListadoDonativos() {
  const { user } = useContext(AuthContext);
  const URL_DONATIVOS = process.env.REACT_APP_URL_DONATIVOS;
  const URL_ABONOS_DONATIVOS = process.env.REACT_APP_URL_ABONOS_DONATIVOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EDO_CTA_DONATIVOS = process.env.REACT_APP_URL_EDO_CTA_DONATIVOS;
  const [abonosDonativos, setAbonosDonativos] = useState([]);
  const [cobradores, setCobradores] = useState([]);
  const [ejecutivos, setEjecutivos] = useState([]);
  const [selectedCobrador, setSelectedCobrador] = useState("");
  const [selectedEjecutivo, setSelectedEjecutivo] = useState("");
  const [donativos, setDonativos] = useState([]);
  const [pendiente, setPendiente] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [fecha, setFecha] = useState("");
  const [concepto, setConcepto] = useState("");
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [value, setValue] = useState();

  const URL_DONADORES = `${process.env.REACT_APP_URL_DONADORES}`;
  const [cargosDonador, setCargosDonador] = useState([]);
  const [abonosDonador, setAbonosDonador] = useState([]);
  const [donadores, setDonadores] = useState([]);
  const [selectedDonador, setSelectedDonador] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [selectedUltimoPagoInicio, setSelectedUltimoPagoInicio] = useState("");
  const [selectedUltimoPagoFin, setSelectedUltimoPagoFin] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [activo, setActivo] = useState("Si");

  const [modalInfo, setModalInfo] = useState(false);
  const toggleInfo = () => setModalInfo(!modalInfo);
  const [idDonadorInfo, setIdDonadorInfo] = useState([]);

  const [modalSaldo, setModalSaldo] = useState(false);
  const toggleSaldo = () => setModalSaldo(!modalSaldo);
  const [idDonadorSaldo, setIdDonadorSaldo] = useState([]);
  const [nombre, setNombre] = useState([]);
  const [apellido, setApellido] = useState([]);
  const [email, setEmail] = useState([]);

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCobradores = response.data;
        setCobradores(allCobradores);
        let allEjecutivos = response.data;
        setEjecutivos(allEjecutivos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonadores = response.data;
        let arrayTabla = allDonadores
          .sort((a, b) => (a.apellido > b.apellido ? 1 : -1))
          .map((a) => {
            return {
              idDonador: a._id,
              is_active: a.is_active,
              nombre: a.nombre,
              apellido: a.apellido,
              donadores: a.apellido + " " + a.nombre,
              ultimo_pago: a.ultimo_pago,
              fecha_visita: a.fecha_visita,
              idEjecutivo: a.ejecutivo[0]._id,
              ejecutivo: a.ejecutivo[0].nombre + " " + a.ejecutivo[0].apellido,
              idCobrador: a.colaboradores[0]._id,
              cobrador:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              cargos: a.cargos,
              abonos: a.abonos,
              saldo: a.cargos - a.abonos,
              telefono: a.telefono,
              email: a.email,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // PDF
  let totalCargos = 0;
  let totalAbonos = 0;
  let totalSaldo = 0;

  let tablaCargos = 0;
  let tablaAbonos = 0;
  let tablaSaldo = 0;

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active) &&
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (selectedEjecutivo == 0 || selectedEjecutivo == a.idEjecutivo) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 || selectedUltimoPagoFin >= a.ultimo_pago)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
        totalCargos = totalCargos + a.cargos;
        totalAbonos = totalAbonos + a.abonos;
        totalSaldo = totalSaldo + a.saldo;
        return [
          a.donadores,
          a.telefono,
          a.email,
          a.ultimo_pago,
          a.fecha_visita,
          a.ejecutivo,
          a.cobrador,
          cargo,
          abono,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 17, 25);
    doc.text(`Listado Donativos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Telefono",
          "Email",
          "Ult Pago",
          "Prox Visita",
          "Ejecutivo",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Donativos.pdf`);
  }
  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active) &&
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (selectedEjecutivo == 0 || selectedEjecutivo == a.idEjecutivo) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 || selectedUltimoPagoFin >= a.ultimo_pago)
      ) {
        return {
          Nombre: a.apellido + " " + a.nombre,
          Telefono: a.telefono,
          Email: a.email,
          UltimoPago: a.ultimo_pago,
          ProxVisita: a.fecha_visita,
          Ejecutivo: a.ejecutivo,
          Cobrador: a.cobrador,
          Cargos: a.cargos,
          Abonos: -a.abonos,
          Saldo: a.saldo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDonativos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDonativos",
        sheetFilter: [
          "Nombre",
          "Telefono",
          "Email",
          "UltimoPago",
          "ProxVisita",
          "Ejecutivo",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
        sheetHeader: [
          "Nombre",
          "Telefono",
          "Email",
          "UltimoPago",
          "ProxVisita",
          "Ejecutivo",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active) &&
        (selectedCobrador == 0 || selectedCobrador == a.idCobrador) &&
        (selectedEjecutivo == 0 || selectedEjecutivo == a.idEjecutivo) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
        (selectedUltimoPagoInicio == 0 ||
          selectedUltimoPagoInicio <= a.ultimo_pago) &&
        (selectedUltimoPagoFin == 0 || selectedUltimoPagoFin >= a.ultimo_pago)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
        totalCargos = totalCargos + a.cargos;
        totalAbonos = totalAbonos + a.abonos;
        totalSaldo = totalSaldo + a.saldo;
        return [
          a.apellido + " " + a.nombre,
          a.telefono,
          a.email,
          a.ultimo_pago,
          a.fecha_visita,
          a.ejecutivo,
          a.cobrador,
          cargo,
          abono,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 17, 25);
    doc.text(`Listado Donativos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Telefono",
          "Email",
          "Ult Pago",
          "Prox Visita",
          "Ejecutivo",
          "Cobrador",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
      var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Resumen Donativos",
          email: mailTo,
          fileName: "Resumen-Donativos.pdf",
          att: base64File,
          text: `<!DOCTYPE html>
          <html>
            <head>
              <style>                                
                .img-container {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                height: 90px;
                                width: auto;
                              }
              </style>
            </head>
            <body>
              <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
              <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
              <h3>Hola,</h3>
              <h3>Adjunto encontraras el detalle de donativos.</h3>
              <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
              Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
            </body>
          </html>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function enviarEdoCtaMasivo() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se enviara un mail a cada donador con su estado de cuenta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalMails = donadores.length;

        donadores.map((a) => {
          axios
            .post(
              URL_EDO_CTA_DONATIVOS,
              {
                donadores: a._id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalMails = totalMails - 1;
              if (totalMails == 0) {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        });
      }
    });
  }

  const options = donadores.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  // Headers
  const headers = [
    { name: "Donador", field: "a.apellido + a.nombre", sortable: true },
    { name: "Ultimo Pago", field: "ultimo_pago", sortable: true },
    { name: "Fecha Visita", field: "fecha_visita", sortable: true },
    { name: "Ejecutivo", field: "ejecutivo", sortable: true },
    { name: "Cobrador", field: "cobrador", sortable: true },
    { name: "Cargos", field: "cargos", sortable: true },
    { name: "Abonos", field: "abonos", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.donadores.toLowerCase().includes(search.toLowerCase()) ||
          comment.ultimo_pago.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha_visita.toLowerCase().includes(search.toLowerCase()) ||
          // comment.ejecutivo.toLowerCase().includes(search.toLowerCase()) ||
          // comment.cobrador.toLowerCase().includes(search.toLowerCase()) ||
          comment.cargos.toString().includes(search) ||
          comment.abonos.toString().includes(search) ||
          comment.saldo.toString().includes(search)
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(activo)
      );
    }

    if (selectedDonador) {
      computedComments = computedComments.filter((e) =>
        e.idDonador.includes(selectedDonador)
      );
    }

    if (selectedEjecutivo) {
      computedComments = computedComments.filter((e) =>
        e.idEjecutivo.includes(selectedEjecutivo)
      );
    }

    if (selectedCobrador) {
      computedComments = computedComments.filter((e) =>
        e.idCobrador.includes(selectedCobrador)
      );
    }

    // if(pendiente){
    //   computedComments = computedComments.filter((e)=>
    //   e.saldo.includes(pendiente)
    //   )
    // }

    if (selectedUltimoPagoInicio) {
      computedComments = computedComments.filter(
        (e) => e.ultimo_pago >= selectedUltimoPagoInicio
      );
    }

    if (selectedUltimoPagoFin) {
      computedComments = computedComments.filter(
        (e) => e.ultimo_pago <= selectedUltimoPagoFin
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha_visita >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha_visita <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cargos" &&
      sorting.field != "abonos" &&
      sorting.field != "saldo"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedDonador,
    selectedEjecutivo,
    selectedCobrador,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
    selectedUltimoPagoInicio,
    selectedUltimoPagoFin,
  ]);

  function info(idDonador) {
    setIdDonadorInfo(idDonador);
    toggleInfo();
  }

  function saldo(idDonador, nombre, apellido, email){
    setIdDonadorSaldo(idDonador)
    setNombre(nombre)
    setApellido(apellido)
    setEmail(email)
    toggleSaldo()
  }

  function excelAnalisis() {

    axios
    .get(`${URL_EDO_CTA_DONATIVOS}AnalisisDonadores`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
     let movs = response.data

     const dataExcel = movs.map((a) => {
        return {
          IDDonador: a.idDonador,
          Donador: a.donador,
          Cargos: a.cargo,
          Abonos: a.abono,
          Saldo: a.saldo,
          CargosDonador: a.cargoDonador,
          AbonosDonador: a.abonoDonador,
          SaldoDonador: a.saldoDonador,
          DifCargos: a.cargo - a.cargoDonador,
          DifAbonos: a.abono - a.abonoDonador,
          DifSaldo: a.saldo - a.saldoDonador
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuenta";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "IDDonador",
          "Donador",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosDonador",
          "AbonosDonador",
          "SaldoDonador",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
        sheetHeader: [
          "IDDonador",
          "Donador",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosDonador",
          "AbonosDonador",
          "SaldoDonador",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

    })
    .catch((err) => {
      console.log(err);
    });

  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_donativos ? (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col sm={8}>
                    <Button
                      size="sm"
                      href="/MenuDonativos"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={enviarEdoCtaMasivo}
                    >
                      <i class="fas fa-paper-plane"></i> Edo Cta Masivo
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        // onClick={excelAnalisis}
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Resumen Donativos</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>

                <h3 align="center">Estado de Cuenta</h3>

                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />
                  <tr>
                    <td style={{ paddingTop: "0px" }}>
                      <Col md={12}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={activo}
                          required
                          onChange={(e) => {
                            setActivo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value="">Todos</option>
                          <option value="Si">Activos</option>
                          <option value="No">Inactivos</option>
                        </Input>
                      </Col>
                    </td>

                    <td style={{ paddingTop: "0px" }}>
                      <Col md={12}>
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedUltimoPagoInicio}
                          required
                          onChange={(e) => {
                            setSelectedUltimoPagoInicio(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedUltimoPagoFin}
                          required
                          onChange={(e) => {
                            setSelectedUltimoPagoFin(e.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      </Col>
                    </td>
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaInicio}
                        required
                        onChange={(e) => {
                          setSelectedFechaInicio(e.target.value);
                          setCurrentPage(1);
                        }}
                      />
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaFin}
                        required
                        onChange={(e) => {
                          setSelectedFechaFin(e.target.value);
                          setCurrentPage(1);
                        }}
                      />
                    </td>
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedEjecutivo}
                        required
                        onChange={(e) => {
                          setSelectedEjecutivo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value="0">Selecciona</option>
                        {ejecutivos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            // if (a.cobrador == "Si") {
                            return (
                              <option value={a._id}>
                                {a.nombre} {a.apellido}
                              </option>
                            );
                            // }
                          })}
                      </Input>
                    </td>
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedCobrador}
                        required
                        onChange={(e) => {
                          setSelectedCobrador(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value="0">Selecciona</option>
                        {cobradores
                          .sort((a, b) => a.nombre - b.nombre)
                          .map((a) => {
                            if (a.cobrador == "Si") {
                              return (
                                <option value={a._id}>
                                  {a.nombre} {a.apellido}
                                </option>
                              );
                            }
                          })}
                      </Input>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      {/* <Input
                        bsSize="sm"
                        type="select"
                        value={pendiente}
                        required
                        onChange={(e) => {
                          setPendiente(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value="0">Selecciona</option>
                        <option value=".000000001">Solo Pendientes</option>
                      </Input> */}
                    </td>
                    <td></td>
                  </tr>
                  <tbody>
                    {commentsData.map((c) => {
                      tablaCargos = tablaCargos + c.cargos;
                      tablaAbonos = tablaAbonos + c.abonos;
                      tablaSaldo = tablaSaldo + c.saldo;
                      return (
                        <tr>
                          <td>{c.donadores} </td>
                          <td align="center">{c.ultimo_pago}</td>
                          <td align="center">{c.fecha_visita}</td>
                          <td>{c.ejecutivo}</td>
                          <td>{c.cobrador}</td>
                          <td align="center">
                            {"$" +
                              new Intl.NumberFormat("en-US").format(
                                c.cargos
                              )}{" "}
                          </td>
                          <td align="center">
                            {"-$" +
                              new Intl.NumberFormat("en-US").format(
                                c.abonos
                              )}{" "}
                          </td>
                          <td align="center">
                            {"$" +
                              new Intl.NumberFormat("en-US").format(
                                c.saldo
                              )}{" "}
                          </td>

                          <td align="center">
                            {user.donadores_edo_cta ? (
                              <Button
                                color="info"
                                size="sm"
                                onClick={(e)=>saldo(c.idDonador, c.nombre, c.apellido, c.email)}
                              >
                                <i class="fas fa-file-alt"></i>
                              </Button>
                            ) : (
                              <Button color="info" size="sm" disabled>
                                <i class="fas fa-file-alt"></i>
                              </Button>
                            )}

                            <Button
                              color="dany"
                              size="sm"
                              onClick={(e) => info(c.idDonador)}
                            >
                              <i class="far fa-address-card"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTALES
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaCargos)}
                      </td>
                      <td className="negrita" align="center">
                        {"-$" +
                          new Intl.NumberFormat("en-US").format(tablaAbonos)}
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaSaldo)}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
               <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

                <Modal size="lg" isOpen={modalInfo} toggle={toggleInfo}>
                  <ModalHeader toggle={toggleInfo}>
                    <h4>Informacion del Donador</h4>
                  </ModalHeader>
                  <ModalBody>
                    <DonadoresInfo idDonador={idDonadorInfo} />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={toggle}>
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal size="xxl" isOpen={modalSaldo} toggle={toggleSaldo}>
                  <ModalHeader toggle={toggleSaldo}>
                    <h4>Estado de Cuenta de {nombre} {apellido} </h4>
                  </ModalHeader>
                  <ModalBody>
                    <DonativosInfo
                      idDonador={idDonadorSaldo}
                      email={email}
                      nombre={nombre}
                      apellido={apellido}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={toggleSaldo}>
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
          {loader}
        </div>
      ) : undefined}
    </>
  );
}

export default ListadoDonativos;
