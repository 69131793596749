import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import TablaPeriodos from "./TablaPeriodos";
import axios from "axios";
import { Table, Button, Input } from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function ListadoPeriodos() {
  const { user } = useContext(AuthContext);
  const URL_PERIODOS = process.env.REACT_APP_URL_PERIODOS;
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const [periodos, setPeriodos] = useState([]);
  const [ciclos, setCiclos] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedCiclo, setSelectedCiclo] = useState("");
  const [selectedArea, setSelectedArea] = useState("");

  useEffect(() => {
    axios
      .get(URL_PERIODOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPeriodos = response.data;
        setPeriodos(allPeriodos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCiclos = res.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
      {user.menu_escuela  && user.menu_periodos ?(
        <div className="row">
          {
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button
                  size = "sm"
                    href="/PeriodosCreate"
                    className="btn btn-success"
                    id="botonListado"
                  >
                    Crear Periodo
                  </Button>
                  <Button
                  size = "sm"
                    href="MenuEscuelas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                  <h3 align="center">Periodos</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th style={{ paddingBottom: "0px" }}>Periodo</th>
                        <th style={{ paddingBottom: "0px" }}>Fecha Incio</th>
                        <th style={{ paddingBottom: "0px" }}>Fecha Fin</th>
                        <th style={{ paddingBottom: "0px" }}>Ciclo</th>
                        <th style={{ paddingBottom: "0px" }}>Area</th>
                        <th style={{ paddingBottom: "0px" }}>Editar</th>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={selectedCiclo}
                            required
                            onChange={(e) =>
                              setSelectedCiclo(e.target.value)
                            }
                          >
                            <option value="0">Selecciona</option>
                            {ciclos
                              .sort(
                                (a, b) =>
                                  a.name - b.name
                              )
                              .map((a) => {
                                return (
                                  <option value={a._id}>
                                    {a.name}
                                  </option>
                                );
                              })}
                          </Input>
                          </td>
                      <td style={{ paddingTop: "0px" }}>
                      <Input
                            bsSize="sm"
                            type="select"
                            value={selectedArea}
                            required
                            onChange={(e) =>
                              setSelectedArea(e.target.value)
                            }
                          >
                            <option value="0">Selecciona</option>
                            {areas
                              .sort(
                                (a, b) =>
                                  a.name - b.name
                              )
                              .map((a) => {
                                return (
                                  <option value={a._id}>
                                    {a.name}
                                  </option>
                                );
                              })}
                          </Input>
                      </td>
                      </tr>
                    </thead>
                    <tbody>
                      {periodos
                        .sort((a, b) =>
                          a.fecha_inicio < b.fecha_inicio ? 1 : -1
                        )
                        .map((a) => {
                          if (
                            (selectedCiclo == 0 ||
                              selectedCiclo ==
                                a.ciclos[0]._id) &&
                                (selectedArea == 0 ||
                                  selectedArea ==
                                    a.areas[0]._id)
                          ) {
                          return (
                            <tr>
                              <TablaPeriodos
                                name={a.name}
                                _id={a._id}
                                fecha_inicio={a.fecha_inicio}
                                fecha_fin={a.fecha_fin}
                                ciclos={a.ciclos[0].name}
                                areas={a.areas[0].name}
                                is_active={a.is_active}
                                idCiclos={a.ciclos[0]._id}
                                idAreas={a.areas[0]._id}
                              />
                            </tr>
                          );
                          }
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
        </div>
        ): undefined } 
      </div>
      <br />
      <Footer />
    </>
  );
}

export default ListadoPeriodos;
