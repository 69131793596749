import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

function GraficasDonativosPie(props) {
  const { user } = useContext(AuthContext);
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const URL_DONATIVOS = process.env.REACT_APP_URL_DONATIVOS;
  const [donativos, setDonativos] = useState([]);

  useEffect(() => {
    axios
      .get(URL_DONATIVOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonativos = response.data;
        setDonativos(allDonativos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  //Array Donativos
  const arrayDonativos = donativos.map((n) => {
    if((props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha)){
    return {
      importe: n.importe,
      sala: n.salasDon[0].name,
      evento: n.eventosDon[0].name
    }}
  })
  .filter(function (el) {
    return el != null;
  });

  
  const groupByDonativos = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        evento: item.evento,
        value: 0,
      };
      groups[val].value += item.importe;
      groups[val].evento = item.evento;
      groups[val].sala = item.sala;
      return groups;
    }, []);
  };

 
  const DonativosAgrupado = groupByDonativos(arrayDonativos, "evento");
  const dataFinalDonativos = Object.values(DonativosAgrupado);

  const InfoDon = dataFinalDonativos.map((ID) => {
    return {
      evento: ID.evento,
      value: ID.value,
    };
  });

  const InfoDonOV = Object.values(InfoDon);
 
 
  const groupByTipoDonativos = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        sala: item.sala,
        importe: 0,
        subData: [],
      };
      groups[val].sala = item.sala;
      groups[val].importe += item.value;
      groups[val].subData = InfoDonOV;
      return groups;
    }, []);
  };

  const donativosTipoAgrupado = groupByTipoDonativos(
    dataFinalDonativos,
    "sala"
  );
  const dataFinalTipoDonativos = Object.values(donativosTipoAgrupado);


  const arrayFinalGrafica = dataFinalTipoDonativos.filter(function (el) {
    return el != null;
  });


  // Grafica Pie

  let container = am4core.create("chartdivPie", am4core.Container);
  container.width = am4core.percent(100);
  container.height = am4core.percent(100);
  container.layout = "horizontal";

  let chart = container.createChild(am4charts.PieChart);

  chart.data = arrayFinalGrafica;

  // Add and configure Series
  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = "importe";
  pieSeries.dataFields.category = "sala";
  pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
  pieSeries.labels.template.text = "{category}\n{value.percent.formatNumber('#.#')}%";

  pieSeries.slices.template.events.on("hit", function (event) {
    selectSlice(event.target.dataItem);
  });

  let chart2 = container.createChild(am4charts.PieChart);
  chart2.width = am4core.percent(30);
  chart2.radius = am4core.percent(80);

  // Add and configure Series
  let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
  pieSeries2.dataFields.value = "value";
  pieSeries2.dataFields.category = "evento";
  pieSeries2.slices.template.states.getKey("active").properties.shiftRadius = 0;
  //pieSeries2.labels.template.radius = am4core.percent(50);
  //pieSeries2.labels.template.inside = true;
  //pieSeries2.labels.template.fill = am4core.color("#ffffff");
  pieSeries2.labels.template.disabled = true;
  // pieSeries2.ticks.template.disabled = true;
  pieSeries2.alignLabels = false;
  pieSeries2.events.on("positionchanged", updateLines);

  let interfaceColors = new am4core.InterfaceColorSet();

  let line1 = container.createChild(am4core.Line);
  line1.strokeDasharray = "2,2";
  line1.strokeOpacity = 0.5;
  line1.stroke = interfaceColors.getFor("alternativeBackground");
  line1.isMeasured = false;

  let line2 = container.createChild(am4core.Line);
  line2.strokeDasharray = "2,2";
  line2.strokeOpacity = 0.5;
  line2.stroke = interfaceColors.getFor("alternativeBackground");
  line2.isMeasured = false;

  let selectedSlice;

  function selectSlice(dataItem) {
    selectedSlice = dataItem.slice;

    let fill = selectedSlice.fill;

    let count = dataItem.dataContext.subData.length;
    // pieSeries2.colors.list = [
    //   am4core.color("#845EC2"),
    //   am4core.color("#D65DB1"),
    //   am4core.color("#FF6F91"),
    //   am4core.color("#FF9671"),
    //   am4core.color("#FFC75F"),
    //   am4core.color("#F9F871"),
    // ];
    // pieSeries2.colors.list = [
    //   am4core.color("#27304D"),
    //   am4core.color("#902C2D"),
    //   am4core.color("#C13D39"),
    // ];
  
    for (var i = 0; i < count; i++) {
      pieSeries2.colors.list.push(fill.brighten((i * 2) / count));
    }

    chart2.data = dataItem.dataContext.subData;
    pieSeries2.appear();

    let middleAngle = selectedSlice.middleAngle;
    let firstAngle = pieSeries.slices.getIndex(0).startAngle;
    let animation = pieSeries.animate([{ property: "startAngle", to: firstAngle - middleAngle }, { property: "endAngle", to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut);
    animation.events.on("animationprogress", updateLines);
  
    selectedSlice.events.on("transformed", updateLines);
  
  
    animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
   animation.events.on("animationprogress", updateLines)
  }

  function updateLines() {
    if (selectedSlice) {
      let p11 = {
        x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle),
        y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle),
      };
      let p12 = {
        x:
          selectedSlice.radius *
          am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc),
        y:
          selectedSlice.radius *
          am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc),
      };

      p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
      p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

      let p21 = { x: 0, y: -pieSeries2.pixelRadius };
      let p22 = { x: 0, y: pieSeries2.pixelRadius };

      p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
      p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

      line1.x1 = p11.x;
      line1.x2 = p21.x;
      line1.y1 = p11.y;
      line1.y2 = p21.y;

      line2.x1 = p12.x;
      line2.x2 = p22.x;
      line2.y1 = p12.y;
      line2.y2 = p22.y;
    }
  }

  chart.events.on("datavalidated", function() {
    setTimeout(function() {
      try {
     selectSlice(pieSeries.dataItems.first)} catch(e){
      // selectSlice(pieSeries.dataItems.first)
     }
   }, 1000);
  });

      // Add legend
      chart.legend = new am4charts.Legend();
    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.filePrefix = "myExport";

  return (
    <>
        <div
          id="chartdivPie"
          style={{ width: "100%", height: "500px", paddingTop: "0px" }}
        ></div>
    </>
  );
}

export default GraficasDonativosPie;
