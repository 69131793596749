import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";


function EventosDonCreate() {
  const URL_EVENTOS_DON = process.env.REACT_APP_URL_EVENTOS_DON;
  const [name, setName] = useState("");
  const [text, setText] = useState(false);

  const clear = () => {
    setName("");
  };

  const saveEventos = (event) => {
    event.preventDefault();
    axios
      .post(
        URL_EVENTOS_DON,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea una Evento nuevo</h3>
        <Form inline onSubmit={saveEventos}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Evento
            </Label>
            <Input
              type="text"
              placeholder="Nombre del Evento"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormGroup>
          <br />
          <br />
          <Row>
            <Button className="btn btn-success">
              Crear Evento
            </Button>
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button color="danger" href="/CatalogosDonativos">
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default EventosDonCreate;
