import React, { useState, useEffect } from "react";
import { Badge, Table, Button, Row, Col, ButtonGroup } from "reactstrap";
import axios from "axios";
import TablaColegiaturasInfo from "./TablaColegiaturasInfo";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ColegiaturasInfo(props) {
  const URL_CARGOS_COLEGIATURAS_FAMILIA = `${process.env.REACT_APP_URL_CARGOS_COLEGIATURAS}/familia/${props.idFamilia}`;
  const [cargos, setCargos] = useState([]);
  const URL_ABONOS_COLEGIATURAS_FAMILIA = `${process.env.REACT_APP_URL_ABONOS_COLEGIATURAS}/familia/${props.idFamilia}`;
  const [abonos, setAbonos] = useState([]);
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [emailPapa, setEmailPapa] = useState("");
  const [emailMama, setEmailMama] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("");
  const [apellidoMaterno, setApellidoMaterno] = useState("");
  

  useEffect(() => {
    axios
      .get(URL_CARGOS_COLEGIATURAS_FAMILIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        setCargos(allCargos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_COLEGIATURAS_FAMILIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonos = response.data;
        setAbonos(allAbonos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let importesCargos = cargos.map((c) => c.importe);
  let totalCargos = importesCargos.reduce(
    (total, importe, index) => total + importe,
    0
  );

  let importesAbonos = abonos.map((a) => a.importe);
  let totalAbonos = importesAbonos.reduce(
    (total, importe, index) => total + importe,
    0
  );

  let saldo = totalCargos - totalAbonos;

  const arrayCargos = cargos.map((c) => {
    return {
      fecha: c.fecha,
      movimiento: "Colegiatura",
      concepto: c.concepto,
      al_fam: c.alumnos[0].name,
      cargos: c.importe,
      abonos: " ",
      forma_pago:"NA"
    };
  });

  const arrayAbonos = abonos.map((n) => {
    return {
      fecha: n.fecha,
      movimiento: "Abono",
      concepto: "Pago",
      al_fam: "NA",
      cargos: " ",
      abonos: n.importe,
      forma_pago: n.forma_pago
    };
  });

  const arrayMovimientos = arrayCargos.concat(arrayAbonos);

  var saldoParcial = 0
  var saldoParcialTabla = 0

  function PDFTabla() {
    const data = arrayMovimientos.map((a) => {
      saldoParcial = saldoParcial + a.cargos - a.abonos
    var saldoParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoParcial);
    var cargos = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
      var abonos = "$" + new Intl.NumberFormat("en-US").format(a.abonos);
      return [a.fecha, a.movimiento, a.concepto, a.al_fam, a.forma_pago, cargos, abonos, saldoParcialFormato];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Estado de Cuenta ${props.codigoFamilia} ${props.apellidoP} ${props.apellidoM}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Movimiento", "Concepto", "Alumno", "Forma Pago", "Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "TOTALES: ",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(saldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaFam-${props.codigoFamilia}.pdf`);
  }

  function excel(){
    const dataExcel = arrayMovimientos.map((a) => {
      return {Fecha:a.fecha, Movimiento:a.movimiento, Concepto:a.concepto, Alumno:a.al_fam, Forma:a.forma_pago, Cargos:a.cargos, Abonos:a.abonos};
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = `EdoCtaFam-${props.codigoFamilia}`;
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: `EdoCtaFam-${props.codigoFamilia}`,
    sheetFilter: ["Fecha", "Movimiento", "Concepto", "Alumno", "Forma", "Cargos", "Abonos"],
    sheetHeader: ["Fecha", "Movimiento", "Concepto", "Alumno", "Forma", "Cargos", "Abonos"],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }


  function enviaMail(){
    const data = arrayMovimientos.map((a) => {
      saldoParcial = saldoParcial + a.cargos - a.abonos
      var saldoParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoParcial);
      var cargos = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
      var abonos = "$" + new Intl.NumberFormat("en-US").format(a.abonos);
      return [a.fecha, a.movimiento, a.concepto, a.al_fam, a.forma_pago, cargos, abonos, saldoParcialFormato];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();  var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion2.png ";
    doc.addImage(img2, "png", 100, 15, 17, 25);
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Estado de Cuenta`,20,30);
    // doc.text(`Familia ${a.apellido_paterno} ${a.apellido_materno}`,20,37);
    doc.autoTable({
      head: [["Fecha", "Movimiento", "Concepto", "Alumno", "Forma Pago", "Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 43,
      foot: [
        [
          "",
          "",
          "",
          "",
          "TOTALES: ",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(saldo),
        ],
      ],
      showFoot: "lastPage",
    });
      var att = doc.output("arraybuffer");
      var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: [props.emailPapa, props.emailMama],
          fileName: "EstadoCuenta.pdf",
          att: base64File,
          text:  `<!DOCTYPE html>
          <html>
            <head>
              <style>                                
                .img-container {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                height: 90px;
                                width: auto;
                              }
              </style>
            </head>
            <body>
              <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
              <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
              <h3>Hola Familia ${props.apellidoP} ${props.apellidoM},</h3>
              <h3>Adjunto encontraras tu estado de cuenta de colegiaturas de La Academia YKTT.</h3>
              <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
              Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
            </body>
          </html>`
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <div className="card container">
        <Row>
          <Col sm={5}>
            <ButtonGroup>
              <Button size="sm" className="btn" color="danger" onClick={PDFTabla}>
                PDF <i class="far fa-file-pdf"></i>
              </Button>
              <Button size="sm" className="btn" color="info" 
              onClick={enviaMail}>
                eMail <i class="fas fa-at"></i>
              </Button>
              <Button
                size="sm"
                className="btn"
                color="primary"
                onClick={excel}
              >
                Excel <i class="far fa-file-excel"></i>
              </Button>
            </ButtonGroup>
          </Col>
          <Col sm={7}>
            <h4 align="right">
              Saldo{" "}
              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                {"$" + new Intl.NumberFormat("en-US").format(saldo)}
              </Badge>
            </h4>
          </Col>
        </Row>
        <Table striped>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Movimiento</th>
              <th>Concepto</th>
              <th>Alumno</th>
              <th>Forma de Pago</th>
              <th>Cargos</th>
              <th>Abonos</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody>
            {arrayMovimientos
              .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
              .map((c) => {
                saldoParcialTabla = saldoParcialTabla + c.cargos - c.abonos
                return (
                  <tr>
                    <TablaColegiaturasInfo
                      fecha={c.fecha}
                      movimiento={c.movimiento}
                      concepto={c.concepto}
                      al_fam={c.al_fam}
                      importe={c.importe}
                      cargos={c.cargos}
                      abonos={c.abonos}
                      forma_pago={c.forma_pago}
                      saldoParcialTabla = {saldoParcialTabla}
                    />
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td td className="negrita" align="center">TOTALES</td>
                <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalCargos)}</td>
                <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(totalAbonos)}</td>
                <td td className="negrita" align="center">{"$" + new Intl.NumberFormat("en-US").format(saldo)}</td>
              </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default ColegiaturasInfo;
