import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Table, Button } from "reactstrap";
import axios from "axios";
import AgregarMateria from "./AgregarMateria";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function MateriasBaja(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
    let idMateria = props.idMateria
  const URL_GRADOS_MATERIAS = `${process.env.REACT_APP_URL_GRADOS}/${props.idGrado}`;
  const [materias, setMaterias] = useState(props.materias);
//   const [selectedMateria, setSelectedMateria] = useState("");
  const [text, setText] = useState(false);


  useEffect(()=>{},[materias])
    
    function eliminar(){
        materias.map((currElement, index)=>{
        if(currElement._id == idMateria){
            materias.splice(index, index+1);   
         }
        })
      }
    
      
      function baja() {
        Swal.fire({
          title: "Estas seguro?",
          text: "Se dara de baja!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, dar de Baja!",
        }).then((result) => {
          if (result.isConfirmed) {
            eliminar()
            axios
              .patch(
                URL_GRADOS_MATERIAS,
                {
                  materias
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then((response) => {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo:'Baja Materia a Grado',
                      detalle: idMateria,
                      user: user.id
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                      },
                    }
                  )
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se dio de Baja",
                  showConfirmButton: false,
                  timer: 2000,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          }
        });
      }

    return (
        <>
            <Button
                      color="link"
                      id="Baja"
                      onClick={baja}
                      size="sm"
                    >
                      <i className="far fa-times-circle"></i>
                    </Button>
        </>
    )
}

export default MateriasBaja
