import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function InfoAlumnosCreate() {
  const { user } = useContext(AuthContext);
  const URL_INFO_ALUMNOS = process.env.REACT_APP_URL_INFO_ALUMNOS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [alumnos, setAlumnos] = useState([]);
  const [selectedAlumno, setSelectedAlumno] = useState('');
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(0);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(0);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(0);
  const [aspecto, setAspecto] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [fecha, setFecha] = useState('');

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAlumnos = res.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function guardar(){
    axios
    .post(
      URL_INFO_ALUMNOS,
      {
      fecha,
      comentarios,
      aspecto,
      alumnos:selectedAlumno,
      user: user.id
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios
      .post(
        URL_LOGS,
        {
          tipo:'Info Alumnos',
          detalle: `${selectedAlumno} / ${fecha} / ${comentarios} / ${aspecto}` ,
          user: user.id
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      clear();
      Swal.fire("Good job!", "Creado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
  }

    function clear(){
        setSelectedClase('')
        setSelectedGrado('')
        setSelectedPlantel('')
        setSelectedAlumno('')
        setSelectedArea('')
        setAspecto('')
        setComentarios('')
        setFecha('')
    }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card container col-5">
        {user.info_alumno_create ? (
          <div className="card-body">
              <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Planteles</Label>
                <Input
                  type="select"
                  value={selectedPlantel}
                  required
                  onChange={(e) => setSelectedPlantel(e.target.value)}
                >
                  <option value="0">Selecciona una Plantel</option>
                  {planteles
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name} </option>;
                    })}
                </Input>
              </Col>
              <Col md={6}>
                <Label className="mr-sm-2">Areas</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => setSelectedArea(e.target.value)}
                >
                  <option value="0">Selecciona una Area</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              </Row>
              <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Grados</Label>
                <Input
                  type="select"
                  value={selectedGrado}
                  required
                  onChange={(e) => setSelectedGrado(e.target.value)}
                >
                  <option value="0">Selecciona una Grado</option>
                  {grados
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (selectedArea == 0 || selectedArea == a.areas[0]._id) {
                        return (
                          <option value={a._id}>
                            {a.name} {a.areas[0].name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={6}>
                <Label className="mr-sm-2">Clases</Label>
                <Input
                  type="select"
                  value={selectedClase}
                  required
                  onChange={(e) => setSelectedClase(e.target.value)}
                >
                  <option value="0">Selecciona una Clase</option>
                  {clases
                    .sort(
                      (a, b) =>
                        a.grados[0].name - b.grados[0].name ||
                        b.name - a.name ||
                        a.planteles[0].name - b.planteles[0].name
                    )
                    .map((a) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == a.planteles[0]._id) &&
                        (selectedArea == 0 ||
                          selectedArea == a.grados[0].areas[0]._id) &&
                        (selectedGrado == 0 || selectedGrado == a.grados[0]._id)
                      ) {
                        return (
                          <option value={a._id}>
                            {a.grados[0].name} {a.name}{" "}
                            {a.grados[0].areas[0].name} {a.planteles[0].name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              </Row>
              <br /><br />
              <Col>
                <Label className="mr-sm-2">Alumno</Label>
                <Input
                  type="select"
                  value={selectedAlumno}
                  required
                  onChange={(e) => setSelectedAlumno(e.target.value)}
                >
                  <option value="0">Selecciona un Alumno</option>
                  {alumnos
                    .sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    .map((a) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == a.clases[0].planteles[0]._id) &&
                        (selectedArea == 0 ||
                          selectedArea == a.clases[0].grados[0].areas[0]._id) &&
                        (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
                        (selectedClase == 0 || selectedClase == a.clases[0]._id)
                      ) {
                        return (
                        <option value={a._id}>{a.familias[0].apellido_paterno} {a.familias[0].apellido_materno} {a.name}</option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Fecha
                  </Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
              <Col>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Aspecto
                  </Label>
                  <Input
                    type="select"
                    placeholder="Aspecto"
                    value={aspecto}
                    required
                    onChange={(e) => {
                      setAspecto(e.target.value);
                    }}
                  >
                    <option value="0">Aspecto</option>
                    <option value="Academico">Academico</option>
                    <option value="Disciplina">Disciplina</option>
                    <option value="Emocional">Emocional</option>
                    <option value="Social">Social</option>
                  </Input>
                </Col>
                <Col>
                  <Label>
                    Comentarios
                  </Label>
                  <Input
                    type="textarea"
                    placeholder="Comentarios"
                    value={comentarios}
                    required
                    onChange={(e) => {
                      setComentarios(e.target.value);
                    }}
                  />
                </Col>
                <br />
            <Row>
              <Button type="submit" className="btn btn-success" onClick={guardar}>
                Guardar
              </Button>
              <Button
                href="/InfoAlumnos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default InfoAlumnosCreate;
