import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  FormGroup,
} from "reactstrap";
import PasswordFamilias from "./PasswordFamilias";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function ListadoFamilias() {
  const { user } = useContext(AuthContext);
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [familias, setFamilias] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [codigo_familia, setCodigoFamilia] = useState("");
  const [apellido_paterno, setApellidoPaterno] = useState("");
  const [apellido_materno, setApellidoMaterno] = useState("");
  const [nombre_papa, setNombrePapa] = useState("");
  const [nombre_mama, setNombreMama] = useState("");
  const [telefono_papa, setTelefonoPapa] = useState("");
  const [telefono_mama, setTelefonoMama] = useState("");
  const [email_papa, setEmailPapa] = useState("");
  const [email_mama, setEmailMama] = useState("");
  const [telefono_casa, setTelefonoCasa] = useState("");
  const [contacto_emergencia, setContactoEmergencia] = useState("");
  const [telefono_emergencia, setTelefonoEmergencia] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState("");
  const [colonia, setColonia] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradoresId, setColaboradoresId] = useState("");
  const [colaboradoresNombre, setColaboradoresNombre] = useState("");
  const [colaboradoresApellido, setColaboradoresApellido] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [idFamilia, setIdFamilia] = useState("");
  const [activo, setActivo] = useState("");

  const [idPassword, setIdPassword] = useState("");
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);


  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFamilias = response.data;
        // Array para pagination
        let arrayTabla = allFamilias
        .sort((a, b) => (a.apellido_paterno > b.apellido_paterno ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              codigo_familia: a.codigo_familia,
              apellido_paterno: a.apellido_paterno,
              apellido_materno: a.apellido_materno,
              nombre_papa: a.nombre_papa,
              nombre_mama: a.nombre_mama,
              telefono_papa: a.telefono_papa,
              telefono_mama: a.telefono_mama,
              telefono_casa: a.telefono_casa,
              email_papa: a.email_papa,
              email_mama: a.email_mama,
              contacto_emergencia: a.contacto_emergencia,
              telefono_emergencia: a.telefono_emergencia,
              calle: a.calle,
              numero_ext: a.numero_ext,
              numero_int: a.numero_int,
              delegacion: a.delegacion,
              estado: a.estado,
              colonia: a.colonia,
              pais: a.pais,
              cp: a.cp,
              ciudad: a.ciudad,
              observaciones: a.observaciones,
              cobrador:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idCobrador: a.colaboradores[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        //
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function Edit(
    idFamilia,
    codigo_familia,
    apellido_paterno,
    apellido_materno,
    nombre_papa,
    nombre_mama,
    telefono_papa,
    telefono_mama,
    email_papa,
    email_mama,
    telefono_casa,
    contacto_emergencia,
    telefono_emergencia,
    calle,
    numero_ext,
    numero_int,
    delegacion,
    estado,
    pais,
    cp,
    colonia,
    ciudad,
    observaciones,
    colaboradoresNombre,
    colaboradoresId
  ) {
    setIdFamilia(idFamilia);
    setCodigoFamilia(codigo_familia);
    setApellidoPaterno(apellido_paterno);
    setApellidoMaterno(apellido_materno);
    setNombrePapa(nombre_papa);
    setNombreMama(nombre_mama);
    setTelefonoPapa(telefono_papa);
    setTelefonoMama(telefono_mama);
    setEmailPapa(email_papa);
    setEmailMama(email_mama);
    setTelefonoCasa(telefono_casa);
    setContactoEmergencia(contacto_emergencia);
    setTelefonoEmergencia(telefono_emergencia);
    setObservaciones(observaciones);
    setCalle(calle);
    setNumeroExt(numero_ext);
    setNumeroInt(numero_int);
    setDelegacion(delegacion);
    setEstado(estado);
    setPais(pais);
    setCP(cp);
    setColonia(colonia);
    setCiudad(ciudad);
    setSelectedColaborador(colaboradoresId);
    setColaboradoresId(colaboradoresId);
    setColaboradoresNombre(colaboradoresNombre);
    toggleEdit();
  }

  function editFamilia(event) {
    const URL_FAMILIAS_EDIT = `${process.env.REACT_APP_URL_FAMILIAS}/${idFamilia}`;
    const URL_SIGNUP_FAM = `${process.env.REACT_APP_URL_USERS}/${idFamilia}`;
    event.preventDefault();
    axios
      .all([
        axios.patch(
          URL_FAMILIAS_EDIT,
          {
            codigo_familia,
            apellido_paterno,
            apellido_materno,
            nombre_papa,
            nombre_mama,
            telefono_papa,
            telefono_mama,
            email_papa,
            email_mama,
            telefono_casa,
            contacto_emergencia,
            telefono_emergencia,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            observaciones,
            colaboradores: selectedColaborador,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
        axios.patch(
          URL_SIGNUP_FAM,
          {
            nombre: nombre_papa,
            apellido: apellido_paterno,
            email: email_papa,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Familia",
            detalle: `Familia: ${codigo_familia}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Cod. Fam.", field: "codigo_familia", sortable: true },
    { name: "Apellido P", field: "apellido_paterno", sortable: true },
    { name: "Apellidos M", field: "apellido_materno", sortable: true },
    { name: "Papa", field: "nombre_papa", sortable: true },
    { name: "Mama", field: "nombre_mama", sortable: true },
    { name: "Tel Papa", field: "telefono_papa", sortable: false },
    { name: "Tel Mama", field: "telefono_mama", sortable: false },
    { name: "Tel Casa", field: "telefono_casa", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo_familia.toLowerCase().includes(search.toLowerCase()) ||
          comment.apellido_paterno.toLowerCase().includes(search.toLowerCase()) ||
          comment.apellido_materno.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre_papa.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre_mama.toLowerCase().includes(search.toLowerCase())
      );
    }

    if(activo){
      computedComments = computedComments.filter((e)=>
      e.is_active.includes(activo)
      ) 
    } 

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting, activo]);

  function Baja(id, apellido_paterno, apellido_materno) {
    Swal.fire({
      title: "Estas seguro?",
      text: `Se dara de baja la familia ${apellido_paterno} ${apellido_materno} asi como a todos sus hijos`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Baja!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
        .get(`${URL_ALUMNOS}/familia/${id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allAlumnos = res.data;
          allAlumnos.map((a)=>{
            axios
            .patch(
              `${URL_ALUMNOS}/${a._id}`,
              {
                is_active: "No",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
          })
        })
        .catch((err) => {
          console.log(err);
        })
        // desde aqui
        .then(()=>{
          axios
          .patch(
            `${URL_FAMILIAS}/${id}`,
            {
              is_active: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se dio de Baja",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
        })
        // hasta aca
      }
    });
  }

  function Alta(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de Alta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Alta!",
    }).then((result) => {
      if (result.isConfirmed) {
        // axios
        // .get(`${URL_ALUMNOS}/familia/${id}`, {
        //   headers: {
        //     Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //   },
        // })
        // .then((res) => {
        //   const allAlumnos = res.data;
        //   allAlumnos.map((a)=>{
        //     axios
        //     .patch(
        //       `${URL_ALUMNOS}/${a._id}`,
        //       {
        //         is_active: "Si",
        //       },
        //       {
        //         headers: {
        //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        //         },
        //       }
        //     )
        //   })
        // })
        // .catch((err) => {
        //   console.log(err);
        // })
        // desde aqui
        // .then(()=>{
          axios
          .patch(
            `${URL_FAMILIAS}/${id}`,
            {
              is_active: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se dio de Alta",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
        // })
        // hasta aca
      }
    });
  }

  function jalaInfoPassword(idFamilia) {
    const URL_USERS_FAMILIA = `${process.env.REACT_APP_URL_USERS}/familia/${idFamilia}`;
  axios
    .get(URL_USERS_FAMILIA, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let idPasswordFamiliaUsuario = response.data[0]._id;
      setIdPassword(idPasswordFamiliaUsuario);
      togglePassword();
    })
    .catch((err) => {
      console.log(err);
    });
}

  return (
    <>
      <Header />
      <br />
      <br />
      <div>
        {user.menu_familias ? (
          <div className="card col-12">
            <Row>
              {user.familias_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/FamiliasCreate"
                >
                  Nueva Familia
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Familia
                </Button>
              )}
              <Button
                size="sm"
                type="submit"
                className="btn btn-danger"
                href="/MenuAlumnos"
              >
                Regresar
              </Button>
            </Row>
            <h3 align="center">Familias</h3>
            <div className="row">
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
              <div className="col-md-6 d-flex flex-row-reverse">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
               <tr>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={activo}
                      onChange={(e) => {
                        setActivo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </td>
                  </tr>
              <tbody>
                {commentsData.map((f) => {
                  return (
                    <tr>
                      <td>{f.is_active}</td>
                      <td>{f.codigo_familia}</td>
                      <td>{f.apellido_paterno}</td>
                      <td>{f.apellido_materno}</td>
                      <td>{f.nombre_papa}</td>
                      <td>{f.nombre_mama}</td>
                      <td>{f.telefono_papa}</td>
                      <td>{f.telefono_mama}</td>
                      <td>{f.telefono_casa}</td>
                      <td>
                        {user.familias_create ? (
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              Edit(
                                f._id,
                                f.codigo_familia,
                                f.apellido_paterno,
                                f.apellido_materno,
                                f.nombre_papa,
                                f.nombre_mama,
                                f.telefono_papa,
                                f.telefono_mama,
                                f.email_papa,
                                f.email_mama,
                                f.telefono_casa,
                                f.contacto_emergencia,
                                f.telefono_emergencia,
                                f.calle,
                                f.numero_ext,
                                f.numero_int,
                                f.delegacion,
                                f.estado,
                                f.pais,
                                f.cp,
                                f.colonia,
                                f.ciudad,
                                f.observaciones,
                                f.cobrador,
                                f.idCobrador
                              )
                            }
                          >
                            <i class="far fa-edit"></i>
                          </Button>
                        ) : (
                          <Button color="info" id="Editar" size="sm" disabled>
                            <i class="far fa-edit"></i>
                          </Button>
                        )}
                            {user.donadores_create ?(
                              <Button color="success" id="Password" size="sm" onClick={e=>jalaInfoPassword(f._id)}>
                                <i class="fas fa-user-lock"></i>
                              </Button>
                              ):(
                                <Button color="success" id="Password" size="sm" disabled>
                                <i class="fas fa-user-lock"></i>
                              </Button>
                              )}

                             {user.familias_create &&
                               f.is_active == "Si" ? (
                                <Button
                                color="danger"
                                id="Baja"
                                onClick={e=>Baja(f._id, f.apellido_paterno, f.apellido_materno)}
                                size="sm"
                              >
                                <i class="far fa-times-circle"></i>
                              </Button>
                               ) : undefined
                             } 
                        {user.familias_create &&
                               f.is_active == "No" ? (
                                <Button color="primary" onClick={e=>Alta(f._id)} size="sm">
                                <i class="far fa-check-circle"></i>
                              </Button>
                               ) : undefined
                             } 

                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : undefined}

        <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <PasswordFamilias idFamilia={idPassword}/>
                  </ModalBody>
                </Modal>


        <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
          <ModalHeader toggle={toggleEdit}>
            <h4>
              Editar Informacion de la Familia {codigo_familia}{" "}
              {apellido_paterno} {apellido_materno}
            </h4>
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={4}>
                <Label>Codigo de Familia</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo_familia}
                  required
                  onChange={(e) => {
                    setCodigoFamilia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Apellido Paterno</Label>
                <Input
                  type="text"
                  placeholder="Apellido Paterno"
                  value={apellido_paterno}
                  required
                  onChange={(e) => {
                    setApellidoPaterno(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Apellido Materno</Label>
                <Input
                  type="text"
                  placeholder="Apellido Materno"
                  value={apellido_materno}
                  required
                  onChange={(e) => {
                    setApellidoMaterno(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={4}>
                <Label>Nombre Papa</Label>
                <Input
                  type="text"
                  placeholder="Nombre Papa"
                  value={nombre_papa}
                  required
                  onChange={(e) => {
                    setNombrePapa(e.target.value);
                  }}
                />
              </Col>

              <Col md={4}>
                <Label>Telefono Papa</Label>
                <Input
                  type="text"
                  placeholder="Telefono Papa"
                  value={telefono_papa}
                  required
                  onChange={(e) => {
                    setTelefonoPapa(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Email Papa</Label>
                <Input
                  type="email"
                  placeholder="Email Papa"
                  value={email_papa}
                  required
                  onChange={(e) => {
                    setEmailPapa(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={4}>
                <Label>Nombre Mama</Label>
                <Input
                  type="text"
                  placeholder="Nombre Mama"
                  value={nombre_mama}
                  required
                  onChange={(e) => {
                    setNombreMama(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Telefono Mama</Label>
                <Input
                  type="text"
                  placeholder="Telefono Mama"
                  value={telefono_mama}
                  required
                  onChange={(e) => {
                    setTelefonoMama(e.target.value);
                  }}
                />
              </Col>

              <Col md={4}>
                <Label>Email Mama</Label>
                <Input
                  type="email"
                  placeholder="Email Mama"
                  value={email_mama}
                  required
                  onChange={(e) => {
                    setEmailMama(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={4}>
                <Label>Telefono Casa</Label>
                <Input
                  type="text"
                  placeholder="Telefono Casa"
                  value={telefono_casa}
                  required
                  onChange={(e) => {
                    setTelefonoCasa(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Contacto Emergencia</Label>
                <Input
                  type="text"
                  placeholder="Contacto Emergencia"
                  value={contacto_emergencia}
                  required
                  onChange={(e) => {
                    setContactoEmergencia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Telefono Emergencia</Label>
                <Input
                  type="text"
                  placeholder="Telefono Emergencia"
                  value={telefono_emergencia}
                  required
                  onChange={(e) => {
                    setTelefonoEmergencia(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={8}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <Label>Observaciones</Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Cobrador</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColaborador}
                  required
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                  }}
                >
                  <option value={colaboradoresId}>
                    {colaboradoresNombre + " " + colaboradoresApellido}
                  </option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      if (a.cobrador == "Si") {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={editFamilia}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <br />
      {loader}
    </>
  );
}

export default ListadoFamilias;
