import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function MateriasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_MATERIAS = process.env.REACT_APP_URL_MATERIAS;
  const [name, setName] = useState("");
  const [text, setText] = useState(false);
  const [seccion, setSeccion] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
    setValidaBoton(true)
  };

  const saveMaterias = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    axios
      .post(
        URL_MATERIAS,
        {
          name,
          seccion,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Materia',
            detalle: `${name} / ${seccion}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea una Materia nueva</h3>
        <Form onSubmit={saveMaterias}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Materia
            </Label>
            <Input
              type="text"
              name="email"
              id="exampleEmail"
              placeholder="Nombre de la materia"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Label for="exampleEmail" className="mr-sm-2">
              Seccion
            </Label>
            <Input
              type="select"
              placeholder="Seccion"
              value={seccion}
              required
              onChange={(e) => {
                setSeccion(e.target.value);
              }}
            >
              <option value="0">Seccion</option>
              <option value="Español">Español</option>
              <option value="Hebreo">Hebreo</option>
            </Input>
          </FormGroup>
          <br />
          <Row>
          {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                // text="SweetAlert in React"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button
              href="/MenuEscuelas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default MateriasCreate;
