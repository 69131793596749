import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../layout/Header/Header";
import TablaEdoCtaFamilias from "./TablaEdoCtaFamilias";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EdoCtaFamInfo from "./EdoCtaFamInfo";
import FamiliasInfo from "../Alumnos/Familias/FamiliasInfo";

function EstadoCuentaFamilias() {
  const { user } = useContext(AuthContext);
  const URL_ABONOS_COLEGIATURAS = process.env.REACT_APP_URL_ABONOS_COLEGIATURAS;
  const URL_ABONOS_INSCRIPCIONES =
    process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
  const URL_CARGOS_COLEGIATURAS = process.env.REACT_APP_URL_CARGOS_COLEGIATURAS;
  const URL_CARGOS_INSCRIPCIONES =
    process.env.REACT_APP_URL_CARGOS_INSCRIPCIONES;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EDO_CTA_FAMILIAS = process.env.REACT_APP_URL_EDO_CTA_FAMILIAS;

  const [abonosColegiaturas, setAbonosColegiaturas] = useState([]);
  const [abonosInscripciones, setAbonosInscripciones] = useState([]);
  const [cargosColegiaturas, setCargosColegiaturas] = useState([]);
  const [cargosInscripciones, setCargosInscripciones] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [concepto, setConcepto] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [pendiente, setPendiente] = useState("");

  const [idFamilia, setIDFamilia] = useState("");
  const [IDFamiliaInfo, setIDFamiliaInfo] = useState("");
  const [nameFamilia, setNameFamilia] = useState("");
  const [emailPapa, setEmailPapa] = useState("");
  const [emailMama, setEmailMama] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  // const [fecha, setFecha] = useState("");
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [value, setValue] = useState();

  const [modalSaldo, setModalSaldo] = useState(false);
  const toggleSaldo = () => setModalSaldo(!modalSaldo);

  const [modalInfo, setModalInfo] = useState(false);
  const toggleInfo = () => setModalInfo(!modalInfo);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useEffect(() => {
    axios
      .get(URL_CARGOS_COLEGIATURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosColegiaturas = response.data;
        setCargosColegiaturas(allCargosColegiaturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CARGOS_INSCRIPCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosInscripciones = response.data;
        setCargosInscripciones(allCargosInscripciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_COLEGIATURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonosColegiaturas = response.data;
        setAbonosColegiaturas(allAbonosColegiaturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_INSCRIPCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonosInscripciones = response.data;
        setAbonosInscripciones(allAbonosInscripciones);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Array Cargos
  const arrayCargosColegiaturas = cargosColegiaturas.map((n) => {
    return {
      idCargo: n.idCargoColegiatura,
      fecha: n.fecha,
      movimiento: "Cargo",
      concepto: "Colegiatura",
      nombrePapa: n.familias[0].nombre_papa,
      nombreMama: n.familias[0].nombre_mama,
      name: [
        n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno,
      ],
      codigoFamilia: n.familias[0].codigo_familia,
      cargos: n.importe,
      abonos: 0,
      saldo: n.importe,
      forma_pago: "NA",
      alumno: n.alumnos[0].name,
      idFamilia: n.familias[0]._id,
      emailPapa: n.familias[0].email_papa,
      emailMama: n.familias[0].email_mama,
      is_active: n.familias[0].is_active,
    };
  });

  const arrayCargosInscripciones = cargosInscripciones.map((n) => {
    return {
      idCargo: n.idCargoInscripcion,
      fecha: n.fecha,
      movimiento: "Cargo",
      concepto: "Inscripcion",
      nombrePapa: n.familias[0].nombre_papa,
      nombreMama: n.familias[0].nombre_mama,
      name: [
        n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno,
      ],
      codigoFamilia: n.familias[0].codigo_familia,
      cargos: n.importe,
      abonos: 0,
      saldo: n.importe,
      forma_pago: "NA",
      alumno: n.alumnos[0].name,
      idFamilia: n.familias[0]._id,
      emailPapa: n.familias[0].email_papa,
      emailMama: n.familias[0].email_mama,
      is_active: n.familias[0].is_active,
    };
  });
  const arrayCargos = arrayCargosColegiaturas
    .concat(arrayCargosInscripciones)
    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1));

  const arrayFinalCargos = arrayCargos.filter(function (el) {
    return el != null;
  });

  //Array Abonos
  const arrayAbonosColegiaturas = abonosColegiaturas.map((n) => {
    return {
      idAbono: n.idAbonoColegiatura,
      fecha: n.fecha,
      movimiento: "Abono",
      concepto: "Colegiatura",
      nombrePapa: n.familias[0].nombre_papa,
      nombreMama: n.familias[0].nombre_mama,
      name: [
        n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno,
      ],
      codigoFamilia: n.familias[0].codigo_familia,
      cargos: 0,
      abonos: n.importe,
      saldo: -n.importe,
      forma_pago: n.forma_pago,
      idFamilia: n.familias[0]._id,
      emailPapa: n.familias[0].email_papa,
      emailMama: n.familias[0].email_mama,
      alumno: "NA",
      is_active: n.familias[0].is_active,
    };
  });

  const arrayAbonosInscripciones = abonosInscripciones.map((n) => {
    return {
      idAbono: n.idAbonoInscripcion,
      fecha: n.fecha,
      movimiento: "Abono",
      concepto: "Inscripcion",
      nombrePapa: n.familias[0].nombre_papa,
      nombreMama: n.familias[0].nombre_mama,
      name: [
        n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno,
      ],
      codigoFamilia: n.familias[0].codigo_familia,
      cargos: 0,
      abonos: n.importe,
      saldo: -n.importe,
      forma_pago: n.forma_pago,
      idFamilia: n.familias[0]._id,
      emailPapa: n.familias[0].email_papa,
      emailMama: n.familias[0].email_mama,
      alumno: "NA",
      is_active: n.familias[0].is_active,
    };
  });
  const arrayAbonos = arrayAbonosColegiaturas
    .concat(arrayAbonosInscripciones)
    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1));

  const arrayFinalIngresos = arrayAbonos.filter(function (el) {
    return el != null;
  });

  //Juntamos Arrays
  const arrayMovimientos = arrayFinalIngresos.concat(arrayFinalCargos);

  //   Agrupar abonos y cargos por familia
  const groupByFamilia = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        idFamilia: item.idFamilia,
        cargos: 0,
        abonos: 0,
        saldo: 0,
      };
      groups[val].cargos += item.cargos;
      groups[val].abonos += item.abonos;
      groups[val].saldo += item.saldo;
      groups[val].idFamilia = item.idFamilia;
      groups[val].name = item.name;
      groups[val].nombrePapa = item.nombrePapa;
      groups[val].nombreMama = item.nombreMama;
      groups[val].codigoFamilia = item.codigoFamilia;
      groups[val].alumno = item.alumno;
      groups[val].emailPapa = item.emailPapa;
      groups[val].emailMama = item.emailMama;
      return groups;
    }, []);
  };

  const movimientosAgrupado = groupByFamilia(arrayMovimientos, "idFamilia");
  const dataFinalMovimientos = Object.values(movimientosAgrupado).sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  // PDF
  let totalCargos = 0;
  let totalAbonos = 0;
  let totalSaldo = 0;

  let tablaCargos = 0;
  let tablaAbonos = 0;
  let tablaSaldo = 0;

  function PDFTabla() {
    const data = dataFinalMovimientos.map((c) => {
      if (
        (pendiente == 0 || (pendiente < c.saldo && c.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha_visita) &&
        (value == null || value._id == c.idFamilia)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(c.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(c.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(c.saldo);
        totalCargos = totalCargos + c.cargos;
        totalAbonos = totalAbonos + c.abonos;
        totalSaldo = totalSaldo + c.saldo;
        return [
          c.codigoFamilia,
          c.nombrePapa,
          c.nombreMama,
          c.name,
          cargo,
          abono,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 17, 25);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo Familia",
          "Nombre Papa",
          "Nombre Mama",
          "Apellidos",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Movimientos.pdf`);
  }

  function excel() {
    const dataExcel = dataFinalMovimientos.map((c) => {
      if (
        (pendiente == 0 || (pendiente < c.saldo && c.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha_visita) &&
        (value == null || value._id == c.idFamilia)
      ) {
        return {
          CodigoFamilia: c.codigoFamilia,
          NombrePapa: c.nombrePapa,
          NombreMama: c.nombreMama,
          Apellidos: c.name,
          Cargos: c.cargos,
          Abonos: -c.abonos,
          Saldo: c.saldo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "SaldoFamilias";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "SaldoFamilias",
        sheetFilter: [
          "CodigoFamilia",
          "NombrePapa",
          "NombreMama",
          "Apellidos",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
        sheetHeader: [
          "CodigoFamilia",
          "NombrePapa",
          "NombreMama",
          "Apellidos",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = dataFinalMovimientos.map((c) => {
      if (
        (pendiente == 0 || (pendiente < c.saldo && c.saldo != 0)) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha_visita) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha_visita) &&
        (value == null || value._id == c.idFamilia)
      ) {
        const cargo = "$" + new Intl.NumberFormat("en-US").format(c.cargos);
        const abono = "-$" + new Intl.NumberFormat("en-US").format(c.abonos);
        const saldos = "$" + new Intl.NumberFormat("en-US").format(c.saldo);
        totalCargos = totalCargos + c.cargos;
        totalAbonos = totalAbonos + c.abonos;
        totalSaldo = totalSaldo + c.saldo;
        return [
          c.codigoFamilia,
          c.nombrePapa,
          c.nombreMama,
          c.name,
          cargo,
          abono,
          saldos,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 17, 25);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo Familia",
          "Nombre Papa",
          "Nombre Mama",
          "Apellidos",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
      var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Saldo Familia",
          email: mailTo,
          fileName: "SaldoFamilias.pdf",
          att: base64File,
          text: 
          `<!DOCTYPE html>
          <html>
            <head>
              <style>                                
                .img-container {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                height: 90px;
                                width: auto;
                              }
              </style>
            </head>
            <body>
              <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
              <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
              <h3>Hola,</h3>
              <h3>Adjunto encontraras el saldo de las Familias.</h3>
              <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
              Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
            </body>
          </html>` 
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function enviarEdoCtaMasivo() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se enviara un mail a cada donador con su estado de cuenta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalMails = familias.length;
        familias.map((a) => {
          if (a.is_active == "Si") {
            axios
              .post(
                URL_EDO_CTA_FAMILIAS,
                {
                  familias: a._id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalMails = totalMails - 1;
          }
        });
      }
    });
  }

  const options = familias.map((option) => {
    const junta =
      option.apellido_paterno +
      " " +
      option.apellido_materno +
      " / " +
      option.codigo_familia;
    const firstLetter = option.apellido_paterno[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaInfo(id, nameFamilia, emailPapa, emailMama) {
    setIDFamilia(id);
    setNameFamilia(nameFamilia);
    setEmailPapa(emailPapa);
    setEmailMama(emailMama);
    toggleSaldo();
  }

  function Info(id) {
    setIDFamiliaInfo(id);
    toggleInfo();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_colegiaturas ? (
        <div className="row">
          <div className="col-12">
            <div className="card container">
              <div className="card-body">
                <Row>
                  <Col sm={8}>
                    <Button
                      size="sm"
                      href="/MenuCobranza"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={enviarEdoCtaMasivo}
                    >
                      <i class="fas fa-paper-plane"></i> Edo Cta Masivo
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Saldo de Familias</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>

                <h3 align="center">Estado de Cuenta</h3>

                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                      <th style={{ paddingBottom: "0px" }}>Familia</th>
                      <th style={{ paddingBottom: "0px" }}>Cargos</th>
                      <th style={{ paddingBottom: "0px" }}>Abonos</th>
                      <th style={{ paddingBottom: "0px" }}>Saldo</th>
                      <th style={{ paddingBottom: "0px" }}>Edo Cta</th>
                      <th style={{ paddingBottom: "0px" }}>Info</th>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: "0px" }}>
                        <Autocomplete
                          size="small"
                          value={value}
                          onChange={(event, selectedFamilia) => {
                            setValue(selectedFamilia);
                          }}
                          options={options.sort(
                            (a, b) =>
                              -b.firstLetter.localeCompare(a.firstLetter)
                          )}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.junta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.apellido_paterno}{" "}
                              {option.apellido_materno} /{" "}
                              {option.codigo_familia}
                            </React.Fragment>
                          )}
                        />
                      </td>

                      <td></td>
                      <td></td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={pendiente}
                          required
                          onChange={(e) => setPendiente(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          <option value=".000000001">Solo Pendientes</option>
                        </Input>
                      </td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFinalMovimientos.map((c) => {
                      if (
                        (pendiente == 0 ||
                          (pendiente < c.saldo && c.saldo != 0)) &&
                        (selectedFechaInicio == 0 ||
                          selectedFechaInicio <= c.fecha_visita) &&
                        (selectedFechaFin == 0 ||
                          selectedFechaFin >= c.fecha_visita) &&
                        (value == null || value._id == c.idFamilia)
                      ) {
                        tablaCargos = tablaCargos + c.cargos;
                        tablaAbonos = tablaAbonos + c.abonos;
                        tablaSaldo = tablaSaldo + c.saldo;
                        return (
                          <tr>
                            <td>
                              {c.codigoFamilia} {c.name}
                            </td>
                            <td align="center">
                              {"$" +
                                new Intl.NumberFormat("en-US").format(
                                  c.cargos
                                )}{" "}
                            </td>
                            <td align="center">
                              {"-$" +
                                new Intl.NumberFormat("en-US").format(
                                  c.abonos
                                )}{" "}
                            </td>
                            <td align="center">
                              {"$" +
                                new Intl.NumberFormat("en-US").format(
                                  c.saldo
                                )}{" "}
                            </td>

                            <td align="center">
                              {user.colegiaturas_edo_cta ? (
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={(e) =>
                                    jalaInfo(
                                      c.idFamilia,
                                      c.name,
                                      c.emailPapa,
                                      c.emailMama
                                    )
                                  }
                                >
                                  <i class="fas fa-file-invoice-dollar"></i>
                                </Button>
                              ) : (
                                <Button color="info" size="sm" disabled>
                                  <i class="fas fa-file-invoice-dollar"></i>
                                </Button>
                              )}
                            </td>
                            <td align="center">
                              <Button
                                color="info"
                                size="sm"
                                onClick={(e) => Info(c.idFamilia)}
                              >
                                <i class="fas fa-info"></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      <td className="negrita" align="center">
                        TOTALES
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaCargos)}
                      </td>
                      <td className="negrita" align="center">
                        {"-$" +
                          new Intl.NumberFormat("en-US").format(tablaAbonos)}
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaSaldo)}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <Modal size="xl" isOpen={modalSaldo} toggle={toggleSaldo}>
                  <ModalHeader toggle={toggleSaldo}>
                    <h4>Estado de Cuenta {nameFamilia}</h4>
                  </ModalHeader>
                  <ModalBody>
                    <EdoCtaFamInfo
                      idFamilia={idFamilia}
                      nameFamilia={nameFamilia}
                      emailPapa={emailPapa}
                      emailMama={emailMama}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={toggleSaldo}>
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal size="sm" isOpen={modalInfo} toggle={toggleInfo}>
                  <ModalHeader toggle={toggleInfo}>
                    <h4>Informacion de la Familia</h4>
                  </ModalHeader>
                  <ModalBody>
                    <FamiliasInfo idFamilias={IDFamiliaInfo} />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={toggleInfo}>
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>
               <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default EstadoCuentaFamilias;
