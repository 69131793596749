import React from 'react';
function TablaColegiaturasInfo(props) {    
    return (
        <>
            <td>{props.fecha}</td>
            <td>{props.movimiento}</td>
            <td>{props.concepto}</td>
            <td>{props.al_fam} </td>
            <td>{props.forma_pago} </td>
            <td align="center">{"$" + new Intl.NumberFormat("en-US").format(props.cargos)}</td>
            <td align="center">{"$" + new Intl.NumberFormat("en-US").format(props.abonos)}</td>
            <td align="center">{"$" + new Intl.NumberFormat("en-US").format(props.saldoParcialTabla)}</td>                  
        </>
    )
}

export default TablaColegiaturasInfo

