import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function PeriodosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PERIODOS = process.env.REACT_APP_URL_PERIODOS;
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const [name, setName] = useState("");
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const [ciclos, setCiclos] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedCiclo, setSelectedCiclo] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
    setFechaInicio("");
    setFechaFin("");
    setSelectedCiclo();
    setSelectedArea();
    setValidaBoton(true);
  };

  useEffect(() => {
    axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCiclos = res.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const savePeriodos = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_PERIODOS,
        {
          name,
          fecha_inicio,
          fecha_fin,
          ciclos: selectedCiclo,
          areas: selectedArea,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Periodo",
            detalle: `${name} / ${selectedCiclo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_escuela && user.menu_periodos ? (
        <div className="content">
          <div className="card container col-sm-4">
            <h3 align="center">Crea un Periodo nuevo</h3>
            <Form onSubmit={savePeriodos}>
              <Label for="exampleEmail" className="mr-sm-2">
                Periodo
              </Label>
              <Input
                type="text"
                name="email"
                id="exampleEmail"
                placeholder="Nombre del Periodo"
                value={name}
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Row>
                <Col sm={6}>
                  <Label className="mr-sm-2">Ciclo</Label>
                  <Input
                    type="select"
                    value={selectedCiclo}
                    required
                    onChange={(e) => {
                      setSelectedCiclo(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona un Ciclo</option>
                    {ciclos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((c) => {
                        return <option value={c._id}>{c.name}</option>;
                      })}
                  </Input>
                </Col>
                <Col sm={6}>
                  <Label className="mr-sm-2">Area</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    required
                    onChange={(e) => {
                      setSelectedArea(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona un Area</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((d) => {
                        return <option value={d._id}>{d.name}</option>;
                      })}
                  </Input>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Label>Fecha Inicio</Label>
                  <Input
                    type="date"
                    value={fecha_inicio}
                    required
                    onChange={(e) => {
                      setFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label>Fecha Fin</Label>
                  <Input
                    type="date"
                    value={fecha_fin}
                    required
                    onChange={(e) => {
                      setFechaFin(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      // text="SweetAlert in React"
                      onConfirm={() => {
                        setText(false);
                        window.location.href = "/Periodos";
                      }}
                    />
                  </div>
                  <Button
                    href="Periodos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
      
    </>
  );
}

export default PeriodosCreate;
