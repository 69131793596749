import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader, Label, Input } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"

function TablaListas(props) {
  
  const { user } = useContext(AuthContext);
  const URL_ALUMNOS_EDIT = `${process.env.REACT_APP_URL_ALUMNOS}/${props.idAlumno}`;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(props.idClase);
  const [alumnos, setAlumnos] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  useEffect(() => {
  axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);

  function editAlumnos(event) {
    event.preventDefault();
    axios
      .patch(
        URL_ALUMNOS_EDIT,
        {
          clases: selectedClase,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
          <td>{props.num}</td>
          <td>{props.apellido_paterno}{" "}{props.apellido_materno} {props.name}</td>
          <td>{props.plantel}</td>
          <td>{props.area}</td>
          <td>{props.grado}</td>
          <td>{props.clase}</td>
          <td>
          {user.alumnos_create  ? (
        <Button color="info" id="Editar" size="sm" onClick={toggleEdit}>
          <i class="far fa-edit"></i>
        </Button>
        ) : (<Button color="info" id="Editar" size="sm" disabled>
        <i class="far fa-edit"></i>
      </Button>)} 
          </td>
          <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}><h4>
          Cambiar de Clase a {props.name} {props.apellido_paterno} {props.apellido_materno}</h4>
        </ModalHeader>
        <ModalBody>
        <Label className="mr-sm-2">Clase</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedClase}
                required
                onChange={(i) => {
                  setSelectedClase(i.target.value);
                }}
              >
                <option value="1">Selecciona su Clase</option>
                {clases.map((p) => {
                  return (
                    <option value={p._id}>
                      {p.grados[0].name} {p.name} {p.grados[0].areas[0].name}{" "}
                      {p.planteles[0].name}
                    </option>
                  );
                })}
              </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editAlumnos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaListas;
