import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from '../../Baja'

function TablaClases(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLASES_EDIT = `${process.env.REACT_APP_URL_CLASES}/${props._id}`;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [name, setName] = useState(props.name);
  const [clases, setClases] =useState('')
  const [grados, setGrados] = useState([]);
  const [planteles, setPlanteles] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(props.idGrado)
  const [selectedPlantel, setSelectedPlantel] = useState(props.idPlantel)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);
  
  
  useEffect(() => {
    axios
      .get(URL_CLASES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClases = response.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function editClase(event) {
    event.preventDefault();
    axios
      .patch(
        URL_CLASES_EDIT,
        {
          name,
          grados: selectedGrado,
          planteles: selectedPlantel
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Clase',
            detalle: `${name} / ${selectedGrado}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <td>
        {props.grado} {props.name} 
      </td>
      <td>{props.areas}</td>
      <td>{props.plantel}</td>
      <td>
      <Button color="info" size="sm" id="Editar" onClick={toggleEdit}>
          <i class="far fa-edit"></i>
        </Button>
          <Baja idStatus={props._id} is_active={props.is_active} URL_BAJA={process.env.REACT_APP_URL_CLASES} />
      </td>
      <Modal isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar Clase</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {user.areas == AREAS_GENERAL? (
                <div>
          <Label className="mr-sm-2">Grado</Label>
            <Input
              className="col-sm-12"
              type="select"
              value={selectedGrado}
              required
              onChange={(e) => {
                setSelectedGrado(e.target.value);
              }}
            >
              <option value={props.idGrado}>{props.grado} {props.areas}</option>
              {grados
               .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((a) => {
                return <option value={a._id}>{a.name} {a.areas[0].name}</option>;
              })}
            </Input>
            </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Grado</Label>
                  <Input
                    type="select"
                    value={selectedGrado}
                    onChange={(e) => setSelectedGrado(e.target.value)}
                  >
                    <option value="0">Selecciona un Grado </option>
                    {grados
                     .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                       if (a.areas[0]._id == user.areas) {
                return <option value={a._id}>{a.name} {a.areas[0].name}</option>;
                       }
              })}
                  </Input>
                </div>
              )}
            {user.planteles == PLANTELES_GENERAL? (
                <div>
            <Label className="mr-sm-2">Plantel</Label>
            <Input
              className="col-sm-12"
              type="select"
              value={selectedPlantel}
              required
              onChange={(e) => {
                setSelectedPlantel(e.target.value);
              }}
            >
              <option value={props.idPlantel}>{props.plantel}</option>
              {planteles.map((a) => {
                if (a._id != PLANTELES_GENERAL){
                return <option value={a._id}>{a.name}</option>}
              })}
            </Input>
            </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Planteles</Label>
                  <Input
                    type="select"
                    value={selectedPlantel}
                    onChange={(e) => setSelectedPlantel(e.target.value)}
                  >
                    <option value="0">Selecciona un Plantel </option>
                    {planteles
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (a._id == user.planteles) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </div>
              )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editClase}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaClases;
