import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../../contexts/AuthContext'
import axios from "axios";
import Header from "../../../../layout/Header/Header";
import { Button, Row, Table, Input } from "reactstrap";
import TablaInfoHijos from "./TablaInfoHijos";

function InfoHijos() {
    const { user } = useContext(AuthContext);
    const URL_ALUMNOS_FAMILIA = `${process.env.REACT_APP_URL_ALUMNOS}/familia/${user.familias}`;
    const [alumnos, setAlumnos] = useState([]);
    const [apellidoP, setApellidoP] = useState('');
    const [apellidoM, setApellidoM] = useState('');

    useEffect(() => {
        axios
          .get(URL_ALUMNOS_FAMILIA, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allAlumnos = response.data;
            setAlumnos(allAlumnos);
            let apeP = response.data[0].familias[0].apellido_paterno
            setApellidoP(apeP)
            let apeM = response.data[0].familias[0].apellido_materno
            setApellidoM(apeM)
          })
          .catch((err) => {
            console.log(err);
          });
        },[user]);

    return (
        <>
        <Header />
        <br /><br />
        {user.menu_edoCta_Fam ?(
          <div className="card container">
            <h3 align="center">Informacion Alumnos {apellidoP} {apellidoM}</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th style={{ paddingBottom: "0px" }}>Activo</th>
                        <th style={{ paddingBottom: "0px" }}>Nombre</th>
                        <th style={{ paddingBottom: "0px" }}>Plantel</th>
                        <th style={{ paddingBottom: "0px" }}>Clase</th>
                        <th style={{ paddingBottom: "0px" }}>Colegiatura</th>
                        <th style={{ paddingBottom: "0px" }}>Inscripcion</th>
                        <th style={{ paddingBottom: "0px" }}>Beca Colegiatura</th>
                        <th style={{ paddingBottom: "0px" }}>Beca Inscripcion</th>
                      </tr>
                      </thead>
                    <tbody>
                      {alumnos
                        .map((c) => {
                          return (
                            <tr>
                              <TablaInfoHijos
                                is_active={c.is_active}
                                codigoFamilia={c.familias[0].codigo_familia}
                                name={c.name}
                                genero={c.genero}
                                registro={c.registro}
                                idClase={c.clases[0]._id}
                                fecha_ingreso={c.fecha_ingreso}
                                fecha_nacimiento={c.fecha_nacimiento}
                                telefono={c.telefono}
                                observaciones={c.observaciones}
                                alergias={c.alergias}
                                tipo_sangre={c.tipo_sangre}
                                apellidoP={c.familias[0].apellido_paterno}
                                apellidoM={c.familias[0].apellido_materno}
                                plantel={c.clases[0].planteles[0].name}
                                clase={c.clases[0].name}
                                grado={c.clases[0].grados[0].name}
                                colegiatura={c.clases[0].grados[0].colegiatura}
                                inscripcion={c.clases[0].grados[0].inscripcion}
                                area={c.clases[0].grados[0].areas[0].name}
                                _idAlumno={c._id}
                                _id={c._id}
                                idFamilia={c.familias[0]._id}
                                beca_importe_col={c.beca_importe_col}
                                beca_importe_ins={c.beca_importe_ins}
                                beca_porcentaje_col={c.beca_porcentaje_col}
                                beca_porcentaje_ins={c.beca_porcentaje_ins}
                              />
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>            
            </div>
      ): undefined } 
    </>
    )
}

export default InfoHijos
