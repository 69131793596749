import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
} from "reactstrap";
import AlumnosInfo from "../../Alumnos/AlumnosInfo";

function TablaBecas(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

    let colegiatura
    let inscripcion

function calculaCol(a) {
  if(props.beca_porcentaje_col ==0){
    return(
    colegiatura = "$" + new Intl.NumberFormat("en-US").format(props.beca_importe_col)
    )
  } else{
    return(
    colegiatura = `${props.beca_porcentaje_col} %`
    )
  }  
}
calculaCol()

function calculaIns(a) {
  if(props.beca_porcentaje_ins ==0){
    return(
    inscripcion = "$" + new Intl.NumberFormat("en-US").format(props.beca_importe_ins)
    )
  } else{
    return(
    inscripcion = `${props.beca_porcentaje_ins} %`
    )
  }  
}
calculaIns()

    return (
        <>
      <td>{props.codigoFamilia}</td>
      <td>{props.name}</td>
      <td>{props.apellidoP}</td>
      <td>{props.apellidoM} </td>
      <td>{props.plantel} </td>
      <td>{props.area}</td>
      <td>{props.grado} {props.clase}</td>
    <td align="center">{colegiatura}</td>
    <td align="center">{inscripcion}</td>
    <td align="center">
        <span>
          <Button color="dany" size="sm" onClick={toggle} id="Detalle">
            <i class="fas fa-file-alt"></i>
          </Button>
        </span>

        <Modal size="sm" isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Informacion del Alumno</ModalHeader>
          <ModalBody>
            <AlumnosInfo _idAlumno={props._idAlumno} />
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={toggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
        </td>
        </>
    )
}

export default TablaBecas
