import React, { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import axios from "axios";
import AgregarClasesMaestro from "./AgregarClasesMaestro";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import ClasesBajaMaestros from "./ClasesBajaMaestros";

function ClasesInfoMaestro(props) {
  let idColaborador = props.idColaborador;
  const URL_COLABORADORES_CLASES = `${process.env.REACT_APP_URL_COLABORADORES}/${idColaborador}`;
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState("");
  const [text, setText] = useState(false);

  useEffect(() => {
    axios
      .get(URL_COLABORADORES_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClases = response.data.clases;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  


  return (
    <>
      <div className="card container">
        <Table size="sm" borderless>
          <tbody>
            {clases.map((a) => {
              return (
                <table>
                  <td>
                    <ClasesBajaMaestros idColaborador={idColaborador} idClase={a._id} clases={clases} />
                  </td>
                  
                  <td>{a.grados[0].name} {a.name} {a.grados[0].areas[0].name} {a.planteles[0].name}</td>
                </table>
              );
            })}
          </tbody>
        </Table>
      </div>
      <AgregarClasesMaestro idColaborador={idColaborador} clases={clases} />
    </>
  );
}

export default ClasesInfoMaestro;
