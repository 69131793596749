import React, { useState, useEffect, useContext, useMemo } from "react";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TablaCobranza from "./TablaCobranza";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DonadoresInfo from "../Donadores/DonadoresInfo";
import DonativosInfo from "./DonativosInfo";

function ListadoCobranza() {
  const { user } = useContext(AuthContext);
  const URL_DONATIVOS = process.env.REACT_APP_URL_DONATIVOS;
  const URL_ABONOS_DONATIVOS = process.env.REACT_APP_URL_ABONOS_DONATIVOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
  const [donador, setDonador] = useState([]);
  const [fecha_visita, setFechaVisita] = useState("");
  const [hora_visita, setHoraVisita] = useState("");
  const [compromiso, setCompromiso] = useState("");
  const [abonosDonativos, setAbonosDonativos] = useState([]);
  const [cobradores, setCobradores] = useState([]);
  const [selectedCobrador, setSelectedCobrador] = useState("");
  const [donativos, setDonativos] = useState([]);
  const [pendiente, setPendiente] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [fecha, setFecha] = useState("");
  const [concepto, setConcepto] = useState("");
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [value, setValue] = useState();

  const [cargosDonador, setCargosDonador] = useState([]);
  const [abonosDonador, setAbonosDonador] = useState([]);
  const [donadores, setDonadores] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [selectedUltimoPagoInicio, setSelectedUltimoPagoInicio] = useState("");
  const [selectedUltimoPagoFin, setSelectedUltimoPagoFin] = useState("");


  const [modalInfo, setModalInfo] = useState(false);
  const toggleInfo = () => setModalInfo(!modalInfo);
  const [idDonadorInfo, setIdDonadorInfo] = useState([]);

  const [modalSaldo, setModalSaldo] = useState(false);
  const toggleSaldo = () => setModalSaldo(!modalSaldo);
  const [idDonadorSaldo, setIdDonadorSaldo] = useState([]);
  const [nombre, setNombre] = useState([]);
  const [apellido, setApellido] = useState([]);
  const [email, setEmail] = useState([]);

  const [modalVisita, setModalVisita] = useState(false);
  const toggleVisita = () => setModalVisita(!modalVisita);
  const [idDonadorVisita, setIdDonadorVisita] = useState([]);
  const [nombreVisita, setNombreVisita] = useState([]);
  const [apellidoVisita, setApellidoVisita] = useState([]);

  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [activo, setActivo] = useState("");

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
  
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCobradores = response.data;
        setCobradores(allCobradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonadores = response.data;
        let arrayTabla = allDonadores
        .sort((a, b) => (a.apellido > b.apellido ? 1 : -1))
        .map((a) => {
          return {
            idDonador:a._id,
            donador: a.apellido + " " + a.nombre,
            nombre: a.nombre,
            apellido: a.apellido,
            ultimo_pago: a.ultimo_pago,
            fecha_visita: a.fecha_visita,
            hora_visita: a.hora_visita,
            cobrador:a.colaboradores[0].nombre +" " + a.colaboradores[0].apellido,
            forma_pago: a.forma_pago,
            email: a.email,
            compromiso: a.compromiso,
            idCobrador: a.colaboradores[0]._id
          };
        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
        setDonadores(allDonadores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 

 

  const options = donadores.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function saldo(idDonador, nombre, apellido, email){
    setIdDonadorSaldo(idDonador)
    setNombre(nombre)
    setApellido(apellido)
    setEmail(email)
    toggleSaldo()
  }

  function info(idDonador){
    setIdDonadorInfo(idDonador)
    toggleInfo()
  }

  function editarVisita(idDonador, nombre, apellido, hora_visita, compromiso, fecha_visita){
    setIdDonadorVisita(idDonador)
    setNombreVisita(nombre)
    setApellidoVisita(apellido)
      setHoraVisita(hora_visita)
      setCompromiso(compromiso)
      setFechaVisita(fecha_visita)
      toggleVisita()
   
  }

  function guardarVisita(){

    axios
    .patch(
      `${URL_DONADORES}/${idDonadorVisita}`,
      {
        fecha_visita,
        hora_visita,
        compromiso
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios
      .post(
        URL_LOGS,
        {
          tipo:'Editar Visita Donador',
          detalle: `${nombreVisita} ${apellidoVisita} / Fecha: ${fecha_visita} / Hora: ${hora_visita}`,
          user: user.id
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      toggle();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
  }

  const headers = [
    { name: "Donador", field: "donador", sortable: true },
    { name: "Ultimo Pago", field: "ultimo_pago", sortable: false },
    { name: "Proxima Visita", field: "fecha_visita", sortable: false },
    { name: "Hora", field: "hora_visita", sortable: false },
    { name: "Compromiso", field: "compromiso", sortable: false },
    { name: "Cobrador", field: "cobrador", sortable: true },
    { name: "Forma Pago", field: "forma_pago", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

const commentsData = useMemo(() => {
  let computedComments = comments

  if (search) {
      computedComments = computedComments.filter(
          comment =>
              comment.donador.toLowerCase().includes(search.toLowerCase()) ||
              comment.cobrador.toLowerCase().includes(search.toLowerCase()) ||
              comment.forma_pago.toLowerCase().includes(search.toLowerCase())
      );
  }

  if(selectedCobrador){
    computedComments = computedComments.filter((e)=>
    e.idCobrador.includes(selectedCobrador)
    ) 
  }

  if(forma_pago){
    computedComments = computedComments.filter((e)=>
    e.forma_pago.includes(forma_pago)
    ) 
  }

  if(selectedUltimoPagoInicio){
    computedComments = computedComments.filter((e)=>
    e.ultimo_pago >= selectedUltimoPagoInicio
    ) 
  }

  if(selectedUltimoPagoFin){
    computedComments = computedComments.filter((e)=>
    e.ultimo_pago <= selectedUltimoPagoFin
    ) 
  }

  if(selectedFechaInicio){
    computedComments = computedComments.filter((e)=>
    e.fecha_visita >= selectedFechaInicio
    ) 
  }

  if(selectedFechaFin){
    computedComments = computedComments.filter((e)=>
    e.fecha_visita <= selectedFechaFin
    ) 
  }


  setTotalItems(computedComments.length);

  //Sorting comments
  if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
          (a, b) =>
              reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
  }

  //Current Page slice
  return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [comments, currentPage, search, sorting, selectedCobrador, forma_pago , selectedFechaInicio, selectedFechaFin, selectedUltimoPagoInicio, selectedUltimoPagoFin]);

// PDF

function PDFTabla() {
  const data = donadores.map((a) => {
    if (
      (selectedCobrador == 0 || selectedCobrador == a.colaboradores[0]._id) &&
      (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
      (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
      (selectedUltimoPagoInicio == 0 ||
        selectedUltimoPagoInicio <= a.ultimo_pago) &&
      (selectedUltimoPagoFin == 0 ||
        selectedUltimoPagoFin >= a.ultimo_pago)
    ) {

      return [
        a.apellido + " " + a.nombre,
        a.calle + " " + a.numero_ext + " " + a.numero_int,
        a.telefono,
        a.ejecutivo[0].nombre + " " + a.ejecutivo[0].apellido,
        a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
        a.ultimo_pago,
        a.fecha_visita,
        a.hora_visita,
        a.compromiso,
      ];
    }
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF("landscape");
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 30, 45, 15);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 140, 15, 17, 25);
  doc.text(`Listado Cobranza`, 20, 40);
  doc.autoTable({
    head: [
      [
        "Nombre",
        "Direccion",
        "Telefono",
        "Ejecutivo",
        "Cobrador",
        "Ult. Pago",
        "Visita",
        "Hora",
        "Compromiso",
        "Donativo",
      ],
    ],
    body: dataPDFLimpia,
    startY: 45,
    foot: [
      [
        "",
        "",
        "",
        "",
      ],
    ],
    showFoot: "lastPage",
  });
  doc.save(`Cobranza.pdf`);
}

function excel() {
  const dataExcel = donadores.map((a) => {
    if (
      (selectedCobrador == 0 || selectedCobrador == a.colaboradores[0]._id) &&
      (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
      (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
      (selectedUltimoPagoInicio == 0 ||
        selectedUltimoPagoInicio <= a.ultimo_pago) &&
      (selectedUltimoPagoFin == 0 ||
        selectedUltimoPagoFin >= a.ultimo_pago)
    ) {
      return {
        Nombre: a.apellido + " " + a.nombre,
        Direccion: a.calle + " " + a.numero_ext + " " + a.numero_int,
        Telefono: a.telefono,
        Cobrador: a.colaboradores[0].nombre +" " + a.colaboradores[0].apellido,
        UltimoPago: a.ultimo_pago,
        ProximaVisita: a.fecha_visita,
        HoraVisita: a.hora_visita,
        compromiso: a.compromiso,
      };
    }
  });
  const dataExportExcel = Object.values(dataExcel);
  const dataExcelLimpia = dataExportExcel.filter(function (el) {
    return el != null;
  });

  var option = {};

  option.fileName = "ListadoCobranza";

  option.datas = [
    {
      sheetData: dataExcelLimpia,
      sheetName: "ListadoCobranza",
      sheetFilter: [
        "Nombre",
        "Direccion",
        "Telefono",
        "Cobrador",
        "UltimoPago",
        "ProximaVisita",
        "HoraVisita",
        "Compromiso",
      ],
      sheetHeader: [
        "Nombre",
        "Direccion",
        "Telefono",
        "Cobrador",
        "UltimoPago",
        "ProximaVisita",
        "HoraVisita",
        "Compromiso",
      ],
    },
  ];

  var toExcel = new ExportJsonExcel(option);
  toExcel.saveExcel();
}

function enviaMail() {
  const data = donadores.map((a) => {
    if (
      (selectedCobrador == 0 || selectedCobrador == a.colaboradores[0]._id) &&
      (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha_visita) &&
      (selectedFechaFin == 0 || selectedFechaFin >= a.fecha_visita) &&
      (selectedUltimoPagoInicio == 0 ||
        selectedUltimoPagoInicio <= a.ultimo_pago) &&
      (selectedUltimoPagoFin == 0 ||
        selectedUltimoPagoFin >= a.ultimo_pago)
    ) {

      return [
        a.apellido + " " + a.nombre,
        a.calle + " " + a.numero_ext + " " + a.numero_int,
        a.telefono,
        a.contacto[0].nombre + " " + a.contacto[0].apellido,
        a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
        a.ultimo_pago,
        a.fecha_visita,
        a.hora_visita,
        a.compromiso,
      ];
    }
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF("landscape");
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 30, 45, 15);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 140, 15, 17, 25);
  doc.text(`Listado Cobranza`, 20, 40);
  doc.autoTable({
    head: [
      [
        "Nombre",
        "Direccion",
        "Telefono",
        "Ejecutivo",
        "Cobrador",
        "Ult. Pago",
        "Visita",
        "Hora",
        "Compromiso",
        "Donativo",
      ],
    ],
    body: dataPDFLimpia,
    startY: 45,
    foot: [
      [
        "",
        "",
        "",
        "",
      ],
    ],
    showFoot: "lastPage",
  });
  var att = doc.output("arraybuffer");
    var base64File = encode(att);

  // Envia el Mail
  axios
    .post(
      URL_SEND_MAIL_ATT,
      {
        subject: "Resumen Cobranza",
        email: mailTo,
        fileName: "Resumen-Cobranza.pdf",
        att: base64File,
        text: `<!DOCTYPE html>
        <html>
          <head>
            <style>                                
              .img-container {
                              display: block;
                              margin-left: auto;
                              margin-right: auto;
                              height: 90px;
                              width: auto;
                            }
            </style>
          </head>
          <body>
            <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
            <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
            <h3>Hola,</h3>
            <h3>Adjunto encontraras el resumen de cobranza.</h3>
            <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
            Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
          </body>
        </html>`
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      toggleMail();
      Swal.fire("Good job!", "Enviado con exito", "success");
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}



  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_donativos ? (
        <div className="card">
                <Row>
                  <Col sm={8}>
                    <Button
                      size="sm"
                      href="/MenuDonativos"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Resumen Donativos</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" 
                    onClick={enviaMail}
                    >
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>

                <h3 align="center">Listado Cobranza</h3>

                <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                    
                    <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
               
                    <tr>
                      <td style={{ paddingTop: "0px" }}>
                        {/* <Autocomplete
                          size="small"
                          value={value}
                          onChange={(event, selectedDonador) => {
                            setValue(selectedDonador);
                          }}
                          options={options.sort(
                            (a, b) =>
                              -b.firstLetter.localeCompare(a.firstLetter)
                          )}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.junta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecciona"
                              variant="outlined"
                            />
                          )}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.apellido} {option.nombre} /{" "}
                              {option.nombre_comercial}
                            </React.Fragment>
                          )}
                        /> */}
                      </td>

                      <td style={{ paddingTop: "0px" }}>
                        <Col md={12}>
                          <Input
                            bsSize="sm"
                            type="date"
                            value={selectedUltimoPagoInicio}
                            onChange={(e) => {
                              setSelectedUltimoPagoInicio(e.target.value)
                              setCurrentPage(1)
                            }}
                          />
                          <Input
                            bsSize="sm"
                            type="date"
                            value={selectedUltimoPagoFin}
                            onChange={(e) => {
                              setSelectedUltimoPagoFin(e.target.value)
                              setCurrentPage(1)
                            }}
                          />
                        </Col>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaInicio}
                          onChange={(e) => {
                            setSelectedFechaInicio(e.target.value)
                            setCurrentPage(1)
                          }}
                        />
                        <Input
                          bsSize="sm"
                          type="date"
                          value={selectedFechaFin}
                          onChange={(e) => {
                            setSelectedFechaFin(e.target.value)
                            setCurrentPage(1)
                          }}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCobrador}
                          required
                          onChange={(e) => {setSelectedCobrador(e.target.value)     
                                           setCurrentPage(1)}}
                        >
                          <option value="">Selecciona</option>
                          {cobradores
                            .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                            .map((a) => {
                              if (a.cobrador == "Si") {
                                return (
                                  <option value={a._id}>
                                    {a.nombre} {a.apellido}
                                  </option>
                                );
                              }
                            })}
                        </Input>
                      </td>
                      {/* <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={pendiente}
                          required
                          onChange={(e) => setPendiente(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          <option value=".000000001">Solo Pendientes</option>
                        </Input>
                      </td> */}
                      <td style={{ paddingTop: "0px" }}>
                      <Input
                      bsSize="sm"
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value)
                      setCurrentPage(1)
                    }}
                  >
                    <option value="">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                      </td>
                    </tr>
                  <tbody>
                    {commentsData.map((c) => {
                       {
                        return (
                          <tr>
                          <td>{c.donador} </td>
                          <td align="center">{c.ultimo_pago}</td>
                          <td align="center">{c.fecha_visita}</td>
                          <td align="center">{c.hora_visita}</td>
                          <td align="center">{c.compromiso}</td>
                          <td>{c.cobrador}</td>
                          <td align="center">{c.forma_pago}</td>
                          <td>
                          <Row>
                            {user.donadores_edo_cta ?(
                              <div>
                            <Button color="info" size="sm" onClick={(e)=>saldo(c.idDonador, c.nombre, c.apellido, c.email)}>
                              <i class="fas fa-file-alt"></i>
                            </Button>
                              <Button color="dany" size="sm" onClick={(e)=>info(c.idDonador)}>
                              <i class="far fa-address-card"></i>
                            </Button>
                            <Button color="success" size="sm" onClick={(e)=>editarVisita(c.idDonador, c.nombre, c.apellido, c.hora_visita, c.compromiso, c.fecha_visita)}>
                              <i class="far fa-clock"></i>
                            </Button>
                            </div>
                            ):(
                              <Button color="info" size="sm" disabled>
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            )}
                            </Row>
                          </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
                <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>

          <Modal size="xl" isOpen={modalSaldo} toggle={toggleSaldo}>
        <ModalHeader toggle={toggleSaldo}><h4>Estado de Cuenta de {apellido} {nombre} </h4></ModalHeader>
        <ModalBody>
          <DonativosInfo idDonador={idDonadorSaldo} email={email} nombre={nombre} apellido={apellido}/>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleSaldo}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>


      <Modal size="lg" isOpen={modalInfo} toggle={toggleInfo}>
        <ModalHeader toggle={toggleInfo}><h4>Informacion del Donador</h4></ModalHeader>
        <ModalBody>
          <DonadoresInfo idDonador={idDonadorInfo} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalVisita} toggle={toggleVisita}>
  <ModalHeader toggle={toggleVisita}><h4>Estado de Cuenta de {apellidoVisita} {nombreVisita} </h4></ModalHeader>
        <ModalBody>
        <Col md={6}>
                <Label>Fecha Visita</Label>
                <Input
                  type="date"
                  placeholder="Fecha Visita"
                  value={fecha_visita}
                  required
                  onChange={(e) => {
                    setFechaVisita(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label>Hora Visita</Label>
                <Input
                  type="text"
                  placeholder="Hora Visita"
                  value={hora_visita}
                  required
                  onChange={(e) => {
                    setHoraVisita(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label>Compromiso</Label>
                <Input
                  type="text"
                  placeholder="Compromiso"
                  value={compromiso}
                  required
                  onChange={(e) => {
                    setCompromiso(e.target.value);
                  }}
                />
              </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarVisita}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      
      {loader}
        </div>
      ) : undefined}
    </>
  );
}

export default ListadoCobranza;
