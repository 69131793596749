import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Label, Input, Row, Col, Button, ButtonGroup, Table, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import TablaInfoAlumnos from "./TablaInfoAlumnos";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { encode } from "base64-arraybuffer";

function ListadoInfoAlumnos() {
  const { user } = useContext(AuthContext);
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [alumnos, setAlumnos] = useState([]);
  const [selectedAlumno, setSelectedAlumno] = useState(0);
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(0);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(0);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(0);
  const [aspecto, setAspecto] = useState(0);
  const [info, setInfo] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [expFile, setExpFile] = useState(false)
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAlumnos = res.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function buscarInfo() {
    const URL_INFO_ALUMNOS_ID = `${process.env.REACT_APP_URL_INFO_ALUMNOS}/alumno/${selectedAlumno}`;
    axios
      .get(URL_INFO_ALUMNOS_ID, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInfo = res.data;
        setInfo(allInfo);
        setExpFile(true)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function PDFTabla() {
      if(!expFile){
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Selecciona un Alumno",
          });
      } else{
    const data = info.map((i) => {
        if(
            (selectedFechaInicio == 0 ||
                selectedFechaInicio <= i.fecha) &&
              (selectedFechaFin == 0 ||
                selectedFechaFin >= i.fecha) &&
                (aspecto ==0 || aspecto == i.aspecto)
      ) {
        return [
          i.fecha,
          i.aspecto,
          i.comentarios,
          [i.user[0].nombre +" "+ i.user[0].apellido]
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text(`Info del Alumno ${info[0].alumnos[0].name} ${info[0].alumnos[0].familias[0].apellido_paterno} ${info[0].alumnos[0].familias[0].apellido_materno}`, 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
         "Aspecto",
         "Comentarios",
         "Creado Por"
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
    });
    doc.save(`Info-${info[0].alumnos[0].name}-${info[0].alumnos[0].familias[0].apellido_paterno}-${info[0].alumnos[0].familias[0].apellido_materno}.pdf`);
  }}

  function excel() {
    if(!expFile){
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Selecciona un Alumno",
          });
      } else{
    const dataExcel = info.map((i) => {
        if(
            (selectedFechaInicio == 0 ||
                selectedFechaInicio <= i.fecha) &&
              (selectedFechaFin == 0 ||
                selectedFechaFin >= i.fecha) &&
                (aspecto ==0 || aspecto == i.aspecto)
      ) {
          return {
            Fecha: i.fecha,
            Nombre: i.alumnos[0].name + " " + i.alumnos[0].familias[0].apellido_paterno + " " + i.alumnos[0].familias[0].apellido_materno,
            Aspecto: i.aspecto,
            Comentarios: i.comentarios,
            Creado: i.user[0].nombre +" "+ i.user[0].apellido
          };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `Info-${info[0].alumnos[0].name}-${info[0].alumnos[0].familias[0].apellido_paterno}-${info[0].alumnos[0].familias[0].apellido_materno}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `Info del Alumno`,
        sheetFilter:  [
          "Fecha",
          "Nombre",
         "Aspecto",
         "Comentarios",
         "Creado"
        ],
        sheetHeader:  [
          "Fecha",
          "Nombre",
         "Aspecto",
         "Comentarios",
         "Creado"
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }}

  function enviaMail(){
    if(!expFile){
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Selecciona un Alumno",
        });
    } else{
  const data = info.map((i) => {
      if(
          (selectedFechaInicio == 0 ||
              selectedFechaInicio <= i.fecha) &&
            (selectedFechaFin == 0 ||
              selectedFechaFin >= i.fecha) &&
              (aspecto ==0 || aspecto == i.aspecto)
    ) {
      return [
        i.fecha,
        i.aspecto,
        i.comentarios,
        [i.user[0].nombre +" "+ i.user[0].apellido]
      ];
    }
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF('landscape');
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 15, 45, 15);
  doc.text(`Info del Alumno ${info[0].alumnos[0].name} ${info[0].alumnos[0].familias[0].apellido_paterno} ${info[0].alumnos[0].familias[0].apellido_materno}`, 20, 25);
  doc.autoTable({
    head: [
      [
        "Fecha",
       "Aspecto",
       "Comentarios",
       "Creado Por"
      ],
    ],
    body: dataPDFLimpia,
    startY: 30,
  });
  var att = doc.output('arraybuffer');
  var base64File = encode.encode(att, 'base64')
  // Envia el Mail
  axios
  .post(
    URL_SEND_MAIL_ATT,
    {
      subject:'Info del Alumno',
      email: mailTo,
      fileName: `InfoAlumno.pdf`,
      att: base64File,
      text:
      `<!DOCTYPE html>
        <html>
          <head>
            <style>                                
              .img-container {
                              display: block;
                              margin-left: auto;
                              margin-right: auto;
                              height: 90px;
                              width: auto;
                            }
            </style>
          </head>
          <body>
            <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
            <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
            <h3>Hola.</h3>
            <h3>Adjunto encontraras el resumen del alumno ${info[0].alumnos[0].name} ${info[0].alumnos[0].familias[0].apellido_paterno} ${info[0].alumnos[0].familias[0].apellido_materno}.</h3>
            <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
            Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
          </body>
        </html>`
    },
    {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    }
  )
  .then(() => {
    toggleMail()
    Swal.fire("Good job!", "Enviado con exito", "success");
  })
  .catch((error) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `${error.response.data}`,
    });
    console.log(error);
  })
  }
}

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card container">
        {user.menu_info_alumno ? (
          <div className="card-body">
            <Row>
              <Col md={8}>
                {user.info_alumno_create ? (
                  <Button
                    size="sm"
                    href="/InfoAlumnosCreate"
                    className="btn btn-success"
                    id="botonListado"
                  >
                    Nuevo Comentario
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    disabled
                    className="btn btn-success"
                    id="botonListado"
                  >
                    Nuevo Comentario
                  </Button>
                )}
                <Button
                  size="sm"
                  href="/MenuAcademico"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Col>
              <Col md={4}>
                <ButtonGroup id="logoutBoton">
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    onClick={PDFTabla}
                  >
                    PDF <i class="far fa-file-pdf"></i>
                  </Button>
                  <Button size="sm" className="btn" color="info" onClick={toggleMail}>
                    eMail <i class="fas fa-at"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="primary"
                    onClick={excel}
                  >
                    Excel <i class="far fa-file-excel"></i>
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Resumen Donativos</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>


            <h3 align="center">Seguimiento de Alumnos</h3>
            <Row>
            {user.planteles == PLANTELES_GENERAL ? (
                    <Col md={3}>
                      <Label className="mr-sm-2">Planteles</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedPlantel}
                        onChange={(e) => setSelectedPlantel(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {planteles
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != PLANTELES_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </Col>
                  ) : undefined}
             {user.areas == AREAS_GENERAL ? (
                    <Col md={3}>
                      <Label className="mr-sm-2">Areas</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => setSelectedArea(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </Col>
                  ) : undefined}
              <Col md={3}>
              {user.areas == AREAS_GENERAL && user.planteles == PLANTELES_GENERAL ? (
                <div>
              <Label className="mr-sm-2">Grado</Label>
              <Input
              bsSize="sm"
                className="col-sm-12"
                type="select"
                value={selectedGrado}
                required
                onChange={(i) => {
                  setSelectedGrado(i.target.value);
                }}
              >
                <option value="1">Selecciona su Grado</option>
                {grados
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((p) => {
                  if (
                    (selectedArea == 0 ||
                      selectedArea == p.areas[0]._id)
                  ) {
                  return (
                    <option value={p._id}>{p.name} {p.areas[0].name}</option>
                  )}
                })}
              </Input>
              </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Grado</Label>
                  <Input
                  bsSize="sm"
                    type="select"
                    value={selectedGrado}
                    onChange={(e) => setSelectedGrado(e.target.value)}
                  >
                    <option value="0">Selecciona una Grado </option>
                    {grados
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((p) => {
                      if (
                        (p.areas[0]._id == user.areas)
                       ) {
                  return (
                    <option value={p._id}> {p.name} {p.areas[0].name}
                    </option>
                  );
                      }
                })}
                  </Input>
                </div>
              )}
              </Col>
              <Col md={3}>
              {user.areas == AREAS_GENERAL && user.planteles == PLANTELES_GENERAL ? (
                <div>
              <Label className="mr-sm-2">Clase</Label>
              <Input
              bsSize="sm"
                className="col-sm-12"
                type="select"
                value={selectedClase}
                required
                onChange={(i) => {
                  setSelectedClase(i.target.value);
                }}
              >
                <option value="1">Selecciona su Clase</option>
                {clases
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((p) => {
                  if (
                    (selectedPlantel == 0 ||
                      selectedPlantel == p.planteles[0]._id) &&
                    (selectedArea == 0 ||
                      selectedArea == p.grados[0].areas[0]._id) &&
                    (selectedGrado == 0 || selectedGrado == p.grados[0]._id)
                  ) {
                  return (
                    <option value={p._id}>
                      {p.grados[0].name} {p.name} {p.grados[0].areas[0].name}{" "}
                      {p.planteles[0].name}{" "}
                    </option>
                  )}
                })}
              </Input>
              </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Clase</Label>
                  <Input
                  bsSize="sm"
                    type="select"
                    value={selectedClase}
                    onChange={(e) => setSelectedClase(e.target.value)}
                  >
                    <option value="0">Selecciona una Clase </option>
                    {clases
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((p) => {
                      if (
                        (p.grados[0].areas[0]._id == user.areas && p.planteles[0]._id == user.planteles) &&
                        (selectedGrado == 0 || selectedGrado == p.grados[0]._id)) {
                  return (
                    <option value={p._id}>
                      {p.grados[0].name} {p.name} {p.grados[0].areas[0].name}{" "}
                      {p.planteles[0].name}{" "}
                    </option>
                  );
                      }
                })}
                  </Input>
                </div>
              )}
              </Col>
              </Row>
              <Row>
              <Col md={3}>
              {user.areas == AREAS_GENERAL && user.planteles == PLANTELES_GENERAL ? (
                <div>
              <Label className="mr-sm-2">Alumno</Label>
              <Input
              bsSize="sm"
                className="col-sm-12"
                type="select"
                value={selectedAlumno}
                required
                onChange={(i) => {
                  setSelectedAlumno(i.target.value);
                }}
              >
                <option value="1">Selecciona su Alumno</option>
                {alumnos
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((p) => {
                  if (
                    (selectedPlantel == 0 ||
                      selectedPlantel == p.clases[0].planteles[0]._id) &&
                    (selectedArea == 0 ||
                      selectedArea == p.clases[0].grados[0].areas[0]._id) &&
                    (selectedGrado == 0 || selectedGrado == p.clases[0].grados[0]._id) &&
                    (selectedClase == 0 || selectedClase == p.clases[0]._id)
                  ) {
                  return (
                    <option value={p._id}>{p.familias[0].apellido_paterno} {p.familias[0].apellido_materno} {p.name} </option>
                  )}
                })}
              </Input>
              </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Alumno</Label>
                  <Input
                  bsSize="sm"
                    type="select"
                    value={selectedAlumno}
                    onChange={(e) => setSelectedAlumno(e.target.value)}
                  >
                    <option value="0">Selecciona una Alumno </option>
                    {alumnos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((p) => {
                      if (
                        (p.clases[0].grados[0].areas[0]._id == user.areas && p.clases[0].planteles[0]._id == user.planteles) &&
                        (selectedGrado == 0 || selectedGrado == p.clases[0].grados[0]._id) &&
                        (selectedClase == 0 || selectedClase == p.clases[0]._id)) {
                  return (
                    <option value={p._id}>{p.familias[0].apellido_paterno} {p.familias[0].apellido_materno} {p.name} </option>
                  );
                      }
                })}
                  </Input>
                </div>
              )}
              </Col>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Aspecto
                </Label>
                <Input
                  size="sm"
                  type="select"
                  placeholder="Aspecto"
                  value={aspecto}
                  required
                  onChange={(e) => {
                    setAspecto(e.target.value);
                  }}
                >
                  <option value="0">Aspecto</option>
                  <option value="Academico">Academico</option>
                  <option value="Disciplina">Disciplina</option>
                  <option value="Emocional">Emocional</option>
                  <option value="Social">Social</option>
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha Inicio</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaInicio}
                  onChange={(e) => {
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Fin</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={selectedFechaFin}
                  onChange={(e) => {
                    setSelectedFechaFin(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                  <br />
                  {selectedAlumno!=0?(
                <Button
                  size="sm"
                  className="btn" 
                  color="info"
                  id="botonListado"
                  onClick={buscarInfo}
                >
                  Buscar
                </Button>
                  ):(
                    <Button
                    size="sm"
                    className="btn" 
                    color="info"
                    id="botonListado"
                    disabled
                  >
                    Selecciona Alumno
                  </Button>
                  )}
                
              </Col>
            </Row>
            <div className="container col-11">
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Aspecto</th>
                    <th>Comentarios</th>
                    <th>Creado Por</th>
                  </tr>
                </thead>
                <tbody>
                  {info
                    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
                    .map((i) => {
                        if(
                            (selectedFechaInicio == 0 ||
                                selectedFechaInicio <= i.fecha) &&
                              (selectedFechaFin == 0 ||
                                selectedFechaFin >= i.fecha) &&
                                (aspecto ==0 || aspecto == i.aspecto)
                        )
                      return (
                        <tr>
                          <TablaInfoAlumnos
                            fecha={i.fecha}
                            aspecto={i.aspecto}
                            comentarios={i.comentarios}
                            creadoN={i.user[0].nombre}
                            creadoA={i.user[0].apellido}
                            idAlumno={i.alumnos[0]._id}
                            idColaborador={i.user[0]._id}
                            idInfoAlumnos={i._id}
                          />
                        </tr>
                      );
                    })}
                </tbody>
                <br />
              </Table>
            </div>
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default ListadoInfoAlumnos;
