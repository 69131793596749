import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCobranza() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <div className="container">
        <br />
        <br />
        {user.menu_cobranza ?(
          <div>
        <Row>
          <Col md={4} align="center">
          {user.menu_colegiaturas ?(
            <Button href="/Colegiaturas" className="botonesMenu" color="success">
            <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Colegiaturas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
            <br />
            <br />
            Colegiaturas
          </Button> }
            </Col>
            <Col md={4} align="center">
              {user.menu_inscripciones ?(
            <Button href="/Inscripciones" className="botonesMenu" color="success">
            <i class="fas fa-university fa-7x"></i>
              <br />
              <br />
              Inscripciones
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-university fa-7x"></i>
                <br />
                <br />
                Inscripciones
              </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_becas ?(
            <Button href="/Becas" className="botonesMenu" color="success">
            <i class="fas fa-percent fa-7x"></i>
              <br />
              <br />
              Becas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-percent fa-7x"></i>
              <br />
              <br />
              Becas
            </Button> }
          </Col>
        </Row>
        <br></br>
        <Row>
        <Col md={4} align="center">
              {user.colegiaturas_abonos ? (
                <Button
                  href="/AbonosFamilias"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.colegiaturas_create ? (
                <Button
                  href="/CargosFamilias"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Cargos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Cargos
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.menu_colegiaturas ? (
                <Button
                  href="/EstadoCuentaFamilias"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice fa-7x"></i>
                  <br />
                  <br />
                  Estado de Cuenta
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice fa-7x"></i>
                  <br />
                  <br />
                  Estado de Cuenta
                </Button>
              )}
            </Col>
        </Row>
      </div>
        ): undefined } 
      </div>
      
    </>
  );
}

export default BotonesCobranza;
