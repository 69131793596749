import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"
import axios from "axios";
import { Table, Button, Col, Row, Label, Input, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,
  Spinner, } from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import DetalleDonativoInfo from "./DetalleDonativoInfo";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from 'moment';


function ListadoDetalleDonativos() {
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DONATIVOS = process.env.REACT_APP_URL_DONATIVOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
  const URL_CONCEPTOS_DON = process.env.REACT_APP_URL_CONCEPTOS_DON;
  const URL_EVENTOS_DON = process.env.REACT_APP_URL_EVENTOS_DON;
  const URL_SALAS_DON = process.env.REACT_APP_URL_SALAS_DON;
  const URL_INSTITUCIONES_DON = process.env.REACT_APP_URL_INSTITUCIONES_DON;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  
  const [donativos, setDonativos] = useState([]);
  const [donadores, setDonadores] = useState([]);
  const [conceptosDon, setConceptosDon] = useState([]);
  const [eventosDon, setEventosDon] = useState([]);
  const [salasDon, setSalasDon] = useState([]);
  const [institucionesDon, setInstitucionesDon] = useState([]);
  const [selectedDonador, setSelectedDonador] = useState("");
  const [selectedConceptosDon, setSelectedConceptosDon] = useState("");
  const [selectedEventosDon, setSelectedEventosDon] = useState("");
  const [selectedSalasDon, setSelectedSalasDon] = useState("");
  const [selectedInstitucionesDon, setSelectedInstitucionesDon] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [contacto, setContacto] = useState([]);
  const [selectedContacto, setSelectedContacto] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');
  const [value, setValue] = useState();
  const [valueEdit, setValueEdit] = useState();
  const [validaBoton, setValidaBoton] = useState(true);

  const [text, setText] = useState(false);

  const [selectedDonadorEdit, setSelectedDonadorEdit] = useState('')
  const [selectedSalasDonEdit, setSelectedSalasDonEdit] = useState('')
  const [selectedInstitucionesDonEdit, setSelectedInstitucionesDonEdit] = useState('')
  const [selectedEventosDonEdit, setSelectedEventosDonEdit] = useState('')
  const [selectedConceptosDonEdit, setSelectedConceptosDonEdit] = useState('')
  const [fecha, setFecha] = useState('')
  const [importe, setImporte] = useState('')
  const [importeViejo, setImporteViejo] = useState('')
  const [descripcion, setDescripcion] = useState('')
  const [IdEditDonativo, setIdEditDonativo] = useState('')
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });

const ITEMS_PER_PAGE = 50

  useMemo(() => {
    axios
    .get(`${URL_DONATIVOS}/fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonativos = response.data;
        let arrayTabla = allDonativos
        .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            fecha: a.fecha,
            donador: a.donadores[0].nombre + " " + a.donadores[0].apellido,
            idDonador: a.donadores[0]._id,
            salasDon: a.salasDon[0].name,
            idSalasDon: a.salasDon[0]._id,
            institucionesDon: a.institucionesDon[0].name,
            idInstitucionesDon: a.institucionesDon[0]._id,
            eventosDon: a.eventosDon[0].name,
            idEventosDon: a.eventosDon[0]._id,
            conceptosDon: a.conceptosDon[0].name,
            idConceptosDon: a.conceptosDon[0]._id,
            descripcion: a.descripcion,
            cobrador: a.donadores[0].colaboradores[0].nombre + " " + a.donadores[0].colaboradores[0].apellido,
            idCobrador: a.donadores[0].colaboradores[0]._id,
            contacto: a.donadores[0].contacto[0].nombre + " " + a.donadores[0].contacto[0].apellido,
            idContacto: a.donadores[0].contacto[0]._id,
            importe: a.importe
          };
        }).filter(function (el) {
          return el != null;
        });
        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
        setDonativos(allDonativos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDonadores = res.data;
        setDonadores(allDonadores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CONCEPTOS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosDon = res.data;
        setConceptosDon(allConceptosDon);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_EVENTOS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allEventosDon = res.data;
        setEventosDon(allEventosDon);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_SALAS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSalasDon = res.data;
        setSalasDon(allSalasDon);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_INSTITUCIONES_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInstitucionesDon = res.data;
        setInstitucionesDon(allInstitucionesDon);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);
  let total = 0;

  function PDFTabla() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = donativos.map((c) => {
      if (
        (selectedDonador == 0 || selectedDonador == c.donadores[0]._id) &&
        (selectedSalasDon == 0 || selectedSalasDon == c.salasDon[0]._id) &&
        (selectedInstitucionesDon == 0 ||
          selectedInstitucionesDon == c.institucionesDon[0]._id) &&
        (selectedEventosDon == 0 ||
          selectedEventosDon == c.eventosDon[0]._id) &&
        (selectedConceptosDon == 0 ||
          selectedConceptosDon == c.conceptosDon[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.donadores[0].colaboradores[0]._id) &&
          (selectedContacto == 0 ||
            selectedContacto == c.donadores[0].contacto[0]._id)
      ) {
        total = total + c.importe;
        return [
          c.fecha,
          [c.donadores[0].nombre + " " + c.donadores[0].apellido],
          c.salasDon[0].name,
          c.institucionesDon[0].name,
          c.eventosDon[0].name,
          c.conceptosDon[0].name,
          [c.donadores[0].colaboradores[0].nombre + " " + c.donadores[0].colaboradores[0].apellido],
          [c.donadores[0].contacto[0].nombre + " " + c.donadores[0].contacto[0].apellido],
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Detalle de Donativos", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Donador",
          "Sala",
          "Institucion",
          "Evento",
          "Concepto",
          "Cobrador",
          "Contacto",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleDonativos.pdf");
  }

  function excel(){
    const dataExcel = donativos.map((c) => {
      if (
        (selectedDonador == 0 || selectedDonador == c.donadores[0]._id) &&
        (selectedSalasDon == 0 || selectedSalasDon == c.salasDon[0]._id) &&
        (selectedInstitucionesDon == 0 ||
          selectedInstitucionesDon == c.institucionesDon[0]._id) &&
        (selectedEventosDon == 0 ||
          selectedEventosDon == c.eventosDon[0]._id) &&
        (selectedConceptosDon == 0 ||
          selectedConceptosDon == c.conceptosDon[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.donadores[0].colaboradores[0]._id) &&
          (selectedContacto == 0 ||
            selectedContacto == c.donadores[0].contacto[0]._id)
      ) {
        return {
          Fecha: c.fecha,
          Donador: c.donadores[0].nombre + " " + c.donadores[0].apellido,
          Telefono: c.donadores[0].telefono,
          Sala: c.salasDon[0].name,
          Institucion: c.institucionesDon[0].name,
          Evento: c.eventosDon[0].name,
          Concepto: c.conceptosDon[0].name,
          Descripcion: c.descripcion,
          Cobrador: c.donadores[0].colaboradores[0].nombre + " " + c.donadores[0].colaboradores[0].apellido,
          Contacto: c.donadores[0].contacto[0].nombre + " " + c.donadores[0].contacto[0].apellido,
          Importe: c.importe}
        ;
      }
    });

    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });


    var option = {};
 
option.fileName = "DetalleDonativos";
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: "DetalleDonativos",
    sheetFilter: [ "Fecha",
    "Donador",
    "Telefono",
    "Sala",
    "Institucion",
    "Evento",
    "Concepto",
    "Descripcion",
    "Cobrador",
    "Contacto",
    "Importe"],
    sheetHeader: [
      "Fecha",
      "Donador",
      "Telefono",
      "Sala",
      "Institucion",
      "Evento",
      "Concepto",
      "Descripcion",
      "Cobrador",
      "Contacto",
      "Importe"
    ],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

  function enviaMail(){
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = donativos.map((c) => {
      if (
        (selectedDonador == 0 || selectedDonador == c.donadores[0]._id) &&
        (selectedSalasDon == 0 || selectedSalasDon == c.salasDon[0]._id) &&
        (selectedInstitucionesDon == 0 ||
          selectedInstitucionesDon == c.institucionesDon[0]._id) &&
        (selectedEventosDon == 0 ||
          selectedEventosDon == c.eventosDon[0]._id) &&
        (selectedConceptosDon == 0 ||
          selectedConceptosDon == c.conceptosDon[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedColaborador == 0 ||
          selectedColaborador == c.donadores[0].colaboradores[0]._id) &&
          (selectedContacto == 0 ||
            selectedContacto == c.donadores[0].contacto[0]._id)
      ) {
        total = total + c.importe;
        return [
          c.fecha,
          [c.donadores[0].nombre + " " + c.donadores[0].apellido],
          c.salasDon[0].name,
          c.institucionesDon[0].name,
          c.eventosDon[0].name,
          c.conceptosDon[0].name,
          [c.donadores[0].colaboradores[0].nombre + " " + c.donadores[0].colaboradores[0].apellido],
          [c.donadores[0].contacto[0].nombre + " " + c.donadores[0].contacto[0].apellido],
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Detalle de Donativos", 20, 25);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Donador",
          "Sala",
          "Institucion",
          "Evento",
          "Concepto",
          "Cobrador",
          "Contacto",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Detalle Donativos',
         email: mailTo,
         fileName: 'Detalle-Donativos.pdf',
         att: base64File,
         text: `<!DOCTYPE html>
         <html>
           <head>
             <style>                                
               .img-container {
                               display: block;
                               margin-left: auto;
                               margin-right: auto;
                               height: 90px;
                               width: auto;
                             }
             </style>
           </head>
           <body>
             <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
             <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
             <h3>Hola,</h3>
             <h3>Adjunto encontraras el detalle de donativos.</h3>
             <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
             Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
           </body>
         </html>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
// setTimeout(() => {
//       window.location.reload();
//     }, 1000);
      })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }
 


  function editDonativo(event) {
    event.preventDefault();
    setValidaBoton(false)
    const URL_DONATIVOS_EDIT = `${process.env.REACT_APP_URL_DONATIVOS}/${IdEditDonativo}`;
    axios
      .patch(
        URL_DONATIVOS_EDIT,
        {
          fecha,
          descripcion,
          importe,
          donadores: selectedDonadorEdit,
          salasDon: selectedSalasDonEdit,
          eventosDon: selectedEventosDonEdit,
          institucionesDon: selectedInstitucionesDonEdit,
          conceptosDon: selectedConceptosDonEdit,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
           // Actualizamos donador
           axios
           .patch(
             `${URL_DONADORES}CargosEdit/${selectedDonadorEdit}`,
             {
              cargoViejo:importeViejo,
              cargoNuevo:importe
             },
             {
               headers: {
                 Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
               },
             }
           )
           .then(() => {
            axios
            .post(
              URL_LOGS,
              {
                tipo:'Editar Cargo Donativo',
                detalle: `${selectedDonadorEdit}`,
                user: user.id
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            toggleEdit();
             Swal.fire("Good job!", "Creado con exito", "success");
             setTimeout(() => {
               window.location.reload();
             }, 1000);
           })
            .catch((error) => {
             Swal.fire({
               icon: "error",
               title: "Oops...",
               text: "Something went wrong!",
               footer: `${error.response.data}`,
             });
             console.log(error);
           });
     
           // Termina actualizar donador
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }



  function jalaInfo(idEdit) {
    const URL_DONATIVOS_EDIT = `${process.env.REACT_APP_URL_DONATIVOS}/${idEdit}`;
    axios
      .get(URL_DONATIVOS_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setFecha(response.data.fecha);
        setDescripcion(response.data.descripcion);
        setImporte(response.data.importe);
        setImporteViejo(response.data.importe);
        setSelectedDonadorEdit(response.data.donadores[0]._id);
        setSelectedSalasDonEdit(response.data.salasDon[0]._id);
        setSelectedEventosDonEdit(response.data.eventosDon[0]._id);
        setSelectedInstitucionesDonEdit(response.data.institucionesDon[0]._id);
        setSelectedConceptosDonEdit(response.data.conceptosDon[0]._id);
        setIdEditDonativo(idEdit)
        toggleEdit();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const options = donadores.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Donador", field: "donador", sortable: true },
    { name: "Sala", field: "salasDon", sortable: true },
    { name: "Institucion", field: "institucionesDon", sortable: true },
    { name: "Evento", field: "eventosDon", sortable: true },
    { name: "Concepto", field: "conceptosDon", sortable: true },
    { name: "Cobrador", field: "cobrador", sortable: true },
    { name: "Contacto", field: "contacto", sortable: true },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
];

const commentsData = useMemo(() => {
  let computedComments = comments

  if (search) {
      computedComments = computedComments.filter(
          comment =>
              comment.donador.toLowerCase().includes(search.toLowerCase()) ||
              comment.salasDon.toLowerCase().includes(search.toLowerCase()) ||
              comment.institucionesDon.toLowerCase().includes(search.toLowerCase()) ||
              comment.eventosDon.toLowerCase().includes(search.toLowerCase()) ||
              comment.conceptosDon.toLowerCase().includes(search.toLowerCase()) ||
              comment.cobrador.toLowerCase().includes(search.toLowerCase()) ||
              comment.contacto.toLowerCase().includes(search.toLowerCase()) 
      );
  }


  if(selectedDonador){
    computedComments = computedComments.filter((e)=>
    e.idDonador.includes(selectedDonador)
    ) 
  } 
  
  if(selectedSalasDon){
    computedComments = computedComments.filter((e)=>
    e.idSalasDon.includes(selectedSalasDon)
    ) 
  } 

  if(selectedInstitucionesDon){
    computedComments = computedComments.filter((e)=>
    e.idInstitucionesDon.includes(selectedInstitucionesDon)
    ) 
  }

  if(selectedEventosDon){
    computedComments = computedComments.filter((e)=>
    e.idEventosDon.includes(selectedEventosDon)
    ) 
  } 
  
  if(selectedConceptosDon){
    computedComments = computedComments.filter((e)=>
    e.idConceptosDon.includes(selectedConceptosDon)
    ) 
  } 

  if(selectedColaborador){
    computedComments = computedComments.filter((e)=>
    e.idCobrador.includes(selectedColaborador)
    ) 
  }

  if(selectedContacto){
    computedComments = computedComments.filter((e)=>
    e.idContacto.includes(selectedContacto)
    ) 
  }


  setTotalItems(computedComments.length);

  //Sorting comments
  if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
          (a, b) =>
              reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
  }

  //Current Page slice
  return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [comments, currentPage, search, sorting, selectedDonador, selectedSalasDon, selectedInstitucionesDon, selectedEventosDon, selectedConceptosDon, selectedColaborador, selectedContacto]);


  return (
    <>
      <Header />
      <br />
      <br />
        {user.menu_donativos ?(
            <div className="card">
                  <Row>
                    <Col sm={8}>
                    {user.donativos_cargos_create ?(
                      <Button
                        size="sm"
                        href="/DonativosCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Donativo
                      </Button>
                    ):(
                      <Button
                        size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Donativo
                      </Button>
                    )}
                      <Button
                        size="sm"
                        href="/MenuDonativos"
                        className="btn btn-danger"
                        id="botonListado"
                      >
                        Regresar
                      </Button>
                    </Col>
                    <Col sm={4}>
                      <ButtonGroup id="logoutBoton">
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={PDFTabla}
                        >
                          PDF <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button 
                        size="sm" 
                        className="btn" 
                        color="info"
                        onClick={toggleMail}
                        >
                          eMail <i class="fas fa-at"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="primary"
                          onClick={excel}
                        >
                          Excel <i class="far fa-file-excel"></i>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Detalle Donativos</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>

                  <h3 align="center">Cargos Donativos</h3>

                  <Row>
                    <Col md={2}>
                      <Label className="mr-sm-2">Fecha Inicio</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaInicio}
                        required
                        onChange={(e) => {
                          setSelectedFechaInicio(e.target.value)
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Label className="mr-sm-2">Fecha Fin</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaFin}
                        required
                        onChange={(e) => {
                          setSelectedFechaFin(e.target.value)
                        }}
                      />
                    </Col>
                  </Row>

                  <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                <tr>
                        <td></td><td></td>
                       

                        <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedSalasDon}
                        required
                        onChange={(e) => {setSelectedSalasDon(e.target.value)
                          setCurrentPage(1)}}
                      >
                        <option value="">Selecciona</option>
                        {salasDon
                         .sort((a, b) => (a.name > b.name ? 1 : -1))

                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                        </td>
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                        bsSize="sm"
                        type="select"
                        value={selectedInstitucionesDon}
                        required
                        onChange={(e) => {setSelectedInstitucionesDon(e.target.value)
                            setCurrentPage(1)}
                        }
                      >
                        <option value="">Selecciona</option>
                        {institucionesDon
                          .sort((a, b) => (a.name > b.name ? 1 : -1))

                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                        </td>
                        <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedEventosDon}
                        required
                        onChange={(e) => {setSelectedEventosDon(e.target.value)
                          setCurrentPage(1)}}
                      >
                        <option value="">Selecciona</option>
                        {eventosDon
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                        </td>
                        <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedConceptosDon}
                        required
                        onChange={(e) =>{
                          setSelectedConceptosDon(e.target.value)
                          setCurrentPage(1)}
                        }
                      >
                        <option value="">Selecciona</option>
                        {conceptosDon
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                        </td>
                        <td style={{ paddingTop: "0px" }}>
                        <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                required
                onChange={(e) => {
                  setSelectedColaborador(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    if (a.cobrador=="Si") {
                    return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                    }
                  })}
              </Input>
              </td>
                        <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedContacto}
                required
                onChange={(e) => {
                  setSelectedContacto(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                  })}
              </Input>
                        </td>
                      </tr>
                    <tbody>
                      {commentsData
                        .map((c) => {
                            total = total + c.importe;
                            return (
                              <tr>
                              <td>{c.fecha}</td>
                              <td>{c.donador}</td>
                              <td>{c.salasDon}</td>
                              <td>{c.institucionesDon}</td>
                              <td>{c.eventosDon}</td>
                              <td>{c.conceptosDon}</td>
                              <td>{c.cobrador}</td>
                              <td>{c.contacto}</td>
                              <td >
                                  {"$" + new Intl.NumberFormat("en-US").format(c.importe)}
                                </td>
                                <td> 
                                  <Row>
                                 
                                {user.donativos_cargos_create ?(
                                   <Button
                                   color="info"
                                   id="Editar"
                                   size="sm"
                                   onClick={(e) => jalaInfo(c._id)}
                                 ><i class="far fa-edit"></i>
                                 </Button>
                                    ):(
                                      <Button color="info" id="Editar" size="sm" disabled>
                                      <i class="far fa-edit"></i>
                                    </Button>
                                    )}
                                    </Row>
                                  </td>
                              </tr>
                            );
                        })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="negrita" align="center">
                          TOTAL
                        </td>
                        <td className="negrita">
                          {"$" + new Intl.NumberFormat("en-US").format(total)}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                  
                  <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                </div>
          ): undefined } 
       <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Donativo</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            <Col md={6}>
                <Label>Importe</Label>
                <Input
                  className="col-sm-12"
                  type="number"
                  placeholder="Importe"
                  value={importe}
                  required
                  onChange={(e) => {
                    setImporte(e.target.value);
                  }}
                />
              </Col>
              </Row>
            <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Sala</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSalasDonEdit}
                  required
                  onChange={(e) => {
                    setSelectedSalasDonEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Sala</option>
                  {salasDon.map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
              <Col md={6}>
                <Label className="mr-sm-2">Institucion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedInstitucionesDonEdit}
                  required
                  onChange={(e) => {
                    setSelectedInstitucionesDonEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Institucion</option>
                  {institucionesDon.map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Evento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedEventosDonEdit}
                  required
                  onChange={(e) => {
                    setSelectedEventosDonEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Evento</option>
                  {eventosDon.map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
              <Col md={6}>
                <Label className="mr-sm-2">Concepto</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedConceptosDonEdit}
                  required
                  onChange={(e) => {
                    setSelectedConceptosDonEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Concepto</option>
                  {conceptosDon.map((c) => {
                    return <option value={c._id}>{c.name}</option>;
                  })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
            <Label className="mr-sm-2">Descripcion</Label>
            <Input
              className="col-sm-12"
              type="text"
              placeholder="Descripcion"
              value={descripcion}
              required
              onChange={(e) => {
                setDescripcion(e.target.value);
              }}
            />
           </Col>
           </Row>
        </ModalBody>
        <ModalFooter>

        {validaBoton ? (
        <Button className="btn btn-success" onClick={editDonativo}>
        Registrar
      </Button>
      ):(
      <Button className="btn btn-success" disabled>
        Registrar
      </Button>)}
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoDetalleDonativos;
