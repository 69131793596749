import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import TablaMaestros from './TablaMaestros'
import { Button, Table } from "reactstrap";

import axios from "axios";

function ListadoMaestros() {
  const { user } = useContext(AuthContext);
    const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
    const [colaboradores, setColaboradores] = useState([]);
    let activo;
  
    useEffect(() => {
      axios
        .get(URL_COLABORADORES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allColaboradores = response.data;
          setColaboradores(allColaboradores);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
      {user.menu_academico && user.menu_maestros ?(
        <div className="row">
          {
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Button
                    href="/MenuAcademico"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                  <h3 align="center">Maestros</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th className="tituloTabla">Activo</th>
                        <th className="tituloTabla">Nombre</th>
                        <th className="tituloTabla">Jefe</th>
                        <th className="tituloTabla">Telefono</th>
                        <th className="tituloTabla">Plantel</th>
                        <th className="tituloTabla">Asignar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((p) => {
                          if(p.maestro== "Si"){
                          return (
                            <tr>
                              <TablaMaestros
                                is_active={p.is_active}
                                nombre={p.nombre}
                                apellido={p.apellido}
                                jefe={p.puestos[0].colaboradores[0].nombre}
                                jefeApellido={p.puestos[0].colaboradores[0].apellido}
                                telefono={p.telefono}
                                puesto={p.puestos[0].name}
                                idPuesto={p.puestos[0]._id}
                                sueldo={p.sueldo_actual}
                                contEmer={p.contacto_emergencia}
                                telEmer={p.telefono_emergencia}
                                _id={p._id}
                                identificacion={p.identificacion}
                                direccion={p.direccion}
                                fecha_ingreso={p.fecha_ingreso}
                                fecha_nacimiento={p.fecha_nacimiento}
                                contacto_emergencia={p.contacto_emergencia}
                                telefono_emergencia={p.telefono_emergencia}
                                sueldo_actual={p.sueldo_actual}
                                ultimo_aumento={p.ultimo_aumento}
                                sueldo_anterior={p.sueldo_anterior}
                                observaciones={p.observaciones}
                                banco={p.banco}
                                clabe={p.clabe}
                                idCobrador={p.cobrador}
                                idMaestro={p.maestro}
                                planteles={p.planteles[0].name}
                                idPlantel={p.planteles[0]._id}
                                materias={p.materias}
                                clases={p.clases}
                              />
                            </tr>
                          );}
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
        </div>
        ): undefined } 
      </div>
      <br />
      
    </>
  );
}

export default ListadoMaestros
