import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import { Row, Col, Label, Input } from "reactstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

function GraficasIngresosBarras(props) {
  const { user } = useContext(AuthContext);
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_NOMINAS = process.env.REACT_APP_URL_NOMINAS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const [nominas, setNominas] = useState([]);
  const [gastos, setGastos] = useState([]);

  useEffect(() => {
    axios
      .get(URL_NOMINAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNominas = response.data;
        setNominas(allNominas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGastos = response.data;
        setGastos(allGastos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Array Nominas
  const arrayNominas = nominas
    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
    .map((n) => {
      if (user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL){
      if(
        (n.pagado=="Si") &&
      (props.selectedPlantel == 0 ||
        props.selectedPlantel == n.planteles[0]._id) &&
      (props.selectedArea == 0 ||
        props.selectedArea == n.areas[0]._id) &&
      (props.fechaInicio == 0 ||
        props.fechaInicio <= n.fecha) &&
      (props.fechaFin == 0 ||
        props.fechaFin >= n.fecha) 
        ){
      return {
        fecha: n.fecha,
        movimiento: "Nomina",
        name: n.colaboradores[0].nombre + " " + n.colaboradores[0].apellido,
        Nominas: n.importe,
        Gastos: 0,
      } } else return null} else{ if(
        (n.pagado=="Si") &&
        (user.planteles == n.planteles[0]._id) &&
        (user.areas == n.areas[0]._id) &&
        (props.fechaInicio == 0 ||
          props.fechaInicio <= n.fecha) &&
        (props.fechaFin == 0 ||
          props.fechaFin >= n.fecha)
      ){
        return {
          fecha: n.fecha,
          movimiento: "Nomina",
          name: n.colaboradores[0].nombre + " " + n.colaboradores[0].apellido,
          Nominas: n.importe,
          Gastos: 0,
        }} else return null}
      })
      .filter(function (el) {
        return el != null;
      });
    
  //Array Gastos
  const arrayGastos = gastos
    .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
      .map((n) => {
        if (user.planteles == PLANTELES_GENERAL &&
          user.areas == AREAS_GENERAL){
        if((n.pagado=="Si") &&
        (props.selectedPlantel == 0 ||
          props.selectedPlantel == n.presupuestos[0].planteles[0]._id) &&
        (props.selectedArea == 0 ||
          props.selectedArea == n.presupuestos[0].areas[0]._id) &&
        (props.fechaInicio == 0 ||
          props.fechaInicio <= n.fecha) &&
        (props.fechaFin == 0 ||
          props.fechaFin >= n.fecha) ){
      return {
        fecha: n.fecha,
        movimiento: "Gasto",
        name: n.proveedores[0].nombre_comercial,
        Gastos: n.total,
        Nominas: 0,
      }}  else return null} else{ if(
        (n.pagado=="Si") &&
        (user.planteles == n.presupuestos[0].planteles[0]._id) &&
        (user.areas == n.presupuestos[0].areas[0]._id) &&
        (props.fechaInicio == 0 ||
          props.fechaInicio <= n.fecha) &&
        (props.fechaFin == 0 ||
          props.fechaFin >= n.fecha)
      ){
        return {
          fecha: n.fecha,
          movimiento: "Gasto",
          name: n.proveedores[0].nombre_comercial,
          Gastos: n.total,
          Nominas: 0,
        }} else return null}
      })
      .filter(function (el) {
        return el != null;
      });

  const groupByNominas = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop].substring(0, 7);
      groups[val] = groups[val] || {
        fecha: item.fecha,
        Nominas: 0,
      };
      groups[val].Nominas += item.Nominas;
      groups[val].fecha = item.fecha.substring(0, 7)+'-01';
      groups[val].Gastos = item.Gastos;
      return groups;
    }, []);
  };

  const groupByGastos = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop].substring(0, 7);
      groups[val] = groups[val] || {
        fecha: item.fecha,
        Gastos: 0,
      };
      groups[val].Gastos += item.Gastos;
      groups[val].fecha = item.fecha.substring(0, 7)+'-01';
      groups[val].Nominas = item.Nominas;
      return groups;
    }, []);
  };

  const groupBy = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop].substring(0, 7);
      groups[val] = groups[val] || {
        fecha: item.fecha,
        Nominas: 0,
        Gastos: 0,
      };
      groups[val].Nominas += item.Nominas;
      groups[val].Gastos += item.Gastos;
      groups[val].fecha = item.fecha.substring(0, 7);
      return groups;
    }, []);
  };

  const NominasAgrupado = groupByNominas(arrayNominas, "fecha");
  const dataFinalNominas = Object.values(NominasAgrupado);
  const GastosAgrupado = groupByGastos(arrayGastos, "fecha");
  const dataFinalGastos = Object.values(GastosAgrupado);

  //Juntamos Arrays
  const arrayEgresos = dataFinalNominas
    .concat(dataFinalGastos)
    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1));
  const arrayFinalEgresos = arrayEgresos.filter(function (el) {
    return el != null;
  });

  const egresosAgrupado = groupBy(arrayFinalEgresos, "fecha");
  const dataFinal = Object.values(egresosAgrupado);

  let chart = am4core.create("chartdivBarrasEgresos", am4charts.XYChart3D);

  chart.data = dataFinal;


  // Create axes
  let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

  // Set input format for the dates
dateAxis.periodChangeDateFormats.setKey("month", "[bold]MMM yy");
dateAxis.dateFormats.setKey("month", "MMM yy")



dateAxis.dataFields.date = "fecha";
dateAxis.title.text = "Tipos de Egreso";
dateAxis.renderer.grid.template.location = 0;
dateAxis.renderer.minGridDistance = 20;
dateAxis.renderer.cellStartLocation = 0.1;
dateAxis.renderer.cellEndLocation = 0.9;

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.min = 0;
  valueAxis.title.text = "Importe MXN";

  // Create series
  function createSeries(field, name, stacked) {
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "fecha";
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    series.stacked = stacked;
    series.columns.template.width = am4core.percent(95);
  }

  createSeries("Nominas", "Nominas", false);
  createSeries("Gastos", "Gastos", true);

  // Add legend
  chart.legend = new am4charts.Legend();
    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.filePrefix = "myExport";

  return (
    <>
    {/* <Row>
                    <Col md={2}>
                      <Label>Fecha Inicio</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaInicio}
                        onChange={(e) => {
                          setSelectedFechaInicio(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Label>Fecha Fin</Label>
                      <Input
                        bsSize="sm"
                        type="date"
                        value={selectedFechaFin}
                        onChange={(e) => {
                          setSelectedFechaFin(e.target.value);
                        }}
                      />
                    </Col>
                  </Row> */}
      <div
        id="chartdivBarrasEgresos"
        style={{ width: "100%", height: "500px", paddingTop: "0px" }}
      ></div>
    </>
  );
}

export default GraficasIngresosBarras;
