import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { encode } from "base64-arraybuffer";
import moment from 'moment';

function AbonosDonativosCreate() {
  let hoy = new Date()
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
  const URL_ABONOS_DONATIVOS = process.env.REACT_APP_URL_ABONOS_DONATIVOS;
  const URL_COUNTERS = process.env.REACT_APP_URL_COUNTERS;
  const [text, setText] = useState(false);
  const [donadores, setDonadores] = useState([]);
  const [counters, setCounters] = useState([]);
  const [fecha, setFecha] = useState(endDate);
  // const [fecha_visita, setFechaVisita] = useState("");
  const [importe, setImporte] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [selectedDonador, setSelectedDonador] = useState("");
  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

 function clear(){
    setSelectedDonador("")
    setFecha(endDate)
    setImporte("")
    setFormaPago("")
    setFormaPago("")
    // setFechaVisita("")
    setValidaBoton(true)
    setValue("")
   
    axios
    .get(URL_COUNTERS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allCounters = response.data;
      setCounters(allCounters);
    })
    .catch((err) => {
      console.log(err);
    });

 numero = counters
  .map((c) => {
    if (c.id == "idAbonoDonativo") {
      return c.seq + 1;
    } else return null;
  })
  .filter(function (el) {
    return el != null;
  });
  }

  
  useEffect(() => {
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonadores = response.data;
        setDonadores(allDonadores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COUNTERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCounters = response.data;
        setCounters(allCounters);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let numero = counters
    .map((c) => {
      if (c.id == "idAbonoDonativo") {
        return c.seq + 1;
      } else return null;
    })
    .filter(function (el) {
      return el != null;
    })

  const savePago = (event) => {
    numero = counters
  .map((c) => {
    if (c.id == "idAbonoDonativo") {
      return c.seq + 1;
    } else return null;
  })
  .filter(function (el) {
    return el != null;
  })
    event.preventDefault();
    setValidaBoton(false)
    const URL_DONADORES_RECIBO = `${process.env.REACT_APP_URL_DONADORES}/${value._id}`;
    axios
      .get(URL_DONADORES_RECIBO, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        var ND = response.data.nombre;
        var AD = response.data.apellido;
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        // Horizontal, Vertical, Ancho, Alto
        doc.addImage(img, "png", 150, 10, 35, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        // Horizontal, Vertical, Ancho, Alto
        doc.addImage(img2, "png", 95, 15, 23, 35);
        // Horizontal, Vertical
        doc.setFontSize(16);
        doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 65, 65);
        doc.text(`__________________`, 80, 150);
        doc.text(`Firma`, 100, 160);
        doc.autoTable({
          head: [["No Recibo", "Fecha", "Donador", "Concepto", "Importe"]],
          body: [
            [
              numero,
              fecha,
              [ND + " " + AD],
              "Recibo de Donativo",
              "$" + new Intl.NumberFormat("en-US").format(importe),
            ],
          ],
          startY: 70,
        });

        var att = doc.output("arraybuffer");
          var base64File = encode(att);
        
        axios.post(
            URL_ABONOS_DONATIVOS,
            {
              fecha,
              importe,
              forma_pago,
              donadores: value._id,
              base64File,
              // fecha_visita,
              ultimo_pago:fecha,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(()=>{
              // Actualizamos donador
        axios
        .patch(
          `${URL_DONADORES}Abonos/${value._id}`,
          {
           abonos:importe
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            doc.save(`Recibo-${numero}.pdf`);
            clear()
          }, 2000);

        })
         .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true)
        });
  
        // Termina actualizar donador
          
          })            
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
        setValidaBoton(true)
      });
  };



  const options = donadores.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.donativos_abonos_create ? (
        <div className="container">
          <div className="card container col-sm-6">
            <h3 align="center">Captura un pago de Donativo</h3>
            <Form onSubmit={savePago}>
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Donador</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedDonador) => {
                      setValue(selectedDonador);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.apellido} {option.nombre} /{" "}
                        {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label>Importe</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    placeholder="Importe"
                    value={importe}
                    required
                    onChange={(e) => {
                      setImporte(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Forma de Pago</Label>
                  <Input
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                </Col>
              </Row>
              <Row>
              {/* <Col md={6}>
                  <Label>Proxima Visita</Label>
                  <Input
                    type="date"
                    value={fecha_visita}
                    required
                    onChange={(e) => {
                      setFechaVisita(e.target.value);
                    }}
                  />
                </Col> */}
              </Row>

              <br />
              <Row>
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                  Registrar
                </Button>
                ):(
                <Button type="submit" className="btn btn-success" disabled>
                  Registrar
                </Button>)}
                
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
                <Button
                  href="/AbonosDonativos"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default AbonosDonativosCreate;
