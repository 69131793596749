import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import TablaGrados from "./TablaGrados";
import GradosCreate from "./GradosCreate";
import axios from "axios";
import { Table, Input } from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function ListadoGrados() {
  const { user } = useContext(AuthContext);
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [grados, setGrados] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  useEffect(() => {
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrados = response.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        {user.menu_escuela  && user.menu_grados ? (
          <div className="row">
            <div className="col-lg-8 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Grados</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th style={{ paddingBottom: "0px" }}>Nombre</th>
                        <th style={{ paddingBottom: "0px" }}>Area</th>
                        <th style={{ paddingBottom: "0px" }}>Colegiatura</th>
                        <th style={{ paddingBottom: "0px" }}>Inscripcion</th>
                        <th style={{ paddingBottom: "0px" }}>Materias</th>
                        <th style={{ paddingBottom: "0px" }}>Editar</th>
                      </tr>
                        {user.areas == AREAS_GENERAL ? (
                      <tr>
                        <td></td>
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => setSelectedArea(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                    </td>
                      </tr>
                  ) : undefined}
                    </thead>
                    <tbody>
                      {user.areas == AREAS_GENERAL ?
                      grados
                        .sort((a, b) =>
                          a.numero_interno > b.numero_interno ? 1 : -1
                        )
                        .map((g) => {
                          if (
                                (selectedArea == 0 ||
                                  selectedArea ==
                                    g.areas[0]._id)
                          ) {
                          return (
                            <tr>
                              <TablaGrados
                                name={g.name}
                                areas={g.areas[0].name}
                                idAreas={g.areas[0]._id}
                                colegiatura={g.colegiatura}
                                inscripcion={g.inscripcion}
                                _id={g._id}
                                is_active={g.is_active}
                              />
                            </tr>
                          );
                          }
                        }):
                        grados
                        .sort((a, b) =>
                          a.numero_interno > b.numero_interno ? 1 : -1
                        )
                        .map((g) => {
                          if (user.areas == g.areas[0]._id) {
                          return (
                            <tr>
                              <TablaGrados
                                name={g.name}
                                areas={g.areas[0].name}
                                idAreas={g.areas[0]._id}
                                colegiatura={g.colegiatura}
                                inscripcion={g.inscripcion}
                                _id={g._id}
                                is_active={g.is_active}
                              />
                            </tr>
                          );
                          }
                        })
                        }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <GradosCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <br />
      <Footer />
    </>
  );
}

export default ListadoGrados;
