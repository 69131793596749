import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import TablaPlanteles from "./TablaPlanteles";
import PlantelesCreate from "./PlantelesCreate";
import axios from "axios";
import { Table } from 'reactstrap';
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function ListadoPlanteles() {
  const { user } = useContext(AuthContext);
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const [planteles, setPlanteles] = useState([]);
  let activo;

  useEffect(() => {
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPlanteles = response.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
    <Header />
    <br />
    <br />
      <div className="container">
      {user.menu_escuela  && user.menu_planteles ?(
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="card">
              <div className="card-body">
                <h3 align="center">Planteles</h3>
                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                    <th className="tituloTabla">Activo</th>
                      <th className="tituloTabla">
                        Nombre
                      </th><th className="tituloTabla">
                        Telefono
                      </th>
                      <th className="tituloTabla">
                        Editar
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {planteles.map((p) => {
                      if(p._id != PLANTELES_GENERAL){
                        if (p.is_active) {
                          activo = "Si";
                        } else {
                          activo = "No";
                        }
                      return (
                        <tr>
                          <TablaPlanteles
                            name={p.name}
                            telefono={p.telefono}
                            _id={p._id}
                            direccion={p.direccion}
                            is_active={p.is_active}
                            activo={activo}
                          />
                        </tr>
                      )};
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        <div className="col-md-6 col-sm-6">
          <div className="card">
            <div className="card-body">
              <PlantelesCreate />
            </div>
          </div>
        </div>
        </div>
        ): undefined } 
      </div>
      <br />

    </>
  );
}

export default ListadoPlanteles;
