import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../layout/Header/Header";
// import TablaCargosDonativos from "./TablaCargosDonativos";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoCargosFamilias() {
  const { user } = useContext(AuthContext);
  const URL_CARGOS_COLEGIATURAS = process.env.REACT_APP_URL_CARGOS_COLEGIATURAS;
  const URL_CARGOS_INSCRIPCIONES = process.env.REACT_APP_URL_CARGOS_INSCRIPCIONES;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  // const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [cargosColegiaturas, setCargosColegiaturas] = useState([]);
  const [cargosInscripciones, setCargosInscripciones] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  // const [colaboradores, setColaboradores] = useState([]);
  // const [selectedColaborador, setSelectedColaborador] = useState("");
  // const [contactos, setContactos] = useState([]);
  // const [selectedContacto, setSelectedContacto] = useState("");
  const [concepto, setConcepto] = useState(""); 
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');
  const [value, setValue] = useState();
  const [idEdit, setIdEdit] = useState("")
  const [importe, setImporte] = useState("")
  const [familiaEdit, setFamiliaEdit] = useState([]);
  const [conceptoEdit, setConceptoEdit] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);  
  const [validaBoton, setValidaBoton] = useState(true);
  const [text, setText] = useState(false);

  useEffect(() => {
    axios
      .get(URL_CARGOS_COLEGIATURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosColegiaturas = response.data;
        setCargosColegiaturas(allCargosColegiaturas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_CARGOS_INSCRIPCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosInscripciones = response.data;
        setCargosInscripciones(allCargosInscripciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get(URL_COLABORADORES, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allColaboradores = res.data;
    //     setColaboradores(allColaboradores);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  const arrayColegiaturas = cargosColegiaturas.map((n) => {
    return {
      idCargo: n.idCargoColegiatura,
      id: n._id,
      fecha: n.fecha,
      concepto: "Colegiatura",
      name: [n.familias[0].codigo_familia + " " + n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno],
      importe: n.importe,
      alumno: n.alumnos[0].name,
      idFamilia: n.familias[0]._id
    };
  });

  const arrayInscripciones = cargosInscripciones.map((n) => {
    return {
      idCargo: n.idCargoInscripcion,
      id: n._id,
      fecha: n.fecha,
      concepto: "Inscripcion",
      name: [n.familias[0].codigo_familia + " " + n.familias[0].apellido_paterno + " " + n.familias[0].apellido_materno],
      importe: n.importe,
      alumno: n.alumnos[0].name,
      idFamilia: n.familias[0]._id
    };
  });
  const arrayCargos = arrayColegiaturas.concat(
    arrayInscripciones,
  ).sort((a, b) => (a.fecha < b.fecha ? 1 : -1))


  const arrayFinalCargos = arrayCargos.filter(function (el) {
    return el != null;
  });

  let total = 0;

  // PDF
  let totalCargos = 0;

  function PDFTabla() {
    totalCargos = 0;
    const data = arrayFinalCargos.map((c) => {
      if (
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                (value == null || value._id == c.idFamilia) &&
                (concepto == 0 || concepto == c.concepto)
      ) {
        totalCargos = totalCargos + c.importe;
        return [
          c.fecha,
          c.name,
          c.alumno,
          c.concepto,
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Cargos ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Familia", "Alumno", "Concepto", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`CargosFamilia.pdf`);
  }

  function excel() {
    const dataExcel = arrayFinalCargos.map((c) => {
      if (
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                (value == null || value._id == c.idFamilia) &&
                (concepto == 0 || concepto == c.concepto)
      ) {
        return {
          Fecha: c.fecha,
          Familia: c.name,
          Alumno: c.alumno,
          Concepto: c.concepto,
          Importe: c.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCargosFamilias";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCargosFamilias",
        sheetFilter: ["Fecha", "Familia", "Alumno", "Concepto", "Importe"],
        sheetHeader: ["Fecha", "Familia", "Alumno", "Concepto", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail(){
    totalCargos = 0;
    const data = arrayFinalCargos.map((c) => {
      if (
        (selectedFechaInicio == 0 ||
          selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                (value == null || value._id == c.idFamilia) &&
                (concepto == 0 || concepto == c.concepto)
      ) {
        totalCargos = totalCargos + c.importe;
        return [
          c.fecha,
          c.name,
          c.alumno,
          c.concepto,
          "$" + new Intl.NumberFormat("en-US").format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(
      `Cargos ${selectedFechaInicio} - ${selectedFechaFin}`,
      20,
      25
    );
    doc.autoTable({
      head: [["Fecha", "Familia", "Alumno", "Concepto", "Importe"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Detalle Cargos Familias',
         email: mailTo,
         fileName: 'Detalle-Cargos-Familias.pdf',
         att: base64File,
         text:          `<!DOCTYPE html>
         <html>
           <head>
             <style>                                
               .img-container {
                               display: block;
                               margin-left: auto;
                               margin-right: auto;
                               height: 90px;
                               width: auto;
                             }
             </style>
           </head>
           <body>
             <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
             <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
             <h3>Hola,</h3>
             <h3>Adjunto encontraras el detalle de los Cargos.</h3>
             <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
             Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
           </body>
         </html>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
       toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
      })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }

  function EditCargo(id, importe, familia, concepto){
    setIdEdit(id)
    setImporte(importe)
    setFamiliaEdit(familia)
    setConceptoEdit(concepto)
    toggleEdit()
  }

  const EditCargoSave = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    if(conceptoEdit=="Colegiatura"){
        axios
      .patch(
        `${URL_CARGOS_COLEGIATURAS}/${idEdit}`,
        {
          importe:importe,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Cargo Colegiatura',
            detalle: `Importe: ${importe} / Familia: ${familiaEdit}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response}`,
        });
        console.log(error);
      })}
      else{
        axios
        .patch(
          `${URL_CARGOS_INSCRIPCIONES}/${idEdit}`,
          {
            importe:importe,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios
          .post(
            URL_LOGS,
            {
              tipo:'Editar Cargo Inscripcion',
              detalle: `Importe: ${importe} / Familia: ${familiaEdit}`,
              user: user.id
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
        })
      }
    }

  const options = familias.map((option) => {
    const junta =
     option.codigo_familia + " " + option.apellido_paterno + " " + option.apellido_materno;
    const firstLetter = option.apellido_paterno[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.colegiaturas_create ? (
        <div className="card container col-10">
          <Row>
            <Col sm={8}>
              {/* {user.colegiaturas_create ? (
                <Button
                  size="sm"
                  href="/CargosColegiaturas"
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Cargo
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Capturar Cargo
                </Button>
              )} */}
              <Button
                size="sm"
                href="/MenuCobranza"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button size="sm" className="btn" color="info" onClick={toggleMail}>
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Detalle de Cargos</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                      <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>


          <h3 align="center">Detalle de Cargos</h3>

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Table size="sm" striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                <th style={{ paddingBottom: "0px" }}>Numero</th>
                <th style={{ paddingBottom: "0px" }}>Fecha</th>
                <th style={{ paddingBottom: "0px" }}>Familia</th>
                <th style={{ paddingBottom: "0px" }}>Alumno</th>
                <th style={{ paddingBottom: "0px" }}>Concepto</th>
                <th style={{ paddingBottom: "0px" }}>Importe</th>
                <th style={{ paddingBottom: "0px" }}>Acciones</th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td style={{ paddingTop: "0px" }}>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedFamilia) => {
                      setValue(selectedFamilia)
                    }}
                    onBlur={selectedFamilia}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo_familia} {option.apellido_paterno} {option.apellido_materno}
                      </React.Fragment>
                    )}
                  />
                      </td>
                      <td></td>
                      <td style={{ paddingTop: "0px" }}>
                  <Input
                    bsSize="sm"
                    type="select"
                    placeholder="Concepto"
                    value={concepto}
                    required
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="0">Concepto</option>
                    <option value="Colegiatura">Colegiatura</option>
                    <option value="Inscripcion">Inscripcion</option>
                  </Input>
                </td>
              </tr>
            </thead>
            <tbody>
              {arrayFinalCargos
                .map((c) => {
                  if (
                    (selectedFechaInicio == 0 ||
                      selectedFechaInicio <= c.fecha) &&
                    (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
                            (value == null || value._id == c.idFamilia) &&
                            (concepto == 0 || concepto == c.concepto)
                  ) {
                    total = total + c.importe;
                    return (
                      <tr>
                        <td>{c.idCargo}</td>
                        <td>{c.fecha}</td>
                        <td>{c.name}</td>
                        <td>{c.alumno}</td>
                        <td>{c.concepto}</td>
                        <td>{"$" + new Intl.NumberFormat("en-US").format(c.importe)}</td>
                        <td>
                        {user.colegiaturas_create ? (
                          <Button size="sm" className="btn" color="info" 
                          onClick={(e)=>EditCargo(c.id, c.importe, c.name, c.concepto)}
                          >
                          <i class="far fa-edit"></i>
                        </Button>
                        ):(
                          <Button size="sm" className="btn" color="info" disabled >
                          <i class="far fa-edit"></i>
                        </Button>
                        )}
                        </td>
                      </tr>
                    );
                  }
                })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {"$" + new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>


          <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
                      <ModalHeader toggle={toggleEdit}><h4>Editar Cargo</h4></ModalHeader>
                      <ModalBody>
            <Form  onSubmit={EditCargoSave} >
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Familia</Label>
                  
                  <Input
                    bsSize="sm"
                    type="text"
                    value={familiaEdit}
                    required
                    disabled
                  >
                  </Input>
                </Col>
                <Col md={6}>
                  <Label>Importe</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    placeholder="Importe"
                    value={importe}
                    required
                    onChange={(e) => {
                      setImporte(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <br />
              <Row>
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                 Guardar
                </Button>
                ):(
                <Button type="submit" className="btn btn-success" disabled>
                 Guardar
                </Button>)}
                
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
              </Row>
            </Form>
            </ModalBody>
                      
                    </Modal>


        </div>
      ) : undefined}
    </>
  );
}

export default ListadoCargosFamilias;
