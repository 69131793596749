import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesReportes() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="container">
          <Row>
            <Col md={6} align="center">
            {user.menu_ingresos && user.menu_egresos ?(
              <Button
                href="/DashboardAdmin"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-pie fa-7x"></i>
                <br />
                <br />
                Dashboard Admin
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-pie fa-7x"></i>
            <br />
            <br />
            Dashboard Admin
          </Button> }
            </Col>
            <Col md={6} align="center">
            {user.menu_ingresos && user.menu_egresos ?(
              <Button href="/Cartera" className="botonesMenu" color="success">
                <i class="fas fa-wallet fa-7x"></i>
                <br />
                <br />
                Cartera
              </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-wallet fa-7x"></i>
            <br />
            <br />
            Cartera
          </Button> }
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.menu_ingresos ?(
              <Button
                href="/GraficasCarteraIngresos"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Ingresos
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Ingresos
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.menu_egresos ?(
              <Button
                href="/GraficasCarteraEgresos"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-bar fa-7x"></i>
                <br />
                <br />
                Egresos
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-bar fa-7x"></i>
            <br />
            <br />
            Egresos
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.menu_ingresos ?(
              <Button
                href="/GraficasCarteraDonativos"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-signal fa-7x"></i>

                <br />
                <br />
                Donativos
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-signal fa-7x"></i>

            <br />
            <br />
            Donativos
          </Button> }
          </Col>
            </Row>
            <br />
      </div>
      ) : undefined}
      
    </>
  );
}

export default BotonesReportes;
