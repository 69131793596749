import React from 'react'
import { Spinner } from "reactstrap";
import Header from '../layout/Header/Header';

function Loading() {
    return (
        <>
        <div className="divPadre">
            <div className="divHijo">
            <Spinner style={{ width: '3rem', height: '3rem', color:"#232B41" }} type="grow"  />
            <Spinner style={{ width: '3rem', height: '3rem', color:"#232B41" }} type="grow"  />
            <Spinner style={{ width: '3rem', height: '3rem', color:"#232B41" }} type="grow"  />
            </div>
        </div>
        </>
    )
}

export default Loading
