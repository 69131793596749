import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import { Button, Row, Table, Input, Col, ButtonGroup } from "reactstrap";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import TablaBecas from "./TablaBecas";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"

function ListadoBecas() {
  const { user } = useContext(AuthContext);
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [alumnos, setAlumnos] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [planteles, setPlanteles] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedApellidoP, setSelectedApellidoP] = useState("");
  const [selectedApellidoM, setSelectedApellidoM] = useState("");
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [selectedArea, setSelectedArea] = useState("");

  useEffect(() => {
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAlumnos = response.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFamilias = response.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPlanteles = response.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function PDFTabla() {
    let beca_col
    let beca_ins
    const data = alumnos.map((c) => {
      if (
        c.is_active == "Si" &&
        (c.beca_porcentaje_col != 0 ||
          c.beca_importe_col != 0 ||
          c.beca_importe_ins != 0 ||
          c.beca_porcentaje_ins != 0)
      ) {
        if (
          (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
          (selectedFamilia == 0 ||
            selectedFamilia == c.familias[0]._id) &&
          (selectedName == 0 || selectedName == c.name) &&
          (selectedApellidoP == 0 ||
            selectedApellidoP ==
              c.familias[0].apellido_paterno) &&
          (selectedApellidoM == 0 ||
            selectedApellidoM ==
              c.familias[0].apellido_materno) &&
          (selectedPlantel == 0 ||
            selectedPlantel ==
              c.clases[0].planteles[0]._id) &&
          (selectedArea == 0 ||
            selectedArea ==
              c.clases[0].grados[0].areas[0]._id)
        ){
          if(c.beca_porcentaje_col ==0){beca_col="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_col)}else{beca_col=`${c.beca_porcentaje_col} %`}
          if(c.beca_porcentaje_ins ==0){beca_ins="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_ins)}else{beca_ins=`${c.beca_porcentaje_ins} %`}
      return [c.familias[0].codigo_familia, c.familias[0].apellido_paterno + " "+ c.familias[0].apellido_materno + " " + c.name, c.clases[0].planteles[0].name, c.clases[0].grados[0].areas[0].name, c.clases[0].grados[0].name + " " + c.clases[0].name, beca_col, beca_ins ];
        } else if(
          (user.planteles == c.clases[0].planteles[0]._id) &&
          (user.areas == c.clases[0].grados[0].areas[0]._id) &&
          (selectedFamilia == 0 ||
            selectedFamilia == c.familias[0]._id) &&
          (selectedName == 0 || selectedName == c.name) &&
          (selectedApellidoP == 0 ||
            selectedApellidoP ==
              c.familias[0].apellido_paterno) &&
          (selectedApellidoM == 0 ||
            selectedApellidoM ==
              c.familias[0].apellido_materno)
        ){
          if(c.beca_porcentaje_col ==0){beca_col="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_col)}else{beca_col=`${c.beca_porcentaje_col} %`}
          if(c.beca_porcentaje_ins ==0){beca_ins="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_ins)}else{beca_ins=`${c.beca_porcentaje_ins} %`}
      return [c.familias[0].codigo_familia, c.familias[0].apellido_paterno + " "+ c.familias[0].apellido_materno + " " + c.name, c.clases[0].planteles[0].name, c.clases[0].grados[0].areas[0].name, c.clases[0].grados[0].name + " " + c.clases[0].name, beca_col, beca_ins ];
        }
      }
    })
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text(`Listado Becas`, 20, 25);
    doc.autoTable({
      head: [["Codigo Fam", "Nombre", "Plantel", "Area", "Clase", "Beca Colegiatura", "Beca Inscripcion"]],
      body: dataPDFLimpia,
      startY: 30,
      showFoot: "lastPage",
    });
    doc.save(`Becas.pdf`);
  }
  function excel(){
    let beca_col
    let beca_ins
    const dataExcel = alumnos.map((c) => {
      if (
        c.is_active == "Si" &&
        (c.beca_porcentaje_col != 0 ||
          c.beca_importe_col != 0 ||
          c.beca_importe_ins != 0 ||
          c.beca_porcentaje_ins != 0)
      ) {
        if (
          (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
          (selectedFamilia == 0 ||
            selectedFamilia == c.familias[0]._id) &&
          (selectedName == 0 || selectedName == c.name) &&
          (selectedApellidoP == 0 ||
            selectedApellidoP ==
              c.familias[0].apellido_paterno) &&
          (selectedApellidoM == 0 ||
            selectedApellidoM ==
              c.familias[0].apellido_materno) &&
          (selectedPlantel == 0 ||
            selectedPlantel ==
              c.clases[0].planteles[0]._id) &&
          (selectedArea == 0 ||
            selectedArea ==
              c.clases[0].grados[0].areas[0]._id)
        ){
          if(c.beca_porcentaje_col ==0){beca_col="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_col)}else{beca_col=`${c.beca_porcentaje_col} %`}
          if(c.beca_porcentaje_ins ==0){beca_ins="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_ins)}else{beca_ins=`${c.beca_porcentaje_ins} %`}
      return {CodigoFam: c.familias[0].codigo_familia, Nombre: c.familias[0].apellido_paterno + " "+ c.familias[0].apellido_materno + " " + c.name, Plantel: c.clases[0].planteles[0].name, Area: c.clases[0].grados[0].areas[0].name, Clase: c.clases[0].grados[0].name + " " + c.clases[0].name, BecaColegiatura: beca_col, BecaInscripcion: beca_ins};
        }else if(
          (user.planteles == c.clases[0].planteles[0]._id) &&
          (user.areas == c.clases[0].grados[0].areas[0]._id) &&
          (selectedFamilia == 0 ||
            selectedFamilia == c.familias[0]._id) &&
          (selectedName == 0 || selectedName == c.name) &&
          (selectedApellidoP == 0 ||
            selectedApellidoP ==
              c.familias[0].apellido_paterno) &&
          (selectedApellidoM == 0 ||
            selectedApellidoM ==
              c.familias[0].apellido_materno)
        ){
          if(c.beca_porcentaje_col ==0){beca_col="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_col)}else{beca_col=`${c.beca_porcentaje_col} %`}
          if(c.beca_porcentaje_ins ==0){beca_ins="$" + new Intl.NumberFormat("en-US").format(c.beca_importe_ins)}else{beca_ins=`${c.beca_porcentaje_ins} %`}
      return {CodigoFam: c.familias[0].codigo_familia, Nombre: c.familias[0].apellido_paterno + " "+ c.familias[0].apellido_materno + " " + c.name, Plantel: c.clases[0].planteles[0].name, Area: c.clases[0].grados[0].areas[0].name, Clase: c.clases[0].grados[0].name + " " + c.clases[0].name, BecaColegiatura: beca_col, BecaInscripcion: beca_ins};
        }
      }
    })
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = "ListadoBecas";
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: "ListadoBecas",
    sheetFilter: ["CodigoFam", "Nombre", "Plantel", "Area", "Clase", "BecaColegiatura", "BecaInscripcion"],
    sheetHeader: ["CodigoFam", "Nombre", "Plantel", "Area", "Clase", "BecaColegiatura", "BecaInscripcion"],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_cobranza && user.menu_becas ? (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <Row>
                  <Col md={8}>
                  <Button
                    size="sm"
                    type="submit"
                    className="btn btn-danger"
                    href="/MenuCobranza"
                  >
                    Regresar
                  </Button>
                  </Col>
                  <Col sm={4}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button size="sm" className="btn" color="info">
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <h3 align="center">Alumnos con Beca</h3>
                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                      <th style={{ paddingBottom: "0px" }}>Cod. Fam.</th>
                      <th style={{ paddingBottom: "0px" }}>Nombre</th>
                      <th style={{ paddingBottom: "0px" }}>Apellido P.</th>
                      <th style={{ paddingBottom: "0px" }}>Apellido M.</th>
                      <th style={{ paddingBottom: "0px" }}>Plantel</th>
                      <th style={{ paddingBottom: "0px" }}>Area</th>
                      <th style={{ paddingBottom: "0px" }}>Clase</th>
                      <th style={{ paddingBottom: "0px" }}>Beca Colegiatura</th>
                      <th style={{ paddingBottom: "0px" }}>Beca Inscripcion</th>
                      <th style={{ paddingBottom: "0px" }}>Info</th>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedFamilia}
                          required
                          onChange={(e) => setSelectedFamilia(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {familias
                            .sort((a, b) =>
                              a.codigo_familia > b.codigo_familia ? 1 : -1
                            )
                            .map((c) => { 
                              if (
                            (selectedApellidoP == 0 ||
                              selectedApellidoP ==
                                c.apellido_paterno) &&
                            (selectedApellidoM == 0 ||
                              selectedApellidoM ==
                                c.apellido_materno)
                          ){
                              return (
                                <option value={c._id}>
                                  {c.codigo_familia}
                                </option>
                              );
                          }
                            })}
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedName}
                          required
                          onChange={(e) => setSelectedName(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {alumnos
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((c) => {
                              if (
                                (selectedFamilia == 0 ||
                                  selectedFamilia == c.familias[0]._id) &&
                                (selectedApellidoP == 0 ||
                                  selectedApellidoP ==
                                    c.familias[0].apellido_paterno) &&
                                (selectedApellidoM == 0 ||
                                  selectedApellidoM ==
                                    c.familias[0].apellido_materno)
                              ){
                              return <option value={c.name}>{c.name}</option>;
                              }})}
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedApellidoP}
                          required
                          onChange={(e) => setSelectedApellidoP(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {familias
                            .sort((a, b) =>
                              a.apellido_paterno > b.apellido_paterno ? 1 : -1
                            )
                            .map((c) => {
                              if (
                                (selectedFamilia == 0 ||
                                  selectedFamilia ==
                                    c._id) &&
                                (selectedApellidoM == 0 ||
                                  selectedApellidoM ==
                                    c.apellido_materno)
                              ){
                              return (
                                <option value={c.apellido_paterno}>
                                  {c.apellido_paterno}
                                </option>
                              );}
                            })}
                        </Input>
                      </td>
                      <td style={{ paddingTop: "0px" }}>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedApellidoM}
                          required
                          onChange={(e) => setSelectedApellidoM(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {familias
                            .sort((a, b) =>
                              a.apellido_materno > b.apellido_materno ? 1 : -1
                            )
                            .map((c) => {
                              if (
                                (selectedFamilia == 0 ||
                                  selectedFamilia ==
                                    c._id) &&
                                (selectedApellidoP == 0 ||
                                  selectedApellidoP ==
                                    c.apellido_paterno)
                              ){
                              return (
                                <option value={c.apellido_materno}>
                                  {c.apellido_materno}
                                </option>
                              );}
                            })}
                        </Input>
                      </td>
                      {user.planteles == PLANTELES_GENERAL ? (
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedPlantel}
                        onChange={(e) => setSelectedPlantel(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {planteles
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != PLANTELES_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  {user.areas == AREAS_GENERAL ? (
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => setSelectedArea(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                    </td>
                  ) : (
                    <td></td>
                  )}
                    </tr>
                  </thead>
                  <tbody>
                    {user.planteles == PLANTELES_GENERAL &&
                      user.areas == AREAS_GENERAL ?
                    alumnos
                      .sort((a, b) =>
                        a.familias[0].codigo_familia >
                        b.familias[0].codigo_familia
                          ? 1
                          : -1
                      )
                      .map((c) => {
                        if (
                          c.is_active == "Si" &&
                          (c.beca_porcentaje_col != 0 ||
                            c.beca_importe_col != 0 ||
                            c.beca_importe_ins != 0 ||
                            c.beca_porcentaje_ins != 0)
                        ) {
                          if (
                            (selectedFamilia == 0 ||
                              selectedFamilia == c.familias[0]._id) &&
                            (selectedName == 0 || selectedName == c.name) &&
                            (selectedApellidoP == 0 ||
                              selectedApellidoP ==
                                c.familias[0].apellido_paterno) &&
                            (selectedApellidoM == 0 ||
                              selectedApellidoM ==
                                c.familias[0].apellido_materno) &&
                            (selectedPlantel == 0 ||
                              selectedPlantel ==
                                c.clases[0].planteles[0]._id) &&
                            (selectedArea == 0 ||
                              selectedArea ==
                                c.clases[0].grados[0].areas[0]._id)
                          ){
                            return (
                              <tr>
                                <TablaBecas
                                  codigoFamilia={c.familias[0].codigo_familia}
                                  name={c.name}
                                  genero={c.genero}
                                  registro={c.registro}
                                  idClase={c.clases[0]._id}
                                  fecha_ingreso={c.fecha_ingreso}
                                  fecha_nacimiento={c.fecha_nacimiento}
                                  telefono={c.telefono}
                                  observaciones={c.observaciones}
                                  alergias={c.alergias}
                                  tipo_sangre={c.tipo_sangre}
                                  apellidoP={c.familias[0].apellido_paterno}
                                  apellidoM={c.familias[0].apellido_materno}
                                  plantel={c.clases[0].planteles[0].name}
                                  clase={c.clases[0].name}
                                  grado={c.clases[0].grados[0].name}
                                  area={c.clases[0].grados[0].areas[0].name}
                                  _idAlumno={c._id}
                                  _id={c._id}
                                  idFamilia={c.familias[0]._id}
                                  beca_importe_col={c.beca_importe_col}
                                  beca_importe_ins={c.beca_importe_ins}
                                  beca_porcentaje_col={c.beca_porcentaje_col}
                                  beca_porcentaje_ins={c.beca_porcentaje_ins}
                                />
                              </tr>
                            )}
                        }
                      }):
                      alumnos
                      .sort((a, b) =>
                        a.familias[0].codigo_familia >
                        b.familias[0].codigo_familia
                          ? 1
                          : -1
                      )
                      .map((c) => {
                        if (
                          c.is_active == "Si" &&
                          (c.beca_porcentaje_col != 0 ||
                            c.beca_importe_col != 0 ||
                            c.beca_importe_ins != 0 ||
                            c.beca_porcentaje_ins != 0)
                        ) {
                          if (
                            (selectedFamilia == 0 ||
                              selectedFamilia == c.familias[0]._id) &&
                            (selectedName == 0 || selectedName == c.name) &&
                            (selectedApellidoP == 0 ||
                              selectedApellidoP ==
                                c.familias[0].apellido_paterno) &&
                            (selectedApellidoM == 0 ||
                              selectedApellidoM ==
                                c.familias[0].apellido_materno) &&
                                (user.planteles == c.clases[0].planteles[0]._id) &&
                                (user.areas == c.clases[0].grados[0].areas[0]._id)
                          ){
                            return (
                              <tr>
                                <TablaBecas
                                  codigoFamilia={c.familias[0].codigo_familia}
                                  name={c.name}
                                  genero={c.genero}
                                  registro={c.registro}
                                  idClase={c.clases[0]._id}
                                  fecha_ingreso={c.fecha_ingreso}
                                  fecha_nacimiento={c.fecha_nacimiento}
                                  telefono={c.telefono}
                                  observaciones={c.observaciones}
                                  alergias={c.alergias}
                                  tipo_sangre={c.tipo_sangre}
                                  apellidoP={c.familias[0].apellido_paterno}
                                  apellidoM={c.familias[0].apellido_materno}
                                  plantel={c.clases[0].planteles[0].name}
                                  clase={c.clases[0].name}
                                  grado={c.clases[0].grados[0].name}
                                  area={c.clases[0].grados[0].areas[0].name}
                                  _idAlumno={c._id}
                                  _id={c._id}
                                  idFamilia={c.familias[0]._id}
                                  beca_importe_col={c.beca_importe_col}
                                  beca_importe_ins={c.beca_importe_ins}
                                  beca_porcentaje_col={c.beca_porcentaje_col}
                                  beca_porcentaje_ins={c.beca_porcentaje_ins}
                                />
                              </tr>
                            )}
                        }
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default ListadoBecas;
