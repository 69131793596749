import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import TablaClases from "./TablaClases";
import ClasesCreate from "./ClasesCreate";
import axios from "axios";
import { Table, Input } from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function ListadoClases() {
  const { user } = useContext(AuthContext);
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState("");
  const [clases, setClases] = useState([]);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(0);

  useEffect(() => {
    axios
    .get(URL_AREAS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allAreas = res.data;
      setAreas(allAreas);
    })
    .catch((err) => {
      console.log(err);
    });
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClases = response.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedPlantel]);

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        {user.menu_escuela && user.menu_clases ? (
          <div className="row">
            {
              <div className="col-lg-7 col-sm-7">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Clases</h3>
                    <Table size="sm" striped borderless className="table-responsive-xl">
                      <thead>
                        <tr>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Nombre
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Areas
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Plantel
                          </th>
                          <th
                            style={{ paddingBottom: "0px" }}
                            className="tituloTabla"
                          >
                            Editar
                          </th>
                        </tr>
                        <tr>
                          <td style={{ paddingTop: "0px" }}>
                            <Input
                              bsSize="sm"
                              type="select"
                              value={selectedGrado}
                              onChange={(e) => setSelectedGrado(e.target.value)}
                            >
                              <option value="0">Selecciona</option>
                              {grados
                                .sort((a, b) => a.name - b.name)
                                .map((a) => {
                                  return (
                                    <option value={a._id}>
                                      {a.name} {a.areas[0].name}
                                    </option>
                                  );
                                })}
                            </Input>
                          </td>
                        {user.areas == AREAS_GENERAL ? (
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => setSelectedArea(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </td>
                  ) : undefined}
                          {user.planteles == PLANTELES_GENERAL ? (
                            <td style={{ paddingTop: "0px" }}>
                              <Input
                                bsSize="sm"
                                type="select"
                                value={selectedPlantel}
                                onChange={(e) =>
                                  setSelectedPlantel(e.target.value)
                                }
                              >
                                <option value="0">Selecciona</option>
                                {planteles
                                  .sort((a, b) => a.name - b.name)
                                  .map((a) => {
                                    if (a._id != PLANTELES_GENERAL) {
                                      return (
                                        <option value={a._id}>{a.name}</option>
                                      );
                                    }
                                  })}
                              </Input>
                            </td>
                          ) : undefined}
                        </tr>
                      </thead>
                      <tbody>
                        {user.planteles == PLANTELES_GENERAL &&
                      user.areas == AREAS_GENERAL?
                       clases
                       .sort((a, b) => (a.grados[0].name > b.grados[0].name ? 1 : -1))
                       .map((c) => {
                              if (
                                (selectedGrado == 0 ||
                                  selectedGrado == c.grados[0]._id) &&
                                (selectedPlantel == 0 ||
                                  selectedPlantel == c.planteles[0]._id) &&
                                  (selectedArea == 0 ||
                                    selectedArea == c.grados[0].areas[0]._id)
                              ) {
                                return (
                                  <tr>
                                    <TablaClases
                                      name={c.name}
                                      grado={c.grados[0].name}
                                      idGrado={c.grados[0]._id}
                                      areas={c.grados[0].areas[0].name}
                                      plantel={c.planteles[0].name}
                                      idPlantel={c.planteles[0]._id}
                                      is_active={c.is_active}
                                      _id={c._id}
                                    />
                                  </tr>
                                );
                              }
                            })
                          : clases.map((c) => {
                              if (
                                (selectedGrado == 0 ||
                                  selectedGrado == c.grados[0]._id) &&
                                (user.planteles == c.planteles[0]._id) &&
                                (user.areas == c.grados[0].areas[0]._id)
                              ) {
                                return (
                                  <tr>
                                    <TablaClases
                                      name={c.name}
                                      grado={c.grados[0].name}
                                      idGrado={c.grados[0]._id}
                                      areas={c.grados[0].areas[0].name}
                                      plantel={c.planteles[0].name}
                                      idPlantel={c.planteles[0]._id}
                                      is_active={c.is_active}
                                      _id={c._id}
                                    />
                                  </tr>
                                );
                              }
                            })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-lg-5 col-sm-5">
              <div className="card">
                <div className="card-body">
                  <ClasesCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <br />
      <Footer />
    </>
  );
}

export default ListadoClases;
