import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function AbonosColegiaturasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_ABONOS_COLEGIATURAS = process.env.REACT_APP_URL_ABONOS_COLEGIATURAS;
  
  const [text, setText] = useState(false);
  const [familias, setFamilias] = useState([]);
  const [alumnos, setAlumnos] = useState([])
  const [fecha, setFecha] = useState("");
  const [importe, setImporte] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedAlumno, setSelectedAlumno] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [alumnoPlantel, setAlumnoPlantel] = useState("");
  const [alumnoArea, setAlumnoArea] = useState("");
  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(()=>{
    axios
    .get(URL_FAMILIAS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allFamilias = response.data;
      setFamilias(allFamilias);
    })
    .catch((err) => {
      console.log(err);
    });
  })

  const selectFamilia = (e)=>{
      setSelectedFamilia(e.target.value);
    const URL_ALUMNOS_FAM = `${process.env.REACT_APP_URL_ALUMNOS}/familia/${value._id}`
    axios
    .get(URL_ALUMNOS_FAM, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allAlumnos = response.data;
      setAlumnos(allAlumnos);
    })
    .catch((err) => {
      console.log(err);
    });
  }

 const savePago = async (event) => {
    event.preventDefault();
    setValidaBoton(false)
  try{
    const URL_ALUMNOS_ABONO = `${process.env.REACT_APP_URL_ALUMNOS}/${alumnos[0]._id}`;
    await axios
    .get(URL_ALUMNOS_ABONO, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      const AP = response.data.clases[0].planteles[0]._id;
      const AA = response.data.clases[0].grados[0].areas[0]._id;
      axios
      .post(
        URL_ABONOS_COLEGIATURAS,
        {
          fecha,
          importe,
          forma_pago,
          alumnos: alumnos[0]._id,
          familias: value._id,
          planteles: AP,
          areas: AA
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Abono Colegiatura',
            detalle: `Fecha: ${fecha} / Familia: ${value._id}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      })
    })
    .catch((err) => {
      console.log(err);
    });
   }
      catch(error) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        }
  }

  const options = familias.map((option) => {
    const junta =
     option.codigo_familia + " " + option.apellido_paterno + " " + option.apellido_materno;
    const firstLetter = option.apellido_paterno[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.colegiaturas_create  ?(
        <div className="card container col-sm-6">
          <h3 align="center">Captura un pago de Colegiatura</h3>
          <Form onSubmit={savePago}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={6}>
                <Label className="mr-sm-2">Familia</Label>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedFamilia) => {
                      setValue(selectedFamilia)
                    }}
                    onBlur={selectFamilia}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo_familia} {option.apellido_paterno} {option.apellido_materno}
                      </React.Fragment>
                    )}
                  />
                </Col>
                {/* <Col md={6}>
                <Label className="mr-sm-2">Alumno</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedAlumno}
                required
                onChange={(e) => {
                  setSelectedAlumno(e.target.value);
                }}
              >
                <option value="0">Selecciona un Alumno</option>
                {
                  alumnos.map((a)=>{
                    return (
                      <option value={a._id}>
                        {a.name + " " + a.clases[0].name + " " + a.clases[0].grados[0].name}
                      </option>
                    );
                  })
                }
                </Input>
                </Col>
              </Row>
             <Row> */}
               <Col md={6}>
               <Label>Importe</Label>
              <Input
                className="col-sm-12"
                type="number"
                placeholder="Importe"
                value={importe}
                required
                onChange={(e) => {
                  setImporte(e.target.value);
                }}
              />
               </Col>
               <Col md={6}>
               <Label>Forma de Pago</Label>
                    <Input
                      type="select"
                      placeholder="Forma de Pago"
                      value={forma_pago}
                      required
                      onChange={(e) => {
                        setFormaPago(e.target.value);
                      }}
                    >
                      <option value="0">Forma de Pago</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Deposito">Deposito</option>
                      <option value="TDC">TDC</option>
                      <option value="Cheque">Cheque</option>
                    </Input>
               </Col>
             {/* </Row>
             <Row> */}
               <Col md={6}>
               <Label>Fecha</Label>
              <Input
                type="date"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
               </Col>
             </Row>
            </FormGroup>
            <br />
            <Row>
            {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Registrar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Registrar
      </Button>)}
              <div>
                <SweetAlert
                  show={text}
                  title="Creado con Exito"
                  // text="SweetAlert in React"
                  onConfirm={() => {
                    setText(false);
                  }}
                />
              </div>

              <Button
                href="/Colegiaturas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
        </div>
      ): undefined } 
      
    </>
  );
}

export default AbonosColegiaturasCreate;
