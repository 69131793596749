import React from 'react'

function TablaInfoHijos(props) {

    let colegiatura
    let inscripcion

function calculaCol(a) {
  if(props.beca_porcentaje_col ==0){
    return(
    colegiatura = "$" + new Intl.NumberFormat("en-US").format(props.beca_importe_col)
    )
  } else{
    return(
    colegiatura = `${props.beca_porcentaje_col} %`
    )
  }  
}
calculaCol()

function calculaIns(a) {
  if(props.beca_porcentaje_ins ==0){
    return(
    inscripcion = "$" + new Intl.NumberFormat("en-US").format(props.beca_importe_ins)
    )
  } else{
    return(
    inscripcion = `${props.beca_porcentaje_ins} %`
    )
  }  
}
calculaIns()

    return (
        <>
        <td>{props.is_active}</td>
        <td>{props.name}</td>
        <td>{props.plantel} </td>
        <td>{props.grado} {props.clase} {props.area}</td>
        <td>{`${"$" + new Intl.NumberFormat("en-US").format(props.colegiatura)}`}</td>
        <td>{`${"$" + new Intl.NumberFormat("en-US").format(props.inscripcion)}`}</td>
        <td align="center">{colegiatura}</td>
    <td align="center">{inscripcion}</td>
        </>
    )
}

export default TablaInfoHijos
