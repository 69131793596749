import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";

function TablaPeriodos(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PERIODOS_EDIT = `${process.env.REACT_APP_URL_PERIODOS}/${props._id}`;
  const URL_CICLOS = process.env.REACT_APP_URL_CICLOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const [periodos, setPeriodos] = useState("");
  const [name, setName] = useState(props.name);
  const [ciclos, setCiclos] = useState([]);
  const [selectedCiclo, setSelectedCiclo] = useState(props.idCiclos);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(props.idArea);
  const [fecha_inicio, setFechaInicio] = useState(props.fecha_inicio);
  const [fecha_fin, setFechaFin] = useState(props.fecha_fin);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);

  useEffect(() => {
    axios
      .get(URL_PERIODOS_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPeriodos = response.data;
        setPeriodos(allPeriodos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CICLOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCiclos = res.data;
        setCiclos(allCiclos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function editPeriodo(event) {
    event.preventDefault();
    axios
      .patch(
        URL_PERIODOS_EDIT,
        {
          name,
          fecha_inicio,
          fecha_fin,
          ciclos: selectedCiclo,
          areas: selectedArea,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Periodo',
            detalle: `${name} / ${selectedCiclo}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <td>{props.name}</td>
      <td>{props.fecha_inicio.split("T")[0].split("-").reverse().join("/")}</td>
      <td>{props.fecha_fin.split("T")[0].split("-").reverse().join("/")}</td>
      <td>{props.ciclos}</td>
      <td>{props.areas}</td>
      <td>
        <Button color="info" id="Editar" size="sm" onClick={toggle}>
          <i class="far fa-edit"></i>{" "}
        </Button>
        <Baja
          idStatus={props._id}
          is_active={props.is_active}
          URL_BAJA={process.env.REACT_APP_URL_PERIODOS}
        />
      </td>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><h4>Editar Periodo</h4></ModalHeader>
        <ModalBody>
        <Label for="exampleEmail" className="mr-sm-2">
              Periodo
            </Label>
            <Input
              type="text"
              name="email"
              id="exampleEmail"
              placeholder="Nombre del Periodo"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Row>
              <Col sm={6}>
                <Label className="mr-sm-2">Ciclo</Label>
                <Input
                  type="select"
                  value={selectedCiclo}
                  required
                  onChange={(e) => {
                    setSelectedCiclo(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Ciclo</option>
                  {ciclos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((c) => {
                      return <option value={c._id}>{c.name}</option>;
                    })}
                </Input>
              </Col>
              <Col sm={6}>
                <Label className="mr-sm-2">Area</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Area</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((d) => {
                      return <option value={d._id}>{d.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Label>Fecha Inicio</Label>
                <Input
                  type="date"
                  value={fecha_inicio}
                  required
                  onChange={(e) => {
                    setFechaInicio(e.target.value);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label>Fecha Fin</Label>
                <Input
                  type="date"
                  value={fecha_fin}
                  required
                  onChange={(e) => {
                    setFechaFin(e.target.value);
                  }}
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPeriodo}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaPeriodos;
