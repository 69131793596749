import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Label,
  Input,
  Row,
  Col,
  Table,
  Button
} from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import TablaCalificaciones from './TablaCalificaciones'
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function CapturarCalificaciones() {
  const { user } = useContext(AuthContext);
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_CALIFICACIONES = process.env.REACT_APP_URL_CALIFICACIONES;
  const URL_PERIODOS = process.env.REACT_APP_URL_PERIODOS;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [alumnos, setAlumnos] = useState([]);
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(0);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(0);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(0);
  const [materias, setMaterias] = useState([]);
  const [selectedMateria, setSelectedMateria] = useState(0);
  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState(0);
  
  const [calificaciones, setCalificaciones] = useState([])
  
  const [aspecto1, setAspecto1] = useState('');
  const [aspecto2, setAspecto2] = useState('');
  const [aspecto3, setAspecto3] = useState('');
  const [porcentaje1, setPorcentaje1] = useState();
  const [porcentaje2, setPorcentaje2] = useState();
  const [porcentaje3, setPorcentaje3] = useState();

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PERIODOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPeriodos = res.data;
        setPeriodos(allPeriodos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAlumnos = res.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const materiasClase = (e) => {
    setSelectedClase(e.target.value);
    clases.map((c) => {
      if (selectedClase == c._id) {
        setMaterias(c.grados[0].materias);
      }
    })
    setCalificaciones(alumnos.map((a)=>{
      if ( a.is_active == "Si" &&
        (selectedPlantel == 0 ||
          selectedPlantel == a.clases[0].planteles[0]._id) &&
        (selectedArea == 0 ||
          selectedArea ==
            a.clases[0].grados[0].areas[0]._id) &&
        (selectedGrado == 0 ||
          selectedGrado == a.clases[0].grados[0]._id) &&
        (selectedClase == a.clases[0]._id) 
      ) { 
        return {
          calificacionP1: "",
          calificacionP2: "",
          calificacionP3: "",
          materias: "",
          alumnos: a._id,
          periodos: "",
          apellido_paterno:a.familias[0].apellido_paterno,
          apellido_materno:a.familias[0].apellido_materno,
          name:a.name
        }
      }
  }).filter(function (el) {
    return el != null;
  }))
  };


  function actualizaArray(arrayAlumno, index){
    let data = calificaciones;
    data[index].calificacionP1= arrayAlumno.calificacionP1;
    data[index].calificacionP2= arrayAlumno.calificacionP2;
    data[index].calificacionP3= arrayAlumno.calificacionP3;
    data[index].materias= arrayAlumno.materias;
    data[index].alumnos= arrayAlumno.alumnos;
    data[index].periodos= arrayAlumno.periodos;
    setCalificaciones(data)
  }  


  const guardaCalificaciones = (event) => {
    let por = parseFloat(porcentaje1) + parseFloat(porcentaje2) + parseFloat(porcentaje3)
    if(por == 100){
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Guardar",
    }).then((result) => {
      if (result.isConfirmed) {
    calificaciones.map((a) => {
      let califFinal = parseFloat(a.calificacionP1*(porcentaje1/100)+a.calificacionP2*(porcentaje2/100)+a.calificacionP3*(porcentaje3/100)).toFixed(2)
        axios
          .post(
            URL_CALIFICACIONES,
            {
            nombreP1: aspecto1,
            nombreP2:aspecto2,
            nombreP3:aspecto3,
            porcentajeP1: porcentaje1,
            porcentajeP2:porcentaje2,
            porcentajeP3:porcentaje3,
            calificacionP1: a.calificacionP1,
            calificacionP2:a.calificacionP2,
            calificacionP3:a.calificacionP3,
            calificacionFinal:califFinal,
            materias:a.materias,
            alumnos:a.alumnos,
            periodos:a.periodos
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios
            .post(
              URL_LOGS,
              {
                tipo:'Captura Calificaciones',
                detalle: 'Alta Calificaciones',
                user: user.id
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            console.log(error);
          })
        })
    }
  }
    )
  }
  else{Swal.fire({
    icon: "error",
    title: "Oops...",
    text: `La suma de los porcentajes es ${por}% y debe ser 100%`,
  })}
}


  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
      {user.menu_calificaciones ?(
        <div className="card container col-sm-12">
          <h3 align="center">Calificaciones</h3>

          <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Planteles</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedPlantel}
                  required
                  onChange={(e) => setSelectedPlantel(e.target.value)}
                >
                  <option value="0">Selecciona una Plantel</option>
                  {planteles
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name} </option>;
                    })}
                </Input>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Areas</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => setSelectedArea(e.target.value)}
                >
                  <option value="0">Selecciona una Area</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Grados</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedGrado}
                  required
                  onChange={(e) => setSelectedGrado(e.target.value)}
                >
                  <option value="0">Selecciona una Grado</option>
                  {grados
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (selectedArea == 0 || selectedArea == a.areas[0]._id) {
                        return (
                          <option value={a._id}>
                            {a.name} {a.areas[0].name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
             
            </Row>
         
              <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Clases</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedClase}
                  required
                  onChange={materiasClase}
                  onBlur={materiasClase}
                >
                  <option value="0">Selecciona una Clase</option>
                  {clases
                    .sort(
                      (a, b) =>
                        a.grados[0].name - b.grados[0].name ||
                        b.name - a.name ||
                        a.planteles[0].name - b.planteles[0].name
                    )
                    .map((a) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == a.planteles[0]._id) &&
                        (selectedArea == 0 ||
                          selectedArea == a.grados[0].areas[0]._id) &&
                        (selectedGrado == 0 || selectedGrado == a.grados[0]._id)
                      ) {
                        return (
                          <option value={a._id}>
                            {a.grados[0].name} {a.name}{" "}
                            {a.grados[0].areas[0].name} {a.planteles[0].name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
                <Col md={4}>
                  <Label className="mr-sm-2">Periodo</Label>
                  <Input
                  size="sm"
                    className="col-sm-12"
                    type="select"
                    value={selectedPeriodo}
                    required
                    onChange={(e) => setSelectedPeriodo(e.target.value)}
                    name="periodo"
                  >
                    <option value="0">Selecciona un Periodo</option>
                    {periodos
                      .sort((a, b) =>
                        a.ciclos[0].name > b.ciclos[0].name ? 1 : -1
                      )
                      .map((p) => {
                        return (
                          <option value={p._id}>
                            {p.name} {p.ciclos[0].name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={4}>
                  <Label className="mr-sm-2">Materia</Label>
                  <Input
                  size="sm"
                    className="col-sm-12"
                    type="select"
                    value={selectedMateria}
                    required
                    onChange={(e) => {
                      setSelectedMateria(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona una Materia</option>
                    {materias.map((e)=>{
                          return <option value={e._id}>{e.name}</option>;
                        })
                    }
                  </Input>
                </Col>
              </Row>
              <br />
              <h3 align="center">Aspectos a Calificar</h3>
              <Row>
                <Col>
                <Label>Aspecto 1</Label>
                <Input
                size="sm"
                    type="text"
                    placeholder="Descripcion"
                    value={aspecto1}
                    required
                    onChange={(e) => {
                      setAspecto1(e.target.value);
                    }}
                  />
                  <Input
                  size="sm"
                    type="number"
                    placeholder="Porcentaje"
                    value={porcentaje1}
                    required
                    onChange={(e) => {
                      setPorcentaje1(e.target.value);
                    }}
                  />
                </Col>
                <Col>
                <Label>Aspecto 2</Label>
                <Input
                size="sm"
                    type="text"
                    placeholder="Descripcion"
                    value={aspecto2}
                    required
                    onChange={(e) => {
                      setAspecto2(e.target.value);
                    }}
                  />
                  <Input
                  size="sm"
                    type="number"
                    placeholder="Porcentaje"
                    value={porcentaje2}
                    required
                    onChange={(e) => {
                      setPorcentaje2(e.target.value);
                    }}
                  />
                </Col>
                <Col>
                <Label>Aspecto 3</Label>
                <Input
                size="sm"
                    type="text"
                    placeholder="Descripcion"
                    value={aspecto3}
                    required
                    onChange={(e) => {
                      setAspecto3(e.target.value);
                    }}
                  />
                  <Input
                  size="sm"
                    type="number"
                    placeholder="Porcentaje"
                    value={porcentaje3}
                    required
                    onChange={(e) => {
                      setPorcentaje3(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <div className="container col-8">
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>Nombre</th>
                  <th>{aspecto1} {porcentaje1}%</th>
                    <th>{aspecto2} {porcentaje2}%</th>
                    <th>{aspecto3} {porcentaje3}%</th>
                  <th>Calificacion Final</th>
                  </tr>
                </thead>
                <tbody>
                  {calificaciones
                    .map((a, index) => {
                        if (
                            (aspecto1 != '') && (aspecto2 != '') && (aspecto3 != '') &&
                            (porcentaje1 >= 0) && (porcentaje2 >= 0) && (porcentaje3 >= 0) &&
                            (selectedMateria != 0) && (selectedPeriodo != 0)
                        ) {
                          return (
                            <tr>
                              <TablaCalificaciones
                                apellido_paterno={a.apellido_paterno}
                                apellido_materno={a.apellido_materno}
                                name={a.name}
                                id={a.alumnos}
                                aspecto1={aspecto1}
                                aspecto2={aspecto2}
                                aspecto3={aspecto3}
                                P1={porcentaje1}
                                P2={porcentaje2}
                                P3={porcentaje3}
                                idPeriodo={selectedPeriodo}
                                idMateria={selectedMateria}
                                actualizaArray={actualizaArray}
                                index={index}
                                key={a.alumnos}
                              />
                            </tr>
                          );
                        }
                    })}
                </tbody>
                <br />
              </Table>
              {
                (
                  (aspecto1 != '') && (aspecto2 != '') && (aspecto3 != '') &&
                  (porcentaje1 >= 0) && (porcentaje2 >= 0) && (porcentaje3 >= 0) &&
                  (selectedMateria != 0) && (selectedPeriodo != 0) && (selectedClase!=0)
              )?(
                <Button
              size="sm"
              onClick={guardaCalificaciones}
              className="btn btn-danger"
              id="botonListado"
            >
              Guardar
            </Button>
              ):(
                <Button
              size="sm"
              disabled
              className="btn btn-danger"
              id="botonListado"
            >
              Guardar
            </Button>
              )
              }
              
            </div>
        </div>
        ): undefined } 
      </div>
    </>
  );
}

export default CapturarCalificaciones;
