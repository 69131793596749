import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from '../../Baja'
import MateriasInfo from './MateriasInfo'


function TablaGrados(props) {
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const { user } = useContext(AuthContext);
  let _id = props._id
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const URL_GRADOS_EDIT = `${process.env.REACT_APP_URL_GRADOS}/${props._id}`;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [grados, setGrados] = useState("");
  const [name, setName] = useState(props.name);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(props.idAreas)
  const [colegiatura, setColegiatura] = useState(props.colegiatura);
  const [inscripcion, setInscripcion] = useState(props.inscripcion)
  
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);
  
  useEffect(() => {
    axios
      .get(URL_GRADOS_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrados = response.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function editGrado(event) {
    event.preventDefault();
    axios
      .patch(
        URL_GRADOS_EDIT,
        {
          name,
          areas: selectedArea,
          colegiatura,
          inscripcion
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Grado',
            detalle: `${name} / ${selectedArea}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <td>{props.name}</td>
      <td>{props.areas}</td>
      <td align="right">
        {"$" + new Intl.NumberFormat("en-US").format(props.colegiatura)}
      </td>
      <td align="right">
        {"$" + new Intl.NumberFormat("en-US").format(props.inscripcion)}
      </td>

      <td align="center">
        <Button color="info" id="Materias"  size="sm" onClick={toggle}>
        <i class="fas fa-book-open"></i>
        </Button>
      </td>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Materias de {props.name} {props.areas}</ModalHeader>
        <ModalBody>
          <MateriasInfo _id={_id} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>

        </ModalFooter>
      </Modal>

      <td>
      <Button color="info" id="Editar" size="sm" onClick={toggleEdit}>
          <i class="far fa-edit"></i>
        </Button>
          <Baja idStatus={props._id} is_active={props.is_active} URL_BAJA={process.env.REACT_APP_URL_GRADOS} />
      </td>
      <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar Grado</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
           {user.areas == AREAS_GENERAL? (
                <div>
          <Label className="mr-sm-2">Area</Label>
            <Input
              className="col-sm-12"
              type="select"
              value={selectedArea}
              required
              onChange={(e) => {
                setSelectedArea(e.target.value);
              }}
            >
              <option value="0">{props.areas}</option>
              {areas.map((a) => {
                if (a._id != AREAS_GENERAL){
                return <option value={a._id}>{a.name}</option>}
              })}
            </Input>
            </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Area</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    <option value="0">Selecciona un Area </option>
                    {areas
                     .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                       if (a._id == user.areas) {
                return <option value={a._id}>{a.name}</option>;
                       }
              })}
                  </Input>
                </div>
              )}
            <Label for="exampleEmail" className="mr-sm-2">
              Colegiatura
            </Label>
            <Input
              type="number"
              placeholder="Importe de Colegiatura"
              value={colegiatura}
              required
              onChange={(e) => {
                setColegiatura(e.target.value);
              }}
            />
            <Label for="exampleEmail" className="mr-sm-2">
              Inscripcion
            </Label>
            <Input
              type="number"
              placeholder="Importe de Inscripcion"
              value={inscripcion}
              required
              onChange={(e) => {
                setInscripcion(e.target.value);
              }}
            />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editGrado}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaGrados;
