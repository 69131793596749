import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import Signup from "./Signup";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col
} from "reactstrap";
import Header from "../Header/Header";
import { TableHeader, Pagination, Search } from "../../DataTable"
import useFullPageLoader from "../../hooks/useFullPageLoader"
import Baja from "../../views/Baja";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ListadoUsuarios() {
  const { user } = useContext(AuthContext);
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_LISTA_USERS = process.env.REACT_APP_URL_LISTA_USERS;
  const [usuarios, setUsuarios] = useState([]);

  //--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });
const [activo, setActivo] = useState("");
const ITEMS_PER_PAGE = 50

const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
const URL_AREAS = process.env.REACT_APP_URL_AREAS;
const [idEdit, setIdEdit] = useState();
const [nombre, setNombre] = useState();
const [apellido, setApellido] = useState();
const [email, setEmail] = useState();
const [is_active, setIsActive] = useState();
const [planteles, setPlanteles] = useState([]);
const [plantel, setPlantel] = useState();
const [areas, setAreas] = useState([]);
const [area, setArea] = useState();

const [idPermisos, setIdPermisos] = useState();
const [menu_escuela, setMenuEscuela] = useState();
const [menu_planteles, setMenuPlanteles] = useState();
const [menu_areas, setMenuAreas] = useState();
const [menu_grados, setMenuGrados] = useState();
const [menu_clases, setMenuClases] = useState();
const [menu_materias, setMenuMaterias] = useState();
const [menu_ciclos, setMenuCiclos] = useState();
const [menu_periodos, setMenuPeriodos] = useState();

const [menu_alumnos, setMenuAlumnos] = useState();
const [alumnos_listado, setAlumnosListado] = useState();
const [alumnos_create, setAlumnosCreate] = useState();
const [becas_create, setBecasCreate] = useState();
const [menu_familias, setMenuFamilias] = useState();
const [familias_create, setFamiliasCreate] = useState();
const [familias_edo_cta, setFamiliasEdoCta] = useState();
const [menu_avisos, setMenuAvisos] = useState();
const [avisos_create, setAvisosCreate] = useState();

const [menu_academico, setMenuAcademico] = useState();
const [menu_boletas, setMenuBoletas] = useState();
const [menu_info_alumno, setMenuInfoAlumno] = useState();
const [info_alumno_create, setInfoAlumnoCreate] = useState();
const [menu_calificaciones, setMenuCalificaciones] = useState();
const [menu_maestros, setMenuMaestros] = useState();
const [menu_listados, setMenuListados] = useState();

const [menu_admin, setMenuAdmin] = useState();
const [menu_periodos_admin, setMenuPeriodosAdmin] = useState();
const [menu_departamentos, setMenuDepartamentos] = useState();
const [menu_presupuestos, setMenuPresupuestos] = useState();
const [presupuestos_autorizar, setPresupuestosAutorizar] = useState();
const [presupuestos_create, setPresupuestosCreate] = useState();
const [menu_proveedores, setMenuProveedores] = useState();
const [proveedores_create, setProveedoresCreate] = useState();
const [menu_gastos, setMenuGastos] = useState();
const [gastos_create, setGastosCreate] = useState();
const [gastos_autorizar, setGastosAutorizar] = useState();

const [menu_cobranza, setMenuCobranza] = useState();
const [menu_colegiaturas, setMenuColegiaturas] = useState();
const [colegiaturas_create, setColegiaturasCreate] = useState();
const [colegiaturas_cargos_esp, setColegiaturasCargosEsp] = useState();
const [colegiaturas_abonos, setColegiaturasAbonos] = useState();
const [colegiaturas_abonos_edit, setColegiaturasAbonosEdit] = useState();
const [colegiaturas_edo_cta, setColegiaturasEdoCta] = useState();
const [menu_inscripciones, setMenuInscripciones] = useState();
const [inscripciones_create, setInscripcionesCreate] = useState();
const [inscripciones_cargos_esp, setInscripcionesCargosEsp] = useState();
const [inscripciones_abonos, setInscripcionesAbonos] = useState();
const [inscripciones_abonos_edit, setInscripcionesAbonosEdit] = useState();
const [inscripciones_edo_cta, setInscripcionesEdoCta] = useState();
const [menu_becas, setMenuBecas] = useState();

const [menu_donativos, setMenuDonativos] = useState();
const [menu_donadores, setMenuDonadores] = useState();
const [donadores_create, setDonadoresCreate] = useState();
const [donadores_edo_cta, setDonadoresEdoCta] = useState();
const [donativos_edo_cta, setDonativosEdoCta] = useState();
const [donativos_recurrentes, setDonativosRecurrentes] = useState();
const [donativos_recurrentes_generar, setDonativosRecurrentesGenerar] = useState();
const [donativos_recurrentes_create, setDonativosRecurrentesCreate] = useState();
const [donativos_cargos, setDonativosCargos] = useState();
const [donativos_cargos_create, setDonativosCargosCreate] = useState();
const [donativos_abonos, setDonativosAbonos] = useState();
const [donativos_abonos_create, setDonativosAbonosCreate] = useState();
const [donativos_abonos_edit, setDonativosAbonosEdit] = useState();
const [menu_catalogos, setMenuCatalogos] = useState();

const [menu_nominas, setMenuNominas] = useState();
const [menu_empleados, setMenuEmpleados] = useState();
const [empleados_create, setEmpleadosCreate] = useState();
const [empleados_sueldo, setEmpleadosSueldo] = useState();
const [menu_puestos, setMenuPuestos] = useState();
const [puestos_create, setPuestosCreate] = useState();
const [nominas_create, setNominasCreate] = useState();
const [nominas_listado, setNominasListado] = useState();
const [nominas_pagos_esp, setNominasPagosEsp] = useState();
const [nominas_pagar, setNominasPagar] = useState();

const [menu_reportes, setMenuReportes] = useState();
const [menu_ingresos, setMenuIngresos] = useState();
const [menu_egresos, setMenuEgresos] = useState();

const [menu_usuarios, setMenuUsuarios] = useState();
const [usuarios_create, setUsuariosCreate] = useState();
const [usuarios_permisos, setUsuariosPermisos] = useState();

const [menu_logs, setMenuLogs] = useState();

const [emailSis, setEmailSis] = useState("admin@sistemify.com");

const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);
const [text, setText] = useState(false);

const [modalPermisos, setModalPermisos] = useState(false);
const togglePermisos = () => setModalPermisos(!modalPermisos);

  useEffect(() => {
    axios
      .get(URL_LISTA_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsuarios = response.data;
        let arrayTabla = allUsuarios
        .sort((a, b) => (a.apellido > b.apellido ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            nombre: a.nombre,
            apellido: a.apellido,
            email: a.email,
            is_active: a.is_active,
            // area: a.areas[0].name,
            // idArea: a.areas[0]._id,
            // plantel: a.planteles[0].name,
            // idPlantel: a.planteles[0]._id,
            menu_edoCta_Fam: a.menu_edoCta_Fam,
            menu_edoCta_Don: a.menu_edoCta_Don,
            menu_escuela: a.menu_escuela,
            menu_planteles: a.menu_planteles,
            menu_areas: a.menu_areas,
            menu_grados: a.menu_grados,
            menu_clases: a.menu_clases,
            menu_materias: a.menu_materias,
            menu_ciclos: a.menu_ciclos,
            menu_periodos: a.menu_periodos,
            menu_alumnos: a.menu_alumnos,
            alumnos_create: a.alumnos_create,
            alumnos_listado: a.alumnos_listado,
            becas_create: a.becas_create,
            menu_familias: a.menu_familias,
            familias_create: a.familias_create,
            familias_edo_cta: a.familias_edo_cta,
            menu_academico: a.menu_academico,
            menu_boletas: a.menu_boletas,
            menu_info_alumno: a.menu_info_alumno,
            info_alumno_create: a.info_alumno_create,
            menu_calificaciones: a.menu_calificaciones,
            menu_maestros: a.menu_maestros,
            menu_listados: a.menu_listados,
            menu_admin: a.menu_admin,
            menu_periodos_admin: a.menu_periodos_admin,
            menu_departamentos: a.menu_departamentos,
            menu_presupuestos: a.menu_presupuestos,
            presupuestos_autorizar: a.presupuestos_autorizar,
            presupuestos_create: a.presupuestos_create,
            menu_proveedores: a.menu_proveedores,
            proveedores_create: a.proveedores_create,
            menu_gastos: a.menu_gastos,
            gastos_create: a.gastos_create,
            gastos_autorizar: a.gastos_autorizar,
            menu_cobranza: a.menu_cobranza,
            menu_colegiaturas: a.menu_colegiaturas,
            colegiaturas_create: a.colegiaturas_create,
            colegiaturas_cargos_esp: a.colegiaturas_cargos_esp,
            colegiaturas_abonos: a.colegiaturas_abonos,
            colegiaturas_edo_cta: a.colegiaturas_edo_cta,
            menu_inscripciones: a.menu_inscripciones,
            inscripciones_create: a.inscripciones_create,
            inscripciones_cargos_esp: a.inscripciones_cargos_esp,
            inscripciones_abonos: a.inscripciones_abonos,
            inscripciones_edo_cta: a.inscripciones_edo_cta,
            menu_becas: a.menu_becas,
            menu_donativos: a.menu_donativos,
            menu_donadores: a.menu_donadores,
            donadores_create: a.donadores_create,
            donadores_edo_cta: a.donadores_edo_cta,
            donativos_edo_cta: a.donativos_edo_cta,
            donativos_recurrentes: a.donativos_recurrentes,
            donativos_recurrentes_create: a.donativos_recurrentes_create,
            donativos_recurrentes_generar: a.donativos_recurrentes_generar,
            donativos_cargos: a.donativos_cargos,
            donativos_cargos_create: a.donativos_cargos_create,
            donativos_abonos: a.donativos_abonos,
            donativos_abonos_create: a.donativos_abonos_create,
            menu_catalogos: a.menu_catalogos,
            menu_nominas: a.menu_nominas,
            menu_empleados: a.menu_empleados,
            empleados_create: a.empleados_create,
            empleados_sueldo: a.empleados_sueldo,
            menu_puestos: a.menu_puestos,
            puestos_create: a.puestos_create,
            nominas_create: a.nominas_create,
            nominas_listado: a.nominas_listado,
            nominas_pagos_esp: a.nominas_pagos_esp,
            nominas_pagar: a.nominas_pagar,
            menu_reportes: a.menu_reportes,
            menu_ingresos: a.menu_ingresos,
            menu_egresos: a.menu_egresos,
            menu_usuarios: a.menu_usuarios,
            usuarios_create: a.usuarios_create,
            usuarios_permisos: a.usuarios_permisos,
            donativos_abonos_edit: a.donativos_abonos_edit,
            colegiaturas_abonos_edit: a.colegiaturas_abonos_edit,
            inscripciones_abonos_edit: a.inscripciones_abonos_edit,
            menu_avisos: a.menu_avisos,
            avisos_create: a.avisos_create,
            menu_logs: a.menu_logs,
          }
        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
        setUsuarios(allUsuarios);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Apellido", field: "apellido", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
                comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
                comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
                comment.email.toLowerCase().includes(search.toLowerCase())
        );
    }


    if(activo){
      computedComments = computedComments.filter((e)=>
      e.is_active.includes(activo)
      ) 
    } 

    if(emailSis){
      computedComments = computedComments.filter((e)=>
      !e.email.includes(emailSis)
      ) 
    }     

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, activo, email]);

function Edit(idEdit, nombre, apellido, email){
  axios
  .get(`${URL_USERS}/${idEdit}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  }).then((response)=>{
    let usuarioEdit = response.data;
    setPlantel(usuarioEdit.planteles[0]._id)
    setArea(usuarioEdit.areas[0]._id)
  })
  setIdEdit(idEdit)
  setNombre(nombre)
  setApellido(apellido)
  setEmail(email)
  toggle()
}

function editUsuario(event) {
  event.preventDefault();
  axios
    .patch(
      `${URL_USERS}/${idEdit}`,
      {
        nombre,
        apellido,
        email,
        planteles: plantel,
        areas: area,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      toggle();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function Permisos (
  id, 
  nombre,
  apellido,
  menu_escuela,
  menu_planteles,
  menu_areas,
  menu_grados,
  menu_clases,
  menu_materias,
  menu_ciclos,
  menu_periodos,
  menu_alumnos,
  alumnos_create,
  alumnos_listado,
  becas_create,
  menu_familias,
  familias_create,
  familias_edo_cta,
  menu_academico,
  menu_boletas,
  menu_info_alumno,
  info_alumno_create,
  menu_calificaciones,
  menu_maestros,
  menu_listados,
  menu_admin,
  menu_periodos_admin,
  menu_departamentos,
  menu_presupuestos,
  presupuestos_autorizar,
  presupuestos_create,
  menu_proveedores,
  proveedores_create,
  menu_gastos,
  gastos_create,
  gastos_autorizar,
  menu_cobranza,
  menu_colegiaturas,
  colegiaturas_create,
  colegiaturas_cargos_esp,
  colegiaturas_abonos,
  colegiaturas_edo_cta,
  menu_inscripciones,
  inscripciones_create,
  inscripciones_cargos_esp,
  inscripciones_abonos,
  inscripciones_edo_cta,
  menu_becas,
  menu_donativos,
  menu_donadores,
  donadores_create,
  donadores_edo_cta,
  donativos_edo_cta,
  donativos_recurrentes,
  donativos_recurrentes_create,
  donativos_recurrentes_generar,
  donativos_cargos,
  donativos_cargos_create,
  donativos_abonos,
  donativos_abonos_create,
  menu_catalogos,
  menu_nominas,
  menu_empleados,
  empleados_create,
  empleados_sueldo,
  menu_puestos,
  puestos_create,
  nominas_create,
  nominas_listado,
  nominas_pagos_esp,
  nominas_pagar,
  menu_reportes,
  menu_ingresos,
  menu_egresos,
  menu_usuarios,
  usuarios_create,
  usuarios_permisos,
  donativos_abonos_edit,
  colegiaturas_abonos_edit,
  inscripciones_abonos_edit,
  menu_avisos,
  avisos_create,
  menu_logs,
  ){
        setIdPermisos(id)
        setNombre(nombre)
        setApellido(apellido)
        setMenuEscuela(menu_escuela)
        setMenuAlumnos(menu_alumnos)
        setMenuAcademico(menu_academico)
        setMenuAdmin(menu_admin)
        setMenuCobranza(menu_cobranza)
        setMenuDonativos(menu_donativos)
        setMenuNominas(menu_nominas)
        setMenuReportes(menu_reportes)
        setMenuUsuarios(menu_usuarios)
        setMenuPlanteles(menu_planteles)
        setMenuAreas(menu_areas)
        setMenuGrados(menu_grados)
        setMenuClases(menu_clases)
        setMenuMaterias(menu_materias)
        setMenuCiclos(menu_ciclos)
        setMenuPeriodos(menu_periodos)
        setMenuFamilias(menu_familias)
        setMenuBoletas(menu_boletas)
        setMenuInfoAlumno(menu_info_alumno)
        setInfoAlumnoCreate(info_alumno_create)
        setMenuCalificaciones(menu_calificaciones)
        setMenuMaestros(menu_maestros)
        setMenuListados(menu_listados)
        setMenuPeriodosAdmin(menu_periodos_admin)
        setMenuDepartamentos(menu_departamentos)
        setMenuPresupuestos(menu_presupuestos)
        setPresupuestosAutorizar(presupuestos_autorizar)
        setPresupuestosCreate(presupuestos_create)
        setMenuProveedores(menu_proveedores)
        setMenuGastos(menu_gastos)
        setMenuColegiaturas(menu_colegiaturas)
        setMenuInscripciones(menu_inscripciones)
        setMenuBecas(menu_becas)
        setMenuDonadores(menu_donadores)
        setMenuCatalogos(menu_catalogos)
        setMenuEmpleados(menu_empleados)
        setMenuPuestos(menu_puestos)
        setMenuIngresos(menu_ingresos)
        setMenuEgresos(menu_egresos)
        setNominasCreate(nominas_create)
        setAlumnosCreate(alumnos_create)
        setBecasCreate(becas_create)
        setFamiliasCreate(familias_create)
        setFamiliasEdoCta(familias_edo_cta)
        setProveedoresCreate(proveedores_create)
        setGastosCreate(gastos_create)
        setGastosAutorizar(gastos_autorizar)
        setColegiaturasCreate(colegiaturas_create)
        setColegiaturasCargosEsp(colegiaturas_cargos_esp)
        setColegiaturasAbonos(colegiaturas_abonos)
        setColegiaturasEdoCta(colegiaturas_edo_cta)
        setInscripcionesCreate(inscripciones_create)
        setInscripcionesCargosEsp(inscripciones_cargos_esp)
        setInscripcionesAbonos(inscripciones_abonos)
        setInscripcionesEdoCta(inscripciones_edo_cta)
        setDonadoresCreate(donadores_create)
        setDonadoresEdoCta(donadores_edo_cta)
        setDonativosEdoCta(donativos_edo_cta)
        setDonativosRecurrentes(donativos_recurrentes)
        setDonativosRecurrentesCreate(donativos_recurrentes_create)
        setDonativosRecurrentesGenerar(donativos_recurrentes_generar)
        setDonativosCargos(donativos_cargos)
        setDonativosCargosCreate(donativos_cargos_create)
        setDonativosAbonos(donativos_abonos)
        setDonativosAbonosCreate(donativos_abonos_create)
        setEmpleadosCreate(empleados_create)
        setEmpleadosSueldo(empleados_sueldo)
        setPuestosCreate(puestos_create)
        setNominasListado(nominas_listado)
        setNominasPagosEsp(nominas_pagos_esp)
        setNominasPagar(nominas_pagar)
        setUsuariosCreate(usuarios_create)
        setUsuariosPermisos(usuarios_permisos)
        setAlumnosListado(alumnos_listado)
        setDonativosAbonosEdit(donativos_abonos_edit)
        setColegiaturasAbonosEdit(colegiaturas_abonos_edit)
        setInscripcionesAbonosEdit(inscripciones_abonos_edit)
        setMenuAvisos(menu_avisos)
        setAvisosCreate(avisos_create)
        setMenuLogs(menu_logs)
        togglePermisos()
}

function permisosUsuario(event) {
  event.preventDefault();
  axios
    .patch(
      `${URL_USERS}/${idPermisos}`,
      {
        menu_escuela,
        menu_planteles,
        menu_areas,
        menu_grados,
        menu_clases,
        menu_materias,
        menu_ciclos,
        menu_periodos,
        menu_alumnos,
        alumnos_create,
        alumnos_listado,
        becas_create,
        menu_familias,
        familias_create,
        familias_edo_cta,
        menu_academico,
        menu_boletas,
        menu_info_alumno,
        info_alumno_create,
        menu_calificaciones,
        menu_maestros,
        menu_listados,
        menu_admin,
        menu_periodos_admin,
        menu_departamentos,
        menu_presupuestos,
        presupuestos_autorizar,
        presupuestos_create,
        menu_proveedores,
        proveedores_create,
        menu_gastos,
        gastos_create,
        gastos_autorizar,
        menu_cobranza,
        menu_colegiaturas,
        colegiaturas_create,
        colegiaturas_cargos_esp,
        colegiaturas_abonos,
        colegiaturas_edo_cta,
        menu_inscripciones,
        inscripciones_create,
        inscripciones_cargos_esp,
        inscripciones_abonos,
        inscripciones_edo_cta,
        menu_becas,
        menu_donativos,
        menu_donadores,
        donadores_create,
        donadores_edo_cta,
        donativos_edo_cta,
        donativos_recurrentes,
        donativos_recurrentes_create,
        donativos_recurrentes_generar,
        donativos_cargos,
        donativos_cargos_create,
        donativos_abonos,
        donativos_abonos_create,
        menu_catalogos,
        menu_nominas,
        menu_empleados,
        empleados_create,
        empleados_sueldo,
        menu_puestos,
        puestos_create,
        nominas_create,
        nominas_listado,
        nominas_pagos_esp,
        nominas_pagar,
        menu_reportes,
        menu_ingresos,
        menu_egresos,
        menu_usuarios,
        usuarios_create,
        usuarios_permisos,
        donativos_abonos_edit,
        colegiaturas_abonos_edit,
        inscripciones_abonos_edit,
        menu_avisos,
        avisos_create,
        menu_logs
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      togglePermisos();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

  return (
    <>
    <Header />
    <br />
    {user.menu_usuarios  ?(
        <div className="row">
        {
          <div className="col-8">
            <div className="card">
              <div className="card-body">
                <h3 align="center">Usuarios</h3>
                <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                    <tbody>
                    {commentsData
                    .map((p) => {
                        // if (p.menu_edoCta_Fam == false && p.menu_edoCta_Don == false && p.email != "admin@sistemify.com") {
                      return (
                        <tr>
                          <td>{p.is_active}</td>
                          <td>{p.nombre}</td>
                          <td>{p.apellido}</td>
                          <td>{p.email}</td>
                          <td>
                          {user.usuarios_create?(
                          <Button color="info" id="Editar" size="sm" onClick={e=>{Edit(p._id, p.nombre, p.apellido, p.email)}}>
                            <i class="far fa-edit"></i>
                          </Button>
                        ):(
                          <Button color="info" id="Editar" size="sm" disabled>
                            <i class="far fa-edit"></i>
                          </Button>
                        )}
                            {user.usuarios_permisos  && user.is_active =="Si" ?(
                            <Button color="success" id="Permisos" size="sm" onClick={e=>{Permisos(
                              p._id, 
                              p.nombre,
                              p.apellido,
                              p.menu_escuela,
                              p.menu_planteles,
                              p.menu_areas,
                              p.menu_grados,
                              p.menu_clases,
                              p.menu_materias,
                              p.menu_ciclos,
                              p.menu_periodos,
                              p.menu_alumnos,
                              p.alumnos_create,
                              p.alumnos_listado,
                              p.becas_create,
                              p.menu_familias,
                              p.familias_create,
                              p.familias_edo_cta,
                              p.menu_academico,
                              p.menu_boletas,
                              p.menu_info_alumno,
                              p.info_alumno_create,
                              p.menu_calificaciones,
                              p.menu_maestros,
                              p.menu_listados,
                              p.menu_admin,
                              p.menu_periodos_admin,
                              p.menu_departamentos,
                              p.menu_presupuestos,
                              p.presupuestos_autorizar,
                              p.presupuestos_create,
                              p.menu_proveedores,
                              p.proveedores_create,
                              p.menu_gastos,
                              p.gastos_create,
                              p.gastos_autorizar,
                              p.menu_cobranza,
                              p.menu_colegiaturas,
                              p.colegiaturas_create,
                              p.colegiaturas_cargos_esp,
                              p.colegiaturas_abonos,
                              p.colegiaturas_edo_cta,
                              p.menu_inscripciones,
                              p.inscripciones_create,
                              p.inscripciones_cargos_esp,
                              p.inscripciones_abonos,
                              p.inscripciones_edo_cta,
                              p.menu_becas,
                              p.menu_donativos,
                              p.menu_donadores,
                              p.donadores_create,
                              p.donadores_edo_cta,
                              p.donativos_edo_cta,
                              p.donativos_recurrentes,
                              p.donativos_recurrentes_create,
                              p.donativos_recurrentes_generar,
                              p.donativos_cargos,
                              p.donativos_cargos_create,
                              p.donativos_abonos,
                              p.donativos_abonos_create,
                              p.menu_catalogos,
                              p.menu_nominas,
                              p.menu_empleados,
                              p.empleados_create,
                              p.empleados_sueldo,
                              p.menu_puestos,
                              p.puestos_create,
                              p.nominas_create,
                              p.nominas_listado,
                              p.nominas_pagos_esp,
                              p.nominas_pagar,
                              p.menu_reportes,
                              p.menu_ingresos,
                              p.menu_egresos,
                              p.menu_usuarios,
                              p.usuarios_create,
                              p.usuarios_permisos,
                              p.donativos_abonos_edit,
                              p.colegiaturas_abonos_edit,
                              p.inscripciones_abonos_edit,
                              p.menu_avisos,
                              p.avisos_create,
                              p.menu_logs,
                              )}}>
                            <i class="fas fa-key"></i>{" "}
                          </Button>
                            ):(
                              <Button color="success" id="Permisos" size="sm" disabled>
                            <i class="fas fa-key"></i>{" "}
                          </Button>
                            )}
                            {user.usuarios_create?(
                          <Baja
                            idStatus={p._id}
                            is_active={p.is_active}
                            URL_BAJA={process.env.REACT_APP_URL_USERS}
                          />
                            ):undefined}
                          </td>
                        </tr>
                      );
                      // }
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        }
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <Signup />
            </div>
          </div>
        </div>
        </div>
      ): undefined } 

<Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><h4>Editar Usuario {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nombre}
            required
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Apellido</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={apellido}
            required
            onChange={(e) => {
              setApellido(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={email}
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Label>Plantel</Label>
              <Input
                type="select"
                value={plantel}
                required
                onChange={(e) => {
                  setPlantel(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {planteles.map((a) => {
                  return <option value={a._id}>{a.name}</option>;
                })}
              </Input>
              <Label className="mr-sm-2">Areas</Label>
                <Input
                  type="select"
                  value={area}
                  required
                  onChange={(e) => setArea(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editUsuario}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      {/* //Permisos */}
      <Modal size="xl" isOpen={modalPermisos} toggle={togglePermisos}>
        <ModalHeader toggle={togglePermisos}><h4>Permisos de {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
        <div className="container">
          <Row>
    <Col md={3}>
    <h5>ESCUELAS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuEscuela(event.currentTarget.checked)}
        checked={menu_escuela}
      /><h5>Menu Escuelas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuPlanteles(event.currentTarget.checked)}
        checked={menu_planteles}
      /><h5>Menu Planteles</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuAreas(event.currentTarget.checked)}
        checked={menu_areas}
      /><h5>Menu Areas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuGrados(event.currentTarget.checked)}
        checked={menu_grados}
      /><h5>Menu Grados</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuClases(event.currentTarget.checked)}
        checked={menu_clases}
      /><h5>Menu Clases</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuMaterias(event.currentTarget.checked)}
        checked={menu_materias}
      /><h5>Menu Materias</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuCiclos(event.currentTarget.checked)}
        checked={menu_ciclos}
      /><h5>Menu Ciclos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuPeriodos(event.currentTarget.checked)}
        checked={menu_periodos}
      /><h5>Menu Periodos</h5>
      </Col>

      <Col md={3}>
    <h5>ALUMNOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuAlumnos(event.currentTarget.checked)}
        checked={menu_alumnos}
      /><h5>Menu Alumnos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setAlumnosListado(event.currentTarget.checked)}
        checked={alumnos_listado}
        className="subMenu"
      /><h5 className="subMenuTitulo">Listado Alumnos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setAlumnosCreate(event.currentTarget.checked)}
        checked={alumnos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Alumnos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setBecasCreate(event.currentTarget.checked)}
        checked={becas_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Becas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuFamilias(event.currentTarget.checked)}
        checked={menu_familias}
      /><h5>Menu Familias</h5>
      <Input
        type="checkbox"
        onChange={(event) => setFamiliasCreate(event.currentTarget.checked)}
        checked={familias_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Familias</h5>
      <Input
        type="checkbox"
        onChange={(event) => setFamiliasEdoCta(event.currentTarget.checked)}
        checked={familias_edo_cta}
        className="subMenu"
      /><h5 className="subMenuTitulo">Edo Cta Familias</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuAvisos(event.currentTarget.checked)}
        checked={menu_avisos}
      /><h5>Menu Avisos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setAvisosCreate(event.currentTarget.checked)}
        checked={avisos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Avisos</h5>
      </Col>

      <Col md={3}>
    <h5>ACADEMICO</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuAcademico(event.currentTarget.checked)}
        checked={menu_academico}
      /><h5>Menu Academico</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuBoletas(event.currentTarget.checked)}
        checked={menu_boletas}
      /><h5>Menu Boletas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuInfoAlumno(event.currentTarget.checked)}
        checked={menu_info_alumno}
      /><h5>Menu Info Alumno</h5>
      <Input
        type="checkbox"
        onChange={(event) => setInfoAlumnoCreate(event.currentTarget.checked)}
        checked={info_alumno_create}
        className="subMenu"
        /><h5 className="subMenuTitulo">Crear Info Alumno</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuCalificaciones(event.currentTarget.checked)}
        checked={menu_calificaciones}
      /><h5>Menu Calificaciones</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuMaestros(event.currentTarget.checked)}
        checked={menu_maestros}
      /><h5>Menu Maestros</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuListados(event.currentTarget.checked)}
        checked={menu_listados}
      /><h5>Menu Listados</h5>
      </Col>

      <Col md={3}>
    <h5>ADMIN</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuAdmin(event.currentTarget.checked)}
        checked={menu_admin}
      /><h5>Menu Admin</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuPeriodosAdmin(event.currentTarget.checked)}
        checked={menu_periodos_admin}
      /><h5>Menu Periodos Admin</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuDepartamentos(event.currentTarget.checked)}
        checked={menu_departamentos}
      /><h5>Menu Departamentos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuPresupuestos(event.currentTarget.checked)}
        checked={menu_presupuestos}
      /><h5>Menu Presupuestos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setPresupuestosCreate(event.currentTarget.checked)}
        checked={presupuestos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Presupuestos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setPresupuestosAutorizar(event.currentTarget.checked)}
        checked={presupuestos_autorizar}
        className="subMenu"
      /><h5 className="subMenuTitulo">Autorizar Presupuestos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuProveedores(event.currentTarget.checked)}
        checked={menu_proveedores}
      /><h5>Menu Proveedores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setProveedoresCreate(event.currentTarget.checked)}
        checked={proveedores_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Proveedores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuGastos(event.currentTarget.checked)}
        checked={menu_gastos}
      /><h5>Menu Gastos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setGastosCreate(event.currentTarget.checked)}
        checked={gastos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Gastos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setGastosAutorizar(event.currentTarget.checked)}
        checked={gastos_autorizar}
        className="subMenu"
      /><h5 className="subMenuTitulo">Autorizar Gastos</h5>
      </Col>
      </Row>
<br />
{/* Row 2 */}
<Row>
    <Col md={3}>
      <h5>COBRANZA</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuCobranza(event.currentTarget.checked)}
        checked={menu_cobranza}
      /><h5>Menu Cobranza</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuColegiaturas(event.currentTarget.checked)}
        checked={menu_colegiaturas}
      /><h5>Menu Colegiaturas</h5>
       <Input
        type="checkbox"
        onChange={(event) => setColegiaturasCreate(event.currentTarget.checked)}
        checked={colegiaturas_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Colegiaturas</h5>
       <Input
        type="checkbox"
        onChange={(event) => setColegiaturasCargosEsp(event.currentTarget.checked)}
        checked={colegiaturas_cargos_esp}
        className="subMenu"
      /><h5 className="subMenuTitulo">Cargos Especiales</h5>
       <Input
        type="checkbox"
        onChange={(event) => setColegiaturasAbonos(event.currentTarget.checked)}
        checked={colegiaturas_abonos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Abonos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setColegiaturasAbonosEdit(event.currentTarget.checked)}
        checked={colegiaturas_abonos_edit}
        className="subMenu"
      /><h5 className="subMenuTitulo">Editar Abonos</h5>
       <Input
        type="checkbox"
        onChange={(event) => setColegiaturasEdoCta(event.currentTarget.checked)}
        checked={colegiaturas_edo_cta}
        className="subMenu"
      /><h5 className="subMenuTitulo">Edo Cta</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuInscripciones(event.currentTarget.checked)}
        checked={menu_inscripciones}
      /><h5>Menu Inscripciones</h5>
      <Input
        type="checkbox"
        onChange={(event) => setInscripcionesCreate(event.currentTarget.checked)}
        checked={inscripciones_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Inscripciones</h5>
       <Input
        type="checkbox"
        onChange={(event) => setInscripcionesCargosEsp(event.currentTarget.checked)}
        checked={inscripciones_cargos_esp}
        className="subMenu"
      /><h5 className="subMenuTitulo">Cargos Especiales</h5>
       <Input
        type="checkbox"
        onChange={(event) => setInscripcionesAbonos(event.currentTarget.checked)}
        checked={inscripciones_abonos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Abonos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setInscripcionesAbonosEdit(event.currentTarget.checked)}
        checked={inscripciones_abonos_edit}
        className="subMenu"
      /><h5 className="subMenuTitulo">Editar Abonos</h5>
       <Input
        type="checkbox"
        onChange={(event) => setInscripcionesEdoCta(event.currentTarget.checked)}
        checked={inscripciones_edo_cta}
        className="subMenu"
      /><h5 className="subMenuTitulo">Edo Cta</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuBecas(event.currentTarget.checked)}
        checked={menu_becas}
      /><h5>Menu Becas</h5>
      </Col>

      <Col md={3}>
    <h5>DONATIVOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuDonativos(event.currentTarget.checked)}
        checked={menu_donativos}
      /><h5>Menu Donativos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuDonadores(event.currentTarget.checked)}
        checked={menu_donadores}
      /><h5>Menu Donadores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonadoresCreate(event.currentTarget.checked)}
        checked={donadores_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Donadores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosEdoCta(event.currentTarget.checked)}
        checked={donativos_edo_cta}
      /><h5>Donativos Edo Cta</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonadoresEdoCta(event.currentTarget.checked)}
        checked={donadores_edo_cta}
        className="subMenu"
      /><h5 className="subMenuTitulo">Detalle Donativos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosRecurrentes(event.currentTarget.checked)}
        checked={donativos_recurrentes}
      /><h5>Recurrentes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosRecurrentesCreate(event.currentTarget.checked)}
        checked={donativos_recurrentes_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Recurrentes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosRecurrentesGenerar(event.currentTarget.checked)}
        checked={donativos_recurrentes_generar}
        className="subMenu"
      /><h5 className="subMenuTitulo">Generar Recurrentes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosCargos(event.currentTarget.checked)}
        checked={donativos_cargos}
      /><h5>Cargos Donativos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosCargosCreate(event.currentTarget.checked)}
        checked={donativos_cargos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Cargos</h5>
       <Input
        type="checkbox"
        onChange={(event) => setDonativosAbonos(event.currentTarget.checked)}
        checked={donativos_abonos}
      /><h5>Abonos Donativos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosAbonosCreate(event.currentTarget.checked)}
        checked={donativos_abonos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Abonos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDonativosAbonosEdit(event.currentTarget.checked)}
        checked={donativos_abonos_edit}
        className="subMenu"
      /><h5 className="subMenuTitulo">Editar Abonos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuCatalogos(event.currentTarget.checked)}
        checked={menu_catalogos}
      /><h5>Menu Catalogos</h5>
      </Col>

      <Col md={3}>
    <h5>NOMINAS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuNominas(event.currentTarget.checked)}
        checked={menu_nominas}
      /><h5>Menu Nominas</h5>
       <Input
        type="checkbox"
        onChange={(event) => setNominasListado(event.currentTarget.checked)}
        checked={nominas_listado}
        className="subMenu"
      /><h5 className="subMenuTitulo">Listado Nominas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setNominasCreate(event.currentTarget.checked)}
        checked={nominas_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Nominas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setNominasPagosEsp(event.currentTarget.checked)}
        checked={nominas_pagos_esp}
        className="subMenu"
      /><h5 className="subMenuTitulo">Pagos Especiales</h5>
      <Input
        type="checkbox"
        onChange={(event) => setNominasPagar(event.currentTarget.checked)}
        checked={nominas_pagar}
        className="subMenu"
      /><h5 className="subMenuTitulo">Pagar Nominas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuEmpleados(event.currentTarget.checked)}
        checked={menu_empleados}
      /><h5>Menu Empleados</h5>
      <Input
        type="checkbox"
        onChange={(event) => setEmpleadosCreate(event.currentTarget.checked)}
        checked={empleados_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Empleados</h5>
      <Input
        type="checkbox"
        onChange={(event) => setEmpleadosSueldo(event.currentTarget.checked)}
        checked={empleados_sueldo}
        className="subMenu"
      /><h5 className="subMenuTitulo">Sueldo Empleados</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuPuestos(event.currentTarget.checked)}
        checked={menu_puestos}
      /><h5>Menu Puestos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setPuestosCreate(event.currentTarget.checked)}
        checked={puestos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Puestos</h5>
      </Col>

      <Col md={3}>
    <h5>REPORTES</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuReportes(event.currentTarget.checked)}
        checked={menu_reportes}
      /><h5>Menu Reportes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuIngresos(event.currentTarget.checked)}
        checked={menu_ingresos}
      /><h5>Reporte Ingresos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuEgresos(event.currentTarget.checked)}
        checked={menu_egresos}
      /><h5>Reporte Egresos</h5>
      <br />
      <br />
      <h5>USUARIOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuUsuarios(event.currentTarget.checked)}
        checked={menu_usuarios}
      /><h5>Menu Usuarios</h5>
      <Input
        type="checkbox"
        onChange={(event) => setUsuariosCreate(event.currentTarget.checked)}
        checked={usuarios_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Usuarios</h5>
      <Input
        type="checkbox"
        onChange={(event) => setUsuariosPermisos(event.currentTarget.checked)}
        checked={usuarios_permisos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Permisos Usuarios</h5>
       <br />
      <br />
      <h5>LOGS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuLogs(event.currentTarget.checked)}
        checked={menu_logs}
      /><h5>Menu Logs</h5>
      </Col>
      </Row>
      </div>
        </ModalBody>
        <ModalFooter>
          <div className="container">
          <Button color="success" 
          onClick={permisosUsuario}
          >
            Guardar
          </Button>
          </div>
        </ModalFooter>
      </Modal>

{loader}
    </>
  );
}

export default ListadoUsuarios;
