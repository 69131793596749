import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function GradosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [name, setName] = useState("");
  const [colegiatura, setColegiatura] = useState("");
  const [inscripcion, setInscripcion] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setName("");
    setAreas([]);
    setSelectedArea("");
    setColegiatura("");
    setInscripcion("");
    setValidaBoton(true);
  };

  useEffect(() => {
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveGrados = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_GRADOS,
        {
          name,
          areas: selectedArea,
          colegiatura,
          inscripcion,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Grado",
            detalle: `${name} / ${selectedArea}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea un Grado nuevo</h3>
        <Form onSubmit={saveGrados}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Grados
            </Label>
            <Input
              type="text"
              placeholder="Nombre del Grado"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            {user.areas == AREAS_GENERAL ? (
              <div>
                <Label className="mr-sm-2">Areas</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => setSelectedArea(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (AREAS_GENERAL != a._id) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </div>
            ) : (
              <div>
                <Label className="mr-sm-2">Areas</Label>
                <Input
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => setSelectedArea(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (user.areas == a._id) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </div>
            )}
            <Label for="exampleEmail" className="mr-sm-2">
              Colegiatura
            </Label>
            <Input
              type="number"
              placeholder="Importe de Colegiatura"
              value={colegiatura}
              required
              onChange={(e) => {
                setColegiatura(e.target.value);
              }}
            />
            <Label for="exampleEmail" className="mr-sm-2">
              Inscripcion
            </Label>
            <Input
              type="number"
              placeholder="Importe de Inscripcion"
              value={inscripcion}
              required
              onChange={(e) => {
                setInscripcion(e.target.value);
              }}
            />
          </FormGroup>
          <br />
          <Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button
              href="/MenuEscuelas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default GradosCreate;
