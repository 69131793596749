import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from '../../Baja'

function TablaClases(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FALTAS_COLABORADORES_EDIT = `${process.env.REACT_APP_URL_FALTAS_COLABORADORES}/${props._id}`;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [areas, setAreas] = useState([]);
  const [planteles, setPlanteles] = useState([]);
  const [faltasColaboradores, setFaltasColaboradores] = useState("")
  const [fecha, setFecha] = useState(props.fecha);
  const [justificada, setJustificada] = useState(props.justificada);
  const [observaciones, setObservaciones] = useState(props.observaciones);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState(props.idColaborador);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  
  
  useEffect(() => {
    axios
    .get(URL_AREAS, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allAreas = res.data;
      setAreas(allAreas);
    })
    .catch((err) => {
      console.log(err);
    });
      axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_FALTAS_COLABORADORES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFaltasColaboradores = response.data;
        setFaltasColaboradores(allFaltasColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function editFalta(event) {
    event.preventDefault();
    axios
      .patch(
        URL_FALTAS_COLABORADORES_EDIT,
        {
          colaboradores: selectedColaborador,
          fecha,
          justificada,
          observaciones
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Falta Colaborador',
            detalle: `${selectedColaborador} / ${fecha}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  return (
    <>
      <td>
        {props.nombre} {props.apellido} 
      </td>
      <td>{props.plantel}</td>
      <td>{props.area}</td>
      <td>{props.fecha}</td>
      <td>{props.justificada}</td>
      <td>
          <Button color="dany" size="sm" onClick={toggle} id="Observaciones">
            <i class="fas fa-file-alt"></i>
          </Button>
        {user.donadores_create ?(
        <Button color="info" id="Editar" size="sm" onClick={toggleEdit}>
          <i class="far fa-edit"></i>
        </Button>
        ):(
          <Button color="info" id="Editar" size="sm" disabled>
          <i class="far fa-edit"></i>
        </Button>
        )}

        <Modal size="sm" isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}><h4>Observaciones</h4> </ModalHeader>
          <ModalBody>
            <h5>{props.observaciones}</h5>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={toggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
        <h4>Editar falta de {props.nombre} {props.apellido}</h4>
        </ModalHeader>
        <ModalBody>
        <Label className="mr-sm-2">Empleado</Label>
            <Input
              className="col-sm-12"
              type="select"
              value={selectedColaborador}
              required
              onChange={(e) => {
                setSelectedColaborador(e.target.value);
              }}
            >
              <option value="0">Selecciona un Empleado</option>
              {colaboradores
                .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                .map((a) => {
                  if (
                    user.planteles == PLANTELES_GENERAL &&
                    user.areas == AREAS_GENERAL
                  ) {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  } else if (
                    user.planteles == a.planteles[0]._id &&
                    user.areas == a.areas[0]._id
                  ) {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  }
                })}
            </Input>
            <Label for="exampleEmail" className="mr-sm-2">
              Fecha
            </Label>
            <Input
              type="date"
              value={fecha}
              required
              onChange={(e) => {
                setFecha(e.target.value);
              }}
            />
            <Label for="exampleEmail" className="mr-sm-2">
              Justificada
            </Label>
            <Input
              type="select"
              placeholder="Justificada"
              value={justificada}
              required
              onChange={(e) => {
                setJustificada(e.target.value);
              }}
            >
              <option value="0">Justificada</option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Input>
            <Label for="exampleEmail" className="mr-sm-2">
              Observaciones
            </Label>
            <Input
              type="textarea"
              placeholder="Observaciones"
              value={observaciones}
              required
              onChange={(e) => {
                setObservaciones(e.target.value);
              }}
            />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editFalta}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      </td>
    </>
  );
}

export default TablaClases;
