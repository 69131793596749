import React, { useContext } from "react";
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";
import { AuthContext } from '../../contexts/AuthContext'

function BotonesEsquemas() {
  const { user } = useContext(AuthContext);

  return (
      <>
      <Header />
        <br />
        <br />
        {user.menu_escuela  ? (
        <div className="container">
        <Row>
          <Col md={4} align="center">
          {user.menu_planteles ?(
            <Button href="/Planteles" className="botonesMenu" color="success">
              <i class="fas fa-building fa-7x"></i>
              <br />
              <br />
              Planteles
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-building fa-7x"></i>
            <br />
            <br />
            Planteles
          </Button> } 
          </Col>
          <Col md={4} align="center">
          {user.menu_areas ?(
            <Button href="/Areas" className="botonesMenu" color="success">
              <i class="fas fa-puzzle-piece fa-7x"></i>
              <br />
              <br />
              Areas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-puzzle-piece fa-7x"></i>
            <br />
            <br />
            Areas
          </Button> } 
          </Col>
          <Col md={4} align="center">
          {user.menu_grados ?(
            <Button href="/Grados" className="botonesMenu" color="success">
            <i class="fas fa-layer-group fa-7x"></i>
              <br />
              <br />
              Grados
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-layer-group fa-7x"></i>
              <br />
              <br />
              Grados
            </Button> } 
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_clases ?(
            <Button href="/Clases" className="botonesMenu" color="success">
              <i class="fas fa-users fa-7x"></i>
              <br />
              <br />
              Clases
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-users fa-7x"></i>
            <br />
            <br />
            Clases
          </Button> } 
          </Col>
          <Col md={4} align="center">
          {user.menu_materias ?(
            <Button href="/Materias" className="botonesMenu" color="success">
              <i class="fas fa-book fa-7x"></i>
              <br />
              <br />
              Materias
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-book fa-7x"></i>
            <br />
            <br />
            Materias
          </Button> } 
          </Col>
          <Col md={4} align="center">
          {user.menu_ciclos ?(
            <Button href="/Ciclos" className="botonesMenu" color="success">
              <i class="far fa-calendar-alt fa-7x"></i>
              <br />
              <br />
              Ciclos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="far fa-calendar-alt fa-7x"></i>
            <br />
            <br />
            Ciclos
          </Button> } 
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={12} align="center">
          {user.menu_periodos ?(
            <Button href="/Periodos" className="botonesMenu" color="success">
              <i class="far fa-calendar-check fa-7x"></i>
              <br />
              <br />
              Periodos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="far fa-calendar-check fa-7x"></i>
            <br />
            <br />
            Periodos
          </Button> } 
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  )
}

export default BotonesEsquemas;
