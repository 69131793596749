import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function TareasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TAREAS = process.env.REACT_APP_URL_TAREAS;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
 
  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [selectedDonador, setSelectedDonador] = useState("");
  const [accion, setAccion] = useState("");
  const [donadores, setDonadores] = useState([]);
  const [value, setValue] = useState("");

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);


  const clear = () => {
    setSelectedDonador("");
    setValue("");
    setFecha("");
    setObservaciones("");
    setAccion("");
    setValidaBoton(true)
  };
  

  useEffect(() => {
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDonadores = res.data;
        setDonadores(allDonadores);
      })
      .catch((err) => {
        console.log(err);
      });
    
  });



 
  const saveTarea = (event) => {
    event.preventDefault();
    setValidaBoton(false)
      axios
        .all([
          axios.post(
            URL_TAREAS,
            {
              fecha,
              donadores: value._id,
              observaciones,
              accion
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ),
        ])
        .then(() => {
          axios
          .post(
            URL_LOGS,
            {
              tipo:'Crear Tarea',
              detalle: `Fecha: ${fecha} / Donador: ${value._id}`,
              user: user.id
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
            clear();
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true)
        });

  };

  const options = donadores.map((option) => {
    const junta =
      option.apellido + " " + option.nombre + " / " + option.nombre_comercial;
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_donadores  ?(
      <div className="container">
        <div className="card container col-sm-8">
          <h3 align="center">Dar de alta una Tarea</h3>
          <Form onSubmit={saveTarea}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col sm={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                Fecha
              </Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
                </Col>
                <Col sm={6}>
                <Label className="mr-sm-2">Donador</Label>
                <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedDonador) => {
                      setValue(selectedDonador);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.apellido} {option.nombre} /{" "}
                        {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
              </Row>
              <Row>
              <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Accion
                    </Label>
                    <Input
                      type="select"
                      placeholder="Accion"
                      value={accion}
                      required
                      onChange={(e) => {
                        setAccion(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      <option value="Llamar">Llamar</option>
                      <option value="Programar">Programar</option>
                      <option value="Revisar">Revisar</option>
                      <option value="Mensaje">Mensaje</option>
                      <option value="Otro">Otro</option>
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="textarea"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
              </Row>
              
            </FormGroup>
            <br />
            <Col>
              <Row>
              {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
                <Button
                  href="/ListadoTareas"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Col>
          </Form>
        </div>
      </div>
      ): undefined } 
    </>
  );
}

export default TareasCreate;
