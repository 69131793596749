import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Input, Row, Col, Label } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function AgregarMateriaMaestro(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_MATERIAS = process.env.REACT_APP_URL_MATERIAS;
  const URL_COLABORADORES_MATERIAS = `${process.env.REACT_APP_URL_COLABORADORES}/${props.idColaborador}`;
  const [materias, setMaterias] = useState([]);
  const [selectedMateria, setSelectedMateria] = useState("");
  const [text, setText] = useState(false);

  useEffect(() => {
    axios
      .get(URL_MATERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMaterias = res.data;
        setMaterias(allMaterias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let mas = props.materias.map((m) => {
    return m._id;
  });

  function masMateria() {
    mas.push(selectedMateria);
    axios
      .patch(
        URL_COLABORADORES_MATERIAS,
        {
          materias: mas,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Agregar Materia a Maestro',
            detalle: `Materia: ${selectedMateria} / Maestro: ${props.idColaborador}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <Label className="mr-sm-2">Agregar Materia</Label>
      <Row>
        <Col className="col-sm-7">
          <Input
            id="boton"
            type="select"
            value={selectedMateria}
            required
            onChange={(e) => {
              setSelectedMateria(e.target.value);
            }}
          >
            <option value="0">Selecciona un Materia</option>
            {materias
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((a) => {
                return <option value={a._id}>{a.name}</option>;
              })}
          </Input>
        </Col>
        <Col className="col-sm-4">
          <Button
           size="sm"
            type="submit"
            className="btn btn-success"
            onClick={masMateria}
          >
            Agregar
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default AgregarMateriaMaestro;
