import React, { useState, useEffect, useContext } from "react";
import { Table } from "reactstrap";
import axios from 'axios'
import { AuthContext } from "../../contexts/AuthContext";

function AlumnosInfo(props) {
  const { user } = useContext(AuthContext);
    const URL_ALUMNOS_INFO = `${process.env.REACT_APP_URL_ALUMNOS}/${props._idAlumno}`;
    const [alumno, setAlumno] = useState([]);
    const [familia, setFamilia] = useState([]);
  
    useEffect(() => {
      axios
        .get(URL_ALUMNOS_INFO, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allFamilia = response.data.familias[0]
          setFamilia(allFamilia);
          let allAlumno = response.data
          setAlumno(allAlumno);
        })
        .catch((err) => {
          console.log(err);
        });
    },[]);

    return (
        <>
        <div className="card container">
        {user.menu_alumnos  ?(
           <Table size="sm" borderless>
             <tbody>
             <tr><td>Nombre</td><td>{alumno.name} {familia.apellido_paterno} {familia.apellido_materno}</td></tr>
             <tr><td>Nombre Mama</td><td>{familia.nombre_mama} {familia.apellido_materno}</td></tr>
             <tr><td>Telefono Mama</td><td>{familia.telefono_mama}</td></tr>
                <tr><td>Nombre Papa</td><td>{familia.nombre_papa} {familia.apellido_paterno} </td></tr>
                <tr><td>Telefono Papa</td><td>{familia.telefono_papa}</td></tr>
                <tr><td>Telefono Casa</td><td>{familia.telefono_casa}</td></tr>
                <tr><td>Tipo de Sangre</td><td>{alumno.tipo_sangre}</td></tr>
                <tr><td>Contacto Emergencia</td><td>{familia.contacto_emergencia}</td></tr>
                <tr><td>Telefono Emergencia</td><td>{familia.telefono_emergencia}</td></tr>
                <tr><td>Direccion</td><td>{familia.calle} {familia.numero_ext} {familia.numero_int}</td></tr> 
                <tr><td>Colonia</td><td>{familia.colonia}</td></tr>
                </tbody> 
                </Table>
            ): undefined } 
            </div>
        </>
    )
}

export default AlumnosInfo
