import React, { useState } from 'react'
import { Input } from 'reactstrap'
// import axios from "axios";


const TablaNominasCreate=(props)=> {
  const quincena = props.importe
  const index = props.index;
  const [sueldo, setSueldo] = useState(quincena);
  const [nombre, setNombre] = useState(props.nombre);
  const [puesto, setPuesto] = useState(props.puesto);
  const [fecha, setFecha] = useState(props.fecha);
  const [key, setKey] = useState(props.id);
  const sueldoModificado = props.sueldoModificado
  const notas = props.comentarios


  return (
    <>
      <td>{nombre}</td>
      <td>{props.plantel}</td>
      <td>{props.area}</td>
      <td>{puesto}</td>
      <td>{"$" + new Intl.NumberFormat("en-US").format(sueldo)}</td>
      <td><Input
        type="number" 
        key={key}
        required
        onBlur ={(e)=>{sueldoModificado(e.target.value, index, sueldo)
        }}
        /></td>
        <td><Input
        type="text" 
        key={key}
        onBlur ={(e)=>{notas(e.target.value, index)
        }}
        /></td>
    </>
  );
}


export default TablaNominasCreate
