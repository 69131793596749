import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";
//  {/* Escuelas */}
import BotonesEsquemas from "./views/Esquemas/BotonesEsquemas"
import ListadoPlanteles from './views/Esquemas/Planteles/ListadoPlanteles'
import ListadoAreas from './views/Esquemas/Areas/ListadoAreas'
import ListadoGrados from './views/Esquemas/Grados/ListadoGrados'
import ListadoClases from './views/Esquemas/Clases/ListadoClases'
import ListadoMaterias from './views/Esquemas/Materias/ListadoMaterias'
import ListadoCiclos from './views/Esquemas/Ciclos/ListadoCiclos'
import ListadoPeriodos from './views/Esquemas/Periodos/ListadoPeriodos'
import PeriodosCreate from './views/Esquemas/Periodos/PeriodosCreate'
// {/* Admin */}
import BotonesConfigAdmin from "./views/ConfigAdmin/BotonesConfigAdmin"
import ListadoPeriodosAdmin from './views/ConfigAdmin/PeriodosAdmin/ListadoPeriodosAdmin'
import ListadoAreasAdmin from './views/ConfigAdmin/AreasAdmin/ListadoAreasAdmin'
import ListadoDepartamentos from './views/ConfigAdmin/Departamentos/ListadoDepartamentos'
import ListadoPresupuestos from './views/ConfigAdmin/Presupuestos/ListadoPresupuestos'
import PeriodosAdminCreate from './views/ConfigAdmin/PeriodosAdmin/PeriodosAdminCreate'
import PresupuestosCreate from './views/ConfigAdmin/Presupuestos/PresupuestosCreate'
import ListadoProveedores from './views/ConfigAdmin/Proveedores/ListadoProveedores'
import ListadoGastos from './views/ConfigAdmin/Gastos/ListadoGastos'
import GastosCreate from './views/ConfigAdmin/Gastos/GastosCreate'
import ProveedoresCreate from './views/ConfigAdmin/Proveedores/ProveedoresCreate'
//Nominas
import BotonesNominas from './views/Empleados/BotonesNominas'
import ListadoNominas from "./views/Empleados/Nominas/ListadoNominas"
import ListadoColaboradores from "./views/Empleados/Colaboradores/ListadoColaboradores"
import ListadoFaltasColaboradores from './views/Empleados/Faltas/ListadoFaltasColaboradores'
import FaltasColaboradoresCreate from './views/Empleados/Faltas/FaltasColaboradoresCreate'
import ListadoPuestos from "./views/Empleados/Puestos/ListadoPuestos"
import NominasCreate from "./views/Empleados/Nominas/NominasCreate"
import ColaboradoresCreate from "./views/Empleados/Colaboradores/ColaboradoresCreate"
// Alumnos
import BotonesAlumnos from './views/Alumnos/BotonesAlumnos'
import ListadoAlumnos from './views/Alumnos/ListadoAlumnos'
import ListadoFamilias from './views/Alumnos/Familias/ListadoFamilias'
import AlumnosCreate from './views/Alumnos/AlumnosCreate'
import FamiliasCreate from './views/Alumnos/Familias/FamiliasCreate'
import AvisosCreate from './views/Alumnos/Avisos/AvisosCreate'
// Cobranza
import BotonesCobranza from "./views/CarteraFamilias/BotonesCobranza"
import ListadoInscripciones from "./views/CarteraFamilias/Inscripciones/ListadoInscripciones"
import ListadoColegiaturas from "./views/CarteraFamilias/Colegiaturas/ListadoColegiaturas"
import AbonosColegiaturasCreate from "./views/CarteraFamilias/Colegiaturas/AbonosColegiaturasCreate"
import AbonosInscripcionesCreate from "./views/CarteraFamilias/Inscripciones/AbonosInscripcionesCreate"
import ListadoBecas from "./views/CarteraFamilias/Becas/ListadoBecas"
import ListadoAbonosFamilias from './views/CarteraFamilias/ListadoAbonosFamilias'
import ListadoCargosFamilias from './views/CarteraFamilias/ListadoCargosFamilias'
import EstadoCuentaFamilias from "./views/CarteraFamilias/EstadoCuentaFamilias";
import ListadoConvenios from "./views/CarteraFamilias/Convenios/ListadoConvenios";
import ConveniosCreate from "./views/CarteraFamilias/Convenios/ConveniosCreate";
// Tzedaka
import BotonesTzedaka from './views/Tzedaka/BotonesTzedaka'
import BotonesCatalogosDonativos from './views/Tzedaka/Donativos/Catalogos/BotonesCatalogosDonativos'
import ListadoDonadores from "./views/Tzedaka/Donadores/ListadoDonadores"
import ListadoConceptosDon from "./views/Tzedaka/Donativos/Catalogos/Conceptos/ListadoConceptosDon"
import ListadoEventosDon from "./views/Tzedaka/Donativos/Catalogos/Eventos/ListadoEventosDon"
import ListadoSalasDon from "./views/Tzedaka/Donativos/Catalogos/Salas/ListadoSalasDon"
import ListadoInstitucionesDon from "./views/Tzedaka/Donativos/Catalogos/Instituciones/ListadoInstitucionesDon"
import DonadoresCreate from "./views/Tzedaka/Donadores/DonadoresCreate"
import DonativosCreate from "./views/Tzedaka/Donativos/DonativosCreate"
import ListadoDonativos from "./views/Tzedaka/Donativos/ListadoDonativos"
import ListadoDetalleDonativos from "./views/Tzedaka/Donativos/ListadoDetalleDonativos"
import ListadoGrupos from './views/Tzedaka/Donativos/Catalogos/Grupos/ListadoGrupos'
import AbonosDonativosCreate from "./views/Tzedaka/Donativos/AbonosDonativosCreate"
import ListadoDonativosRecurrentes from "./views/Tzedaka/DonativosRecurrentes/ListadoDonativosRecurrentes"
import DonativosRecurrentesCreate from "./views/Tzedaka/DonativosRecurrentes/DonativosRecurrentesCreate"
import ListadoAbonosDonativos from './views/Tzedaka/Donativos/ListadoAbonosDonativos'
import ListadoCobranza from "./views/Tzedaka/Donativos/ListadoCobranza"
import ListadoTareas from "./views/Tzedaka/Tareas/ListadoTareas"
import TareasCreate from "./views/Tzedaka/Tareas/TareasCreate"
// Academico
import BotonesAcademico from './views/Academico/BotonesAcademico'
import CapturarCalificaciones from './views/Academico/Calificaciones/CapturarCalificaciones'
import Listas from './views/Academico/Listados/Listas'
import ListadoMaestros from './views/Academico/Maestros/ListadoMaestros'
import InfoAlumnosCreate from './views/Academico/InfoAlumnos/InfoAlumnosCreate'
import ListadoInfoAlumnos from './views/Academico/InfoAlumnos/ListadoInfoAlumnos'
import AsistenciasCreate from './views/Academico/Asistencias/AsistenciasCreate'
import ListadoAsistencias from './views/Academico/Asistencias/ListadoAsistencias'
//Reportes
import BotonesReportes from './views/Reportes/BotonesReportes'
import VistaCartera from './views/Reportes/Cartera/VastaCartera'
import GraficasCarteraIngresos from './views/Reportes/GraficasIngresos/GraficasCarteraIngresos'
import GraficasCarteraEgresos from './views/Reportes/GraficasEgresos/GraficasCarteraEgresos'
import DashboardAdmin from './views/Reportes/Dashboard/DashboardAdmin'
import GraficasCarteraDonativos from './views/Reportes/GraficasDonativos/GraficasCarteraDonativos'
// Edo Cta Familias
import EdoCtaVistaFamilias from './views/Alumnos/Familias/LoginFam/EdoCtaVistaFamilias'
import InfoHijos from './views/Alumnos/Familias/LoginFam/InfoHijos'
// Edo Cta Donadores
import EdoCtaVistaDonadores from './views/Tzedaka/Donadores/LoginDonador/EdoCtaVistaDonador'
// Logs
import Logs from './views/Logs/ListadoLogs'
// Config
import Configuraciones from './views/Configuracion/Configuraciones'


function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/MenuUsuarios" component={ListadoUsuarios} />
                {/* Escuelas */}
                <Route exact path ="/MenuEscuelas" component={BotonesEsquemas} />
                <Route exact path ="/Planteles" component={ListadoPlanteles} />
                <Route exact path ="/Areas" component={ListadoAreas} />
                <Route exact path ="/Grados" component={ListadoGrados} />
                <Route exact path ="/Clases" component={ListadoClases} />
                <Route exact path ="/Materias" component={ListadoMaterias} />
                <Route exact path ="/Ciclos" component={ListadoCiclos} />
                <Route exact path ="/Periodos" component={ListadoPeriodos} />
                <Route exact path ="/PeriodosCreate" component={PeriodosCreate} />
                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesConfigAdmin} />
                <Route exact path ="/PeriodosAdmin" component={ListadoPeriodosAdmin} />
                <Route exact path ="/AreasAdmin" component={ListadoAreasAdmin} />
                <Route exact path ="/Departamentos" component={ListadoDepartamentos} />
                <Route exact path ="/Presupuestos" component={ListadoPresupuestos} />
                <Route exact path ="/PeriodosAdminCreate" component={PeriodosAdminCreate} />
                <Route exact path ="/PresupuestosCreate" component={PresupuestosCreate} />
                <Route exact path ="/MenuNominas" component={BotonesNominas} />
                <Route exact path ="/Nominas" component={ListadoNominas} />
                <Route exact path ="/Empleados" component={ListadoColaboradores} />
                <Route exact path ="/EmpleadosCreate" component={ColaboradoresCreate} />
                <Route exact path ="/FaltasColaboradores" component={ListadoFaltasColaboradores} />
                <Route exact path ="/FaltasColaboradoresCreate" component={FaltasColaboradoresCreate} />
                <Route exact path ="/NominasCreate" component={NominasCreate} />
                <Route exact path ="/Puestos" component={ListadoPuestos} />
                <Route exact path ="/Proveedores" component={ListadoProveedores} />
                <Route exact path ="/Gastos" component={ListadoGastos} />
                <Route exact path ="/GastosCreate" component={GastosCreate} />
                <Route exact path ="/ProveedoresCreate" component={ProveedoresCreate} />
                {/* Alumnos */}
                <Route exact path ="/MenuAlumnos" component={BotonesAlumnos} />
                <Route exact path ="/Alumnos" component={ListadoAlumnos} />
                <Route exact path ="/Familias" component={ListadoFamilias} />
                <Route exact path ="/AlumnosCreate" component={AlumnosCreate} />
                <Route exact path ="/FamiliasCreate" component={FamiliasCreate} />
                <Route exact path ="/Avisos" component={AvisosCreate} />
                {/* Cobranza */}
                <Route exact path ="/MenuCobranza" component={BotonesCobranza} />
                <Route exact path ="/Inscripciones" component={ListadoInscripciones} />
                <Route exact path ="/Colegiaturas" component={ListadoColegiaturas} />
                <Route exact path ="/AbonosColegiaturas" component={AbonosColegiaturasCreate} />
                <Route exact path ="/AbonosInscripciones" component={AbonosInscripcionesCreate} />
                <Route exact path ="/Becas" component={ListadoBecas}/>
                <Route exact path ="/AbonosFamilias" component={ListadoAbonosFamilias} />
                <Route exact path ="/CargosFamilias" component={ListadoCargosFamilias} />
                <Route exact path ="/EstadoCuentaFamilias" component={EstadoCuentaFamilias}/>
                <Route exact path ="/ListadoConvenios" component={ListadoConvenios}/>
                <Route exact path ="/ConveniosCreate" component={ConveniosCreate}/>
                {/* Tzedaka */}
                <Route exact path ="/MenuDonativos" component={BotonesTzedaka} />
                <Route exact path ="/CatalogosDonativos" component={BotonesCatalogosDonativos} />
                <Route exact path ="/Donadores" component={ListadoDonadores} />
                <Route exact path ="/DonadoresCreate" component={DonadoresCreate} />
                <Route exact path ="/DonativosCreate" component={DonativosCreate} />
                <Route exact path ="/Donativos" component={ListadoDonativos} />
                <Route exact path ="/DetalleDonativos" component={ListadoDetalleDonativos} />
                <Route exact path ="/Zonas" component={ListadoGrupos} />
                <Route exact path ="/ConceptosDon" component={ListadoConceptosDon} />
                <Route exact path ="/EventosDon" component={ListadoEventosDon} />
                <Route exact path ="/SalasDon" component={ListadoSalasDon} />
                <Route exact path ="/InstitucionesDon" component={ListadoInstitucionesDon} />
                <Route exact path ="/AbonosDonativosCreate" component={AbonosDonativosCreate} />
                <Route exact path ="/DonativosRecurrentes" component={ListadoDonativosRecurrentes} />
                <Route exact path ="/DonativosRecurrentesCreate" component={DonativosRecurrentesCreate} />
                <Route exact path ="/AbonosDonativos" component={ListadoAbonosDonativos} />
                <Route exact path ="/ListadoCobranza" component={ListadoCobranza} />
                <Route exact path ="/ListadoTareas" component={ListadoTareas} />
                <Route exact path ="/TareasCreate" component={TareasCreate} />
                {/* Academico */}
                <Route exact path ="/MenuAcademico" component={BotonesAcademico} />
                <Route exact path ="/Calificaciones" component={CapturarCalificaciones} />
                <Route exact path ="/Listas" component={Listas} />
                <Route exact path ="/Maestros" component={ListadoMaestros} />
                <Route exact path ="/InfoAlumnosCreate" component={InfoAlumnosCreate} />
                <Route exact path ="/InfoAlumnos" component={ListadoInfoAlumnos} />
                <Route exact path ="/AsistenciasCreate" component={AsistenciasCreate} />
                <Route exact path ="/ListadoAsistencias" component={ListadoAsistencias} />
                {/* Reportes */}
                <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/Cartera" component={VistaCartera} />
                <Route exact path ="/GraficasCarteraIngresos" component={GraficasCarteraIngresos} />
                <Route exact path ="/GraficasCarteraEgresos" component={GraficasCarteraEgresos} />
                <Route exact path ="/DashboardAdmin" component={DashboardAdmin} />
                <Route exact path ="/GraficasCarteraDonativos" component={GraficasCarteraDonativos} />
                {/* Edo Cta Familias  */}
                <Route exact path ="/EdoCtaVistaFamilias" component={EdoCtaVistaFamilias} />
                <Route exact path ="/InfoHijos" component={InfoHijos} />
                {/* Edo Cta Donadores  */}
                <Route exact path ="/EdoCtaVistaDonadores" component={EdoCtaVistaDonadores} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                {/* Config  */}
                <Route exact path ="/MenuConfiguraciones" component={Configuraciones} />
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
