import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Label,
  Input,
  Row,
  Col,
  Button,
  ButtonGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import moment from "moment";

function ListadoAsistencias() {
  const { user } = useContext(AuthContext);
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_ASISTENCIAS = process.env.REACT_APP_URL_ASISTENCIAS;
  const [alumnos, setAlumnos] = useState([]);
  const [asistencias, setAsistencias] = useState([]);
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(0);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(0);
  const [selectedAlumno, setSelectedAlumno] = useState("");

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAlumnos = res.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {
    if (selectedClase) {
      axios
        .get(`${URL_ASISTENCIAS}FechaClase/${selectedClase}/${selectedFechaInicio}/${selectedFechaFin}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allAsistencias = res.data;
          let arrayAsistencias = allAsistencias.map((a) => {
            if(selectedAlumno == 0 || selectedAlumno == a.alumnos[0]._id){
            return {
              alumno: a.alumnos[0].name + " " + a.alumnos[0].familias[0].apellido_paterno + " " + a.alumnos[0].familias[0].apellido_materno,
              status: a.status,
              fecha: a.fecha,
              idAlumno: a.alumnos[0]._id
            };
          }
          })
          
          .filter(function (el) {
            return el != null;
          });

          setAsistencias(arrayAsistencias);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedClase, selectedFechaInicio, selectedFechaFin, selectedAlumno]);

  
  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card ">
        {user.menu_academico && user.menu_listados ? (
          <div>
            <h3 align="center">Reporte de Asistencias</h3>
            <br />
            <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Grados</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedGrado}
                  required
                  onChange={(e) => setSelectedGrado(e.target.value)}
                >
                  <option value="0">Selecciona una Grado</option>
                  {grados
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Clases</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedClase}
                  required
                  onChange={(e) => setSelectedClase(e.target.value)}
                >
                  <option value="0">Selecciona una Clase</option>
                  {clases.map((a) => {
                    if (
                      selectedGrado == 0 ||
                      selectedGrado == a.grados[0]._id
                    ) {
                      return (
                        <option value={a._id}>
                          {a.grados[0].name} {a.name}
                        </option>
                      );
                    }
                  })}
                </Input>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Alumno</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedAlumno}
                  required
                  onChange={(e) => setSelectedAlumno(e.target.value)}
                >
                  <option value="0">Selecciona un Alumno</option>
                  {alumnos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if(selectedClase == 0 ||
                      selectedClase == a.clases[0]._id)
                      {
                      return (
                        <option value={a._id}>
                          {a.name} {a.familias[0].apellido_paterno} {a.familias[0].apellido_materno}
                        </option>
                      );
                    }
                  })}
                </Input>
              </Col>
            </Row>
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Nombre</th>
                    <th>Asistencia</th>
                  </tr>
                </thead>
                <tbody>
                  {asistencias
                  .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                  .map((a) => {
                    return (
                      <tr>
                        <td> {a.fecha}</td>
                        <td> {a.alumno}</td>
                        <td>{a.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <br />
              </Table>
          </div>
          
        ) : undefined}
      </div>
    </>
  );
}

export default ListadoAsistencias;
