import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import MateriasInfoMaestros from './MateriasInfoMaestros'
import ClasesInfoMaestros from './ClasesInfoMaestros'

function TablaMaestros(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalClase, setModalClase] = useState(false);
  const toggleClase = () => setModalClase(!modalClase);
 
  return (
    <>
      <td>{props.is_active}</td>
      <td>{props.nombre} {props.apellido} </td>
      <td>{props.jefe} {props.jefeApellido}</td>
      <td>{props.telefono}</td>
      <td>{props.planteles}</td>
      <td>
      <Button color="info" id="Materias"  size="sm" onClick={toggle}>
      <i class="fas fa-book-open"></i>
        </Button>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><h4>Materias de {props.name}</h4></ModalHeader>
        <ModalBody>
        <MateriasInfoMaestros idColaborador={props._id} materias={props.materias} />
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>

      <Button color="info" id="Clases"  size="sm" onClick={toggleClase}>
      <i class="fas fa-store-alt"></i>
        </Button>
      <Modal size="sm" isOpen={modalClase} toggle={toggleClase}>
        <ModalHeader toggle={toggleClase}><h4>Clases de {props.name}</h4></ModalHeader>
        <ModalBody>
        <ClasesInfoMaestros idColaborador={props._id} clases={props.clases} />
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
      </td>
    </>
  );
}

export default TablaMaestros;
