import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import { Table, Row, Col, Button } from "reactstrap";
import Header from "../../../layout/Header/Header";

function ListadoConvenios() {
  const { user } = useContext(AuthContext);
  const URL_CONVENIOS = process.env.REACT_APP_URL_CONVENIOS;
  const [convenios, setConvenios] = useState([]);

  useEffect(() => {
    axios
      .get(URL_CONVENIOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allConvenios = response.data;
        setConvenios(allConvenios);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_departamentos  ?( */}
      <div className="container">
        <div className="row">
          {
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Convenios</h3>
                  <Row>
                  <Col sm={8}>
                    <Button
                      size="sm"
                      href="/MenuCobranza"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                    <Button
                  size="sm"
                  href="/ConveniosCreate"
                  className="btn btn-success"
                  id="botonListado"
                >
                  Crear Convenio
                </Button>
                  </Col>
                  </Row>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th className="tituloTabla">Fecha</th>
                        <th className="tituloTabla">Familia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {convenios
                        .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                        .map((a) => {
                          return (
                            <tr>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {/* ): undefined }  */}
    </>
  );
}

export default ListadoConvenios;
