import React from 'react'

function TablaInfoAlumnos(props) {
    return (
        <>
        <td style={{minWidth:"100px"}}>{props.fecha}</td>
        <td>{props.aspecto}</td>
        <td>{props.comentarios}</td>
        <td style={{minWidth:"150px"}}>{props.creadoN} {props.creadoA}</td>
        </>
    )
}

export default TablaInfoAlumnos
