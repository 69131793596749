import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import PasswordDonador from "./PasswordDonador";
import Baja from "../../Baja";
import DonadoresInfo from "./DonadoresInfo";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function ListadoDonadores() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [donadores, setDonadores] = useState([]);
  const [donadorEdit, setDonadorEdit] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [idPassword, setIdPassword] = useState("");
  const [idEditDonador, setIdEditDonador] = useState("");
  const [selectedGrupoEdit, setSelectedGrupoEdit] = useState("");
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("");
  const [RFC, setRFC] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState("");
  const [colonia, setColonia] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [emailViejo, setEmailViejo] = useState("");
  const [idInfo, setIdInfo] = useState("");
  const [selectedContacto, setSelectedContacto] = useState("");
  const [selectedContactoName, setSelectedContactoName] = useState("");
  const [selectedCobradorEdit, setSelectedCobradorEdit] = useState("");
  const [selectedCobradorName, setSelectedCobradorName] = useState("");
  const [selectedEjecutivo, setSelectedEjecutivo] = useState("");
  const [selectedEjecutivoName, setSelectedEjecutivoName] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [tipoDonativo, setTipoDonativo] = useState("");
  const [fecha_visita, setFechaVisita] = useState("");
  const [hora_visita, setHoraVisita] = useState("");
  const [ultimo_pago, setUltimoPago] = useState("");
  const [forma_pago, setFormaPago] = useState("");
  const [selected_grupo_name, setSelectedGrupoName] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);

  const [activo, setActivo] = useState('');
  const [contacto, setContacto] = useState('');
  const [cobrador, setCobrador] = useState('');
  const [ejecutivo, setEjecutivo] = useState('');
  const [grupo, setGrupo] = useState('');
  const [selectedContactoFiltro, setSelectedContactoFiltro] = useState("");

    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonadores = response.data;
    // Array para pagination
    let arrayTabla = allDonadores
    .sort((a, b) => (a.apellido > b.apellido ? 1 : -1))
    .map((a) => {
      return {
        _id:a._id,
        activo: a.is_active,
        nombre: a.nombre,
        apellido: a.apellido,
        telefono: a.telefono,
        celular: a.celular,
        email: a.email,
        calle: a.calle,
        numero_ext: a.numero_ext,
        numero_int: a.numero_int,
        pais: a.pais,
        estado: a.estado,
        ciudad: a.ciudad,
        delegacion: a.delegacion,
        colonia: a.colonia,
        cp: a.cp,
        nombre_comercial: a.nombre_comercial,
        razon_social: a.razon_social,
        RFC: a.RFC,
        grupo: a.grupos[0].name,
        idGrupo: a.grupos[0]._id,
        cobrador: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
        idCobrador: a.colaboradores[0]._id,
        contacto: a.contacto[0].nombre + " " + a.contacto[0].apellido,
        idContacto: a.contacto[0]._id,
        ejecutivo: a.ejecutivo[0].nombre + " " + a.ejecutivo[0].apellido,
        idEjecutivo: a.ejecutivo[0]._id,
        observaciones: a.observaciones,
        tipoDonativo: a.tipoDonativo,
        fecha_visita: a.fecha_visita,
        hora_visita: a.hora_visita,
        ultimo_pago: a.ultimo_pago,
        forma_pago: a.forma_pago,

      };
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);

    setDonadores(allDonadores);
    setComments(dataFinal)
    //

      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaDonadores = donadores.sort((a, b) =>
    a.apellido > b.apellido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaDonadores.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        (cobrador == 0 ||
          cobrador == a.colaboradores[0]._id) &&
          (contacto == 0 ||
            contacto == a.contacto[0]._id)  &&
            (ejecutivo == 0 ||
              ejecutivo == a.ejecutivo[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return [
          [a.apellido + " " + a.nombre],
          a.telefono,
          a.email,
          a.grupos[0].name,
          [a.contacto[0].nombre + " " + a.contacto[0].apellido],
          [a.ejecutivo[0].nombre + " " + a.ejecutivo[0].apellido],
          [a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido],
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 17, 25);
    doc.text(`Listado Donadores`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail", "Zona", "Contacto", "Ejecutivo", "Cobrador"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Donadores.pdf`);
  }

  function excel() {
    const dataExcel = ListaDonadores.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        (cobrador == 0 ||
          cobrador == a.colaboradores[0]._id) &&
          (contacto == 0 ||
            contacto == a.contacto[0]._id) &&
            (ejecutivo == 0 ||
              ejecutivo == a.ejecutivo[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return {
          Nombre: [a.apellido + " " + a.nombre],
          Telefono: a.telefono,
          Mail: a.email,
          Zona: a.grupos[0].name,
          Contacto: [
            a.contacto[0].nombre + " " + a.contacto[0].apellido,
          ],
          Ejecutivo: [
            a.ejecutivo[0].nombre + " " + a.ejecutivo[0].apellido,
          ],
          Cobrador: [
            a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
          ],
          Observaciones: a.observaciones
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDonadores";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDonadores",
        sheetFilter: ["Nombre", "Telefono", "Mail", "Zona", "Contacto", "Ejecutivo", "Cobrador", "Observaciones"],
        sheetHeader: ["Nombre", "Telefono", "Mail", "Zona", "Contacto", "Ejecutivo", "Cobrador", "Observaciones"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaDonadores.map((a) => {
      if (
        (grupo == 0 || grupo == a.grupos[0]._id) &&
        (cobrador == 0 ||
          cobrador == a.colaboradores[0]._id) &&
          (contacto == 0 ||
            contacto == a.contacto[0]._id) &&
            (ejecutivo == 0 ||
              ejecutivo == a.ejecutivo[0]._id) &&
          (activo == 0 ||
            activo == a.is_active)
      ) {
        return [
          [a.apellido + " " + a.nombre],
          a.telefono,
          a.email,
          a.grupos[0].name,
          [a.contacto[0].nombre + " " + a.contacto[0].apellido],
          [a.ejecutivo[0].nombre + " " + a.ejecutivo[0].apellido],
          [a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido],
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 17, 25);
    doc.text(`Listado Donadores`, 20, 40);
    doc.autoTable({
      head: [["Nombre", "Telefono", "Mail", "Zona", "Contacto", "Ejecutivo", "Cobrador"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
      var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Donadores",
          email: mailTo,
          fileName: "ListadoDonadores.pdf",
          att: base64File,
          text: `<!DOCTYPE html>
          <html>
            <head>
              <style>                                
                .img-container {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                height: 90px;
                                width: auto;
                              }
              </style>
            </head>
            <body>
              <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
              <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
              <h3>Hola,</h3>
              <h3>Adjunto encontraras el listado de donadores.</h3>
              <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
              Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
            </body>
          </html>`
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, nombre, apellido, telefono, celular, email, calle, numero_ext, 
    numero_int, pais, estado, ciudad, delegacion, colonia, cp, nombre_comercial, 
    razon_social, RFC, gruposName, gruposId, cobrador, idCobrador, ejecutivo, idEjecutivo,
    contactoNombre, contactoId, observaciones, tipoDonativo, fecha_visita, hora_visita, ultimo_pago, forma_pago ) {
        setNombre(nombre);
        setApellido(apellido);
        setTelefono(telefono);
        setCelular(celular);
        setEmail(email);
        setEmailViejo(email);
        setCalle(calle);
        setNumeroExt(numero_ext);
        setNumeroInt(numero_int);
        setPais(pais);
        setEstado(estado);
        setCiudad(ciudad);
        setDelegacion(delegacion);
        setColonia(colonia);
        setCP(cp);
        setNombreComercial(nombre_comercial);
        setRazonSocial(razon_social);
        setRFC(RFC);
        setSelectedGrupoName(gruposName);
        setSelectedGrupoEdit(gruposId);
        setSelectedCobradorName(cobrador);
        setSelectedCobradorEdit(idCobrador);
        setSelectedContactoName(contactoNombre);
        setSelectedContacto(contactoId);
        setSelectedEjecutivoName(ejecutivo);
        setSelectedEjecutivo(idEjecutivo);
        setObservaciones(observaciones);
        setTipoDonativo(tipoDonativo);
        setFechaVisita(fecha_visita);
        setHoraVisita(hora_visita);
        setUltimoPago(ultimo_pago);
        setFormaPago(forma_pago);
        setIdEditDonador(idEdit);
        toggleEdit();
  }

  function Info(id) {
    setIdInfo(id);
    toggle();
  }

  function editDonadores(event) {
    event.preventDefault();
    const URL_DONADORES_EDIT = `${process.env.REACT_APP_URL_DONADORES}/${idEditDonador}`;
    const URL_USERS_DON = `${process.env.REACT_APP_URL_USERS}/email/${emailViejo}`;
    axios
      .get(URL_USERS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let idEditDonadorUsuario = response.data[0]._id;
        const URL_SIGNUP_DON = `${process.env.REACT_APP_URL_USERS}/${idEditDonadorUsuario}`;
        axios
          .all([
            axios.patch(
              URL_DONADORES_EDIT,
              {
                nombre_comercial,
                razon_social,
                RFC,
                calle,
                numero_ext,
                numero_int,
                delegacion,
                estado,
                pais,
                cp,
                colonia,
                ciudad,
                nombre,
                apellido,
                telefono,
                celular,
                email,
                grupos: selectedGrupoEdit,
                colaboradores: selectedCobradorEdit,
                contacto: selectedContacto,
                ejecutivo: selectedEjecutivo,
                observaciones,
                tipoDonativo,
                fecha_visita,
                hora_visita,
                ultimo_pago,
                forma_pago,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ),
          ])
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Donador",
                detalle: `${apellido} ${nombre}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            axios.patch(
              URL_SIGNUP_DON,
              {
                nombre: nombre,
                apellido: apellido,
                email: email,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaInfoPassword(idDonador) {
      const URL_USERS_DONADOR = `${process.env.REACT_APP_URL_USERS}/donador/${idDonador}`;
    axios
      .get(URL_USERS_DONADOR, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let idEditDonadorUsuario = response.data[0]._id;
        setIdPassword(idEditDonadorUsuario);
        togglePassword();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Telefono", field: "telefono", sortable: false },
    { name: "Email", field: "email", sortable: true },
    { name: "Grupo", field: "grupo", sortable: true },
    { name: "Cobrador", field: "cobrador", sortable: true },
    { name: "Contacto", field: "contacto", sortable: true },
    { name: "Ejecutivo", field: "ejecutivo", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
                comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
                comment.email.toLowerCase().includes(search.toLowerCase()) ||
                comment.grupo.toLowerCase().includes(search.toLowerCase()) ||
                comment.cobrador.toLowerCase().includes(search.toLowerCase()) ||
                comment.contacto.toLowerCase().includes(search.toLowerCase())||
                comment.ejecutivo.toLowerCase().includes(search.toLowerCase())
        );
    }
    if(grupo){
      computedComments = computedComments.filter((e)=>
      e.idGrupo.includes(grupo)
      ) 
    } 
    if(contacto){
      computedComments = computedComments.filter((e)=>
      e.idContacto.includes(contacto)
      ) 
    } 
    if(ejecutivo){
      computedComments = computedComments.filter((e)=>
      e.idEjecutivo.includes(ejecutivo)
      ) 
    } 
    if(cobrador){
      computedComments = computedComments.filter((e)=>
      e.idCobrador.includes(cobrador)
      ) 
    } 
    if(activo){
      computedComments = computedComments.filter((e)=>
      e.activo.includes(activo)
      ) 
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, contacto, ejecutivo, cobrador, grupo, activo]);

function actualizaEjecutivo (){
  donadores.map((a)=>{
    axios
      .patch(`${URL_DONADORES}/${a._id}`,
      {
        ejecutivo: a.contacto[0]._id,
      }, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        Swal.fire("Good job!", "Actualizado con exito", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  })
}

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_donadores ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.donadores_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/DonadoresCreate"
                >
                  Nuevo Donador
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Donador
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuDonativos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={actualizaEjecutivo}
                >
                  Desarrollo
                </Button> */}
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Donadores</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Donadores</h3>
          <div className="row">
                        <div className="col-md-8">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
          <Row>
            <Col md={2}>
              <Label>Zonas</Label>
              <Input
                bsSize="sm"
                type="select"
                value={grupo}
                onChange={(e) => {
                  setGrupo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                {grupos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Cobrador</Label>
              <Input
                 bsSize="sm"
                 type="select"
                 value={cobrador}
                 onChange={(e) => {
                   setCobrador(e.target.value)
                   setCurrentPage(1)
                 }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Contacto</Label>
              <Input
                bsSize="sm"
                type="select"
                value={contacto}
                onChange={(e) => {
                  setContacto(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    // }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Ejecutivo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={ejecutivo}
                onChange={(e) => {
                  setEjecutivo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    // }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
            <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
            <tbody>
              {commentsData.map((p) => {
                {
                  return (
                    <tr>
                      <td>
                        {p.apellido} {p.nombre}
                      </td>
                      <td>{p.telefono}</td>
                      <td>{p.email}</td>
                      <td>{p.grupo}</td>
                      <td>
                        {p.cobrador}
                      </td>
                      <td>
                        {p.contacto}
                      </td>
                      <td>
                        {p.ejecutivo}
                      </td>
                      <td>
                        {user.donadores_create ? (
                          <div>
                            <Button
                              color="dany"
                              size="sm"
                              onClick={(e) => Info(p._id)}
                              id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaInfo(p._id, p.nombre, p.apellido, p.telefono, p.celular, p.email, p.calle, p.numero_ext, p.numero_int,
                                p.pais, p.estado, p.ciudad, p.delegacion, p.colonia, p.cp, p.nombre_comercial, p.razon_social, p.RFC, p.grupo,
                                p.idGrupo, p.cobrador, p.idCobrador, p.ejecutivo, p.idEjecutivo, p.contacto,
                                p.idContacto, p.observaciones, p.tipoDonativo, p.fecha_visita, p.hora_visita, p.ultimo_pago, p.forma_pago)}
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Password"
                              size="sm"
                              onClick={(e) => jalaInfoPassword(p._id)}
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button>
                            <Baja
                              idStatus={p._id}
                              is_active={p.activo}
                              URL_BAJA={process.env.REACT_APP_URL_DONADORES}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Password"
                              size="sm"
                              disabled
                            >
                              <i class="fas fa-user-lock"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Informacion del Donador</h4>{" "}
        </ModalHeader>
        <ModalBody>
          <DonadoresInfo idDonador={idInfo} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
        <ModalHeader toggle={togglePassword}>
          <h4>Cambio de contraseña</h4>
        </ModalHeader>
        <ModalBody>
          <PasswordDonador idDonador={idPassword} />
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar donador {apellido} {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row form>
            <Col md={3}>
              <Label>Nombre</Label>
              <Input
                type="text"
                placeholder="Nombre"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Apellido</Label>
              <Input
                type="text"
                placeholder="Apellido"
                value={apellido}
                required
                onChange={(e) => {
                  setApellido(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Telefono</Label>
              <Input
                type="string"
                placeholder="Telefono"
                value={telefono}
                required
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Celular</Label>
              <Input
                type="string"
                placeholder="Celular"
                value={celular}
                required
                onChange={(e) => {
                  setCelular(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row form>
            <Col md={3}>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Col>
            <Col md={5}>
              <Label>Calle</Label>
              <Input
                type="text"
                placeholder="Calle"
                value={calle}
                required
                onChange={(e) => {
                  setCalle(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>No. Ext</Label>
              <Input
                type="text"
                placeholder="No. Ext"
                value={numero_ext}
                required
                onChange={(e) => {
                  setNumeroExt(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>No. Int</Label>
              <Input
                type="text"
                placeholder="No. Int"
                value={numero_int}
                required
                onChange={(e) => {
                  setNumeroInt(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={4}>
              <Label>Pais</Label>
              <Input
                type="text"
                placeholder="Pais"
                value={pais}
                required
                onChange={(e) => {
                  setPais(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Estado</Label>
              <Input
                type="text"
                placeholder="Estado"
                value={estado}
                required
                onChange={(e) => {
                  setEstado(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Ciudad</Label>
              <Input
                type="text"
                placeholder="Ciudad"
                value={ciudad}
                required
                onChange={(e) => {
                  setCiudad(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={4}>
              <Label>Delegacion</Label>
              <Input
                type="text"
                placeholder="Delegacion o Municipio"
                value={delegacion}
                required
                onChange={(e) => {
                  setDelegacion(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Colonia</Label>
              <Input
                type="text"
                placeholder="Colonia"
                value={colonia}
                required
                onChange={(e) => {
                  setColonia(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>CP</Label>
              <Input
                type="text"
                placeholder="CP"
                value={cp}
                required
                onChange={(e) => {
                  setCP(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row form>
            <Col md={4}>
              <Label>Nombre Comercial</Label>
              <Input
                type="text"
                placeholder="Nombre Comercial"
                value={nombre_comercial}
                required
                onChange={(e) => {
                  setNombreComercial(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Razon Social</Label>
              <Input
                type="text"
                placeholder="Razon Social"
                value={razon_social}
                required
                onChange={(e) => {
                  setRazonSocial(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>RFC</Label>
              <Input
                type="text"
                placeholder="RFC"
                value={RFC}
                required
                onChange={(e) => {
                  setRFC(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={4}>
              <Label className="mr-sm-2">Zona</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedGrupoEdit}
                required
                onChange={(e) => {
                  setSelectedGrupoEdit(e.target.value);
                }}
              >
                {grupos.map((a) => {
                  return <option value={a._id}>{a.name}</option>;
                })}
              </Input>
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Contacto Interno</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedContacto}
                required
                onChange={(e) => {
                  setSelectedContacto(e.target.value);
                }}
              >
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Cobrador</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCobradorEdit}
                required
                onChange={(e) => {
                  setSelectedCobradorEdit(e.target.value);
                }}
              >
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    if (a.cobrador == "Si") {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col>
          </Row>
          <Row form>
             <Col md={4}>
                  <Label>Forma de Pago</Label>
                  <Input
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Deposito">Deposito</option>
                    <option value="TDC">TDC</option>
                    <option value="Cheque">Cheque</option>
                  </Input>
                </Col>
            
            <Col md={4}>
              <Label>Fecha Visita</Label>
              <Input
                type="date"
                placeholder="Fecha Visita"
                value={fecha_visita}
                required
                onChange={(e) => {
                  setFechaVisita(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Hora Visita</Label>
              <Input
                type="text"
                placeholder="Hora Visita"
                value={hora_visita}
                required
                onChange={(e) => {
                  setHoraVisita(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
          <Col md={5}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Ejecutivo</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedEjecutivo}
                  required
                  onChange={(e) => {
                    setSelectedEjecutivo(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Ejecutivo</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
            <Col md={4}>
              <Label>Ultimo Pago</Label>
              <Input
                type="date"
                placeholder="Ultimo Pago"
                value={ultimo_pago}
                required
                onChange={(e) => {
                  setUltimoPago(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Descripcion del Donativo
                </Label>
                <Input
                  type="text"
                  placeholder="TipoDonativo"
                  value={tipoDonativo}
                  required
                  onChange={(e) => {
                    setTipoDonativo(e.target.value);
                  }}
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editDonadores}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoDonadores;
