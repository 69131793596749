import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import TablaMaterias from "./TablaMaterias";
import MateriasCreate from "./MateriasCreate";
import axios from "axios";
import { Table, Input } from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function ListadoMaterias() {
  const { user } = useContext(AuthContext);
  const URL_MATERIAS = process.env.REACT_APP_URL_MATERIAS;
  const [materias, setMaterias] = useState([]);
  const [seccion, setSeccion] = useState("");

  useEffect(() => {
    axios
      .get(URL_MATERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMaterias = response.data;
        setMaterias(allMaterias);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
      {user.menu_escuela  && user.menu_materias ?(
        <div className="row">
          {
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Materias</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th style={{ paddingBottom: "0px" }} className="tituloTabla">Nombre</th>
                        <th style={{ paddingBottom: "0px" }} className="tituloTabla">Seccion</th>
                        <th style={{ paddingBottom: "0px" }} className="tituloTabla">Editar</th>
                      </tr>
                      <tr>
                        <td></td>
                      <td style={{ paddingTop: "0px" }}>
                          <Input
                            bsSize="sm"
                            type="select"
                            value={seccion}
                            onChange={(e) =>
                              setSeccion(e.target.value)
                            }
                          >
                            <option value='0'>Selecciona</option>
                            <option value='Hebreo'>Hebreo</option>
                            <option value='Español'>Español</option>
                         
                         
                          </Input>
                        </td>
                </tr>
                    </thead>
                    <tbody>
                      {materias
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          if (
                            (seccion == 0 || seccion == a.seccion)
                          ) {
                          return (
                            <tr>
                              <TablaMaterias
                                name={a.name}
                                _id={a._id}
                                seccion={a.seccion}
                                is_active={a.is_active}
                              />
                            </tr>
                          );
                          }
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
          <div className="col-md-6 col-sm-6">
            <div className="card">
              <div className="card-body">
                <MateriasCreate />
              </div>
            </div>
          </div>
        </div>
        ): undefined } 
      </div>
      <br />
      <Footer />
    </>
  );
}

export default ListadoMaterias;
