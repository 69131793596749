import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";

am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

function GraficasInEgBarras(props) {
  const { user } = useContext(AuthContext);
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
    const URL_ABONOS_DONATIVOS = process.env.REACT_APP_URL_ABONOS_DONATIVOS;
    const URL_ABONOS_COLEGIATURAS = process.env.REACT_APP_URL_ABONOS_COLEGIATURAS;
    const URL_ABONOS_INSCRIPCIONES =
      process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
      const [donativos, setDonativos] = useState([]);
    const [colegiaturas, setColegiaturas] = useState([]);
    const [inscripciones, setInscripciones] = useState([]);
    const URL_NOMINAS = process.env.REACT_APP_URL_NOMINAS;
    const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
    const [nominas, setNominas] = useState([]);
    const [gastos, setGastos] = useState([]);

    useEffect(() => {
      axios
        .get(URL_ABONOS_DONATIVOS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allDonativos = response.data;
          setDonativos(allDonativos);
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(URL_ABONOS_COLEGIATURAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allColegiaturas = response.data;
          setColegiaturas(allColegiaturas);
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(URL_ABONOS_INSCRIPCIONES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allInscripciones = response.data;
          setInscripciones(allInscripciones);
        })
        .catch((err) => {
          console.log(err);
        });
        axios
      .get(URL_NOMINAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNominas = response.data;
        setNominas(allNominas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGastos = response.data;
        setGastos(allGastos);
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);
  
  //Array Colegiaturas
    const arrayColegiaturas = colegiaturas
    .sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
    .map((n) => {
        if (user.planteles == PLANTELES_GENERAL &&
          user.areas == AREAS_GENERAL){
      if(
        (props.selectedPlantel == 0 ||
          props.selectedPlantel == n.planteles[0]._id) &&
        (props.selectedArea == 0 ||
          props.selectedArea == n.areas[0]._id) &&
        (props.fechaInicio == 0 ||
          props.fechaInicio <= n.fecha) &&
        (props.fechaFin == 0 ||
          props.fechaFin >= n.fecha)){
      return {
        fecha: n.fecha,
        movimiento: "Ingreso",
        importe: n.importe,
      }}
      else return null} else{ if(
        (user.planteles == n.planteles[0]._id) &&
        (user.areas == n.areas[0]._id) &&
        (props.fechaInicio == 0 ||
          props.fechaInicio <= n.fecha) &&
        (props.fechaFin == 0 ||
          props.fechaFin >= n.fecha)
      ){
        return {
          fecha: n.fecha,
          movimiento: "Ingreso",
          importe: n.importe,
        }} else return null}
      })
      .filter(function (el) {
        return el != null;
      });
  //Array Inscripciones
    const arrayInscripciones = inscripciones
    .sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
    .map((n) => {
      if (user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL){
      if(
        (props.selectedPlantel == 0 ||
          props.selectedPlantel == n.planteles[0]._id) &&
        (props.selectedArea == 0 ||
          props.selectedArea == n.areas[0]._id) &&
        (props.fechaInicio == 0 ||
          props.fechaInicio <= n.fecha) &&
        (props.fechaFin == 0 ||
          props.fechaFin >= n.fecha)){
      return {
        fecha: n.fecha,
        movimiento: "Ingreso",
        importe: n.importe,
      }}
      else return null} else{ if(
        (user.planteles == n.planteles[0]._id) &&
        (user.areas == n.areas[0]._id) &&
        (props.fechaInicio == 0 ||
          props.fechaInicio <= n.fecha) &&
        (props.fechaFin == 0 ||
          props.fechaFin >= n.fecha)
      ){
        return {
          fecha: n.fecha,
          movimiento: "Ingreso",
          importe: n.importe,
        }} else return null}
      })
      .filter(function (el) {
        return el != null;
      });
      
  //Array Donativos
  const arrayDonativos = donativos
  .sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
  .map((n) => {
    if((props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha)){
        return {
          fecha: n.fecha,
          movimiento: "Ingreso",
          importe: n.importe,
        }}
  })
  .filter(function (el) {
    return el != null;
  });


    //Array Nominas
  const arrayNominas = nominas
  .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
  .map((n) => {
    if (user.planteles == PLANTELES_GENERAL &&
      user.areas == AREAS_GENERAL){
    if(
      (n.pagado=="Si") &&
    (props.selectedPlantel == 0 ||
      props.selectedPlantel == n.planteles[0]._id) &&
    (props.selectedArea == 0 ||
      props.selectedArea == n.areas[0]._id) &&
    (props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha) 
      ){
    return {
      fecha: n.fecha,
      movimiento: "Egreso",
      importe: n.importe,
    }
  } else return null} else{ if(
    (n.pagado=="Si") &&
      (user.planteles == n.planteles[0]._id) &&
      (user.areas == n.areas[0]._id) &&
      (props.fechaInicio == 0 ||
        props.fechaInicio <= n.fecha) &&
      (props.fechaFin == 0 ||
        props.fechaFin >= n.fecha)
    ){ return {
      fecha: n.fecha,
      movimiento: "Egreso",
      importe: n.importe,
    }} else return null}
  })
  .filter(function (el) {
    return el != null;
  });
//Array Gastos
const arrayGastos = gastos
  .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
  .map((n) => {
    if (user.planteles == PLANTELES_GENERAL &&
      user.areas == AREAS_GENERAL){
    if((n.pagado=="Si") &&
    (props.selectedPlantel == 0 ||
      props.selectedPlantel == n.presupuestos[0].planteles[0]._id) &&
    (props.selectedArea == 0 ||
      props.selectedArea == n.presupuestos[0].areas[0]._id) &&
    (props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha) ){
    return {
      fecha: n.fecha,
      movimiento: "Egreso",
      importe: n.total,
    }
  }  else return null} else{ if(
    (n.pagado=="Si") &&
    (user.planteles == n.presupuestos[0].planteles[0]._id) &&
    (user.areas == n.presupuestos[0].areas[0]._id) &&
    (props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha)
    ){  return {
      fecha: n.fecha,
      movimiento: "Egreso",
      importe: n.total,
    }} else return null}
  })
  .filter(function (el) {
    return el != null;
  });
  
  
    const groupByFechaEgresos = function (miarray, prop) {
      return miarray.reduce(function (groups, item) {
        const val = item[prop].substring(0,7);
        groups[val] = groups[val] || {
          fecha: item.fecha,
          egresos: 0
        };
        groups[val].egresos += item.importe;
        groups[val].ingresos = 0;
        groups[val].fecha = item.fecha.substring(0, 7)+'-01';
        return groups;
      }, []);
    };

      const agrupadoEgresos = arrayGastos.concat(arrayNominas)
      const agrupadoMovEgr = groupByFechaEgresos(agrupadoEgresos, 'fecha' )
      const dataFinalEgresos = Object.values(agrupadoMovEgr);
  
      const groupByFechaIngresos = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
          const val = item[prop].substring(0,7);
          groups[val] = groups[val] || {
            fecha: item.fecha,
            ingresos: 0
          };
          groups[val].egresos = 0;
          groups[val].ingresos += item.importe;
          groups[val].fecha = item.fecha.substring(0, 7)+'-01';
          return groups;
        }, []);
      };

      const agrupadoIngresos = arrayColegiaturas.concat(arrayInscripciones, arrayDonativos)
      const agrupadoMovIng = groupByFechaIngresos(agrupadoIngresos, 'fecha' )
      const dataFinalIngresos = Object.values(agrupadoMovIng);

      const dataFinal = dataFinalIngresos.concat(dataFinalEgresos)

      const groupByFecha = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
          const val = item[prop].substring(0,7);
          groups[val] = groups[val] || {
            fecha: item.fecha,
            ingresos: 0,
            egresos: 0,
          };
          groups[val].egresos += item.egresos;
          groups[val].ingresos += item.ingresos;
          groups[val].fecha = item.fecha.substring(0, 7)+'-01';
          return groups;
        }, []);
      };

      const agrupadoMov = groupByFecha(dataFinal, 'fecha' )
      const dataFinalAgrupado = Object.values(agrupadoMov);

    // Create chart instance
let chart = am4core.create("chartdivInEgBarras", am4charts.XYChart3D);


chart.data = dataFinalAgrupado

// Create axes
let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

  // Set input format for the dates
  dateAxis.periodChangeDateFormats.setKey("month", "[bold]MMM yy");
  dateAxis.dateFormats.setKey("month", "MMM yy")

dateAxis.dataFields.date = "fecha";
dateAxis.renderer.grid.template.location = 0;
dateAxis.renderer.minGridDistance = 30;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
// valueAxis.title.text = "Importe MXN";
valueAxis.renderer.labels.template.adapter.add("text", function(text) {
  return "$" + text;
});

// Create series
let series = chart.series.push(new am4charts.ColumnSeries3D());
series.dataFields.valueY = "ingresos";
series.dataFields.dateX = "fecha";
series.name = "Ingresos";
series.clustered = false;
series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
series.columns.template.fillOpacity = 0.9;

let series2 = chart.series.push(new am4charts.ColumnSeries3D());
series2.dataFields.valueY = "egresos";
series2.dataFields.dateX = "fecha";
series2.name = "Egresos";
series2.clustered = false;
series2.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.filePrefix = "myExport";

    return (
        <>
                  <div
        id="chartdivInEgBarras"
        style={{ width: "100%", height: "500px", paddingTop: "0px" }}
      ></div>
        </>
    )
}

export default GraficasInEgBarras
