import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function FamiliasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SIGNUP = process.env.REACT_APP_URL_USERS;
  const [text, setText] = useState(false);
  const [codigo_familia, setCodigoFamilia] = useState("");
  const [apellido_paterno, setApellidoPaterno] = useState("");
  const [apellido_materno, setApellidoMaterno] = useState("");
  const [nombre_papa, setNombrePapa] = useState("");
  const [nombre_mama, setNombreMama] = useState("");
  const [telefono_papa, setTelefonoPapa] = useState("");
  const [telefono_mama, setTelefonoMama] = useState("");
  const [email_papa, setEmailPapa] = useState("");
  const [email_mama, setEmailMama] = useState("");
  const [telefono_casa, setTelefonoCasa] = useState("");
  const [contacto_emergencia, setContactoEmergencia] = useState("");
  const [telefono_emergencia, setTelefonoEmergencia] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumeroExt] = useState("");
  const [numero_int, setNumeroInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [cp, setCP] = useState("");
  const [colonia, setColonia] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [password, setPassword] = useState(
    Math.random().toString(36).substring(7)
  );
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setCodigoFamilia("");
    setApellidoPaterno("");
    setApellidoMaterno("");
    setNombrePapa("");
    setNombreMama("");
    setTelefonoPapa("");
    setTelefonoMama("");
    setEmailPapa("");
    setEmailMama("");
    setTelefonoCasa("");
    setContactoEmergencia("");
    setTelefonoEmergencia("");
    setCalle("");
    setNumeroExt("");
    setNumeroInt("");
    setDelegacion("");
    setEstado("");
    setPais("");
    setCP("");
    setColonia("");
    setCiudad("");
    setObservaciones("");
    setSelectedColaborador("");
    setPassword("");
    setValidaBoton(true);
  };
  useEffect(() => {
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveFamilias = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_FAMILIAS,
          {
            codigo_familia,
            apellido_paterno,
            apellido_materno,
            nombre_papa,
            nombre_mama,
            telefono_papa,
            telefono_mama,
            email_papa,
            email_mama,
            telefono_casa,
            contacto_emergencia,
            telefono_emergencia,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            observaciones,
            colaboradores: selectedColaborador,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Alta Familia",
              detalle: `Familia: ${codigo_familia}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          axios
            .get(`${URL_FAMILIAS}/email/${email_papa}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              const idPost = response.data[0]._id;
              axios
                .post(
                  URL_SIGNUP,
                  {
                    nombre: nombre_papa,
                    apellido: apellido_paterno,
                    email: email_papa,
                    password,
                    menu_edoCta_Fam: true,
                    familias: idPost,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  clear();
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.familias_create ? (
        <div className="card container">
          <h3 align="center">Crea una Familia nuevo</h3>
          <Form onSubmit={saveFamilias}>
            <Row form>
              <Col md={4}>
                <Label>Codigo de Familia</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo_familia}
                  required
                  onChange={(e) => {
                    setCodigoFamilia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Apellido Paterno</Label>
                <Input
                  type="text"
                  placeholder="Apellido Paterno"
                  value={apellido_paterno}
                  required
                  onChange={(e) => {
                    setApellidoPaterno(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Apellido Materno</Label>
                <Input
                  type="text"
                  placeholder="Apellido Materno"
                  value={apellido_materno}
                  required
                  onChange={(e) => {
                    setApellidoMaterno(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={4}>
                <Label>Nombre Papa</Label>
                <Input
                  type="text"
                  placeholder="Nombre Papa"
                  value={nombre_papa}
                  required
                  onChange={(e) => {
                    setNombrePapa(e.target.value);
                  }}
                />
              </Col>

              <Col md={4}>
                <Label>Telefono Papa</Label>
                <Input
                  type="text"
                  placeholder="Telefono Papa"
                  value={telefono_papa}
                  required
                  onChange={(e) => {
                    setTelefonoPapa(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Email Papa</Label>
                <Input
                  type="email"
                  placeholder="Email Papa"
                  value={email_papa}
                  required
                  onChange={(e) => {
                    setEmailPapa(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={4}>
                <Label>Nombre Mama</Label>
                <Input
                  type="text"
                  placeholder="Nombre Mama"
                  value={nombre_mama}
                  required
                  onChange={(e) => {
                    setNombreMama(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Telefono Mama</Label>
                <Input
                  type="text"
                  placeholder="Telefono Mama"
                  value={telefono_mama}
                  required
                  onChange={(e) => {
                    setTelefonoMama(e.target.value);
                  }}
                />
              </Col>

              <Col md={4}>
                <Label>Email Mama</Label>
                <Input
                  type="email"
                  placeholder="Email Mama"
                  value={email_mama}
                  required
                  onChange={(e) => {
                    setEmailMama(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={4}>
                <Label>Telefono Casa</Label>
                <Input
                  type="text"
                  placeholder="Telefono Casa"
                  value={telefono_casa}
                  required
                  onChange={(e) => {
                    setTelefonoCasa(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Contacto Emergencia</Label>
                <Input
                  type="text"
                  placeholder="Contacto Emergencia"
                  value={contacto_emergencia}
                  required
                  onChange={(e) => {
                    setContactoEmergencia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Telefono Emergencia</Label>
                <Input
                  type="text"
                  placeholder="Telefono Emergencia"
                  value={telefono_emergencia}
                  required
                  onChange={(e) => {
                    setTelefonoEmergencia(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={8}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <Label>Observaciones</Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Cobrador</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColaborador}
                  required
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Cobrador</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      if (a.cobrador == "Si") {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}{" "}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Password</Label>
                <Input type="text" placeholder={password} disabled />
              </Col>
            </Row>

            <br />
            <Row>
              {validaBoton ? (
                <Button type="submit" className="btn btn-success">
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              <div>
                <SweetAlert
                  show={text}
                  title="Creado con Exito"
                  // text="SweetAlert in React"
                  onConfirm={() => {
                    setText(false);
                    window.location.href = "/Familias";
                  }}
                />
              </div>
              <Button
                href="/Familias"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <br />
        </div>
      ) : undefined}
      
    </>
  );
}

export default FamiliasCreate;
