import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dataviz);

function GraficasIngresosPieIns(props) {
  const { user } = useContext(AuthContext);
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const URL_ABONOS_INSCRIPCIONES =
  process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
    const [inscripciones, setInscripciones] = useState([]);
  
    useEffect(() => {
      axios
        .get(URL_ABONOS_INSCRIPCIONES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allInscripciones = response.data;
          setInscripciones(allInscripciones);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

    //Array Inscripciones
  const arrayInscripciones = inscripciones
  .map((n) => {
    if (user.planteles == PLANTELES_GENERAL &&
      user.areas == AREAS_GENERAL){
    if(
      (props.selectedPlantel == 0 ||
        props.selectedPlantel == n.planteles[0]._id) &&
      (props.selectedArea == 0 ||
        props.selectedArea == n.areas[0]._id) &&
      (props.fechaInicio == 0 ||
        props.fechaInicio <= n.fecha) &&
      (props.fechaFin == 0 ||
        props.fechaFin >= n.fecha)){
    return {
      plantel: n.planteles[0].name,
      importe: n.importe,
      tipo:'Inscripciones'
    }}
    else return null} else{ if(
      (user.planteles == n.planteles[0]._id) &&
      (user.areas == n.areas[0]._id) &&
      (props.fechaInicio == 0 ||
        props.fechaInicio <= n.fecha) &&
      (props.fechaFin == 0 ||
        props.fechaFin >= n.fecha)
    ){
      return {
        plantel: n.planteles[0].name,
        importe: n.importe,
        tipo:'Inscripciones'
      }} else return null}
    })
    .filter(function (el) {
      return el != null;
    });

  const groupByInscripciones = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        name: item.plantel,
        value: 0,
      };
      groups[val].value += item.importe;
      groups[val].name = item.plantel;
      groups[val].tipo = item.tipo
      return groups;
    }, []);
  };

  const inscripcionesAgrupado = groupByInscripciones(arrayInscripciones, "plantel");
  const dataFinalInscripciones = Object.values(inscripcionesAgrupado);
 
  //Grafica
  let chart = am4core.create("chartdivPieIns", am4charts.PieChart3D);
chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

chart.legend = new am4charts.Legend();
    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.filePrefix = "myExport";

chart.data = dataFinalInscripciones

let series = chart.series.push(new am4charts.PieSeries3D());
series.dataFields.value = "value";
series.dataFields.category = "name";
series.labels.template.disabled = true;
  
    return (
        <>

           <div id="chartdivPieIns" style={{ width: "100%", height: "400px", paddingTop:"0px" }}></div> 

        </>
    )
}

export default GraficasIngresosPieIns
