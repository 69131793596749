import React, { useState } from "react";
import { Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function TablaCalificaciones(props) {
  const [calif1, setCalif1] = useState(0);
  const [calif2, setCalif2] = useState(0);
  const [calif3, setCalif3] = useState(0);
  const [califFinal, setCalifFinal] = useState(0);
  const [arrayCalificaciones, setArrayCalificaciones] = useState({})
  const actualizaArray = props.actualizaArray
  const index = props.index;
  const [key, setKey] = useState(props.id);

function calculaCalif1(e){
    setCalif1(e.target.value)
    setCalifFinal(parseFloat(calif1*(props.P1/100)+calif2*(props.P2/100)+calif3*(props.P3/100)).toFixed(2))
    setArrayCalificaciones({
        calificacionP1: e.target.value,
        calificacionP2: calif2,
        calificacionP3: calif3,
        materias: props.idMateria,
        alumnos:props.id,
        periodos: props.idPeriodo })
        actualizaArray(arrayCalificaciones, index)
}
function calculaCalif2(e){
    setCalif2(e.target.value)
    setCalifFinal(parseFloat(calif1*(props.P1/100)+calif2*(props.P2/100)+calif3*(props.P3/100)).toFixed(2))
    setArrayCalificaciones({
        calificacionP1: calif1,
        calificacionP2: e.target.value,
        calificacionP3: calif3,
        materias: props.idMateria,
        alumnos:props.id,
        periodos: props.idPeriodo })
        actualizaArray(arrayCalificaciones, index)
}
function calculaCalif3(e){
    setCalif3(e.target.value)
    setCalifFinal(parseFloat(calif1*(props.P1/100)+calif2*(props.P2/100)+calif3*(props.P3/100)).toFixed(2))
    setArrayCalificaciones({
        calificacionP1: calif1,
        calificacionP2: calif2,
        calificacionP3: e.target.value,
        materias: props.idMateria,
        alumnos:props.id,
        periodos: props.idPeriodo })
        actualizaArray(arrayCalificaciones, index)
        
}

  return (
    <>
      <td style={{minWidth:"300px"}}>
        {props.apellido_paterno} {props.apellido_materno} {props.name}{" "}
      </td>
      <td style={{maxWidth:"50px"}}>
        <Input
          bsSize="sm"
          type="number"
          required
          key={key}
          onBlur={(e) => calculaCalif1(e) }
          onChange={(e) => calculaCalif1(e) }
        ></Input>
      </td>
      <td style={{maxWidth:"50px"}}>
        <Input
          bsSize="sm"
          type="number"
          required
          key={key}
          onBlur={(e) => calculaCalif2(e) }
          onChange={(e) => calculaCalif2(e) }
        ></Input>
      </td>
      <td style={{maxWidth:"50px"}}>
        <Input
          bsSize="sm"
          type="number"
          required
          key={key}
          onBlur={(e) => calculaCalif3(e) }
          onChange={(e) => calculaCalif3(e) }
        ></Input>
      </td>
      <td style={{maxWidth:"50px"}}>
      <Input
          bsSize="sm"
          type="number"
          value={califFinal}
        ></Input>
      </td>
    </>
  );
}

export default TablaCalificaciones;
