import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
} from "reactstrap";
import Header from "../../layout/Header/Header";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../Baja";
import { TableHeader, Pagination, Search } from "../../DataTable"
import useFullPageLoader from "../../hooks/useFullPageLoader"


function ListadoAlumnos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [alumnos, setAlumnos] = useState([]);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState("");
  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [name, setName] = useState("");
  const [apellidoP, setApellidoP] = useState("");
  const [apellidoM, setApellidoM] = useState("");
  const [activo, setActivo] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalBeca, setModalBeca] = useState(false);
  const toggleBeca = () => setModalBeca(!modalBeca);

  const [idInfo, setIDInfo] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido_paterno, setApellidoPaterno] = useState("");
  const [apellido_materno, setApellidoMaterno] = useState("");
  const [nombreMama, setNombreMama] = useState("");
  const [nombrePapa, setNombrePapa] = useState("");
  const [telefonoMama, setTelefonoMama] = useState("");
  const [telefonoPapa, setTelefonoPapa] = useState("");
  const [telefonoCasa, setTelefonoCasa] = useState("");
  const [tipoSangre, setTipoSangre] = useState("");
  const [contactoEmergencia, setContactoEmergencia] = useState("");
  const [telefonoEmergencia, setTelefonoEmergencia] = useState("");
  const [direccion, setDireccion] = useState("");
  const [colonia, setColonia] = useState("");
  const [idAlumno, setIdAlumno] = useState("");
  const [genero, setGenero] = useState("");
  const [registro, setRegistro] = useState("");
  const [fecha_nacimiento, setFechaNacimiento] = useState("");
  const [fecha_ingreso, setFechaIngreso] = useState("");
  const [telefono, setTelefono] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [alergias, setAlergias] = useState("");
  const [familiasId, setFamiliasId] = useState("");
  const [familiasCodigo, setFamiliasCodigo] = useState("");
  const [claseId, setClaseId] = useState("");
  const [selectedEditClase, setSelectedEditClase] = useState();
  const [selectedEditFamilia, setSelectedEditFamilia] = useState();

  const [grado, setGrado] = useState("");
  const [claseName, setClaseName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [plantelName, setPlantelName] = useState("");

  const [beca_importe_col, setBecaImporteCol] = useState("");
  const [beca_porcentaje_col, setBecaPorcentajeCol] = useState("");
  const [beca_porcentaje_ins, setBecaPorcentajeIns] = useState("");
  const [beca_importe_ins, setBecaImporteIns] = useState("");

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE


//--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });

const ITEMS_PER_PAGE = 50



  useEffect(() => {
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAlumnos = response.data;

        // Array para pagination
        let arrayTabla = allAlumnos
        .sort((a, b) => (a.familias[0].apellido_paterno > b.familias[0].apellido_paterno ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            is_active: a.is_active,
            codigo_familia: a.familias[0].codigo_familia,
            id_codigo_familia: a.familias[0]._id,
            name:a.name,
            apellido_paterno: a.familias[0].apellido_paterno,
            apellido_materno: a.familias[0].apellido_materno,
            plantel: a.clases[0].planteles[0].name,
            area: a.clases[0].grados[0].areas[0].name,
            grado: a.clases[0].grados[0].name,
            clase: a.clases[0].grados[0].name + " " + a.clases[0].name + " " + a.clases[0].grados[0].areas[0].name + " " + a.clases[0].planteles[0].name,
            idPlantel: a.clases[0].planteles[0]._id,
            idArea: a.clases[0].grados[0].areas[0]._id,
            idClase: a.clases[0]._id,
            nombre_papa: a.familias[0].nombre_papa,
            nombre_mama: a.familias[0].nombre_mama,
            telefono_papa:a.familias[0].telefono_papa,
            telefono_mama:a.familias[0].telefono_mama,
            telefono_casa:a.familias[0].telefono_casa,
            tipo_sangre:a.tipo_sangre,
            contacto_emergencia:a.familias[0].contacto_emergencia,
            telefono_emergencia:a.familias[0].telefono_emergencia,
            calle:a.familias[0].calle,
            numero_ext:a.familias[0].numero_ext,
            numero_int:a.familias[0].numero_int,
            colonia:a.familias[0].colonia,
            genero:a.genero,
            registro:a.registro,
            fecha_nacimiento:a.fecha_nacimiento,
            fecha_ingreso:a.fecha_ingreso,
            telefono:a.telefono,
            observaciones:a.observaciones,
            alergias:a.alergias,
            beca_importe_col:a.beca_importe_col,
            beca_porcentaje_col:a.beca_porcentaje_col,
            beca_importe_ins:a.beca_importe_ins,
            beca_porcentaje_ins:a.beca_porcentaje_ins
          };
        }).filter(function (el) {
          return el != null;
        });
      
        let dataFinal = Object.values(arrayTabla);

        setAlumnos(allAlumnos);
        setComments(dataFinal)

        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function Info(nombre, apellido_paterno, apellido_materno, nombre_papa, nombre_mama, telefono_papa, 
    telefono_mama, telefono_casa, tipo_sangre, contacto_emergencia, telefono_emergencia, calle, numero_ext, numero_int, colonia, idInfo){
    
      const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idInfo}`;
      axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => setPhoto(data.data.version))
      .catch((err) => console.log(err));
    
    setNombre(nombre)
    setApellidoPaterno(apellido_paterno)
    setApellidoMaterno(apellido_materno)
    setNombrePapa(nombre_papa)
    setNombreMama(nombre_mama)
    setTelefonoPapa(telefono_papa)
    setTelefonoMama(telefono_mama)
    setTelefonoCasa(telefono_casa)
    setTipoSangre(tipo_sangre)
    setContactoEmergencia(contacto_emergencia)
    setTelefonoEmergencia(telefono_emergencia)
    setDireccion(calle + " " + numero_ext + " " + numero_int)
    setColonia(colonia)
    setIDInfo(idInfo)
    toggle()
  }

  function Edit(idAlumno, nombre, genero, registro, fecha_nacimiento, fecha_ingreso,
    telefono, observaciones, alergias, tipo_sangre, familiasId, familiasCodigo, apellido_paterno, apellido_materno, 
    claseId, grado, clase, area, plantel){
      setIdAlumno(idAlumno)
      setNombre(nombre)
      setApellidoPaterno(apellido_paterno)
      setApellidoMaterno(apellido_materno)
      setGenero(genero)
      setRegistro(registro)
      setFechaNacimiento(fecha_nacimiento)
      setFechaIngreso(fecha_ingreso)
      setTelefono(telefono)
      setObservaciones(observaciones)
      setAlergias(alergias)
      setTipoSangre(tipo_sangre)
      setFamiliasId(familiasId)
      setFamiliasCodigo(familiasCodigo)
      setClaseId(claseId)
      setGrado(grado)
      setClaseName(clase)
      setAreaName(area)
      setPlantelName(plantel)
      toggleEdit()
    }

    function editAlumnos(event) {
      if (file == null) {
        const URL_ALUMNOS_EDIT = `${process.env.REACT_APP_URL_ALUMNOS}/${idAlumno}`;
        event.preventDefault();
        axios
          .patch(
            URL_ALUMNOS_EDIT,
            {
              name: nombre,
              genero,
              registro,
              fecha_ingreso,
              fecha_nacimiento,
              telefono,
              observaciones,
              alergias,
              tipo_sangre: tipoSangre,
              clases: selectedEditClase,
              familias: selectedEditFamilia,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Edit Alumno",
                detalle: `Nombre: ${name} / Familia: ${selectedEditFamilia}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } else {
        const URL_ALUMNOS_EDIT = `${process.env.REACT_APP_URL_ALUMNOS}/${idAlumno}`;
        event.preventDefault();
        axios
          .patch(
            URL_ALUMNOS_EDIT,
            {
              name: nombre,
              genero,
              registro,
              fecha_ingreso,
              fecha_nacimiento,
              telefono,
              observaciones,
              alergias,
              tipo_sangre: tipoSangre,
              clases: selectedEditClase,
              familias: selectedEditFamilia,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("user", idAlumno); 

            axios.post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            });
          })
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Edit Alumno",
                detalle: `Nombre: ${name} / Familia: ${selectedEditFamilia}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    }

    function Beca(idAlumno, nombre, apellido_paterno, apellido_materno,
      beca_importe_col, beca_porcentaje_col, beca_importe_ins, beca_porcentaje_ins){
        setIdAlumno(idAlumno)
        setNombre(nombre)
        setApellidoPaterno(apellido_paterno)
        setApellidoMaterno(apellido_materno)
        setBecaImporteCol(beca_importe_col)
        setBecaPorcentajeCol(beca_porcentaje_col)
        setBecaImporteIns(beca_importe_ins)
        setBecaPorcentajeIns(beca_porcentaje_ins)
        toggleBeca()
    }



    function BecaGuarda(event) {
      event.preventDefault();
      const URL_ALUMNOS_EDIT = `${process.env.REACT_APP_URL_ALUMNOS}/${idAlumno}`;
      axios
        .patch(
          URL_ALUMNOS_EDIT,
          {
            beca_importe_col,
            beca_porcentaje_col,
            beca_porcentaje_ins,
            beca_importe_ins,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          toggleBeca();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }

    const headers = [
      { name: "Activo", field: "is_active", sortable: true },
      { name: "Cod. Fam.", field: "codigo_familia", sortable: true },
      { name: "Nombre", field: "name", sortable: true },
      { name: "Apellidos", field: "apellido_paterno", sortable: true },
      { name: "Plantel", field: "plantel", sortable: true },
      { name: "Area", field: "area", sortable: true },
      { name: "Clase", field: "clase", sortable: true },
      { name: "Acciones", field: "acciones", sortable: false },
  ];

    const commentsData = useMemo(() => {
      let computedComments = comments

      if (search) {
          computedComments = computedComments.filter(
              comment =>
                  comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
                  comment.codigo_familia.toLowerCase().includes(search.toLowerCase()) ||
                  comment.name.toLowerCase().includes(search.toLowerCase()) ||
                  comment.apellido_paterno.toLowerCase().includes(search.toLowerCase()) ||
                  comment.apellido_materno.toLowerCase().includes(search.toLowerCase()) ||
                  comment.plantel.toLowerCase().includes(search.toLowerCase()) ||
                  comment.area.toLowerCase().includes(search.toLowerCase())||
                  comment.clase.toLowerCase().includes(search.toLowerCase())
          );
      }

      if(user.planteles != PLANTELES_GENERAL){
        computedComments = computedComments.filter((e)=>
        e.idPlantel.includes(user.planteles)
        ) 
      } 

      if(user.areas != AREAS_GENERAL){
        computedComments = computedComments.filter((e)=>
        e.idArea.includes(user.areas)
        ) 
      } 

      if(activo){
        computedComments = computedComments.filter((e)=>
        e.is_active.includes(activo)
        ) 
      } 
      
      if(selectedPlantel){
        computedComments = computedComments.filter((e)=>
        e.idPlantel.includes(selectedPlantel)
        ) 
      } 

      if(selectedArea){
        computedComments = computedComments.filter((e)=>
        e.idArea.includes(selectedArea)
        ) 
      }

      if(selectedClase){
        computedComments = computedComments.filter((e)=>
        e.idClase.includes(selectedClase)
        ) 
      }

      setTotalItems(computedComments.length);

      //Sorting comments
      if (sorting.field) {
          const reversed = sorting.order === "asc" ? 1 : -1;
          computedComments = computedComments.sort(
              (a, b) =>
                  reversed * a[sorting.field].localeCompare(b[sorting.field])
          );
      }

      //Current Page slice
      return computedComments.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
  }, [comments, currentPage, search, sorting, selectedPlantel, selectedArea, selectedClase, activo]);

  return (
    <>
      <Header />
      <br />
      <br />
      <div>
        {user.alumnos_listado ? (
          <div className="card col-12">
            <Row>
              {user.alumnos_create ? (
                <Button
                  size="sm"
                  href="/AlumnosCreate"
                  className="btn btn-success"
                  id="botonListado"
                >
                  Crear Alumno
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Crear Alumno
                </Button>
              )}
              <Button
                size="sm"
                type="submit"
                className="btn btn-danger"
                href="/MenuAlumnos"
              >
                Regresar
              </Button>
            </Row>
            <h3 align="center">Alumnos</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                <tr>
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={activo}
                      onChange={(e) => {
                        setActivo(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </td>
                 <td></td>
                 <td></td>
                 <td></td>
                  {user.planteles == PLANTELES_GENERAL ? (
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedPlantel}
                        onChange={(e) => {
                          setSelectedPlantel(e.target.value)
                          setCurrentPage(1)
                        }}
                      >
                        <option value="">Selecciona</option>
                        {planteles
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != PLANTELES_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  {user.areas == AREAS_GENERAL ? (
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => {
                          setSelectedArea(e.target.value)
                          setCurrentPage(1)
                        }}
                      >
                        <option value="">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedClase}
                      required
                      onChange={(e) => {
                        setSelectedClase(e.target.value)
                        setCurrentPage(1)
                      }}
                    >
                      <option value="">Selecciona</option>
                      {user.planteles == PLANTELES_GENERAL &&
                      user.areas == AREAS_GENERAL
                        ? clases
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              if (
                                (selectedPlantel == 0 ||
                                  selectedPlantel == a.planteles[0]._id) &&
                                (selectedArea == 0 ||
                                  selectedArea == a.grados[0].areas[0]._id)
                              )
                                return (
                                  <option value={a._id}>
                                    {a.grados[0].name} {a.name}{" "}
                                    {a.grados[0].areas[0].name}  {a.planteles[0].name}
                                  </option>
                                );
                            })
                        : clases
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              if (
                                user.planteles == a.planteles[0]._id &&
                                user.areas == a.grados[0].areas[0]._id
                              )
                                return (
                                  <option value={a._id}>
                                    {a.grados[0].name} {a.name} {a.planteles[0].name}
                                  </option>
                                );
                            })}
                    </Input>
                  </td>
                </tr>
              <tbody>
                {
                commentsData
                      .map((c) => {
                        {
                          return (                      
                          <tr>
                            <td>{c.is_active}</td>
                            <td>{c.codigo_familia}</td>
                            <td>{c.name}</td>
                            <td>
                              {c.apellido_paterno} {c.apellido_materno}
                            </td>
                            <td>{c.plantel} </td>
                            <td>{c.area} </td>
                            <td>
                              {c.clase}
                            </td>
                            <td>
                            <Button color="dany" size="sm" onClick={(e)=>Info(c.name, c.apellido_paterno, c.apellido_materno,
                              c.nombre_papa, c.nombre_mama, c.telefono_papa, c.telefono_mama, c.telefono_casa,
                              c.tipo_sangre, c.contacto_emergencia, c.telefono_emergencia, c.calle, c.numero_ext,
                              c.numero_int, c.colonia, c._id)} id="Detalle">
                              <i class="fas fa-file-alt"></i>
                            </Button>
                            {user.alumnos_create ? (
                              <Button color="info" id="Editar" size="sm" onClick={(e)=>Edit(c._id, c.name, c.genero, c.registro, c.fecha_nacimiento, c.fecha_ingreso,
                              c.telefono, c.observaciones, c.alergias, c.tipo_sangre, c._id, c.codigo_familia, c.apellido_paterno, c.apellido_materno,
                              c.idClase, c.grado, c.clase, c.area, c.plantel)}>
                                <i class="far fa-edit"></i>
                              </Button>
                            ) : (
                              <Button color="info" id="Editar" size="sm" disabled>
                                <i class="far fa-edit"></i>
                              </Button>
                            )}
                            {user.becas_create ? (
                                <Button color="primary" id="Beca" size="sm" onClick={(e)=>Beca(c._id, c.name, c.apellido_paterno, c.apellido_materno,
                                c.beca_importe_col, c.beca_porcentaje_col, c.beca_importe_ins, c.beca_porcentaje_ins)}>
                                  <i class="fas fa-dollar-sign"></i>
                                </Button>
                              ) : (
                                <Button color="primary" id="Beca" size="sm" disabled>
                                  <i class="fas fa-dollar-sign"></i>
                                </Button>
                              )}
                              {user.alumnos_create ? (
                                <Baja
                                  idStatus={c._id}
                                  is_active={c.is_active}
                                  URL_BAJA={process.env.REACT_APP_URL_ALUMNOS}
                                />
                              ) : undefined}
                            </td>
                          </tr>
                          );
                        }
                      })
                }
              </tbody>
            </Table>
            <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
          </div>
        ) : undefined}
          <Modal size="sm" isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <h4>Informacion del Alumno</h4>
              </ModalHeader>
              <ModalBody>
                <h6 style={{textAlign:"center"}}>
                <img
                    loading="lazy"
                    src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/w_200,c_fill,ar_1:1,g_auto,r_max/v${photo}/alumnos/${idInfo}.jpg`}
                    alt=""
                  />
                </h6>
                <Table size="sm" borderless>
                <tbody>
                <tr><td>Nombre</td><td>{nombre} {apellido_paterno} {apellido_materno}</td></tr>
                <tr><td>Nombre Mama</td><td>{nombreMama} {apellido_materno}</td></tr>
                <tr><td>Telefono Mama</td><td>{telefonoMama}</td></tr>
                    <tr><td>Nombre Papa</td><td>{nombrePapa} {apellido_paterno} </td></tr>
                    <tr><td>Telefono Papa</td><td>{telefonoPapa}</td></tr>
                    <tr><td>Telefono Casa</td><td>{telefonoCasa}</td></tr>
                    <tr><td>Tipo de Sangre</td><td>{tipoSangre}</td></tr>
                    <tr><td>Contacto Emergencia</td><td>{contactoEmergencia}</td></tr>
                    <tr><td>Telefono Emergencia</td><td>{telefonoEmergencia}</td></tr>
                    <tr><td>Direccion</td><td>{direccion}</td></tr> 
                    <tr><td>Colonia</td><td>{colonia}</td></tr>
                    </tbody> 
                    </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="success" onClick={toggle}>
                  Cerrar
                </Button>
              </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Informacion de {nombre} {apellido_paterno} {apellido_materno}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                Nombre
              </Label>
              <Input
                type="text"
                placeholder="Nombre del Alumno"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Genero</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={genero}
                required
                onChange={(e) => {
                  setGenero(e.target.value);
                }}
              >
                <option value={genero}>{genero}</option>
                <option value="Hombre">Hombre</option>;
                <option value="Mujer">Mujer</option>;
              </Input>
            </Col>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                No Registro
              </Label>
              <Input
                type="text"
                placeholder="No de Registro"
                value={registro}
                required
                onChange={(e) => {
                  setRegistro(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                Fecha de Nac
              </Label>
              <Input
                type="date"
                placeholder="Fecha de Nacimiento"
                value={fecha_nacimiento}
                required
                onChange={(e) => {
                  setFechaNacimiento(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                Fecha de Ingreso
              </Label>
              <Input
                type="date"
                placeholder="Fecha de Ingreso"
                value={fecha_ingreso}
                required
                onChange={(e) => {
                  setFechaIngreso(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                Telefono
              </Label>
              <Input
                type="number"
                placeholder="Telefono"
                value={telefono}
                required
                onChange={(e) => {
                  setTelefono(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                Alergias
              </Label>
              <Input
                type="text"
                placeholder="Alergias"
                value={alergias}
                required
                onChange={(e) => {
                  setAlergias(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                Tipo de Sangre
              </Label>
              <Input
                type="select"
                value={tipoSangre}
                required
                onChange={(e) => {
                  setTipoSangre(e.target.value);
                }}
              >
                <option value={tipoSangre}>{tipoSangre}</option>
                <option value="A+">A+</option>
                <option value="B+">B+</option>
                <option value="AB+">AB+</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
                <option value="A-">A-</option>
                <option value="B-">B-</option>
                <option value="AB-">AB-</option>
              </Input>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Label className="mr-sm-2">Familia</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedEditFamilia}
                required
                onChange={(e) => {
                  setSelectedEditFamilia(e.target.value);
                }}
              >
                <option value={familiasId}> {familiasCodigo} {apellido_paterno} {apellido_materno}
                </option>
                {familias.map((a) => {
                  return (
                    <option value={a._id}>
                      {a.codigo_familia} {a.apellido_paterno}
                      {a.apellido_materno}
                    </option>
                  );
                })}
              </Input>
            </Col>
            <Col md={4}>
            {user.areas == AREAS_GENERAL && user.planteles == PLANTELES_GENERAL ? (
                <div>
              <Label className="mr-sm-2">Clase</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedEditClase}
                required
                onChange={(i) => {
                  setSelectedEditClase(i.target.value);
                }}
              >
                <option value={claseId}> {grado} {claseName} {areaName} {plantelName}</option>
                {clases
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((p) => {
                  return (
                    <option value={p._id}>
                      {p.grados[0].name} {p.name} {p.grados[0].areas[0].name}{" "}
                      {p.planteles[0].name}{" "}
                    </option>
                  );
                })}
              </Input>
              </div>
              ) : (
                <div>
                  <Label className="mr-sm-2">Clase</Label>
                  <Input
                    type="select"
                    value={selectedEditClase}
                    onChange={(e) => setSelectedEditClase(e.target.value)}
                  >
                <option value={claseId}> {grado} {claseName} {areaName} {plantelName}</option>
                    {clases
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((p) => {
                      if (p.grados[0].areas[0]._id == user.areas && p.planteles[0]._id == user.planteles) {
                  return (
                    <option value={p._id}>
                      {p.grados[0].name} {p.name} {p.grados[0].areas[0].name}{" "}
                      {p.planteles[0].name}{" "}
                    </option>
                  );
                      }
                })}
                  </Input>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Foto
              </Label>
              <Input
                type="file"
                placeholder="Foto"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>        
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={editAlumnos}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalBeca} toggle={toggleBeca}>
        <ModalHeader toggle={toggleBeca}>
          <h4>
            Editar Beca de {nombre} {apellido_paterno} {apellido_materno}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label className="rojoBold">Beca Colegiatura</Label>
          </Row>
          <Row>
            <Col md={6}>
              <Label>Importe $</Label>
              <Input
                type="text"
                placeholder="Importe Beca"
                value={beca_importe_col}
                required
                onChange={(e) => {
                  setBecaImporteCol(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label>Porcentaje %</Label>
              <Input
                type="text"
                placeholder="Importe Beca"
                value={beca_porcentaje_col}
                required
                onChange={(e) => {
                  setBecaPorcentajeCol(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Label className="rojoBold">Beca Inscripcion</Label>
          </Row>
          <Row>
            <Col md={6}>
              <Label>Importe $</Label>
              <Input
                type="text"
                placeholder="Importe Beca"
                value={beca_importe_ins}
                required
                onChange={(e) => {
                  setBecaImporteIns(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label>Porcentaje %</Label>
              <Input
                type="text"
                placeholder="Importe Beca"
                value={beca_porcentaje_ins}
                required
                onChange={(e) => {
                  setBecaPorcentajeIns(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={BecaGuarda}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      </div>
      <br />
      {loader}
    </>
  );
}

export default ListadoAlumnos;
