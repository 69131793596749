import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  ButtonGroup
} from "reactstrap";
import FamiliasInfo from "../../Alumnos/Familias/FamiliasInfo";
import InscripcionesInfo from "./InscripcionesInfo";

function TablaInscripciones(props) {
  const { user } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalSaldo, setModalSaldo] = useState(false);
  const toggleSaldo = () => setModalSaldo(!modalSaldo);

  let idFamilia = props.idFamilia;
  let emailPapa = props.emailPapa;
  let emailMama = props.emailMama;

  return (
    <>
      <td align="center">{props.codigoFamilia}</td>
      <td align="center">{props.apellidoP}</td>
      <td align="center">{props.apellidoM} </td>
      <td align="center">
        {"$" + new Intl.NumberFormat("en-US").format(props.cargos)}{" "}
      </td>
      <td align="center">
        {"-$" + new Intl.NumberFormat("en-US").format(props.abonos)}{" "}
      </td>
      <td align="center">
        {"$" + new Intl.NumberFormat("en-US").format(props.saldo)}{" "}
      </td>

      <td align="center">
      {user.inscripciones_edo_cta ?(
        <Button color="info" size="sm" onClick={toggleSaldo}>
        <i class="fas fa-file-alt"></i>
      </Button>
    ):(
      <Button color="info" size="sm" disabled>
        <i class="fas fa-file-alt"></i>
      </Button>
    )}
      </td>
      <Modal size="xl" isOpen={modalSaldo} toggle={toggleSaldo}>
        <ModalHeader toggle={toggleSaldo}>
        <h4>Estado de Cuenta {props.codigoFamilia} {props.apellidoP} {props.apellidoM}</h4>
        </ModalHeader>
        <ModalBody>
          <InscripcionesInfo
            idFamilia={idFamilia}
            apellidoP={props.apellidoP}
            apellidoM={props.apellidoM}
            codigoFamilia={props.codigoFamilia}
            emailPapa={emailPapa}
            emailMama={emailMama}
         />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleSaldo}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <td align="center">
        <Button color="info" size="sm" onClick={toggle}>
          <i class="fas fa-file-alt"></i>
        </Button>
      </td>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Informacion de la Familia</h4>
        </ModalHeader>
        <ModalBody>
          <FamiliasInfo idFamilias={idFamilia} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaInscripciones;
