import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faFacebook, fas } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faHome,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Password from "../Login/Password";

library.add(
  fab,
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faFacebook,
  faHome,
  faEnvelope,
  faPhoneAlt
);

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);
  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
          {!user.menu_edoCta_Fam && !user.menu_edoCta_Don && !user.menu_cobradores ? (
            <>
            <Navbar color="dark" dark expand="lg">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  {user.menu_escuela ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuEscuelas">
                        ESCUELA
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ESCUELA
                    </NavLink>
                  )}
                  {user.menu_alumnos ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuAlumnos">
                        ALUMNOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ALUMNOS
                    </NavLink>
                  )}

                  {user.menu_academico ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuAcademico">
                        ASISTENCIAS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ASISTENCIAS
                    </NavLink>
                  )}

                  {user.menu_admin ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuAdmin">
                        ADMIN
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ADMIN
                    </NavLink>
                  )}

                  {user.menu_cobranza ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuCobranza">
                        COLEGIATURAS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      COLEGIATURAS
                    </NavLink>
                  )}

                  {user.menu_donativos ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuDonativos">
                        DONATIVOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      DONATIVOS
                    </NavLink>
                  )}

                  {user.menu_nominas ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuNominas">
                        NOMINAS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      NOMINAS
                    </NavLink>
                  )}

                  {user.menu_reportes ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuReportes">
                        REPORTES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      REPORTES
                    </NavLink>
                  )}
                  {user.menu_usuarios ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuUsuarios">
                        USUARIOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      USUARIOS
                    </NavLink>
                  )}
                  {user.menu_usuarios ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuConfiguraciones">
                        CONFIGURACION
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      CONFIGURACION
                    </NavLink>
                  )}
                  {user.menu_logs ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuLogs">
                        LOGS
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggleLogout}>
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>Password</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>
                <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
              </Collapse>
            </Navbar> 
            </>
          ) : (
            <Navbar color="dark" dark expand="md">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                {user.menu_cobradores ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/AbonosDonativosCreate"
                      >
                        Abonos Donativos
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                  {user.menu_edoCta_Fam ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/EdoCtaVistaFamilias"
                      >
                        ESTADO DE CUENTA
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                   {user.menu_edoCta_Fam ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/InfoHijos"
                      >
                        INFO ALUMNOS
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                  {user.menu_edoCta_Don ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/EdoCtaVistaDonadores"
                      >
                        ESTADO DE CUENTA
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggleLogout}>
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>Password</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>
                <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
              </Collapse>
            </Navbar>
          )}
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
