import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Input, Row, Col, Label } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function AgregarClaseMaestro(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_COLABORADORES_CLASES = `${process.env.REACT_APP_URL_COLABORADORES}/${props.idColaborador}`;
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState("");
  const [text, setText] = useState(false);

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let mas = props.clases.map((m) => {
    return m._id;
  });

  function masClase() {
    mas.push(selectedClase);
    axios
      .patch(
        URL_COLABORADORES_CLASES,
        {
          clases: mas,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Agregar Clase a Maestro',
            detalle: `Clase: ${selectedClase} / Maestro: ${props.idColaborador}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <Label className="mr-sm-2">Agregar Clase</Label>
      <Row>
        <Col className="col-sm-7">
          <Input
            id="boton"
            type="select"
            value={selectedClase}
            required
            onChange={(e) => {
              setSelectedClase(e.target.value);
            }}
          >
            <option value="0">Selecciona un Clase</option>
            {clases
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((a) => {
              return <option value={a._id}>{a.grados[0].name} {a.name} {a.grados[0].areas[0].name} {a.planteles[0].name}</option>;
              })}
          </Input>
        </Col>
        <Col className="col-sm-4">
          <Button
           size="sm"
            type="submit"
            className="btn btn-success"
            onClick={masClase}
          >
            Agregar
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default AgregarClaseMaestro;
