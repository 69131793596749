import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import { Badge, Label, Col, Row } from "reactstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

function GraficasInscripciones(props) {
  const { user } = useContext(AuthContext);
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const URL_ABONOS_INSCRIPCIONES =
    process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
    const [inscripciones, setInscripciones] = useState([]);

  useEffect(() => {
    axios
    .get(URL_ABONOS_INSCRIPCIONES, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allInscripciones = response.data;
      setInscripciones(allInscripciones);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

//Array Inscripciones
const arrayInscripciones = inscripciones
.sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
.map((n) => {
  if (user.planteles == PLANTELES_GENERAL &&
    user.areas == AREAS_GENERAL){
  if(
    (props.selectedPlantel == 0 ||
      props.selectedPlantel == n.planteles[0]._id) &&
    (props.selectedArea == 0 ||
      props.selectedArea == n.areas[0]._id) &&
    (props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha)){
  return {
    fecha: n.fecha,
    movimiento: "Inscripcion",
    name: n.familias[0].codigo_familia,
    importe: n.importe,
  }} else return null} else{ if(
    (user.planteles == n.planteles[0]._id) &&
    (user.areas == n.areas[0]._id) &&
    (props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha)
  ){
    return {
      fecha: n.fecha,
      movimiento: "Inscripcion",
      name: n.familias[0].codigo_familia,
      importe: n.importe,
    }} else return null}
  })
  .filter(function (el) {
    return el != null;
  });

  const groupBy = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        fecha: item.fecha,
        importe: 0,
      };
      groups[val].importe += item.importe;
      groups[val].fecha = item.fecha;
      return groups;
    }, []);
  };

  const InscripcionesAgrupado = groupBy(arrayInscripciones, "fecha");
  const dataFinalInscripciones = Object.values(InscripcionesAgrupado);
  

// Grafica Inscripciones
  // Create chart instance
  let chart = am4core.create("chartdivIns", am4charts.XYChart);

  chart.data = dataFinalInscripciones
  
  
  // Set input format for the dates
  chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
  
  // Create axes
  let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  
  // Create series
  let series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = "importe";
  series.dataFields.dateX = "fecha";
  series.tooltipText = "{importe}"
  series.strokeWidth = 2;
  series.minBulletDistance = 15;
  
  // Drop-shaped tooltips
  series.tooltip.background.cornerRadius = 20;
  series.tooltip.background.strokeOpacity = 0;
  series.tooltip.pointerOrientation = "vertical";
  series.tooltip.label.minWidth = 40;
  series.tooltip.label.minHeight = 40;
  series.tooltip.label.textAlign = "middle";
  series.tooltip.label.textValign = "middle";
  
  // Make bullets grow on hover
  let bullet = series.bullets.push(new am4charts.CircleBullet());
  bullet.circle.strokeWidth = 2;
  bullet.circle.radius = 4;
  bullet.circle.fill = am4core.color("#fff");
  
  let bullethover = bullet.states.create("hover");
  bullethover.properties.scale = 1.3;
  
  // Make a panning cursor
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.behavior = "panXY";
  chart.cursor.xAxis = dateAxis;
  chart.cursor.snapToSeries = series;
  
  // Create vertical scrollbar and place it before the value axis
  // chart.scrollbarY = new am4core.Scrollbar();
  // chart.scrollbarY.parent = chart.leftAxesContainer;
  // chart.scrollbarY.toBack();
  
  // Create a horizontal scrollbar with previe and place it underneath the date axis
  chart.scrollbarX = new am4charts.XYChartScrollbar();
  chart.scrollbarX.series.push(series);
  chart.scrollbarX.parent = chart.bottomAxesContainer;
  
  dateAxis.start = 0;
  dateAxis.keepSelection = true;
  chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.filePrefix = "myExport";

  return (
  <>

           <div id="chartdivIns" style={{ width: "100%", height: "400px", paddingTop:"0px" }}></div> 
  </>
  )
}

export default GraficasInscripciones;
