import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import MateriasCreate from "./MateriasCreate";

function TablaMaterias(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_MATERIAS_EDIT = `${process.env.REACT_APP_URL_MATERIAS}/${props._id}`;
  const [name, setName] = useState(props.name);
  const [materias, setMaterias] = useState("");
  const [seccion, setSeccion] = useState("");
  const [selectedSeccion, setSelectedSeccion] = useState(props.seccion);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [text, setText] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    axios
      .get(URL_MATERIAS_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMaterias = response.data;
        setMaterias(allMaterias);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function editMateria(event) {
    event.preventDefault();
    axios
      .patch(
        URL_MATERIAS_EDIT,
        {
          name,
          seccion: selectedSeccion,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Materia',
            detalle: `${name} / ${selectedSeccion}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  return (
    <>
      <td>{props.name}</td>
      <td>{props.seccion}</td>
      <td>
        <Button color="info" size="sm" id="Editar" onClick={toggleEdit}>
          <i class="far fa-edit"></i>
        </Button>
        <Baja
          idStatus={props._id}
          is_active={props.is_active}
          URL_BAJA={process.env.REACT_APP_URL_MATERIAS}
        />
      </td>
      <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar Materia</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Label for="exampleEmail" className="mr-sm-2">
            Seccion
          </Label>
          <Input
            type="select"
            placeholder="Seccion"
            value={selectedSeccion}
            required
            onChange={(e) => {
              setSelectedSeccion(e.target.value);
            }}
          >
            <option value="0" disabled>
              {props.seccion}
            </option>
            <option value="Español">Español</option>
            <option value="Hebreo">Hebreo</option>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editMateria}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaMaterias;
