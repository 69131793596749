import React, { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import axios from "axios";
import AgregarMateriaMaestro from "./AgregarMateriaMaestro";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import MateriasBajaMaestros from "./MateriasBajaMaestros";

function MateriasInfoMaestro(props) {
  let idColaborador = props.idColaborador;
  const URL_COLABORADORES_MATERIAS = `${process.env.REACT_APP_URL_COLABORADORES}/${idColaborador}`;
  const [materias, setMaterias] = useState([]);
  const [selectedMateria, setSelectedMateria] = useState("");
  const [text, setText] = useState(false);

  useEffect(() => {
    axios
      .get(URL_COLABORADORES_MATERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMaterias = response.data.materias;
        setMaterias(allMaterias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  


  return (
    <>
      <div className="card container">
        <Table size="sm" borderless>
          <tbody>
            {materias.map((a) => {
              return (
                <table>
                  <td>
                    <MateriasBajaMaestros idColaborador={idColaborador} idMateria={a._id} materias={materias} />
                  </td>
                  
                  <td>{a.name}</td>
                </table>
              );
            })}
          </tbody>
        </Table>
      </div>
      <AgregarMateriaMaestro idColaborador={idColaborador} materias={materias} />
    </>
  );
}

export default MateriasInfoMaestro;
