import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Label,
  Input,
  Row,
  Col,
  Button,
  ButtonGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import moment from "moment";

function AsistenciasCreate() {
  const { user } = useContext(AuthContext);
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_ASISTENCIAS = process.env.REACT_APP_URL_ASISTENCIAS;
  const [alumnos, setAlumnos] = useState([]);
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(0);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(0);
 
  let date = new Date();
  let fecha = moment(date).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {
    if (selectedClase) {
      axios
        .get(`${URL_ALUMNOS}Asistencia/${selectedClase}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allAlumnos = res.data;
          let arrayAlumnos = allAlumnos.map((a) => {
            return {
              _id: a._id,
              alumno:
                a.name +
                " " +
                a.familias[0].apellido_paterno +
                " " +
                a.familias[0].apellido_materno,
              status: "No"
            };
          });
          setAlumnos(arrayAlumnos);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedClase]);

  function setStatus(id, status) {
    const newAlumnos = alumnos.map((a) => {
      if (id == a._id) {
        a.status = status;
      }
      return a;
    });
    setAlumnos(newAlumnos);
  }

  function saveAsistencia() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el abono",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
       let  totalAlumnos = alumnos.length
      alumnos.map((a)=>{
        axios.post(
          `${URL_ASISTENCIAS}`,
          {
            fecha,
            alumnos:a._id,
            status: a.status,
            clases: selectedClase
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        )      
        .then(() => {
          totalAlumnos = totalAlumnos -1
          if(totalAlumnos==0){
            Swal.fire("Good job!", "Creado con exito", "success");

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      })
      }
    })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card ">
        {user.menu_academico && user.menu_listados ? (
          <div>
            <h3 align="center">Asistencia de Alumnos</h3>
            <br />
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Grados</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedGrado}
                  required
                  onChange={(e) => setSelectedGrado(e.target.value)}
                >
                  <option value="0">Selecciona una Grado</option>
                  {grados
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Clases</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedClase}
                  required
                  onChange={(e) => setSelectedClase(e.target.value)}
                >
                  <option value="0">Selecciona una Clase</option>
                  {clases.map((a) => {
                    if (
                      selectedGrado == 0 ||
                      selectedGrado == a.grados[0]._id
                    ) {
                      return (
                        <option value={a._id}>
                          {a.grados[0].name} {a.name}
                        </option>
                      );
                    }
                  })}
                </Input>
              </Col>
            </Row>
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Asistencia</th>
                  </tr>
                </thead>
                <tbody>
                  {alumnos
                  .sort((a, b) => (a.alumno > b.alumno ? 1 : -1))
                  .map((a) => {
                    return (
                      <tr>
                        <td> {a.alumno}</td>
                        <td>
                          <Input
                            size="sm"
                            type="select"
                            value={a.status}
                            required
                            onChange={(e) => setStatus(a._id, e.target.value)}
                          >
                            <option value="No">No</option>
                            <option value="Si">Si</option>
                          </Input>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <br />
              </Table>
              <Button
                onClick={e=>{saveAsistencia()}}
              >
                Guardar
              </Button>
              <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
          </div>
          
        ) : undefined}
      </div>
    </>
  );
}

export default AsistenciasCreate;
