import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Label,
  Input,
  Row,
  Col,
  Button,
  ButtonGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import TablaListas from "./TablaListas";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { encode } from "base64-arraybuffer";

function Listas() {
  const { user } = useContext(AuthContext);
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_GRADOS = process.env.REACT_APP_URL_GRADOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const [alumnos, setAlumnos] = useState([]);
  const [grados, setGrados] = useState([]);
  const [selectedGrado, setSelectedGrado] = useState(0);
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState(0);
  const [selectedClaseDestino, setSelectedClaseDestino] = useState(0);
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(0);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');

  const [modalDestino, setModalDestino] = useState(false);
  const toggleDestino = () => setModalDestino(!modalDestino);

  useEffect(() => {
    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClases = res.data;
        setClases(allClases);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRADOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrados = res.data;
        setGrados(allGrados);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAlumnos = res.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function PDFTabla() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    num = 0;
    const data = alumnos.map((a) => {
      if (a.is_active == "Si") {
        if (
          (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
          (selectedPlantel == 0 ||
            selectedPlantel == a.clases[0].planteles[0]._id) &&
          (selectedArea == 0 ||
            selectedArea == a.clases[0].grados[0].areas[0]._id) &&
          (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
          (selectedClase == 0 || selectedClase == a.clases[0]._id)
        ) {
          num = num + 1;
          return [
            num,
            [
              a.familias[0].apellido_paterno +
                " " +
                a.familias[0].apellido_materno +
                " " +
                a.name,
            ],
            a.clases[0].planteles[0].name,
            a.clases[0].grados[0].areas[0].name,
            a.clases[0].grados[0].name,
            a.clases[0].name,
          ];
        } else if(
          (user.planteles == a.clases[0].planteles[0]._id) &&
          (user.areas == a.clases[0].grados[0].areas[0]._id) &&
          (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
          (selectedClase == 0 || selectedClase == a.clases[0]._id)
        ){
          num = num + 1;
          return [
            num,
            [
              a.familias[0].apellido_paterno +
                " " +
                a.familias[0].apellido_materno +
                " " +
                a.name,
            ],
            a.clases[0].planteles[0].name,
            a.clases[0].grados[0].areas[0].name,
            a.clases[0].grados[0].name,
            a.clases[0].name,
          ];
        }
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 160, 3, 30, 10);
    doc.text("Listado de Alumnos", 10, 10);
    doc.autoTable({
      head: [["#", "Nombre", "Plantel", "Area", "Grado", "Clase"]],
      body: dataPDFLimpia,
    });
    doc.save("Alumnos.pdf");
  }
  function excel() {
    num = 0;
    const dataExcel = alumnos.map((a) => {
      if (a.is_active == "Si") {
        if (
          (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
          (selectedPlantel == 0 ||
            selectedPlantel == a.clases[0].planteles[0]._id) &&
          (selectedArea == 0 ||
            selectedArea == a.clases[0].grados[0].areas[0]._id) &&
          (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
          (selectedClase == 0 || selectedClase == a.clases[0]._id)
        ) {
          num = num + 1;
          return {
            Numero: num,
            Nombre:
              a.familias[0].apellido_paterno +
              " " +
              a.familias[0].apellido_materno +
              " " +
              a.name,
            Plantel: a.clases[0].planteles[0].name,
            Area: a.clases[0].grados[0].areas[0].name,
            Grado: a.clases[0].grados[0].name,
            Clase: a.clases[0].name,
          };
        } else if(
          (user.planteles == a.clases[0].planteles[0]._id) &&
          (user.areas == a.clases[0].grados[0].areas[0]._id) &&
          (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
          (selectedClase == 0 || selectedClase == a.clases[0]._id)
        ){
          num = num + 1;
          return {
            Numero: num,
            Nombre:
              a.familias[0].apellido_paterno +
              " " +
              a.familias[0].apellido_materno +
              " " +
              a.name,
            Plantel: a.clases[0].planteles[0].name,
            Area: a.clases[0].grados[0].areas[0].name,
            Grado: a.clases[0].grados[0].name,
            Clase: a.clases[0].name,
          };
        }
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAlumnos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAlumnos",
        sheetFilter: ["Numero", "Nombre", "Plantel", "Area", "Grado", "Clase"],
        sheetHeader: ["Numero", "Nombre", "Plantel", "Area", "Grado", "Clase"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function cambiarClase() {
    if (selectedClase != 0) {
      alumnos.map((a) => {
        if (
          (selectedPlantel == 0 ||
            selectedPlantel == a.clases[0].planteles[0]._id) &&
          (selectedArea == 0 ||
            selectedArea == a.clases[0].grados[0].areas[0]._id) &&
          (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
          (selectedClase == 0 || selectedClase == a.clases[0]._id)
        ) {
          const URL_ALUMNOS_EDIT = `${process.env.REACT_APP_URL_ALUMNOS}/${a._id}`;
          axios
            .patch(
              URL_ALUMNOS_EDIT,
              {
                clases: selectedClaseDestino,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              });
              console.log(error);
            });
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Selecciona una Clase!",
      });
    }
  }

function enviaMail(){
  const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    num = 0;
    const data = alumnos.map((a) => {
      if (a.is_active == "Si") {
        if (
          (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) &&
          (selectedPlantel == 0 ||
            selectedPlantel == a.clases[0].planteles[0]._id) &&
          (selectedArea == 0 ||
            selectedArea == a.clases[0].grados[0].areas[0]._id) &&
          (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
          (selectedClase == 0 || selectedClase == a.clases[0]._id)
        ) {
          num = num + 1;
          return [
            num,
            [
              a.familias[0].apellido_paterno +
                " " +
                a.familias[0].apellido_materno +
                " " +
                a.name,
            ],
            a.clases[0].planteles[0].name,
            a.clases[0].grados[0].areas[0].name,
            a.clases[0].grados[0].name,
            a.clases[0].name,
          ];
        } else if(
          (user.planteles == a.clases[0].planteles[0]._id) &&
          (user.areas == a.clases[0].grados[0].areas[0]._id) &&
          (selectedGrado == 0 || selectedGrado == a.clases[0].grados[0]._id) &&
          (selectedClase == 0 || selectedClase == a.clases[0]._id)
        ){
          num = num + 1;
          return [
            num,
            [
              a.familias[0].apellido_paterno +
                " " +
                a.familias[0].apellido_materno +
                " " +
                a.name,
            ],
            a.clases[0].planteles[0].name,
            a.clases[0].grados[0].areas[0].name,
            a.clases[0].grados[0].name,
            a.clases[0].name,
          ];
        }
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 160, 3, 30, 10);
    doc.text("Listado de Alumnos", 10, 10);
    doc.autoTable({
      head: [["#", "Nombre", "Plantel", "Area", "Grado", "Clase"]],
      body: dataPDFLimpia,
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Lista de Alumnos',
         email: mailTo,
         fileName: 'ListaAlumnos.pdf',
         att: base64File,
         text:
         `<!DOCTYPE html>
         <html>
           <head>
             <style>                                
               .img-container {
                               display: block;
                               margin-left: auto;
                               margin-right: auto;
                               height: 90px;
                               width: auto;
                             }
             </style>
           </head>
           <body>
             <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
             <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
             <h3>Hola.</h3>
             <h3>Adjunto encontraras la lista de Alumnos.</h3>
             <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
             Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
           </body>
         </html>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
      toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
     })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
}

  let num = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card container">
        {user.menu_academico && user.menu_listados ? (
          <div className="card-body">
            <Row>
              <Col md={8}>
                <Button
                  size="sm"
                  href="/MenuAcademico"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Col>
              <Col md={4}>
                <ButtonGroup id="logoutBoton">
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    onClick={PDFTabla}
                  >
                    PDF <i class="far fa-file-pdf"></i>
                  </Button>
                  <Button size="sm" 
                  className="btn" 
                  color="info" 
                  onClick={toggleMail}>
                    eMail <i class="fas fa-at"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="primary"
                    onClick={excel}
                  >
                    Excel <i class="far fa-file-excel"></i>
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Lista de Alumnos</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>

            <h3 align="center">Lista de Alumnos</h3>

            {/* Cambio de Clase */}
        {selectedClase != 0 ?(
            <Button
              color="info"
              size="sm"
              id="logoutBoton"
              onClick={toggleDestino}
            >
              Cambio de Calse
            </Button>
            ):(
              <Button
              color="info"
              size="sm"
              id="logoutBoton"
              disabled
            >
              Selecciona una Calse
            </Button>
            )}
            

            <Modal size="sm" isOpen={modalDestino} toggle={toggleDestino}>
              <ModalHeader toggle={toggleDestino}>
                <h4>Editar Clase</h4>
              </ModalHeader>
              <ModalBody>
                <Label className="mr-sm-2">Clases</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedClaseDestino}
                  required
                  onChange={(e) => setSelectedClaseDestino(e.target.value)}
                >
                  <option value="0">Selecciona una Clase</option>
                  {clases
                    .sort(
                      (a, b) =>
                        a.grados[0].name - b.grados[0].name ||
                        b.name - a.name ||
                        a.planteles[0].name - b.planteles[0].name
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.grados[0].name} {a.name}{" "}
                          {a.grados[0].areas[0].name} {a.planteles[0].name}
                        </option>
                      );
                    })}
                </Input>
              </ModalBody>
              <ModalFooter>
                <Button color="success" onClick={cambiarClase}>
                  Guardar
                </Button>
              </ModalFooter>
            </Modal>
            <br />
            <Row>
            {user.planteles == PLANTELES_GENERAL ? (
                    <Col md={3}>
                      <Label className="mr-sm-2">Planteles</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedPlantel}
                        onChange={(e) => setSelectedPlantel(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {planteles
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != PLANTELES_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </Col>
                  ) : undefined}
             {user.areas == AREAS_GENERAL ? (
                    <Col md={3}>
                      <Label className="mr-sm-2">Areas</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedArea}
                        onChange={(e) => setSelectedArea(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {areas
                          .sort((a, b) => a.name - b.name)
                          .map((a) => {
                            if (a._id != AREAS_GENERAL) {
                              return <option value={a._id}>{a.name}</option>;
                            }
                          })}
                      </Input>
                      </Col>
                  ) : undefined}
              <Col md={3}>
                <Label className="mr-sm-2">Grados</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedGrado}
                  required
                  onChange={(e) => setSelectedGrado(e.target.value)}
                >
                  <option value="0">Selecciona una Grado</option>
                  {user.areas == AREAS_GENERAL ?
                  grados
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (selectedArea == 0 || selectedArea == a.areas[0]._id) {
                        return (
                          <option value={a._id}>
                            {a.name} {a.areas[0].name}
                          </option>
                        );
                      }
                    }):
                    grados
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      if (user.areas == a.areas[0]._id) {
                        return (
                          <option value={a._id}>
                            {a.name} {a.areas[0].name}
                          </option>
                        );
                      }
                    })
                    }
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Clases</Label>
                <Input
                  size="sm"
                  type="select"
                  value={selectedClase}
                  required
                  onChange={(e) => setSelectedClase(e.target.value)}
                >
                  <option value="0">Selecciona una Clase</option>
                  {user.planteles == PLANTELES_GENERAL &&
                      user.areas == AREAS_GENERAL ?
                      clases
                    .sort(
                      (a, b) =>
                        a.grados[0].name - b.grados[0].name ||
                        b.name - a.name ||
                        a.planteles[0].name - b.planteles[0].name
                    )
                    .map((a) => {
                      if (
                        (selectedPlantel == 0 ||
                          selectedPlantel == a.planteles[0]._id) &&
                        (selectedArea == 0 ||
                          selectedArea == a.grados[0].areas[0]._id) &&
                        (selectedGrado == 0 || selectedGrado == a.grados[0]._id)
                      ) {
                        return (
                          <option value={a._id}>
                            {a.grados[0].name} {a.name}{" "}
                            {a.grados[0].areas[0].name} {a.planteles[0].name}
                          </option>
                        );
                      }
                    }):
                    clases
                    .sort(
                      (a, b) =>
                        a.grados[0].name - b.grados[0].name ||
                        b.name - a.name ||
                        a.planteles[0].name - b.planteles[0].name
                    )
                    .map((a) => {
                      if (
                        (user.planteles == a.planteles[0]._id) &&
                        (user.areas == a.grados[0].areas[0]._id) &&
                        (selectedGrado == 0 || selectedGrado == a.grados[0]._id)
                      ) {
                        return (
                          <option value={a._id}>
                            {a.grados[0].name} {a.name}{" "}
                            {a.grados[0].areas[0].name} {a.planteles[0].name}
                          </option>
                        );
                      }
                    })}
                </Input>
              </Col>
            </Row>
            <div className="container col-10">
              <br />
              <br />
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Plantel</th>
                    <th>Area</th>
                    <th>Grado</th>
                    <th>Clase</th>
                    <th>Cambio</th>
                  </tr>
                </thead>
                <tbody>
                  {user.planteles == PLANTELES_GENERAL &&
                      user.areas == AREAS_GENERAL ?
                  alumnos
                    .sort((a, b) =>
                      a.clases[0].planteles[0].name >
                        b.clases[0].planteles[0].name ||
                      a.clases[0].grados[0].areas[0].name >
                        b.clases[0].grados[0].areas[0].name
                        ? 1
                        : -1
                    )
                    .map((a) => {
                      if (a.is_active == "Si") {
                        if (
                          (selectedPlantel == 0 ||
                            selectedPlantel == a.clases[0].planteles[0]._id) &&
                          (selectedArea == 0 ||
                            selectedArea ==
                              a.clases[0].grados[0].areas[0]._id) &&
                          (selectedGrado == 0 ||
                            selectedGrado == a.clases[0].grados[0]._id) &&
                          (selectedClase == 0 ||
                            selectedClase == a.clases[0]._id)
                        ) {
                          num = num + 1;
                          return (
                            <tr>
                              <TablaListas
                                num={num}
                                apellido_paterno={
                                  a.familias[0].apellido_paterno
                                }
                                apellido_materno={
                                  a.familias[0].apellido_materno
                                }
                                name={a.name}
                                plantel={a.clases[0].planteles[0].name}
                                area={a.clases[0].grados[0].areas[0].name}
                                grado={a.clases[0].grados[0].name}
                                clase={a.clases[0].name}
                                idClase={a.clases[0]._id}
                                idAlumno={a._id}
                              />
                            </tr>
                          );
                        }
                      }
                    }):
                    alumnos
                    .sort((a, b) =>
                      a.clases[0].planteles[0].name >
                        b.clases[0].planteles[0].name ||
                      a.clases[0].grados[0].areas[0].name >
                        b.clases[0].grados[0].areas[0].name
                        ? 1
                        : -1
                    )
                    .map((a) => {
                      if (a.is_active == "Si") {
                        if (
                          (user.planteles == a.clases[0].planteles[0]._id) &&
                          (user.areas == a.clases[0].grados[0].areas[0]._id) &&
                          (selectedGrado == 0 ||
                            selectedGrado == a.clases[0].grados[0]._id) &&
                          (selectedClase == 0 ||
                            selectedClase == a.clases[0]._id)
                        ) {
                          num = num + 1;
                          return (
                            <tr>
                              <TablaListas
                                num={num}
                                apellido_paterno={
                                  a.familias[0].apellido_paterno
                                }
                                apellido_materno={
                                  a.familias[0].apellido_materno
                                }
                                name={a.name}
                                plantel={a.clases[0].planteles[0].name}
                                area={a.clases[0].grados[0].areas[0].name}
                                grado={a.clases[0].grados[0].name}
                                clase={a.clases[0].name}
                                idClase={a.clases[0]._id}
                                idAlumno={a._id}
                              />
                            </tr>
                          );
                        }
                      }
                    })
                    }
                </tbody>
                <br />
              </Table>
            </div>
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default Listas;
