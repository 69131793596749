import React, {  useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button, Label } from "reactstrap";
import Footer from '../../layout/Footer/Footer'
import Header from "../../layout/Header/Header";

function BotonesAcademico() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      {user.menu_academico ?(
      <div className="container">
        <br />
        <br />
        <Row>
          {/* <Col md={4} align="center">
          {user.menu_calificaciones ?(
            <Button href="/Calificaciones" className="botonesMenu" color="success">
            <i class="fas fa-graduation-cap fa-7x"></i>
              <br />
              <br />
              Calificaciones
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-graduation-cap fa-7x"></i>
                <br />
                <br />
                Calificaciones
              </Button> }
            </Col>
            <Col md={4} align="center">
              {user.menu_info_alumno ?(
            <Button href="/InfoAlumnos" className="botonesMenu" color="success">
            <i class="fas fa-file-alt fa-7x"></i>
              <br />
              <br />
              Info Alumno
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-file-alt fa-7x"></i>
                <br />
                <br />
                Info Alumno
              </Button> }
          </Col>
            <Col md={4} align="center">
              {user.menu_calificaciones ?(
            <Button href="/" className="botonesMenu" color="success">
            <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Calificaciones
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
                <br />
                <br />
                Calificaciones
              </Button> }
          </Col>
        </Row>
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menu_maestros ?(
            <Button href="/Maestros" className="botonesMenu" color="success">
            <i class="fas fa-chalkboard-teacher fa-7x"></i>
              <br />
              <br />
              Maestros
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-chalkboard-teacher fa-7x"></i>
                <br />
                <br />
                Maestros
              </Button> }
          </Col>
        <Col md={4} align="center">
          {user.menu_listados ?(
            <Button href="/Listas" className="botonesMenu" color="success">
            <i class="fas fa-list-ol fa-7x"></i>
              <br />
              <br />
              Listados
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-list-ol fa-7x"></i>
                <br />
                <br />
                Listados
              </Button> }
          </Col> */}
          <Col md={4} align="center">
          {user.menu_listados ?(
            <Button href="/AsistenciasCreate" className="botonesMenu" color="success">
            <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Registrar <br/> Asistencia
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
                <br />
                <br />
                Registrar <br/> Asistencia
              </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_listados ?(
            <Button href="/ListadoAsistencias" className="botonesMenu" color="success">
            <i class="fas fa-list-ol fa-7x"></i>
              <br />
              <br />
              Reporte <br/> Asistencia
            </Button>
              ): <Button disabled className="botonesMenu" color="success">
              <i class="fas fa-list-ol fa-7x"></i>
                <br />
                <br />
                Reporte <br/> Asistencia
              </Button> }
          </Col>
        </Row>
      </div>
       ): undefined } 
      
    </>
  );
}

export default BotonesAcademico;
