import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Baja from "../../Baja";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import DonadoresInfo from "../Donadores/DonadoresInfo";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function ListadoDonativosRecurrentes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DONATIVOS_RECURRENTES =
    process.env.REACT_APP_URL_DONATIVOS_RECURRENTES;
  const URL_DONATIVOS = process.env.REACT_APP_URL_DONATIVOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_DONADORES = process.env.REACT_APP_URL_DONADORES;
  const URL_CONCEPTOS_DON = process.env.REACT_APP_URL_CONCEPTOS_DON;
  const URL_EVENTOS_DON = process.env.REACT_APP_URL_EVENTOS_DON;
  const URL_SALAS_DON = process.env.REACT_APP_URL_SALAS_DON;
  const URL_INSTITUCIONES_DON = process.env.REACT_APP_URL_INSTITUCIONES_DON;

  const [frecuencia, setFrecuencia] = useState("");
  const [selectedFrecuencia, setSelectedFrecuencia] = useState("");
  const [selectedActivo, setSelectedActivo] = useState("");
  const [fecha, setFecha] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [donadores, setDonadores] = useState([]);
  const [conceptosDon, setConceptosDon] = useState([]);
  const [eventosDon, setEventosDon] = useState([]);
  const [salasDon, setSalasDon] = useState([]);
  const [institucionesDon, setInstitucionesDon] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalGenera, setModalGenera] = useState(false);
  const toggleGenera = () => setModalGenera(!modalGenera);
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalAumento, setModalAumento] = useState(false);
  const toggleAumento = () => setModalAumento(!modalAumento);
  const [idInfo, setIdInfo] = useState("");
  const [idDonativo, setidDonativo] = useState();
  const [nuevo_importe, setNuevoImporte] = useState();
  const [fecha_inicio, setFechaInicio] = useState();
  const [fecha_fin, setFechaFin] = useState("");
  const [fecha_aumento, setFechaAumento] = useState();
  const [ultimo_aumento, setUltimoAumento] = useState();
  const [frecuenciaEdit, setFrecuenciaEdit] = useState();
  const [descripcionEdit, setDescripcionEdit] = useState();
  const [importe_anterior, setImporteAnterior] = useState();
  const [importe_actual, setImporteActual] = useState();
  const [selectedDonador, setSelectedDonador] = useState();
  const [selectedDonadorName, setSelectedDonadorName] = useState();
  const [selectedConceptosDon, setSelectedConceptosDon] = useState();
  const [selectedEventosDon, setSelectedEventosDon] = useState();
  const [selectedSalasDon, setSelectedSalasDon] = useState();
  const [selectedInstitucionesDon, setSelectedInstitucionesDon] = useState();

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("1900-01-01");
  const [selectedFechaFin, setSelectedFechaFin] = useState("3000-01-01");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [mes1, setMes1] = useState("0");
  const [mes2, setMes2] = useState("0");

  //--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });

 const [activo, setActivo] = useState("");

const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_DONATIVOS_RECURRENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonativosRecurrentes = response.data;
        // setDonativosRecurrentes(allDonativosRecurrentes);
        let arrayTabla = allDonativosRecurrentes
        .sort((a, b) => (a.ultimo_aumento > b.ultimo_aumento ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            idDonador: a.donadores[0]._id,
            donador: a.donadores[0].nombre + " " + a.donadores[0].apellido,
            telefono: a.donadores[0].telefono,
            fecha_inicio: a.fecha_inicio,
            fecha_fin: a.fecha_fin,
            ultimo_aumento: a.ultimo_aumento,
            frecuencia: a.frecuencia,
            concepto: a.conceptosDon[0].name,
            importe_actual: a.importe_actual,
            is_active: a.is_active,
            contacto: a.donadores[0].contacto[0].nombre + " " + a.donadores[0].contacto[0].apellido,
            idContacto: a.donadores[0].contacto[0]._id,
            colaborador: a.donadores[0].colaboradores[0].nombre + " " + a.donadores[0].colaboradores[0].apellido,
            idColaborador: a.donadores[0].colaboradores[0]._id,
            idSala: a.salasDon[0]._id,
            idEvento: a.eventosDon[0]._id,
            idInstitucion: a.institucionesDon[0]._id,
            idConcepto: a.conceptosDon[0]._id,
            conceptosDon: a.conceptosDon[0].name,
            ejecutivo: a.donadores[0].ejecutivo[0].nombre + " " + a.donadores[0].ejecutivo[0].apellido,
            idEjecutivo: a.donadores[0].ejecutivo[0]._id,
          };
        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DONADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDonadores = res.data;
        setDonadores(allDonadores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CONCEPTOS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosDon = res.data;
        setConceptosDon(allConceptosDon);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_EVENTOS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allEventosDon = res.data;
        setEventosDon(allEventosDon);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_SALAS_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSalasDon = res.data;
        setSalasDon(allSalasDon);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_INSTITUCIONES_DON, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInstitucionesDon = res.data;
        setInstitucionesDon(allInstitucionesDon);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // PDF
  let totalImporte = 0;

  let tablaImporte = 0;
  let cantidad = 0;

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.idContacto) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)&&
        (selectedFechaInicio <= a.fecha_fin)&&
        (selectedFechaFin >= a.fecha_fin)
      ) {
        const importe =
          "$" + new Intl.NumberFormat("en-US").format(a.importe_actual);
        totalImporte = totalImporte + a.importe_actual;
        return [
          a.donador,
          a.telefono,
          a.fecha_inicio,
          a.frecuencia,
          a.conceptosDon,
          a.colaborador,
          a.contacto,
          importe,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Donativos Recurrentes`, 20, 25);
    doc.autoTable({
      head: [
        [
          "Donador",
          "Telefono",
          "Fecha Inicio",
          "Frecuencia",
          "Concepto",
          "Cobrador",
          "Contacto",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalImporte),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`DonativosRecurrentes.pdf`);
  }

  function generaDonativosRecurrentes() {
    toggleGenera();
    Swal.fire({
      title: "Estas Seguro?",
      text: "Se generara un cargo a cada donador",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, generar cargo",
    }).then((result) => {
      if (result.value) {
        toggleProgreso();
        let totalCargos = comments.length;
        comments.map((a) => {
          if (a.is_active == "Si" && frecuencia == a.frecuencia) {
            axios
              .post(
                URL_DONATIVOS,
                {
                  recurrente: "Si",
                  fecha,
                  descripcion,
                  importe: a.importe_actual,
                  donadores: a.idDonador,
                  salasDon: a.idSala,
                  eventosDon: a.idEvento,
                  institucionesDon: a.idInstitucion,
                  conceptosDon: a.idConcepto,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalCargos = totalCargos - 1;
                if (totalCargos == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalCargos = totalCargos - 1;
          }
        });
      }
    });
  }
  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.idContacto) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)&&
        (selectedFechaInicio <= a.fecha_fin)&&
        (selectedFechaFin >= a.fecha_fin)
      ) {
        return {
          Donador: a.donador,
          Telefono: a.telefono,
          Inicio: a.fecha_inicio,
          Frecuencia: a.frecuencia,
          Concepto: a.conceptosDon,
          Cobrador: a.colaborador,
          Ejecutivo: a.ejecutivo,
          Contacto: a.contacto,
          Importe: a.importe_actual,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDonativosRecurrentes";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDonativosRecurrentes",
        sheetFilter: [
          "Donador",
          "Telefono",
          "Inicio",
          "Frecuencia",
          "Concepto",
          "Cobrador",
          "Ejecutivo",
          "Contacto",
          "Importe",
        ],
        sheetHeader: [
          "Donador",
          "Telefono",
          "Inicio",
          "Frecuencia",
          "Concepto",
          "Cobrador",
          "Ejecutivo",
          "Contacto",
          "Importe",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.idContacto) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)&&
        (selectedFechaInicio <= a.fecha_fin)&&
        (selectedFechaFin >= a.fecha_fin)
      ) {
        const importe =
          "$" + new Intl.NumberFormat("en-US").format(a.importe_actual);
        totalImporte = totalImporte + a.importe_actual;
        return [
          a.donador,
          a.telefono,
          a.fecha_inicio,
          a.frecuencia,
          a.conceptosDon,
          a.colaborador,
          a.contacto,
          importe,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Donativos Recurrentes`, 20, 25);
    doc.autoTable({
      head: [
        [
          "Donador",
          "Telefono",
          "Fecha Inicio",
          "Frecuencia",
          "Concepto",
          "Cobrador",
          "Contacto",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalImporte),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
      var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Resumen Donativos Recurrentes",
          email: mailTo,
          fileName: "Resumen-Donativos-Recurrentes.pdf",
          att: base64File,
          text: `<!DOCTYPE html>
          <html>
            <head>
              <style>                                
                .img-container {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                height: 90px;
                                width: auto;
                              }
              </style>
            </head>
            <body>
              <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
              <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
              <h3>Hola,</h3>
              <h3>Adjunto encontraras el detalle de donativos recurrentes.</h3>
              <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
              Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
            </body>
          </html>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function Info(id) {
    setIdInfo(id);
    toggle();
  }

  function jalaInfoEdit(idEdit) {
    const URL_DONATIVOS_RECURRENTES_EDIT = `${process.env.REACT_APP_URL_DONATIVOS_RECURRENTES}/${idEdit}`;
    axios
      .get(URL_DONATIVOS_RECURRENTES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setSelectedDonador(response.data.donadores[0]._id);
        setSelectedDonadorName(
          response.data.donadores[0].nombre +
            " " +
            response.data.donadores[0].apellido
        );
        setFechaInicio(response.data.fecha_inicio);
        setFechaFin(response.data.fecha_fin);
        setSelectedSalasDon(response.data.salasDon[0]._id);
        setSelectedConceptosDon(response.data.conceptosDon[0]._id);
        setSelectedEventosDon(response.data.eventosDon[0]._id);
        setSelectedInstitucionesDon(response.data.institucionesDon[0]._id);
        setDescripcionEdit(response.data.descripcion);
        setidDonativo(response.data._id);
        toggleEdit();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editDonativoRecurrente(event) {
    event.preventDefault();
    const URL_DONATIVOS_RECURRENTES_EDIT = `${process.env.REACT_APP_URL_DONATIVOS_RECURRENTES}/${idDonativo}`;
    axios
      .patch(
        URL_DONATIVOS_RECURRENTES_EDIT,
        {
          fecha_inicio,
          fecha_fin,
          descripcion: descripcionEdit,
          donadores: selectedDonador,
          salasDon: selectedSalasDon,
          eventosDon: selectedEventosDon,
          institucionesDon: selectedInstitucionesDon,
          conceptosDon: selectedConceptosDon,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Donativo Recurrente",
            detalle: `${selectedDonador}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfoAumento(idEdit) {
    const URL_DONATIVOS_RECURRENTES_EDIT = `${process.env.REACT_APP_URL_DONATIVOS_RECURRENTES}/${idEdit}`;
    axios
      .get(URL_DONATIVOS_RECURRENTES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setSelectedDonadorName(
          response.data.donadores[0].nombre +
            " " +
            response.data.donadores[0].apellido
        );
        setFechaInicio(response.data.fecha_inicio);
        setidDonativo(response.data._id);
        setImporteAnterior(response.data.importe_anterior);
        setImporteActual(response.data.importe_actual);
        setUltimoAumento(response.data.ultimo_aumento);
        setFrecuenciaEdit(response.data.frecuencia);
        setMes1(response.data.mes1)
        setMes2(response.data.mes2)
        toggleAumento();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function aumentoDonativoRecurrente(event) {
    event.preventDefault();
    const URL_DONATIVOS_RECURRENTES_EDIT = `${process.env.REACT_APP_URL_DONATIVOS_RECURRENTES}/${idDonativo}`;
    axios
      .patch(
        URL_DONATIVOS_RECURRENTES_EDIT,
        {
          frecuencia: frecuenciaEdit,
          importe_actual: nuevo_importe,
          importe_anterior: importe_actual,
          ultimo_aumento: fecha_aumento,
          mes1,
          mes2
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleAumento();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Donador", field: "donador", sortable: true },
    { name: "Fecha Inicio", field: "fecha_inicio", sortable: true },
    { name: "Ultimo Aumento", field: "ultimo_aumento", sortable: true },
    { name: "Fecha Fin", field: "fecha_fin", sortable: true },
    { name: "Frecuencia", field: "frecuencia", sortable: true },
    { name: "Concepto", field: "concepto", sortable: true },
    { name: "Contacto", field: "contacto", sortable: true },
    { name: "Cobrador", field: "colaborador", sortable: true },
    { name: "Importe", field: "importe_actual", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

const commentsData = useMemo(() => {
  let computedComments = comments;

  if (search) {
    computedComments = computedComments.filter(
      (comment) =>
      comment.donador.toLowerCase().includes(search.toLowerCase()) ||
      comment.fecha_inicio.toLowerCase().includes(search.toLowerCase()) ||
      comment.ultimo_aumento.toLowerCase().includes(search.toLowerCase()) ||
      comment.fecha_fin.toLowerCase().includes(search.toLowerCase()) ||
      comment.frecuencia.toLowerCase().includes(search.toLowerCase()) ||
      comment.concepto.toLowerCase().includes(search.toLowerCase()) ||
      comment.contacto.toLowerCase().includes(search.toLowerCase()) ||
      comment.colaborador.toLowerCase().includes(search.toLowerCase()) ||
      comment.importe_actual.toString().includes(search) 
    );
  }
  if (selectedFrecuencia) {
    computedComments = computedComments.filter((e) =>
      e.frecuencia.includes(selectedFrecuencia)
    );
  }
  if (selectedColaborador) {
    computedComments = computedComments.filter((e) =>
      e.idContacto.includes(selectedColaborador)
    );
  }

  if (selectedActivo) {
    computedComments = computedComments.filter((e) =>
      e.is_active.includes(selectedActivo)
    );
  }
  if (selectedFechaInicio) {
    computedComments = computedComments.filter(
      (e) => e.fecha_fin >= selectedFechaInicio
    );
  }

  if (selectedFechaFin) {
    computedComments = computedComments.filter(
      (e) => e.fecha_fin <= selectedFechaFin
    );
  }

  setTotalItems(computedComments.length);

  //Sorting comments
  if (
    sorting.field &&
    sorting.field != "importe_actual" 
  ) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computedComments = computedComments.sort(
      (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
  }

  if (
    sorting.field &&
    sorting.order === "asc" &&
    (sorting.field == "importe_actual" )
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  if (
    sorting.field &&
    sorting.order === "desc" &&
    (sorting.field == "importe_actual")
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  //Current Page slice
  return computedComments.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [
  comments,
  currentPage,
  search,
  sorting,
  selectedFrecuencia,
  selectedColaborador,
  selectedFechaInicio,
  selectedFechaFin,
  selectedActivo,
]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.donativos_recurrentes ? (
        <div className="card">
          <Row>
            <Col sm={8}>
              {user.donativos_recurrentes_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/DonativosRecurrentesCreate"
                >
                  Nuevo Donativo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Donativo
                </Button>
              )}
              {user.donativos_recurrentes_generar ? (
                <Button
                  size="sm"
                  onClick={toggleGenera}
                  className="btn btn-success"
                  id="botonListado"
                >
                  Generar Donativos
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Generar Donativos
                </Button>
              )}

              <Modal size="sm" isOpen={modalGenera} toggle={toggleGenera}>
                <ModalHeader toggle={toggleGenera}>
                  Concepto de Donativo
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    className="col-sm-12"
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                  <Label for="exampleEmail" className="mr-sm-12">
                    Frecuencia
                  </Label>
                  <Input
                    type="select"
                    placeholder="Frecuencia"
                    value={frecuencia}
                    required
                    onChange={(e) => {
                      setFrecuencia(e.target.value);
                    }}
                  >
                    <option value="0">Frecuencia</option>
                    <option value="Quincenal">Quincenal</option>
                    <option value="Mensual">Mensual</option>
                    <option value="Bimestral">Bimestral</option>
                    <option value="Trimestral">Trimestral</option>
                    <option value="Semestral">Semestral</option>
                    <option value="Anual">Anual</option>
                  </Input>
                  <Label className="mr-sm-2">Descripcion</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={descripcion}
                    required
                    onChange={(e) => {
                      setDescripcion(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    size="sm"
                    color="success"
                    onClick={generaDonativosRecurrentes}
                  >
                    Generar
                  </Button>
                </ModalFooter>
              </Modal>

              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />

              <Button
                size="sm"
                href="/MenuDonativos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Resumen Donativos Recurrentes</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Donativos Recurrentes</h3>
          <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
          <Row>
            <Col md={2}>
              <Label for="exampleEmail" className="mr-sm-12">
                Frecuencia
              </Label>
              <Input
                bsSize="sm"
                type="select"
                placeholder="Frecuencia"
                value={selectedFrecuencia}
                required
                onChange={(e) => {
                  setSelectedFrecuencia(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                <option value="Anual">Anual</option>
                <option value="Bimestral">Bimestral</option>
                <option value="Mensual">Mensual</option>
                <option value="Quincenal">Quincenal</option>
                <option value="Semestral">Semestral</option>
                <option value="Trimestral">Trimestral</option>
              </Input>
            </Col>
            <Col md={2}>
              <Label>Contacto</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value)
                  setCurrentPage(1)}
                }
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                    // }
                  })}
              </Input>
            </Col>

            {/* <Col md={2}>
              <Label>Concepto</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedConceptosDon}
                onChange={(e) => setSelectedConceptosDon(e.target.value)}
              >
                <option value="0">Selecciona</option>
                {conceptosDon
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                    return (
                      <option value={a._id}>
                        {a.name}
                      </option>
                    );
                    // }
                  })}
              </Input>
            </Col> */}

            <Col md={2}>
              <Label for="exampleEmail" className="mr-sm-12">
                Activo
              </Label>
              <Input
                bsSize="sm"
                type="select"
                placeholder="Activo"
                value={selectedActivo}
                required
                onChange={(e) => {
                  setSelectedActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </Col>
          </Row>
          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
          
            <tbody>
              { commentsData
                .map((c) => {
                    tablaImporte = tablaImporte + c.importe_actual;
                    cantidad = cantidad + 1;
                    return (
                      <tr>
                        <td>
                          {c.donador}
                        </td>
                        <td>{c.fecha_inicio}</td>
                        <td>{c.ultimo_aumento}</td>
                        <td>{c.fecha_fin}</td>
                        <td>{c.frecuencia}</td>
                        <td>{c.concepto}</td>
                        <td>
                          {c.contacto}
                        </td>
                        <td>
                          {c.colaborador}
                        </td>
                        <td align="center">
                          {"$" +
                            new Intl.NumberFormat("en-US").format(
                              c.importe_actual
                            )}
                        </td>
                        <td>
                          {user.donativos_recurrentes_create ? (
                            <div>
                              <Button
                                color="dany"
                                size="sm"
                                onClick={(e) => Info(c.idDonador)}
                                id="Detalle"
                              >
                                <i class="fas fa-file-alt"></i>
                              </Button>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaInfoEdit(c._id)}
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                              <Button
                                color="primary"
                                id="Aumento"
                                size="sm"
                                onClick={(e) => jalaInfoAumento(c._id)}
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                              <Baja
                                idStatus={c._id}
                                is_active={c.is_active}
                                URL_BAJA={
                                  process.env
                                    .REACT_APP_URL_DONATIVOS_RECURRENTES
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                              <Button
                                color="primary"
                                id="Aumento"
                                size="sm"
                                disabled
                              ></Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  Cantidad
                </td>
                <td className="negrita" align="center">
                  {cantidad}
                </td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita" align="center">
                  {"$" + new Intl.NumberFormat("en-US").format(tablaImporte)}
                </td>
                <td></td>
                </tr>
            </tbody>
          </Table>
        </div>
      ) : undefined}

      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Informacion del Donador</h4>{" "}
        </ModalHeader>
        <ModalBody>
          <DonadoresInfo idDonador={idInfo} />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4> Editar Donativo de {selectedDonadorName}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Donador</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedDonador}
                required
                onChange={(e) => {
                  setSelectedDonador(e.target.value);
                }}
              >
                <option value="0">Selecciona un Donador</option>
                {donadores.map((c) => {
                  return (
                    <option value={c._id}>
                      {c.nombre} {c.apellido}{" "}
                    </option>
                  );
                })}
              </Input>
            </Col>
            <Col md={6}>
              <Label>Fecha Inicio</Label>
              <Input
                type="date"
                value={fecha_inicio}
                required
                onChange={(e) => {
                  setFechaInicio(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Sala</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedSalasDon}
                required
                onChange={(e) => {
                  setSelectedSalasDon(e.target.value);
                }}
              >
                <option value="0">Selecciona un Sala</option>
                {salasDon.map((c) => {
                  return <option value={c._id}>{c.name}</option>;
                })}
              </Input>
            </Col>
            <Col md={6}>
              <Label className="mr-sm-2">Institucion</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedInstitucionesDon}
                required
                onChange={(e) => {
                  setSelectedInstitucionesDon(e.target.value);
                }}
              >
                <option value="0">Selecciona una Institucion</option>
                {institucionesDon.map((c) => {
                  return <option value={c._id}>{c.name}</option>;
                })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Evento</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedEventosDon}
                required
                onChange={(e) => {
                  setSelectedEventosDon(e.target.value);
                }}
              >
                <option value="0">Selecciona un Evento</option>
                {eventosDon.map((c) => {
                  return <option value={c._id}>{c.name}</option>;
                })}
              </Input>
            </Col>
            <Col md={6}>
              <Label className="mr-sm-2">Concepto</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedConceptosDon}
                required
                onChange={(e) => {
                  setSelectedConceptosDon(e.target.value);
                }}
              >
                <option value="0">Selecciona un Concepto</option>
                {conceptosDon.map((c) => {
                  return <option value={c._id}>{c.name}</option>;
                })}
              </Input>
            </Col>
          </Row>
          <Row>
          <Col md={6}>
                <Label>Fecha Fin</Label>
                <Input
                  type="date"
                  value={fecha_fin}
                  required
                  onChange={(e) => {
                    setFechaFin(e.target.value);
                  }}
                />
              </Col>
            <Col md={6}>
            <Label className="mr-sm-2">Descripcion</Label>
          <Input
            className="col-sm-12"
            type="text"
            placeholder="Descripcion"
            value={descripcionEdit}
            required
            onChange={(e) => {
              setDescripcionEdit(e.target.value);
            }}
          />
            </Col>
          </Row>
         
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editDonativoRecurrente}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalAumento} toggle={toggleAumento}>
        <ModalHeader toggle={toggleAumento}>
          <h4>Editar Donativo de {selectedDonadorName} </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label>
              Importe Anterior{" "}
              {"$" + new Intl.NumberFormat("en-US").format(importe_anterior)}{" "}
            </Label>
          </Row>
          <Row>
            <Label>Ultimo Aumento {ultimo_aumento}</Label>
          </Row>
          <Row>
            <Label className="rojoBold">
              Importe Actual{" "}
              {"$" + new Intl.NumberFormat("en-US").format(importe_actual)}{" "}
            </Label>
          </Row>

          <Row>
            <Label>Nuevo Importe</Label>
            <Input
              type="text"
              placeholder="Nuevo Importe"
              value={nuevo_importe}
              required
              onChange={(e) => {
                setNuevoImporte(e.target.value);
              }}
            />

            <Label>Fecha de Aumento</Label>
            <Input
              type="date"
              value={fecha_aumento}
              required
              onChange={(e) => {
                setFechaAumento(e.target.value);
              }}
            />

            <Label for="exampleEmail" className="mr-sm-12">
              Frecuencia
            </Label>
            <Input
              type="select"
              placeholder="Frecuencia"
              value={frecuenciaEdit}
              required
              onChange={(e) => {
                setFrecuenciaEdit(e.target.value);
              }}
            >
              <option value="0">Frecuencia</option>
              <option value="Quincenal">Quincenal</option>
              <option value="Mensual">Mensual</option>
              <option value="Bimestral">Bimestral</option>
              <option value="Trimestral">Trimestral</option>
              <option value="Semestral">Semestral</option>
              <option value="Anual">Anual</option>
            </Input>
          </Row>
          {frecuenciaEdit == "Semestral" ?(
              <Row>
            <Label for="exampleEmail" className="mr-sm-12">
                   Meses que se le cargara
                  </Label>
                  <Input
                    type="select"
                    placeholder="Mes1"
                    value={mes1}
                    required
                    onChange={(e) => {
                      setMes1(e.target.value);
                    }}
                  >
                    <option value="0">Mes 1</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </Input>

            <br/>
                  <Input
                    type="select"
                    placeholder="Mes2"
                    value={mes2}
                    required
                    onChange={(e) => {
                      setMes2(e.target.value);
                    }}
                  >
                    <option value="0">Mes 1</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </Input>
              </Row>
            ):undefined}

{frecuenciaEdit == "Anual" ?(
              <Row>
            <Label for="exampleEmail" className="mr-sm-12">
                   Mes que se le cargara
                  </Label>
                  <Input
                    type="select"
                    placeholder="Mes1"
                    value={mes1}
                    required
                    onChange={(e) => {
                      setMes1(e.target.value);
                    }}
                  >
                    <option value="0">Mes 1</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </Input>
              </Row>
            ):undefined}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={aumentoDonativoRecurrente}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
          {loader}
    </>
  );
}

export default ListadoDonativosRecurrentes;
