import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import axios from 'axios'

function FamiliasInfo(props) {
    const URL_FAMILIAS = `${process.env.REACT_APP_URL_FAMILIAS}/${props.idFamilias}`;
    const [familias, setFamilias] = useState([]);
  
    useEffect(() => {
      axios
        .get(URL_FAMILIAS, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allFamilias = response.data;
          setFamilias(allFamilias);
        })
        .catch((err) => {
          console.log(err);
        });
    },[]);

    return (
        <>
         <div className="content">
        <div className="card container">
           <Table size="sm" borderless>
             <tbody>
                <tr><td>Apellido Paterno</td><td>{familias.apellido_paterno}</td></tr>
                <tr><td>Apellido Materno</td><td>{familias.apellido_materno}</td></tr>
                <tr><td>Nombre Papa</td><td>{familias.nombre_papa}</td></tr>
                <tr><td>Nombre Mama</td><td>{familias.nombre_mama}</td></tr>
                <tr><td>Telefono Papa</td><td>{familias.telefono_papa}</td></tr>
                <tr><td>Telefono Mama</td><td>{familias.telefono_mama}</td></tr>
                <tr><td>Telefono Casa</td><td>{familias.telefono_casa}</td></tr>
                <tr><td>Email Papa</td><td>{familias.email_papa}</td></tr>
                <tr><td>Email Mama</td><td>{familias.email_mama}</td></tr>
                </tbody> 
                </Table>
            </div>
            </div>
        </>
    )
}

export default FamiliasInfo
