import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesTzedaka() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_donativos ? (
      <div className="container">
          <Row>
            <Col md={4} align="center">
              {user.menu_donadores ? (
                <Button
                  href="/Donadores"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Donadores
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Donadores
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.donativos_edo_cta ? (
                <Button
                  href="/Donativos"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice fa-7x"></i>
                  <br />
                  <br />
                  Estado de Cuenta
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice fa-7x"></i>
                  <br />
                  <br />
                  Estado de Cuenta
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.donativos_recurrentes ? (
                <Button
                  href="/DonativosRecurrentes"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="far fa-calendar-alt fa-7x"></i>
                  <br />
                  <br />
                  Donativos Recurrentes
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="far fa-calendar-alt fa-7x"></i>
                  <br />
                  <br />
                  Donativos Recurrentes
                </Button>
              )}
            </Col>
            </Row>
            <br />
            <Row>
              <Col md={4} align="center">
              {user.donativos_cargos ? (
                <Button
                  href="/DetalleDonativos"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Cargos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-file-invoice-dollar fa-7x"></i>
                  <br />
                  <br />
                  Cargos
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.donativos_abonos ? (
                <Button
                  href="/AbonosDonativos"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-donate fa-7x"></i>
                  <br />
                  <br />
                  Abonos
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.donativos_edo_cta ? (
                <Button
                  href="/ListadoCobranza"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="far fa-money-bill-alt fa-7x"></i>
                  <br />
                  <br />
                  Cobranza
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="far fa-money-bill-alt fa-7x"></i>
                  <br />
                  <br />
                  Cobranza
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
              {user.menu_catalogos ? (
                <Button
                  href="/CatalogosDonativos"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-folder-open fa-7x"></i>
                  <br />
                  <br />
                  Catalogos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-folder-open fa-7x"></i>
                  <br />
                  <br />
                  Catalogos
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.menu_donadores ? (
                <Button
                  href="/ListadoTareas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="far fa-calendar-check fa-7x"></i>
                  <br />
                  <br />
                 Tareas
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="far fa-calendar-check fa-7x"></i>
                  <br />
                  <br />
                 Tareas
                </Button>
              )}
            </Col>
          </Row>
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesTzedaka;
