import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

function PresupuestosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PRESUPUESTOS = process.env.REACT_APP_URL_PRESUPUESTOS;
  const URL_PERIODOS_GASTOS = process.env.REACT_APP_URL_PERIODOS_GASTOS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [importe, setImporte] = useState("");
  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setImporte("");
    setSelectedPeriodo([]);
    setSelectedDepartamento([]);
    setSelectedArea([]);
    setValidaBoton(true);
  };

  useEffect(() => {
    axios
      .get(URL_PERIODOS_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPeriodos = res.data;
        setPeriodos(allPeriodos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const savePresupuestos = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_PRESUPUESTOS,
        {
          importe,
          areas: selectedArea,
          periodosGastos: selectedPeriodo,
          departamentos: selectedDepartamento,
          utilizado: 0,
          disponible: importe,
          planteles: selectedPlantel,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Presupuesto",
            detalle: `Area: ${selectedArea} / Periodo: ${selectedPeriodo} / Departamento: ${selectedDepartamento}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.presupuestos_create ? (
        <div className="card container col-sm-4">
          <h3 align="center">Crea un nuevo Presupuesto</h3>
          <Form onSubmit={savePresupuestos}>
            <Row>
              <Col md={6}>
                <Label className="mr-sm-2">Periodo</Label>
                <Input
                  type="select"
                  value={selectedPeriodo}
                  required
                  onChange={(e) => {
                    setSelectedPeriodo(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Periodo</option>
                  {periodos
                    .sort((a, b) => (a.fecha_inicio < b.fecha_inicio ? 1 : -1))
                    .map((c) => {
                      return (
                        <option value={c._id}>
                          {c.name} Ciclo {c.ciclos[0].name}
                        </option>
                      );
                    })}
                </Input>
              </Col>
              {user.planteles == PLANTELES_GENERAL ? (
                <Col md={6}>
                  <Label className="mr-sm-2">Planteles</Label>
                  <Input
                    type="select"
                    value={selectedPlantel}
                    onChange={(e) => setSelectedPlantel(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {planteles
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (a._id != PLANTELES_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              ) : (
                <Col md={6}>
                  <Label className="mr-sm-2">Planteles</Label>
                  <Input
                    type="select"
                    value={selectedPlantel}
                    onChange={(e) => setSelectedPlantel(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {planteles
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (a._id == user.planteles) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              )}
            </Row>
            <Row>
              {user.areas == AREAS_GENERAL ? (
                <Col md={6}>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    required
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (AREAS_GENERAL != a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              ) : (
                <Col md={6}>
                  <Label className="mr-sm-2">Areas</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    required
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {areas
                      .sort((a, b) => a.name - b.name)
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>
              )}
              <Col md={6}>
                <Label className="mr-sm-2">Departamento</Label>
                <Input
                  type="select"
                  value={selectedDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Departamento</option>
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((d) => {
                      return <option value={d._id}>{d.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Importe
                </Label>
                <Input
                  type="text"
                  placeholder="Importe"
                  value={importe}
                  required
                  onChange={(e) => {
                    setImporte(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Col>
              <Row>
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    // text="SweetAlert in React"
                    onConfirm={() => {
                      setText(false);
                      window.location.href = "/Presupuestos";
                    }}
                  />
                </div>
                <Button
                  href="/Presupuestos"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Col>
          </Form>
        </div>
      ) : undefined}
      
    </>
  );
}

export default PresupuestosCreate;
