import React from 'react'

function TablaCartera(props) {
  return (
    <>
      <td>{props.fecha}</td>
      <td>{props.movimiento}</td>
      <td>{props.name}</td>
      <td>{props.plantel}</td>
      <td>{props.area}</td>
      <td align="right">{"$" + new Intl.NumberFormat("en-US").format(props.importe)}</td>
    </>
  );
}

export default TablaCartera
