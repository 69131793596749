import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";


am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

function GraficasIngresosBarras(props) {
  const { user } = useContext(AuthContext);
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const URL_ABONOS_DONATIVOS = process.env.REACT_APP_URL_ABONOS_DONATIVOS;
  const URL_ABONOS_COLEGIATURAS = process.env.REACT_APP_URL_ABONOS_COLEGIATURAS;
  const URL_ABONOS_INSCRIPCIONES =
    process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
    const [donativos, setDonativos] = useState([]);
  const [colegiaturas, setColegiaturas] = useState([]);
  const [inscripciones, setInscripciones] = useState([]);

  useEffect(() => {
    axios
      .get(URL_ABONOS_DONATIVOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDonativos = response.data;
        setDonativos(allDonativos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_COLEGIATURAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColegiaturas = response.data;
        setColegiaturas(allColegiaturas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_INSCRIPCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInscripciones = response.data;
        setInscripciones(allInscripciones);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

//Array Colegiaturas
const arrayColegiaturas = colegiaturas
.sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
.map((n) => {
  if (user.planteles == PLANTELES_GENERAL &&
    user.areas == AREAS_GENERAL){
if(
  (props.selectedPlantel == 0 ||
    props.selectedPlantel == n.planteles[0]._id) &&
  (props.selectedArea == 0 ||
    props.selectedArea == n.areas[0]._id) &&
  (props.fechaInicio == 0 ||
    props.fechaInicio <= n.fecha) &&
  (props.fechaFin == 0 ||
    props.fechaFin >= n.fecha)){
  return {
      date: n.fecha,
      name: n.familias[0].codigo_familia,
      Colegiaturas: n.importe,
      Inscripciones:0,
      Donativos:0
  }}
  else return null} else{ if(
    (user.planteles == n.planteles[0]._id) &&
    (user.areas == n.areas[0]._id) &&
    (props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha)
  ){
    return {
      date: n.fecha,
      name: n.familias[0].codigo_familia,
      Colegiaturas: n.importe,
      Inscripciones:0,
      Donativos:0
    }} else return null}
  })
  .filter(function (el) {
    return el != null;
  });

//Array Inscripciones
  const arrayInscripciones = inscripciones
  .sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
  .map((n) => {
    if (user.planteles == PLANTELES_GENERAL &&
      user.areas == AREAS_GENERAL){
    if(
      (props.selectedPlantel == 0 ||
        props.selectedPlantel == n.planteles[0]._id) &&
      (props.selectedArea == 0 ||
        props.selectedArea == n.areas[0]._id) &&
      (props.fechaInicio == 0 ||
        props.fechaInicio <= n.fecha) &&
      (props.fechaFin == 0 ||
        props.fechaFin >= n.fecha)){
    return {
      date: n.fecha,
      name: n.familias[0].codigo_familia,
      Inscripciones: n.importe,
      Colegiaturas:0,
      Donativos:0
    }}
    else return null} else{ if(
      (user.planteles == n.planteles[0]._id) &&
      (user.areas == n.areas[0]._id) &&
      (props.fechaInicio == 0 ||
        props.fechaInicio <= n.fecha) &&
      (props.fechaFin == 0 ||
        props.fechaFin >= n.fecha)
    ){
      return {
        date: n.fecha,
        name: n.familias[0].codigo_familia,
        Inscripciones: n.importe,
        Colegiaturas:0,
        Donativos:0
      }} else return null}
    })
    .filter(function (el) {
      return el != null;
    });

//Array Donativos
  const arrayDonativos = donativos
  .sort((a, b) =>a.fecha > b.fecha ? 1 : -1)
  .map((n) => {
    if((props.fechaInicio == 0 ||
      props.fechaInicio <= n.fecha) &&
    (props.fechaFin == 0 ||
      props.fechaFin >= n.fecha)){
    return {
      date: n.fecha,
      name: n.donadores[0].nombre,
      Donativos: n.importe,
      Colegiaturas:0,
      Inscripciones:0
    }}
  })
  .filter(function (el) {
    return el != null;
  });

  const groupByDonativos = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop].substring(0,7);
      groups[val] = groups[val] || {
        date: item.date,
        Donativos: 0,
      };
      groups[val].Donativos += item.Donativos;
      groups[val].date = item.date.substring(0,7)+'-01';
      groups[val].Colegiaturas = item.Colegiaturas
      groups[val].Inscripciones = item.Inscripciones
      return groups;
    }, []);
  };

  const groupByColegiaturas = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop].substring(0,7);
      groups[val] = groups[val] || {
        date: item.date,
        Colegiaturas: 0,
      };
      groups[val].Colegiaturas += item.Colegiaturas;
      groups[val].date = item.date.substring(0,7)+'-01';
      groups[val].Inscripciones = item.Inscripciones
      groups[val].Donativos = item.Donativos
      return groups;
    }, []);
  };

  const groupByInscripciones = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop].substring(0,7);
      groups[val] = groups[val] || {
        date: item.date,
        Inscripciones: 0,
      };
      groups[val].Inscripciones += item.Inscripciones;
      groups[val].date = item.date.substring(0,7)+'-01';
      groups[val].Donativos = item.Donativos
      groups[val].Colegiaturas = item.Colegiaturas
      return groups;
    }, []);
  };

  const groupBy = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop].substring(0,7);
      groups[val] = groups[val] || {
        date: item.date,
        Donativos: 0,
        Colegiaturas:0,
        Inscripciones:0,
      };
      groups[val].Donativos += item.Donativos;
      groups[val].Colegiaturas += item.Colegiaturas;
      groups[val].Inscripciones += item.Inscripciones;
      groups[val].date = item.date;
      return groups;
    }, []);
  };

  const colegiaturasAgrupado = groupByColegiaturas(arrayColegiaturas, "date");
  const dataFinalColegiaturas = Object.values(colegiaturasAgrupado);
  const InscripcionesAgrupado = groupByInscripciones(arrayInscripciones, "date");
  const dataFinalInscripciones = Object.values(InscripcionesAgrupado);
  const DonativosAgrupado = groupByDonativos(arrayDonativos, "date");
  const dataFinalDonativos = Object.values(DonativosAgrupado);

  //Juntamos Arrays
  const arrayIngresos = dataFinalColegiaturas.concat(
    dataFinalInscripciones,
    dataFinalDonativos
  )
  .sort((a, b) =>a.date > b.date ? 1 : -1)
  const arrayFinalIngresos = arrayIngresos.filter(function (el) {
    return el != null;
  });

  const ingresosAgrupado = groupBy(arrayFinalIngresos, "date");
  const dataFinal = Object.values(ingresosAgrupado);

let chart = am4core.create("chartdivBarras", am4charts.XYChart3D);

chart.data = dataFinal


// Create axes
let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

// Set input format for the dates
dateAxis.periodChangeDateFormats.setKey("month", "[bold]MMM yy");
dateAxis.dateFormats.setKey("month", "MMM yy")


dateAxis.dataFields.date = "date";
dateAxis.title.text = "Tipos de Ingreso";
dateAxis.renderer.grid.template.location = 0;
dateAxis.renderer.minGridDistance = 20;
dateAxis.renderer.cellStartLocation = 0.1;
dateAxis.renderer.cellEndLocation = 0.9;

let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = "Importe MXN";

// Create series
function createSeries(field, name, stacked) {
  let series = chart.series.push(new am4charts.ColumnSeries3D());
  series.dataFields.valueY = field;
  series.dataFields.dateX = "date";
  series.name = name;
  series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
  series.stacked = stacked;
  series.columns.template.width = am4core.percent(95);
}

createSeries("Colegiaturas", "Colegiaturas", false);
createSeries("Donativos", "Donativos", true);
createSeries("Inscripciones", "Inscripciones", true);

// Add legend
chart.legend = new am4charts.Legend();
    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.filePrefix = "myExport";

  return (
  <>
           <div id="chartdivBarras" style={{ width: "100%", height: "500px", paddingTop:"0px" }}></div> 
  </>
  )
}

export default GraficasIngresosBarras;
