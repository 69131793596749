import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TablaInscripciones from "./TablaInscripciones";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel"
import { encode } from "base64-arraybuffer";

function ListadoInscripciones() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CARGOS_INSCRIPCIONES = process.env.REACT_APP_URL_CARGOS_INSCRIPCIONES;
  const URL_ALUMNOS = process.env.REACT_APP_URL_ALUMNOS;
  const URL_ABONOS_INSCRIPCIONES = process.env.REACT_APP_URL_ABONOS_INSCRIPCIONES;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EDO_CTA_FAMILIAS = process.env.REACT_APP_URL_EDO_CTA_FAMILIAS;
  const [abonosInscripciones, setAbonosInscripciones] = useState([]);
  const [cargosInscripciones, setCargosInscripciones] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [fecha, setFecha] = useState("");
  const [concepto, setConcepto] = useState("");
  const [alumnos, setAlumnos] = useState([]);
  const [text, setText] = useState(false);
  const [familias, setFamilias] = useState([]);
  const [importe, setImporte] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedAlumno, setSelectedAlumno] = useState("");
  // const [selectedPlantel, setSelectedPlantel] = useState("");
  // const [selectedArea, setSelectedArea] = useState("");
  const [modalCE, setModalCE] = useState(false);
  const toggleCE = () => setModalCE(!modalCE);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState('');
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useEffect(() => {
    axios
      .get(URL_CARGOS_INSCRIPCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosInscripciones = response.data;
        setCargosInscripciones(allCargosInscripciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ALUMNOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAlumnos = response.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_INSCRIPCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonosInscripciones = response.data;
        setAbonosInscripciones(allAbonosInscripciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFamilias = response.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Arrray Abonos
  const arrayAbonosInscripciones = abonosInscripciones
    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
    .map((n) => {
      return {
        date: n.fecha,
        idFamilia: n.familias[0]._id,
        codigo_familia: n.familias[0].codigo_familia,
        apellido_paterno: n.familias[0].apellido_paterno,
        apellido_materno: n.familias[0].apellido_materno,
        email_papa: n.familias[0].email_papa,
        email_mama: n.familias[0].email_mama,
        abonos: n.importe,
        cargos: 0,
        saldo: -n.importe,
      };
    });

  //   Array Cargos
  const arrayCargosInscripciones = cargosInscripciones
    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
    .map((n) => {
      return {
        date: n.fecha,
        idFamilia: n.familias[0]._id,
        codigo_familia: n.familias[0].codigo_familia,
        apellido_paterno: n.familias[0].apellido_paterno,
        apellido_materno: n.familias[0].apellido_materno,
        email_papa: n.familias[0].email_papa,
        email_mama: n.familias[0].email_mama,
        cargos: n.importe,
        abonos: 0,
        saldo: n.importe,
      };
    });

  //Juntamos Arrays
  const arrayInscripciones = arrayCargosInscripciones.concat(
    arrayAbonosInscripciones
  );

  //   Agrupar abonos y cargos por familia
  const groupByInscripciones = function (miarray, prop) {
    return miarray.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || {
        codigo_familia: item.codigo_familia,
        cargos: 0,
        abonos: 0,
        saldo: 0,
      };
      groups[val].cargos += item.cargos;
      groups[val].abonos += item.abonos;
      groups[val].saldo += item.saldo;
      groups[val].idFamilia = item.idFamilia;
      groups[val].codigo_familia = item.codigo_familia;
      groups[val].apellido_paterno = item.apellido_paterno;
      groups[val].apellido_materno = item.apellido_materno;
      groups[val].email_papa = item.email_papa;
      groups[val].email_mama = item.email_mama;
      return groups;
    }, []);
  };

  const inscripcionesAgrupado = groupByInscripciones(
    arrayInscripciones,
    "codigo_familia"
  );
  const dataFinalInscripciones = Object.values(inscripcionesAgrupado);

  let cargo;

  function calcula(a) {
    if (a.beca_porcentaje_ins == 0) {
      return (cargo = a.clases[0].grados[0].inscripcion - a.beca_importe_ins);
    } else {
      return (cargo =
        a.clases[0].grados[0].inscripcion -
        (a.clases[0].grados[0].inscripcion * a.beca_porcentaje_ins) / 100);
    }
  }

  function generaInscripcion() {
    toggle();
    Swal.fire({
      title: "Estas Seguro?",
      text: "Se generara un cargo a cada alumno",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, generar cargo",
    }).then((result) => {
      if (result.value) {
        toggleProgreso();
        let totalGenerarCargos = alumnos.length;
        alumnos.map((a) => {
          calcula(a);
          if (a.is_active == "Si") {
            axios
              .post(
                URL_CARGOS_INSCRIPCIONES,
                {
                  fecha: fecha,
                  importe: cargo,
                  alumnos: a._id,
                  familias: a.familias[0]._id,
                  concepto: concepto,
                  planteles: a.clases[0].planteles[0]._id,
                  areas: a.clases[0].grados[0].areas[0]._id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalGenerarCargos = totalGenerarCargos - 1;
                if (totalGenerarCargos == 0) {
               
               //Poner el email masivo
               let totalMails = familias.length;
               familias.map((a) => {
                 if (a.is_active == "Si") {
                   axios
                     .post(
                       URL_EDO_CTA_FAMILIAS,
                       {
                         familias: a._id,
                       },
                       {
                         headers: {
                           Authorization: `Bearer: ${localStorage.getItem(
                             "app_token"
                           )}`,
                         },
                       }
                     )
                     .then(() => {
                       totalMails = totalMails - 1;
                       if (totalMails == 0) {
                        axios.post(
                              URL_LOGS,
                              {
                                tipo: "Generar Inscripcion",
                                detalle: `Fecha: ${fecha} / Concepto: ${concepto}`,
                                user: user.id,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then(()=>{
                              Swal.fire("Good job!", "Creado con exito", "success");
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000);

                            })
                       }
                     })
                     .catch((error) => {
                       Swal.fire({
                         icon: "error",
                         title: "Oops...",
                         text: "Something went wrong!",
                         footer: `${error.response.data}`,
                       });
                       console.log(error);
                     });
                 } else {
                   totalMails = totalMails - 1;
                 }
               });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalGenerarCargos = totalGenerarCargos - 1;
          }
        });
      }
    });
  }

  let totalCargos = 0;
  let totalAbonos = 0;
  let totalSaldo = 0;

  let tablaCargos = 0;
  let tablaAbonos = 0;
  let tablaSaldo = 0;

  function PDFTabla() {
    const data = dataFinalInscripciones.map((a) => {
      const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
      const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
      const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
      const apellidos = `${a.apellido_paterno} ${a.apellido_materno}`;
      totalCargos = totalCargos + a.cargos;
      totalAbonos = totalAbonos + a.abonos;
      totalSaldo = totalSaldo + a.saldo;
      return [a.codigo_familia, apellidos, cargo, abono, saldos];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Inscripciones`, 20, 25);
    doc.autoTable({
      head: [["Codigo Fam", "Apellidos", "Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Inscripciones.pdf`);
  }

  function excel(){
    const dataExcel = dataFinalInscripciones.map((a) => {
      return {CodigoFam:a.codigo_familia, Apellidos:a.apellido_paterno + " " + a.apellido_materno, Cargos:a.cargos, Abonos:-a.abonos, Saldo:a.saldo};
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};
 
option.fileName = "ListadoInscripciones";
 
option.datas = [
  {
    sheetData: dataExcelLimpia,
    sheetName: "ListadoInscripciones",
    sheetFilter: ["CodigoFam", "Apellidos", "Cargos", "Abonos", "Saldo"],
    sheetHeader: ["CodigoFam", "Apellidos", "Cargos", "Abonos", "Saldo"],
  },
];
 
var toExcel = new ExportJsonExcel(option); 
toExcel.saveExcel(); 
  }

  const cargoEspecial = (event) => {
    event.preventDefault();
    axios
    .get(`${URL_ALUMNOS}/${selectedAlumno}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let alumnoInfo = response.data;
      let selectedPlantel = alumnoInfo.clases[0].planteles[0]._id
      let selectedArea = alumnoInfo.clases[0].grados[0].areas[0]._id
      axios
      .post(
        URL_CARGOS_INSCRIPCIONES,
        {
          fecha,
          importe,
          alumnos: selectedAlumno,
          familias: selectedFamilia,
          concepto,
          planteles: selectedPlantel,
          areas: selectedArea
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    })
    .catch((err) => {
      console.log(err);
    });    
  };

  const selectFamilia = (e) => {
    setSelectedFamilia(e.target.value);
    const URL_ALUMNOS_FAM = `${process.env.REACT_APP_URL_ALUMNOS}/familia/${selectedFamilia}`;
    axios
      .get(URL_ALUMNOS_FAM, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAlumnos = response.data;
        setAlumnos(allAlumnos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function enviaMail(){
    const data = dataFinalInscripciones.map((a) => {
      const cargo = "$" + new Intl.NumberFormat("en-US").format(a.cargos);
      const abono = "-$" + new Intl.NumberFormat("en-US").format(a.abonos);
      const saldos = "$" + new Intl.NumberFormat("en-US").format(a.saldo);
      const apellidos = `${a.apellido_paterno} ${a.apellido_materno}`;
      totalCargos = totalCargos + a.cargos;
      totalAbonos = totalAbonos + a.abonos;
      totalSaldo = totalSaldo + a.saldo;
      return [a.codigo_familia, apellidos, cargo, abono, saldos];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Inscripciones`, 20, 25);
    doc.autoTable({
      head: [["Codigo Fam", "Apellidos", "Cargos", "Abonos", "Saldo"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalCargos),
          "-$" + new Intl.NumberFormat("en-US").format(totalAbonos),
          "$" + new Intl.NumberFormat("en-US").format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output('arraybuffer');
    var base64File = encode.encode(att, 'base64')

     // Envia el Mail
     axios
     .post(
       URL_SEND_MAIL_ATT,
       {
         subject:'Resumen Inscripciones',
         email: mailTo,
         fileName: 'ResumenInscripciones.pdf',
         att: base64File,
         text: `<!DOCTYPE html>
         <html>
           <head>
             <style>                                
               .img-container {
                               display: block;
                               margin-left: auto;
                               margin-right: auto;
                               height: 90px;
                               width: auto;
                             }
             </style>
           </head>
           <body>
             <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
             <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
             <h3>Hola,</h3>
             <h3>Adjunto encontraras el resumen de inscripciones de La Academia YKTT.</h3>
             <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
             Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
           </body>
         </html>`
       },
       {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       }
     )
     .then(() => {
      toggleMail()
       Swal.fire("Good job!", "Enviado con exito", "success");
     })
     .catch((error) => {
       Swal.fire({
         icon: "error",
         title: "Oops...",
         text: "Something went wrong!",
         footer: `${error.response.data}`,
       });
       console.log(error);
     })
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card container">
      {user.menu_inscripciones ?(
            <div className="card col-12">
                <Row>
                  <Col sm={8}>
                  {user.inscripciones_create ?(
                    <Button
                      size="sm"
                      onClick={toggle}
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Generar Inscripcion
                    </Button>
                  ):(
                    <Button
                      size="sm"
                      disabled
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Generar Inscripcion
                    </Button>
                  )}

                    <Modal size="sm" isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle}><h4>
                        Concepto de Inscripcion</h4>
                      </ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Fecha</Label>
                        <Input
                          className="col-sm-12"
                          type="date"
                          value={fecha}
                          required
                          onChange={(e) => {
                            setFecha(e.target.value);
                          }}
                        />
                        <Label className="mr-sm-2">Concepto</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={concepto}
                          required
                          onChange={(e) => {
                            setConcepto(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={generaInscripcion}
                        >
                          Generar
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                    {user.inscripciones_cargos_esp ?(
                    <Button
                      size="sm"
                      onClick={toggleCE}
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Cargos Especiales
                    </Button>
                    ):(
                      <Button
                      size="sm"
                      disabled
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Cargos Especiales
                    </Button>
                    )}

                    <Modal size="sm" isOpen={modalCE} toggle={toggleCE}>
                      <ModalHeader toggle={toggleCE}>
                        Cargos Especiales
                      </ModalHeader>
                      <ModalBody>
                        <Input
                          className="col-sm-12"
                          type="select"
                          value={selectedFamilia}
                          required
                          onBlur={selectFamilia}
                          onChange={selectFamilia}
                        >
                          <option value="0">Selecciona una Familia</option>
                          {familias
                          .sort((a, b) => (a.apellido_paterno > b.apellido_paterno ? 1 : -1))
                          .map((c) => {
                            return (
                              <option value={c._id}>
                                {c.codigo_familia} {c.apellido_paterno}{" "}
                                {c.apellido_materno}
                              </option>
                            );
                          })}
                        </Input>
                        <Label className="mr-sm-2">Alumno</Label>
                        <Input
                          className="col-sm-12"
                          type="select"
                          value={selectedAlumno}
                          required
                          onChange={(e) => {
                            setSelectedAlumno(e.target.value);
                          }}
                        >
                          <option value="0">Selecciona un Alumno</option>
                          {alumnos.map((a) => {
                            return <option value={a._id}>{a.name + " " + a.clases[0].name + " " + a.clases[0].grados[0].name}</option>;
                          })}
                        </Input>
                        <Label>Importe</Label>
                        <Input
                          className="col-sm-12"
                          type="number"
                          placeholder="Importe"
                          value={importe}
                          required
                          onChange={(e) => {
                            setImporte(e.target.value);
                          }}
                        />

                        <Label className="mr-sm-2">Fecha</Label>
                        <Input
                          className="col-sm-12"
                          type="date"
                          value={fecha}
                          required
                          onChange={(e) => {
                            setFecha(e.target.value);
                          }}
                        />
                        <Label className="mr-sm-2">Concepto</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={concepto}
                          required
                          onChange={(e) => {
                            setConcepto(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={cargoEspecial}
                        >
                          Generar Cargo
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />

                    {user.inscripciones_abonos ?(
                    <Button
                      size="sm"
                      href="/AbonosInscripciones"
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Capturar Abono
                    </Button>
                    ):(
                      <Button
                      size="sm"
                      disabled
                      className="btn btn-success"
                      id="botonListado"
                    >
                      Capturar Abono
                    </Button>
                    )}
                    <Button
                      size="sm"
                      href="/MenuCobranza"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button size="sm" className="btn" color="info" onClick={toggleMail}>
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                      <ModalHeader toggle={toggleMail}><h4>Enviar Resumen de Inscripciones</h4></ModalHeader>
                      <ModalBody>
                        <Label className="mr-sm-2">Email</Label>
                        <Input
                          className="col-sm-12"
                          type="text"
                          value={mailTo}
                          required
                          onChange={(e) => {
                            setMailTo(e.target.value);
                          }}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="success"
                          onClick={enviaMail}
                        >
                          Enviar
                        </Button>
                      </ModalFooter>
                    </Modal>
                <br />
                <h3 align="center">Inscripciones</h3>

                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr align="center">
                      <th align="left">Cod. Fam.</th>
                      <th>Apellido P.</th>
                      <th>Apellido M.</th>
                      <th>Cargos</th>
                      <th>Abonos</th>
                      <th>Saldo</th>
                      <th>Edo Cta</th>
                      <th>Contacto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFinalInscripciones
                      .sort((a, b) =>
                        a.codigo_familia > b.codigo_familia ? 1 : -1
                      )
                      .map((c) => {
                        tablaCargos = tablaCargos + c.cargos;
                        tablaAbonos = tablaAbonos + c.abonos;
                        tablaSaldo = tablaSaldo + c.saldo;
                        return (
                          <tr>
                            <TablaInscripciones
                              codigoFamilia={c.codigo_familia}
                              apellidoP={c.apellido_paterno}
                              apellidoM={c.apellido_materno}
                              cargos={c.cargos}
                              abonos={c.abonos}
                              saldo={c.saldo}
                              idFamilia={c.idFamilia}
                              emailPapa={c.email_papa}
                              emailMama={c.email_mama}
                            />
                          </tr>
                        );
                      })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTALES
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaCargos)}
                      </td>
                      <td className="negrita" align="center">
                        {"-$" +
                          new Intl.NumberFormat("en-US").format(tablaAbonos)}
                      </td>
                      <td className="negrita" align="center">
                        {"$" +
                          new Intl.NumberFormat("en-US").format(tablaSaldo)}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
          </div>
          ): undefined } 
      </div>
    </>
  );
}

export default ListadoInscripciones;
